import { GetConsentStatusResponseBodyPurposesItemDTO } from '@ketch-com/figurehead'
import { ConfigurationV2 } from '@ketch-sdk/ketch-types'
import { useDeploymentPlansPaginated } from 'api/deploymentPlans-v2/queries/useDeploymentPlansPaginated'
import { usePreviewConfig } from 'api/experiences-v2/queries/usePreviewConfig'
import { cloneDeep } from 'lodash'
import { ThemeType } from 'pages/consentAndRights/themes-v3/upsert/utils/enums'
import { useMemo } from 'react'

export type HeaderData = {
  experienceType: ThemeType
  language: string
  jurisdiction: string
  region: string
  privacyPolicyVersion: string
}

export type ConsentContextData = {
  permitTime: string
  consents: GetConsentStatusResponseBodyPurposesItemDTO[]
  previewConfig: ConfigurationV2 | null
  headerData: HeaderData
}

/**
 * Returns mock data for use in the virgin Australia demo
 * @param consents - Consents to be used in the mock data
 * @returns Consent context data
 */
export const useAuditLogConsentContextMockData = (
  consents: GetConsentStatusResponseBodyPurposesItemDTO[],
): ConsentContextData[] => {
  const { data: deploymentPlans } = useDeploymentPlansPaginated({
    params: {
      includeIssues: true,
      includeMetadata: true,
    },
  })

  const permitDeploymentId =
    useMemo(() => {
      const virginAusDeploymentPlan = deploymentPlans?.find(experience => experience.ID === '2uaH1Gv38A0MkbuQ7fSbTl')
      return virginAusDeploymentPlan || deploymentPlans.length ? deploymentPlans[0] : null
    }, [deploymentPlans])?.ID || ''

  const permitPreviewConfig = usePreviewConfig({
    params: { deploymentID: permitDeploymentId },
    enabled: !!permitDeploymentId,
  }).data

  // Below modifies the config for each permit
  const permitConfig = cloneDeep(permitPreviewConfig)
  if (permitConfig?.experiences?.autoInitiated?.content?.banner?.header) {
    permitConfig.experiences.autoInitiated.content.banner.header.title = 'Privacy Preferences'
  }

  return [
    {
      permitTime: 'latest',
      previewConfig: permitConfig,
      consents,
      headerData: {
        experienceType: ThemeType.Preference,
        language: 'English',
        jurisdiction: 'Australia',
        region: 'AU',
        privacyPolicyVersion: '1.0',
      },
    },
    {
      permitTime: new Date(new Date().getTime() - 5 * 60 * 1000).toISOString(),
      previewConfig: permitConfig,
      // Custom for virgin australia demo - all false except essential services
      consents: consents.map(consent => ({
        ...consent,
        allowed:
          (
            {
              essential_services: true,
              analytics: true,
              personalisation: true,
              targeted_advertising: true,
              search_recs: true,
              trip_recommendations: false,
              velocity_partner_offers: true,
              privacy_policy: true,
            } as Record<string, boolean>
          )[consent.code || ''] || false,
      })),
      headerData: {
        experienceType: ThemeType.Preference,
        language: 'English',
        jurisdiction: 'Australia',
        region: 'AU',
        privacyPolicyVersion: '1.0',
      },
    },
    {
      permitTime: new Date(new Date().getTime() - 7 * 60 * 1000).toISOString(),
      previewConfig: permitConfig,
      // Custom for virgin australia demo - all false except essential services
      consents: consents.map(consent => ({
        ...consent,
        allowed:
          (
            {
              essential_services: true,
              analytics: true,
              personalisation: true,
              targeted_advertising: true,
              search_recs: false,
              trip_recommendations: false,
              velocity_partner_offers: true,
              privacy_policy: true,
            } as Record<string, boolean>
          )[consent.code || ''] || false,
      })),
      headerData: {
        experienceType: ThemeType.Preference,
        language: 'English',
        jurisdiction: 'Australia',
        region: 'AU',
        privacyPolicyVersion: '1.0',
      },
    },
    {
      permitTime: new Date(new Date().getTime() - 7.1 * 60 * 1000).toISOString(),
      previewConfig: permitConfig,
      // Custom purpose settings for virgin australia demo
      consents: consents.map(consent => ({
        ...consent,
        allowed:
          (
            {
              essential_services: true,
              analytics: true,
              personalisation: true,
              targeted_advertising: true,
              search_recs: false,
              trip_recommendations: false,
              velocity_partner_offers: false,
              privacy_policy: false,
            } as Record<string, boolean>
          )[consent.code || ''] || false,
      })),
      headerData: {
        experienceType: ThemeType.Modal,
        language: 'English',
        jurisdiction: 'Australia',
        region: 'AU',
        privacyPolicyVersion: '1.0',
      },
    },
    {
      permitTime: new Date(new Date().getTime() - 11 * 60 * 1000).toISOString(),
      previewConfig: permitConfig,
      // Custom purpose settings for virgin australia demo
      consents: consents.map(consent => ({
        ...consent,
        allowed:
          (
            {
              essential_services: true,
              analytics: true,
              personalisation: true,
              targeted_advertising: false,
              search_recs: false,
              trip_recommendations: false,
              velocity_partner_offers: false,
              privacy_policy: false,
            } as Record<string, boolean>
          )[consent.code || ''] || false,
      })),
      headerData: {
        experienceType: ThemeType.Banner,
        language: 'English',
        jurisdiction: 'Australia',
        region: 'AU',
        privacyPolicyVersion: '1.0',
      },
    },
    {
      permitTime: new Date(
        new Date().setDate(new Date().getDate() - 1) - 3 * 60 * 60 * 1000 - 40 * 60 * 1000,
      ).toISOString(),
      previewConfig: permitConfig,
      // Custom purpose settings for virgin australia demo
      consents: consents.map(consent => ({
        ...consent,
        allowed:
          (
            {
              essential_services: false,
              analytics: false,
              personalisation: false,
              targeted_advertising: false,
              search_recs: false,
              trip_recommendations: false,
              velocity_partner_offers: false,
              privacy_policy: false,
            } as Record<string, boolean>
          )[consent.code || ''] || false,
      })),
      headerData: {
        experienceType: ThemeType.Banner,
        language: 'English',
        jurisdiction: 'Australia',
        region: 'AU',
        privacyPolicyVersion: '1.0',
      },
    },
  ]
}
