import { ProcessingActivityDTO } from '@ketch-com/figurehead'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useInfiniteQuery } from 'react-query'
import { fetchProcessingActivities } from '../fetchers/fetchProcessingActivities'
import { APIListRequestParams } from '../../common/utils'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'
import { useFeatureFlag } from 'utils/hooks'

const defaultLimit = 30

type Params = APIListRequestParams<{
  customLimit?: number
  includeDeleted?: boolean
  ordering?: string[]
  types?: string[]
  statuses?: string[]
  dataSystemIds?: string[]
  dataCategoryCodes?: string[]
  purposeIds?: string[]
  purposeCodes?: string[]
  businessFunctionCodes?: string[]
  residencyCountryCodes?: string[]
  securityMeasures?: number[]
}>

export const useInfiniteProcessingActivities = ({
  customLimit,
  includeMetadata = false,
  includeDeleted,
  ordering,
  types,
  statuses,
  dataSystemIds,
  dataCategoryCodes,
  purposeIds,
  purposeCodes,
  businessFunctionCodes,
  residencyCountryCodes,
  securityMeasures,
  query,
}: Params) => {
  const limit = customLimit || defaultLimit
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isV2FeatureFlagEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.ASSESSMENT,
    FEATURE_FLAGS_VALUES.ASSESSMENTS_VERSION,
  )

  const resp = useInfiniteQuery({
    queryKey: [ApiQueryKeys.processingActivitiesInfinite],
    queryFn: async ({ pageParam = 1 }) => {
      const fetchProcessingActivitiesResult = await fetchProcessingActivities({
        limit,
        start: (pageParam - 1) * limit,
        includeMetadata,
        includeDeleted,
        ordering,
        types,
        statuses,
        dataSystemIds,
        dataCategoryCodes,
        purposeIds,
        purposeCodes,
        businessFunctionCodes,
        residencyCountryCodes,
        securityMeasures,
        query,
        isV2FeatureFlagEnabled,
      })

      return {
        processingActivities: fetchProcessingActivitiesResult.data?.processingActivities ?? [],
        totalResults: fetchProcessingActivitiesResult.data?.totalResults ?? 0,
      }
    },
    getNextPageParam: ({ totalResults }, allPages) => {
      let totalItemsFetched = 0
      allPages?.forEach?.(pageOfResults => {
        totalItemsFetched += pageOfResults?.processingActivities?.length || 0
      })
      if (totalItemsFetched >= totalResults) {
        return undefined
      }
      const nextPageParam = Math.ceil(totalItemsFetched / limit) + 1

      return nextPageParam
    },
  })

  let infiniteProcessingActivities: ProcessingActivityDTO[] = []

  let totalResults = 0

  resp.data?.pages?.forEach?.(page => {
    const newProcessingActivities = page?.processingActivities
    infiniteProcessingActivities = [...infiniteProcessingActivities, ...newProcessingActivities]
    totalResults = page?.totalResults || 0
  })

  return {
    ...resp,
    data: infiniteProcessingActivities,
    totalResults,
  }
}
