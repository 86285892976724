import React from 'react'
import type { RangeStatic } from 'quill'
import { SxProps, Theme } from '@mui/material'
import Box from '@mui/material/Box'
import { useField, FieldValidator } from 'formik'
import { Editor, EditorProps } from '@ketch-com/deck'
import ReactQuill, { ReactQuillProps } from 'react-quill'

import { Hint } from 'components/ui-kit/form/common/hint/Hint'
import { Label } from 'components/ui-kit/form/common/label/Label'
import { Error } from 'components/ui-kit/form/common/error/Error'

interface Props {
  id: string
  name: string
  hint?: string
  label?: string | React.ReactNode
  labelColor?: 'black' | 'grey'
  required?: boolean
  validate?: FieldValidator
  fullWidth?: boolean
  /** Max number of characters (not including HTML). Note this will block input once the limit is reached */
  charLimit?: number
  helperText?: React.ReactNode
  sx?: SxProps<Theme>
  onChange?: ReactQuillProps['onChange']
  linkChangeModal?: (
    quillRef: React.RefObject<ReactQuill>,
    range: RangeStatic | null,
    setRange: React.Dispatch<React.SetStateAction<RangeStatic | null>>,
  ) => React.ReactNode
}

export const FormEditor: React.FC<Props> = ({
  name,
  hint = '',
  label = '',
  labelColor = 'black',
  required = false,
  validate,
  fullWidth,
  charLimit,
  helperText,
  sx,
  id: editorId,
  linkChangeModal,
}) => {
  const [field, meta, helpers] = useField({ name, validate })
  const showError = meta.error && meta.touched
  const [hasMounted, setHasMounted] = React.useState(false)
  const [manualErrorMessage, setManualErrorMessage] = React.useState('')

  const onChangeInner: EditorProps['onChange'] = (content, delta, source, editor) => {
    if (!hasMounted) return

    // Set flags indicating if this is a delete and if new length is below character limit
    const isDelete = !!delta?.ops?.length && delta.ops.some(op => op.delete)
    const isBelowLimit = !charLimit || editor.getLength() <= charLimit

    // Always set value in delete case, otherwise set only if <= limit
    const newValue = isDelete || isBelowLimit ? content : field.value

    // If the editor is empty, set the value to an empty string
    // This is needed because content will be a empty html tag when the editor is empty (<p><br></p>)
    if (editor.getText().trim() === '') {
      helpers.setValue('')
      return
    }

    // Set error message and next value
    setManualErrorMessage(isBelowLimit ? '' : `The maximum character count is ${charLimit}.`)
    helpers.setValue(newValue)
  }

  React.useEffect(() => {
    setHasMounted(true)
  }, [])

  return (
    <Box id={name} sx={sx} display="inline-block" width="100%">
      {label && (
        <Label id={name} required={required} color={labelColor}>
          {label}
        </Label>
      )}
      {helperText ?? null}
      <Editor
        id={editorId}
        value={field.value}
        onChange={onChangeInner}
        linkChangeModal={linkChangeModal}
        sx={{
          '& .ql-tooltip': {
            left: '-35px !important',
          },
        }}
      />
      {(showError || manualErrorMessage) && <Error>{meta.error || manualErrorMessage}</Error>}
      {hint && !showError && <Hint>{hint}</Hint>}
    </Box>
  )
}
