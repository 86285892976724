import { Button, Icon, Status, StatusVariant, theme } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import { ProgressIndicator } from 'pages/policyCenter/assessments/view/components/ProgressIndicator'
import { assessmentStatusTypeToCodeMap, statusStateToCodeMap, statusStyleByStatusCode } from '../constants'
import { useCallback, useMemo } from 'react'
import { useWidthRef } from '../hooks/useWidthRef'
import { AssessmentHighLevelDetails } from 'pages/externalPrivacyPortal/types'
import { AssessmentNameAndSubject } from 'pages/externalPrivacyPortal/common/component/AssessmentNameAndSubject'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { AssessmentStatus } from '@ketch-com/windlass/dist/assessments/externalUser'

export interface AssessmentsListItemProps extends AssessmentHighLevelDetails {}

export const AssessmentsListItem: React.FC<AssessmentsListItemProps> = ({
  assessmentName,
  dataSubjectCode,
  dataSubjectIcon,
  status,
  questionCount,
  assessmentId,
}) => {
  const navigate = useNavigate()
  const percent = questionCount.total === 0 ? 100 : Math.round((questionCount.complete / questionCount.total) * 100)
  const actionComplete = status === 'review' || status === 'approved'
  const [width, widthRef] = useWidthRef(750)
  const maxWidth = useMemo(() => `${Math.round(width * 0.51)}px`, [width])
  const statusCode =
    status === AssessmentStatus.in_progress && questionCount.complete === questionCount.total
      ? 'Complete'
      : assessmentStatusTypeToCodeMap[status]

  const handleDrilldown = useCallback(() => {
    navigate(RoutesManager.externalPrivacyPortal.assessment.root.getURL({ assessmentId }))
  }, [navigate, assessmentId])

  return (
    <Box
      padding={3}
      display="flex"
      width="100%"
      height="68px"
      justifyContent="space-between"
      alignItems="center"
      borderRadius={2}
      sx={{ backgroundColor: actionComplete ? theme.palette.Black.o4 : theme.palette.Common.Container }}
      gap={2}
      ref={widthRef}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" width="70%">
        <AssessmentNameAndSubject
          assessmentName={assessmentName}
          dataSubjectCode={dataSubjectCode}
          dataSubjectIcon={dataSubjectIcon}
          maxWidth={maxWidth}
        />
        <Box>
          <Status variant={StatusVariant.small} status={statusStateToCodeMap[statusCode]} label={statusCode} />
        </Box>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" width="30%">
        <Box display="flex" alignItems="center" gap={2}>
          {status !== 'approved' ? (
            <ProgressIndicator style={statusStyleByStatusCode[statusCode]} percent={percent} width={24} />
          ) : (
            <Icon name="FCheckRound" />
          )}
          <Box display="flex" flexDirection="column" marginRight={5}>
            <Typography>{percent}%</Typography>
            <Typography variant="footnote">{`${questionCount.complete}/${questionCount.total} Questions`}</Typography>
          </Box>
        </Box>
        <Button variant="iconSubtle" color="white" onClick={handleDrilldown}>
          <Icon name="OArrowCRight" iconColor={actionComplete ? theme.palette.text.disabled : undefined} />
        </Button>
      </Box>
    </Box>
  )
}
