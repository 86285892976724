import { useParams } from 'react-router-dom'
import { useAssetV2 } from 'api/assets/queries/useAssetV2'
import { useDsrConfigCount } from 'api/assets/queries/useDsrConfigCount'
import React from 'react'
import { ScenarioDetails } from 'pages/dataSystems/AssetMigration/ScenarioDetails'
import { Spinner } from '@ketch-com/deck'
import { Logger } from 'utils/Logger'
import { ScenarioDetailsParams } from 'utils/routing/routesV2/systems'

export const ScenarioDetailsContainer: React.FC = () => {
  const { id: assetId, resourceTypeCode } = useParams<ScenarioDetailsParams>()

  const {
    data: assetSummary,
    isLoading: isAssetSummaryLoading,
    error: errorAsset,
  } = useAssetV2({
    params: {
      assetCode: assetId!,
      resourceTypeCode,
    },
  })
  const {
    data: dsrConfigCount,
    isLoading: isDsrConfigCountLoading,
    error: errorDsr,
  } = useDsrConfigCount({
    params: {
      assetId,
      start: 0,
      limit: 100,
      canonicalRightCode: 'get,delete',
    },
  })

  if (!resourceTypeCode || !assetId) {
    if (!resourceTypeCode) Logger.error('Resource type code is missing')
    if (!assetId) Logger.error('Asset id is missing')
    return null
  } else if (isAssetSummaryLoading || isDsrConfigCountLoading) {
    return <Spinner size={16} />
  } else if (errorAsset) {
    Logger.error('Failed to fetch asset summary', errorAsset)
    return null
  } else if (errorDsr) {
    Logger.error('Failed to fetch dsr config count', errorDsr)
    return null
  } else {
    return <ScenarioDetails assetSummary={assetSummary} dsrConfigCount={dsrConfigCount} />
  }
}
