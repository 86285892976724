import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

interface SendCollaborationAuthParams {
  email?: string
  redirectUrl: string
  recaptchaToken: string
}

export const sendCollaborationAuth = ({ email, redirectUrl, recaptchaToken }: SendCollaborationAuthParams) => {
  const entityUrl = '/api/auth/collaboration-workspace/start'
  const formData = { redirectUrl, recaptchaToken }
  return API.post(
    formatRequestString({
      entityUrl,
      params: {
        filters: { email },
      },
    }),
    formData,
  )
}
