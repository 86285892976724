import { FormFieldTypeDTO } from '@ketch-com/figurehead'
import { RightInvocationFormValues, UpsertRightInvocationDTO } from 'interfaces/rightInvocations'
import { CloudinaryFormFields } from 'interfaces/cloudinary/cloudinary'

export const mapCreateRightInvocationValuesToPayload = ({
  values,
  organizationCode,
  recaptchaToken,
  cloudinaryFormFields = {},
}: {
  values: RightInvocationFormValues
  organizationCode: string
  recaptchaToken: string
  cloudinaryFormFields: CloudinaryFormFields
}): UpsertRightInvocationDTO => {
  const identities = values.identities
    .map((identity, index) => {
      if (identity.code === 'email' && index === 0) {
        return {
          code: identity.code,
          value: values.email,
        }
      }
      return {
        code: identity.code,
        value: identity.value,
      }
    })
    .map(identity => `srn:::::${organizationCode}:id/${identity.code}/${identity.value}`)

  const formData = (values?.formData || []).map(field => {
    const fieldCode = field.code || ''
    const isCheckbox = field?.type === FormFieldTypeDTO.CheckboxFormFieldType
    if (field.type === FormFieldTypeDTO.FileFormFieldType) {
      const cloudinaryData = cloudinaryFormFields[fieldCode]

      if (Boolean(cloudinaryData)) field.value = JSON.stringify(cloudinaryData)
    }
    return {
      name: field.title || '',
      code: field.code || '',
      ...(isCheckbox ? { values: field.values || [] } : { value: field.value || '' }),
    }
  })

  const descriptionValue = values?.formData?.find(field => field.code === 'description')?.value || ''

  const user = {
    email: values.email,
    first: values.first,
    last: values.last,
    country: values?.formData?.find(field => field.code === 'country')?.value || '',
    stateRegion: values?.formData?.find(field => field.code === 'stateRegion')?.value || '',
    city: values?.formData?.find(field => field.code === 'city')?.value || '',
    addressLine1: values?.formData?.find(field => field.code === 'addressLine1')?.value || '',
    addressLine2: values?.formData?.find(field => field.code === 'addressLine2')?.value || '',
    postalCode: values?.formData?.find(field => field.code === 'postalCode')?.value || '',
    phone: values?.formData?.find(field => field.code === 'phone')?.value || '',
    description: descriptionValue,
    dataSubjectTypeCode: values.dataSubjectTypeCode,
    dataSubjectTypeRelationshipDetails: values.dataSubjectTypeRelationshipDetails,
    formData,
  }

  return {
    applicationCode: 'figurehead',
    applicationEnvironmentCode: 'production',
    controllerCode: '',
    identities,
    policyScopeCode: values.policyScopeCode,
    rightCodes: [values.rightCode],
    user,
    dataRole: Number(values.dataRole || '2'),
    recaptchaToken,
  }
}
