import { uniqBy } from 'lodash'

import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { WorkflowActivityGatewayMode } from 'interfaces/workflowActivities/workflowActivity'
import { getSiblingsFromStep } from 'pages/orchestration/workflows/edit/utils/steps/siblingsFromStep'
import { getTransitionToStep } from 'pages/orchestration/workflows/edit/utils/steps/transitionsToStep'
import { CanvasStepType } from 'pages/orchestration/workflows/edit/utils/constants'
import { getStepType } from 'pages/orchestration/workflows/edit/utils/steps/getStepType'

interface IGetStepsOfTypeBeforeStep {
  step: ICanvasStep
  steps: Array<ICanvasStep>
  type: CanvasStepType
}

/**
 * Get the steps of a given type before the current step
 * @param props - The props for the function
 * @returns The steps of the given type before the current step
 */
export const getStepsOfTypeBeforeStep = (props: IGetStepsOfTypeBeforeStep): ICanvasStep[] => {
  const { step, steps, type } = props
  const { parentStep } = getSiblingsFromStep({ step, steps })

  if (!parentStep || parentStep?.start) {
    return []
  }

  const includeParentStep = getStepType(parentStep) === type

  // In case of JOIN we will receive all transitions which lead to it and will get list
  // of all their children and children of their children
  if (parentStep?.gateway?.mode === WorkflowActivityGatewayMode.JOIN) {
    const transitionsToJoin = getTransitionToStep({ step: parentStep, steps })
    const stepsFromJoin: ICanvasStep[] = []

    transitionsToJoin?.forEach?.(transition => {
      const stepsBeforeTransition = getStepsOfTypeBeforeStep({ step: transition, steps, type })

      if (getStepType(transition) === type) {
        stepsFromJoin.push(transition)
      }
      stepsBeforeTransition?.forEach?.(transitionBeforeJoin => stepsFromJoin.push(transitionBeforeJoin))
    })

    return includeParentStep ? [parentStep, ...uniqBy(stepsFromJoin, 'ID')] : uniqBy(stepsFromJoin, 'ID')
  }

  return includeParentStep
    ? [parentStep, ...getStepsOfTypeBeforeStep({ step: parentStep, steps, type })]
    : getStepsOfTypeBeforeStep({ step: parentStep, steps, type })
}
