import { APIListRequestParams } from 'api/common/utils'
import { AssessmentTemplateStatusDTO, AssessmentTemplateListDocumentDTO, ArchiveStatusDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  name?: string
  approvalStatus?: AssessmentTemplateStatusDTO
  archiveStatus?: ArchiveStatusDTO
  isV2FeatureFlagEnabled?: boolean
}>

export const fetchAssessmentTemplates = ({
  start,
  limit,
  name,
  approvalStatus,
  archiveStatus,
  isV2FeatureFlagEnabled,
}: Params) => {
  const entityUrl = isV2FeatureFlagEnabled
    ? `/api/risk-review/resources/assessment-templates`
    : `/api/resources/assessment-templates`
  return API.get<AssessmentTemplateListDocumentDTO>(
    formatRequestString({
      entityUrl,
      params: {
        filters: {
          'page[start]': start,
          'page[limit]': limit,
          ...(name && { 'filter[name]': name }),
          ...(approvalStatus && { 'filter[status]': approvalStatus }),
          ...(archiveStatus && { 'filter[archive]': archiveStatus }),
        },
      },
    }),
  )
}
