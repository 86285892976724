import React from 'react'
import Grid from '@mui/material/Grid'
import { useFormikContext } from 'formik'
import { FormRow } from '@ketch-com/deck'
import { FormSwitch } from 'components/form/FormSwitch'

// types
import { DataSubjectTypeDTO } from '@ketch-com/figurehead'

export const CopyAuthorizedAgent: React.FC = () => {
  const { setFieldValue, values } = useFormikContext<DataSubjectTypeDTO>()

  if (values?.code !== 'authorized_agent') return null

  return (
    <FormRow
      title="Copy Authorized Agent on Data Subject Emails"
      subTitle="Copy the Authorized Agent on all emails sent through the Data Subject workflow activity."
    >
      <Grid container>
        <Grid item xs={6}>
          <FormSwitch
            formPropertyName="copyAuthorizedAgentOnEmails"
            label="Copy Authorized Agent On Data Subject Emails"
            inlineLabel
            size="medium"
            onChange={(_, checked) => setFieldValue('copyAuthorizedAgentOnEmails', checked)}
          />
        </Grid>
      </Grid>
    </FormRow>
  )
}
