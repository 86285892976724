import { compact } from 'lodash'
import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { isEmailActivityParamsTypeGuard } from 'pages/orchestration/workflows/edit/interfaces/typeGuards'

interface IGetEmailInitialValuesParams {
  step: ICanvasStep
}

export interface IGetEmailInitialValues {
  description: string
  code: string
  to: Array<string>
  cc: Array<string>
  responseEmail: Array<string>
  subject: string
  body: string
}

export const getEmailInitialValues = (params: IGetEmailInitialValuesParams): IGetEmailInitialValues => {
  const { step } = params

  const emailBody = ``

  if (isEmailActivityParamsTypeGuard(step.activity?.params)) {
    const description = step.description || ''
    const code = step.code || ''
    const to = step.activity?.params?.to || []
    const cc = step.activity?.params?.cc || []
    const responseEmail = step.activity?.params?.responseEmail || []
    const subject = step.activity?.params?.subject || ''
    const body = step.activity?.params?.body || emailBody

    return {
      description,
      code,
      to: compact(to),
      cc: compact(cc),
      responseEmail: compact(responseEmail),
      subject,
      body,
    }
  }

  return {
    description: '',
    code: '',
    to: [],
    cc: [],
    responseEmail: [],
    subject: '',
    body: emailBody,
  }
}
