import { AssetsTabFilterProvider } from './assetsTab/context/AssetsTabFilterContext'
import { DataCategoriesRolloverProvider } from './context/DataCategoriesRolloverContext'
import { DataSystem } from './DataSystem'
import { useDataSystemsContext } from './context/DataSystemContext'

export const DataSystemsFilterContextContainer: React.FC = () => {
  const { assetsDataSystems, isLoadingAssetsDataSystems } = useDataSystemsContext()
  return (
    <DataCategoriesRolloverProvider
      assetsDataSystems={assetsDataSystems}
      isLoadingAssetsDataSystems={isLoadingAssetsDataSystems}
    >
      <AssetsTabFilterProvider
        assetsDataSystems={assetsDataSystems}
        isLoadingAssetsDataSystems={isLoadingAssetsDataSystems}
      >
        <DataSystem />
      </AssetsTabFilterProvider>
    </DataCategoriesRolloverProvider>
  )
}
