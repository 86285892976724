import React from 'react'
import { Grid, Box, Typography, Tooltip } from '@mui/material'
import { useFormikContext } from 'formik'
import { SwitchToggle, Icon, IconDictionary, theme, FormRow } from '@ketch-com/deck'
import { formatCodeFromName } from 'utils/formatters'
import { ThemeV3DTO } from 'interfaces/themes-v3/theme'
import { isRelease } from 'utils/releases'
import { Release } from 'utils/releases/RELEASES'
import { DeploymentPlanDTO } from 'interfaces/deploymentPlans-v2/deploymentPlan'
import { FormOptionActionSheet } from 'components/form/FormOptionActionSheet'
import { FormInput } from 'components/form/FormInput'

type Props = {
  isEditMode?: boolean
  themes: ThemeV3DTO[]
}

export const DeploymentPlanBasicDetails: React.FC<Props> = ({ isEditMode = false, themes }) => {
  const { setFieldValue, setFieldTouched, values } = useFormikContext()

  return (
    <FormRow title="Basic Details">
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <FormInput
            formPropertyName="name"
            label="Name"
            fullWidth
            placeholder="Example: My New Deployment Plan"
            onChange={e => {
              if (!isEditMode) {
                setFieldTouched('code', true)
                setFieldValue('code', formatCodeFromName(e.target.value))
              }
            }}
            required
          />
        </Grid>

        {!isRelease(Release.PropertyThemeDocuments) && (
          <Grid item xs={3}>
            <FormOptionActionSheet
              label="Theme"
              placeholder="Select Theme"
              buttonProps={{
                size: 'large',
                color: 'tertiary',
              }}
              formPropertyName="themeID"
              items={themes}
              valueKey="id"
              renderItem={item => item.name}
              required
            />
          </Grid>
        )}
      </Grid>

      <Grid container spacing={4} mt={0}>
        <Grid item xs={6}>
          <FormInput
            formPropertyName="code"
            required
            fullWidth
            disabled={isEditMode}
            label="Code"
            placeholder="Example: AP957384"
          />
        </Grid>

        <Grid item xs={3}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Box display="flex" gap={0.5}>
              <Typography variant="label">Quiet Mode</Typography>

              <Tooltip title="Quiet mode deployments will not serve experiences or generate consent.">
                <Box>
                  <Icon
                    name={IconDictionary.FImportant}
                    iconColor={theme.palette.Text.Secondary}
                    width={20}
                    height={20}
                  />
                </Box>
              </Tooltip>
            </Box>
            <SwitchToggle
              size="medium"
              onChange={(e, switchSelection) => {
                setFieldValue('isOrchestrationOnly', switchSelection)
              }}
              checked={(values as DeploymentPlanDTO)?.isOrchestrationOnly}
            />
          </Box>
        </Grid>
      </Grid>
    </FormRow>
  )
}
