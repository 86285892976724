import React from 'react'
import Grid from '@mui/material/Grid'
import { useFormikContext } from 'formik'

// components
import { Box } from '@mui/material'
import { FormRow } from '@ketch-com/deck'
import { FormInput } from 'components/form/FormInput'
import { FormDroplistButton } from 'components/form/FormDroplistButton'

// types
import { DataSubjectTypeDTO, DataSubjectTypeTemplateDTO } from '@ketch-com/figurehead'
type Props = {
  isEditMode: boolean
  templates: DataSubjectTypeTemplateDTO[]
}

export const SelectDataSubjectType: React.FC<Props> = ({ isEditMode, templates }) => {
  const { setFieldValue, setFieldTouched, values } = useFormikContext<DataSubjectTypeDTO>()

  return (
    <FormRow
      title="Select Subject Type"
      subTitle="All Subject Types listed below are translated into all enabled languages."
    >
      <Box mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormDroplistButton
              disableClearable
              disabled={values.isCustomType || isEditMode}
              required
              fullWidth
              label="Name"
              formPropertyName="name"
              placeholder="Select Subject Type"
              items={templates}
              onItemClick={template => {
                if (!values.isCustomType) {
                  setFieldValue('templateId', template?.id)
                }

                if (!isEditMode) {
                  setFieldTouched('code', true)
                  setFieldValue('code', template?.code)
                  setFieldValue('translations', template?.translations)
                }
              }}
              valueKey="name"
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              disabled={values.isCustomType || isEditMode}
              required
              fullWidth
              label="Code"
              formPropertyName="code"
              placeholder="Enter code"
              shouldUpdateDebounced
            />
          </Grid>
        </Grid>
      </Box>
    </FormRow>
  )
}
