import React from 'react'
import { Box, SxProps, Theme, Typography } from '@mui/material'
import { FormFieldTypeDTO } from '@ketch-com/figurehead'
import { ViewFile } from 'components/cloudinary/ViewFile'
import type { CloudinaryResource } from 'interfaces/cloudinary/cloudinary'

type Props = {
  title?: string
  value?: React.ReactNode
  type?: string
  containerSx?: SxProps<Theme>
}

export const SidePanelKeyValueRenderer: React.FC<Props> = ({ title, value, type, containerSx }) => {
  if (!value) return null
  const isFile = type === FormFieldTypeDTO.FileFormFieldType
  let valueEle = <Typography>{value}</Typography>
  if (isFile && typeof value === 'string') {
    const cloudinaryResource: CloudinaryResource = JSON.parse(value)
    const hasCloudinaryUploadData = cloudinaryResource?.secure_url && cloudinaryResource?.public_id
    if (!hasCloudinaryUploadData) return null
    valueEle = <ViewFile cloudinaryResource={cloudinaryResource} size="small" />
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={0.5}
      sx={{
        ...containerSx,
        wordBreak: 'break-word',
      }}
    >
      <Typography variant="smallLabel" color="darkDuskFaded.main">
        {title}
      </Typography>
      {valueEle}
    </Box>
  )
}
