import { Box, FormControlLabel, RadioGroup, Typography } from '@mui/material'
import { getRiskScoreName } from 'pages/policyCenter/assessments/questions/constants'
import { Radio } from '@ketch-com/deck'
import { QuestionAttributesChoicesItemDTO } from '@ketch-com/figurehead'
import { FormError } from 'components/form/FormError'
import { useExternalAssessmentResponse } from '../../context/ExternalAssessmentResponseContext'

export const AssessmentExternalSingleChoiceInput: React.FC<{}> = () => {
  const { isValid, changeValue, choices, block } = useExternalAssessmentResponse()

  const renderLabel = (choice: QuestionAttributesChoicesItemDTO, isSelected?: boolean | undefined) => {
    return (
      <Box mb={1}>
        <Typography component="div" variant="body">
          {choice.displayText || ''}
        </Typography>
        {choice.riskID ? (
          <Typography variant="smallLabel" color="darkDuskFaded.main">
            Risk Score: {getRiskScoreName(choice.riskID)}
          </Typography>
        ) : null}
      </Box>
    )
  }
  return (
    <Box
      border={({ palette }) => (!isValid ? `1px solid ${palette.chileanFire.main}` : undefined)}
      pl={1.5}
      py={1}
      borderRadius="5px"
    >
      <RadioGroup value={choices[0]} row={false} onChange={(event, value) => changeValue(value)}>
        {block.question?.attributes?.choices?.map(option => (
          <FormControlLabel key={option.id || ''} value={option.id} control={<Radio />} label={renderLabel(option)} />
        ))}
      </RadioGroup>

      {!isValid ? <FormError msg="Required" /> : null}
    </Box>
  )
}
