import Box from '@mui/material/Box'
import React, { useContext } from 'react'
import { ActionSheetItem, Button, DropListButton, ListItemText } from '@ketch-com/deck'
import { useFormikContext } from 'formik'
import { FormTemplateDTO, FormFieldTypeDTO } from '@ketch-com/figurehead'
import { FormTemplateUpsertContext } from 'pages/consentAndRights/forms/templates/upsert/context/FormTemplateUpsertContextProvider'

import { getPreviewConfigurationExperienceFormFieldDTOfromFormFieldDTO } from '../utils/getPreviewConfigurationExperienceFormFieldDTOfromFormFieldDTO'
import { Tooltip } from '@mui/material'
import { isCloudinaryEnabled } from 'utils/helpers/isCloudinaryEnabled'

type Props = {
  push: (obj: any) => void
}

export const AddFieldRow: React.FC<Props> = ({ push }) => {
  const { values } = useFormikContext<FormTemplateDTO>()
  const { setSelectedFieldCodeForAddition, selectedFieldCodeForAddition, formFields } =
    useContext(FormTemplateUpsertContext)

  const selectedField = formFields.find(cv => cv.code === selectedFieldCodeForAddition)

  const allCurrentlyUsedFieldIds = values?.sections?.map(cv => cv?.formFields?.map(pdv => pdv.id))?.flat() || []

  const droplistFormFieldItems = formFields
    .filter(cv => !allCurrentlyUsedFieldIds.includes(cv.id))
    .filter(field => {
      if (field.type === FormFieldTypeDTO.FileFormFieldType && !isCloudinaryEnabled()) return false
      return true
    })

  return (
    <Tooltip title={droplistFormFieldItems.length ? '' : 'No more fields to add'}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={1.5}
        sx={{
          backgroundColor: 'sphereFaded.main',
          borderRadius: '11px',
        }}
      >
        {/* Left */}
        <Box display="flex" alignItems="center">
          <DropListButton
            disableClearable={selectedField !== null}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={option => option?.name || option?.title || ''}
            options={droplistFormFieldItems}
            onChange={(_, value) => value && setSelectedFieldCodeForAddition(value?.code || '')}
            renderOption={(props, option, { selected }) => {
              return (
                <ActionSheetItem selected={selected} {...props}>
                  <ListItemText selected={selected}>{option.name}</ListItemText>
                </ActionSheetItem>
              )
            }}
            value={selectedField}
            placeholder="Select Field"
            size="small"
          />
        </Box>

        {/* Right */}
        <Button
          disabled={!selectedField}
          color="secondary"
          onClick={() => {
            if (selectedField) {
              const previewConfigurationExperienceFormFieldDTO =
                getPreviewConfigurationExperienceFormFieldDTOfromFormFieldDTO(selectedField)
              push(previewConfigurationExperienceFormFieldDTO)
              setSelectedFieldCodeForAddition('')
            }
          }}
        >
          Add Field
        </Button>
      </Box>
    </Tooltip>
  )
}
