import { ThemeConfig } from '@ketch-sdk/ketch-types'
import { themeUpsertFormKeys } from '../../../../utils/useThemeValidationSchema'
import Box from '@mui/material/Box'
import { getIn, useFormikContext } from 'formik'
import {
  BuilderTextInputMode,
  FormTextInput,
} from '../../../../../../experiences-v2/upsert/components/upsertExperienceSidebar/components/FormTextInput'
import { SidebarFormGroup } from '../../../../../../experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'
import { FormSectionSwitch } from '../../../../../../experiences-v2/upsert/components/upsertExperienceSidebar/components/FormSectionSwitch'
import { SidebarFormPositionRadioGroup } from '../SidebarFormPositionRadioGroup'
import { FormColorInput } from '../FormColorInput'

export const BannerThemeContainerForm: React.FC = () => {
  const { values } = useFormikContext<ThemeConfig>()

  return (
    <>
      <SidebarFormGroup sx={{ pt: 0, gap: '16px' }}>
        {/* TODO:JB - Banner layout and position descoped for V1 */}
        {/* <SidebarFormRadioGroup
          title={'Layout'}
          name={themeUpsertFormKeys.bannerContainerLayout}
          options={Object.values(BannerContainerLayout).map(layout => ({
            label: BannerContainerLayoutLabels[layout],
            value: layout,
          }))}
          row
        />
        <SidebarFormRadioGroup
          title={'Size'}
          name={themeUpsertFormKeys.bannerContainerSize}
          options={Object.values(BannerContainerSize).map(size => ({
            label: BannerContainerSizeLabels[size],
            value: size,
          }))}
          row
        /> */}
        <SidebarFormPositionRadioGroup variant={'banner'} />
      </SidebarFormGroup>
      <SidebarFormGroup hasBorderTop hasBorderBottom>
        <Box>
          <FormColorInput
            name={themeUpsertFormKeys.bannerContainerBackgroundColor}
            label={'Background Color'}
            fullWidth
            sx={{ width: '100%' }}
            required
          />
        </Box>
        <Box display={'flex'} flexDirection={'column'}>
          <FormTextInput
            name={themeUpsertFormKeys.bannerContainerCornerRadius}
            label={'Corner Radius (px)'}
            mode={BuilderTextInputMode.CornerRadius}
            inputWidth="92px"
          />
        </Box>
      </SidebarFormGroup>
      <SidebarFormGroup>
        <FormSectionSwitch
          title={'Backdrop'}
          name={themeUpsertFormKeys.bannerContainerBackdropVisible}
          tooltip={
            'The backdrop is a color overlay under the banner that covers the entire website content to maximize banner visibility. Please note that the user will not be able to interact with the underlying content until the banner is dismissed.'
          }
        />
        {getIn(values, themeUpsertFormKeys.bannerContainerBackdropVisible) && (
          <>
            <Box px={'18px'}>
              <Box display={'flex'} gap={'12px'}>
                <Box width={232}>
                  <FormColorInput
                    name={themeUpsertFormKeys.bannerContainerBackdropBackgroundColor}
                    label={'Background Color'}
                    required
                  />
                </Box>
                <Box width={92} mt={'-2px'}>
                  <FormTextInput
                    name={themeUpsertFormKeys.bannerContainerBackdropBackgroundOpacity}
                    label={'Opacity (%)'}
                    inputWidth="92px"
                    mode={BuilderTextInputMode.Opacity}
                    min={0}
                    max={100}
                    sx={{ width: '92px' }}
                  />
                </Box>
              </Box>
              {/* TODO:JB - Descoped */}
              {/* <Box display={'flex'} flexDirection={'column'}>
                <FormControlLabel
                  control={<FormCheckbox name={themeUpsertFormKeys.bannerContainerBackdropDisableInteractions} />}
                  label={<Typography variant={'label'}>Disable Underlying Content Interactions</Typography>}
                />
                <Typography variant={'smallBody'} color={theme.palette.Text.Secondary}>
                  When enabled, your website content will remain non-clickable until the banner is dismissed.
                </Typography>
              </Box> */}
            </Box>
          </>
        )}
      </SidebarFormGroup>
    </>
  )
}
