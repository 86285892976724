import React from 'react'
import Box from '@mui/material/Box'

import { SidePanelKeyValueRenderer } from './SidePanelKeyValueRenderer'
import { RightInvocationDTO } from '@ketch-com/figurehead'
import { DataRoleType } from 'interfaces/events'
import { MaybeNull } from 'interfaces'
import { Widget } from '@ketch-com/deck'
import { Typography } from '@mui/material'

type Props = {
  rightInvocation: MaybeNull<RightInvocationDTO>
}

export const SubjectDetailsCard: React.FC<Props> = ({ rightInvocation }) => {
  const wasRightInvocationRaisedByProcessor = rightInvocation?.dataRole === DataRoleType.DATA_ROLE_PROCESSOR

  return (
    <Widget
      sx={{ '&.MuiPaper-root': { backgroundColor: 'tertiary.main' } }}
      expandable
      title="Request Details"
      content={
        <Box display="flex" flexDirection="column" gap={2}>
          <SidePanelKeyValueRenderer
            title="Name"
            value={`${rightInvocation?.user?.first} ${rightInvocation?.user?.last}`}
          />

          <SidePanelKeyValueRenderer title="Email" value={rightInvocation?.user?.email} />

          <SidePanelKeyValueRenderer title="Request Description" value={rightInvocation?.user?.description} />

          {!wasRightInvocationRaisedByProcessor && (
            <SidePanelKeyValueRenderer
              title="Subject Type"
              value={
                <Box display="flex" flexDirection="column">
                  <Typography>{rightInvocation?.user?.dataSubjectTypeName}</Typography>
                  <Typography variant="smallBody" color="darkDuskFaded.main">
                    {rightInvocation?.user?.dataSubjectTypeCode}
                  </Typography>
                </Box>
              }
            />
          )}

          {rightInvocation?.user?.dataSubjectTypeRelationshipDetails && (
            <SidePanelKeyValueRenderer
              title="Relationship to Company"
              value={rightInvocation?.user?.dataSubjectTypeRelationshipDetails}
            />
          )}

          {rightInvocation?.user?.formData?.length &&
            rightInvocation?.user?.formData?.map((field: any) => {
              return (
                <SidePanelKeyValueRenderer
                  key={field?.name}
                  title={field?.name}
                  value={field?.value}
                  type={field?.type}
                />
              )
            })}
        </Box>
      }
    />
  )
}
