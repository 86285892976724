import { logError } from 'utils/helpers/validators/validationLogging'
import * as Yup from 'yup'

export const getValidationSchema = () => {
  return Yup.object().shape({
    testMode: Yup.boolean(),
    headers: Yup.array().when('testMode', {
      is: false,
      then: Yup.array()
        .of(
          Yup.object().shape({
            key: Yup.string()
              .required()
              .test('log-key', 'Required', (value, context) => {
                if (!value) logError('headers.key', context.createError({ message: 'Required' }).message)
                return !!value
              }),
            value: Yup.string()
              .required()
              .test('log-value', 'Required', (value, context) => {
                if (!value) logError('headers.value', context.createError({ message: 'Required' }).message)
                return !!value
              }),
          }),
        )
        .min(1, 'Must have at least 1 header')
        .test('log-headers', 'Must have at least 1 header', (value, context) => {
          if (!value || value.length < 1) {
            logError('headers', context.createError({ message: 'Must have at least 1 header' }).message)
          }
          return !!(value && value.length >= 1)
        }),
      otherwise: Yup.array(), // No validation when testMode is true
    }),
    name: Yup.string()
      .required('Required')
      .test('log-name', 'Required', (value, context) => {
        if (!value) logError('name', context.createError({ message: 'Required' }).message)
        return !!value
      }),
    events: Yup.object()
      .shape({})
      .test({
        name: 'atLeastOneEventIsRequired',
        test: (parent, { createError }) => {
          const isValid = Object.keys(parent || {}).some(event => parent[event])
          if (!isValid) {
            const errorMessage = 'At least one event is required'
            logError('events', errorMessage)
            return createError({ path: 'events', message: errorMessage })
          }
          return true
        },
      }),
    url: Yup.string().when('testMode', {
      is: false,
      then: Yup.string()
        .url()
        .required('Required')
        .test('log-url', 'Required', (value, context) => {
          if (!value) logError('url', context.createError({ message: 'Required' }).message)
          return !!value
        }),
    }),
  })
}
