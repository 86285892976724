import { AssessmentAnswer, AssessmentAnswerAttribute } from '@ketch-com/windlass/dist/assessments/externalUser'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

interface PutExternalAnswerParams {
  answer: AssessmentAnswerAttribute
  assessmentId: string
  blockId: string
}

export const putExternalAnswer = ({ answer, assessmentId, blockId }: PutExternalAnswerParams) => {
  const formData = {
    attributes: answer,
  }
  const entityUrl = `/api/external-risk-review/resources/assessments/${assessmentId}/blocks/${blockId}`
  return API.put<AssessmentAnswer>(formatRequestString({ entityUrl }), formData)
}
