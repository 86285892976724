import { Avatar, Button, Icon, theme } from '@ketch-com/deck'
import { Box, BoxProps, styled, Typography } from '@mui/material'
import { ReactComponent as HeaderKetchLogo } from 'assets/icons/ketch_logo_with_text.svg'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'

const HeaderOuterBox = styled(Box)(({ theme }) => ({
  height: '64px',
  minHeight: '64px',
  background: theme.palette.Common.Container,
  borderBottom: `1px solid ${theme.palette.Common.Divider}`,
  padding: '0 24px',
  width: '100%',
}))
const HeaderInnerBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  height: '63px',
  background: 'white',
  alignItems: 'center',
}))

const HeaderRightBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '24px',
  paddingRight: '10px',
  height: '100%',
  alignItems: 'center',
}))

interface PortalHeaderProps extends BoxProps {
  withoutAuth?: boolean
}

export const PortalHeader: React.FC<PortalHeaderProps> = ({ withoutAuth, ...rest }) => {
  const email = localStorage.getItem('externalUserEmail')
  const navigate = useNavigate()

  const handleSignOut = useCallback(() => {
    localStorage.removeItem('externalUserEmail')
    localStorage.removeItem('collaborationToken')
    navigate(RoutesManager.externalPrivacyPortal.login.getURL())
  }, [navigate])

  return (
    <HeaderOuterBox id="app-header" {...rest}>
      <HeaderInnerBox>
        <HeaderKetchLogo
          id="ketch-logo-button"
          onClick={() => navigate(RoutesManager.externalPrivacyPortal.overview.root.getURL())}
          style={{ position: 'relative', top: '0.5px', cursor: 'pointer' }}
        />
        {!withoutAuth && (
          <HeaderRightBox>
            <Box display="flex" gap={0.5} alignItems="center">
              <Avatar backgroundColor={theme.palette.Black.o16} color={theme.palette.Black.o40} />
              <Typography variant="body">{email}</Typography>
            </Box>
            <Button
              variant="link"
              color="secondary"
              size="medium"
              startIcon={<Icon color="action" name="OLogOut" />}
              onClick={handleSignOut}
            >
              Sign Out
            </Button>
          </HeaderRightBox>
        )}
      </HeaderInnerBox>
    </HeaderOuterBox>
  )
}
