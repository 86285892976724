export const externalPrivacyPortal = {
  root: {
    pattern: '/collaboration-workspace',
    getURL: () => `/collaboration-workspace`,
    permissions: [],
  },
  magicLinkCallback: {
    pattern: '/collaboration-workspace/callback',
    getURL: () => `/collaboration-workspace/callback`,
    permissions: [],
  },
  login: {
    pattern: '/collaboration-workspace/login',
    getURL: () => `/collaboration-workspace/login`,
    permissions: [],
  },
  overview: {
    root: {
      pattern: '/collaboration-workspace/overview',
      getURL: () => `/collaboration-workspace/overview`,
      permissions: [],
    },
  },
  assessment: {
    root: {
      pattern: '/collaboration-workspace/assessment/:assessmentId',
      getURL: ({ assessmentId }: { assessmentId: string }) => `/collaboration-workspace/assessment/${assessmentId}`,
      permissions: [],
    },
  },
}
