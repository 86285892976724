import { Box, Typography } from '@mui/material'
import { Label } from 'components/ui-kit/form/common/label/Label'
import { getRiskScoreName } from 'pages/policyCenter/assessments/questions/constants'
import { Error } from 'components/ui-kit/form/common/error/Error'
import { Checkbox } from '@ketch-com/deck'
import { useExternalAssessmentResponse } from '../../context/ExternalAssessmentResponseContext'
import { useMemo } from 'react'

export const AssessmentExternalMultiChoiceInput: React.FC<{}> = () => {
  const { isValid, changeValue, choices, block } = useExternalAssessmentResponse()

  const choiceOptions = useMemo(() => block.question?.attributes?.choices || [], [block.question?.attributes?.choices])

  return (
    <Box>
      <Box
        border={({ palette }) => (!isValid ? `1px solid ${palette.chileanFire.main}` : undefined)}
        ml={-1}
        pl={1}
        borderRadius="5px"
      >
        {choiceOptions.map(choice => (
          <Box key={choice.id} display="flex" alignItems="center" mb={1.5} mt={1} gap={1}>
            <Checkbox checked={choices.includes(choice.id || '')} onChange={() => changeValue(choice.id || '')} />
            <Label hasMarginBottom={false}>
              <Typography variant="body" component="div">
                {choice.displayText}
              </Typography>
              {choice.riskID ? (
                <Typography variant="smallLabel" color="darkDuskFaded.main">
                  Risk Score: {getRiskScoreName(choice.riskID)}
                </Typography>
              ) : null}
            </Label>
          </Box>
        ))}
      </Box>
      {!isValid ? <Error>Required</Error> : null}
    </Box>
  )
}
