import {
  BannerButtonAction,
  CanonicalRightCode,
  PreferenceCenterPage,
  QuickLinkAction,
  RightsFormMode,
  SwitchButtonDisplay,
} from '@ketch-sdk/ketch-types'
import { useMemo } from 'react'
import { validationMessages } from 'utils/constants/forms/validationMessages'
import * as yup from 'yup'
import { ExperienceBuilderSection, PreferenceExperienceBuilderSection } from '../components/builder/utils/enums'

export const experienceUpsertFormKeys = {
  experienceName: 'name',
  experienceType: 'type',

  // Layout - Banner
  bannerHeaderVisible: 'layout.banner.header.visible',
  bannerHeaderCloseButtonVisible: 'layout.banner.header.closeButtonVisible',
  bannerFooterGpcBadgeVisible: 'layout.banner.footer.gpcBadgeVisible',
  bannerPrimaryButtonVisible: 'layout.banner.buttons.primary.visible',
  bannerPrimaryButtonAction: 'layout.banner.buttons.primary.action',
  bannerPrimaryButtonActionURL: 'layout.banner.buttons.primary.url',
  bannerSecondaryButtonVisible: 'layout.banner.buttons.secondary.visible',
  bannerSecondaryButtonAction: 'layout.banner.buttons.secondary.action',
  bannerSecondaryButtonActionURL: 'layout.banner.buttons.secondary.url',
  bannerTertiaryButtonVisible: 'layout.banner.buttons.tertiary.visible',
  bannerTertiaryButtonAction: 'layout.banner.buttons.tertiary.action',
  bannerTertiaryButtonActionURL: 'layout.banner.buttons.tertiary.url',

  // Layout - Modal
  modalHeaderVisible: 'layout.modal.header.visible',
  modalHeaderCloseButtonVisible: 'layout.modal.header.closeButtonVisible',
  modalDescriptionTitleVisible: 'layout.modal.description.title.visible',
  modalListHeaderVisible: 'layout.modal.purposeListHeader.visible',
  modalListHeaderUseDefaultText: 'layout.modal.purposeListHeader.useDefaultText',
  modalListHeaderVendorsLinkVisible: 'layout.modal.purposeListHeader.links[0].visible',
  modalListHeaderVendorsLinkUseDefaultText: 'layout.modal.purposeListHeader.links[0].useDefaultText',
  modalListHeaderBulkActionButtonsVisible: 'layout.modal.purposeListHeader.bulkActionButtons.visible',
  modalListHeaderBulkActionButtonsUseDefaultText: 'layout.modal.purposeListHeader.bulkActionButtons.useDefaultText',
  modalPurposeListLegalBasisVisible: 'layout.modal.purposeList.legalBasisVisible',
  modalPurposeListSwitchButtonLabelsVisible: 'layout.modal.purposeList.switchButtonLabels.visible',
  modalPurposeListSwitchButtonLabelsDisplay: 'layout.modal.purposeList.switchButtonLabels.display',
  modalPurposeListSwitchButtonLabelsUseDefaultText: 'layout.modal.purposeList.switchButtonLabels.useDefaultText',
  modalPurposeListVendorsVisible: 'layout.modal.purposeList.vendors.visible',
  modalPurposeListVendorsIncludeOtherVendors: 'layout.modal.purposeList.vendors.includeOtherVendors',
  modalPurposeListVendorsOtherVendorIds: 'layout.modal.purposeList.vendors.otherVendorIds',
  modalPurposeListVendorsLinkUseDefaultText: 'layout.modal.purposeList.vendors.link.useDefaultText',
  modalFooterGpcBadgeVisible: 'layout.modal.footer.gpcBadgeVisible',

  // Layout - Preference
  preferenceGpcBadgeVisible: 'layout.preference.gpcBadgeVisible',
  preferenceExitButtonPosition: 'layout.preference.exitPosition',

  preferenceWelcomeTabWelcomeMsgVisible: 'layout.preference.tabs.welcome.welcomeMsg.visible',
  preferenceWelcomeTabQuickLinksVisible: 'layout.preference.tabs.welcome.quickLinks.visible',
  preferenceWelcomeTabQuickLinksLinkOneAction: 'layout.preference.tabs.welcome.quickLinks.links[0].action',
  preferenceWelcomeTabQuickLinksLinkOnePage: 'layout.preference.tabs.welcome.quickLinks.links[0].page',
  preferenceWelcomeTabQuickLinksLinkOneUrl: 'layout.preference.tabs.welcome.quickLinks.links[0].url',
  preferenceWelcomeTabQuickLinksLinkOneVisible: 'layout.preference.tabs.welcome.quickLinks.links[0].visible',
  preferenceWelcomeTabQuickLinksLinkTwoAction: 'layout.preference.tabs.welcome.quickLinks.links[1].action',
  preferenceWelcomeTabQuickLinksLinkTwoPage: 'layout.preference.tabs.welcome.quickLinks.links[1].page',
  preferenceWelcomeTabQuickLinksLinkTwoUrl: 'layout.preference.tabs.welcome.quickLinks.links[1].url',
  preferenceWelcomeTabQuickLinksLinkTwoVisible: 'layout.preference.tabs.welcome.quickLinks.links[1].visible',
  preferenceWelcomeTabQuickLinksLinkThreeAction: 'layout.preference.tabs.welcome.quickLinks.links[2].action',
  preferenceWelcomeTabQuickLinksLinkThreePage: 'layout.preference.tabs.welcome.quickLinks.links[2].page',
  preferenceWelcomeTabQuickLinksLinkThreeUrl: 'layout.preference.tabs.welcome.quickLinks.links[2].url',
  preferenceWelcomeTabQuickLinksLinkThreeVisible: 'layout.preference.tabs.welcome.quickLinks.links[2].visible',
  preferenceWelcomeTabAboutVisible: 'layout.preference.tabs.welcome.about.visible',
  preferenceWelcomeTabFooterShowGpcBadge: 'layout.preference.gpcBadgeVisible',
  preferencePurposesTabHeaderVisible: 'layout.preference.tabs.purposes.header.title.visible',
  preferencePurposesTabListHeaderTitleVisible: 'layout.preference.tabs.purposes.purposeListHeader.titleVisible',
  preferencePurposesTabListHeaderUseDefaultTitle: 'layout.preference.tabs.purposes.purposeListHeader.useDefaultText',
  preferencePurposesTabListHeaderVendorsLinkVisible:
    'layout.preference.tabs.purposes.purposeListHeader.links[0].visible',
  preferencePurposesTabListHeaderVendorsLinkUseDefaultText:
    'layout.preference.tabs.purposes.purposeListHeader.links[0].useDefaultText',
  preferencePurposesTabListHeaderBulkActionButtonsVisible:
    'layout.preference.tabs.purposes.purposeListHeader.bulkActionButtons.visible',
  preferencePurposesTabListHeaderBulkActionButtonsUseDefaultText:
    'layout.preference.tabs.purposes.purposeListHeader.bulkActionButtons.useDefaultText',
  preferencePurposesTabListLegalBasisVisible: 'layout.preference.tabs.purposes.purposeList.legalBasisVisible',
  preferencePurposesTabListSwitchButtonLabelsVisible:
    'layout.preference.tabs.purposes.purposeList.switchButtonLabels.visible',
  preferencePurposesTabListSwitchButtonLabelsDisplay:
    'layout.preference.tabs.purposes.purposeList.switchButtonLabels.display',
  preferencePurposesTabListSwitchButtonLabelsUseDefaultText:
    'layout.preference.tabs.purposes.purposeList.switchButtonLabels.useDefaultText',
  preferencePurposesTabListSwitchButtonLabels: 'layout.preference.tabs.purposes.purposeList.switchButtonLabels.display',
  preferencePurposesTabListVendorsVisible: 'layout.preference.tabs.purposes.purposeList.vendors.visible',
  preferencePurposesTabListVendorsIncludeOtherVendors:
    'layout.preference.tabs.purposes.purposeList.vendors.includeOtherVendors',
  preferencePurposesTabListVendorsOtherVendorIds: 'layout.preference.tabs.purposes.purposeList.vendors.otherVendorIds',
  preferencePurposesTabListVendorsLinkUseDefaultText:
    'layout.preference.tabs.purposes.purposeList.vendors.link.useDefaultText',
  preferencePurposesTabActionButtonUseDefaultText: 'layout.preference.tabs.purposes.actionButtonUseDefaultText',
  preferenceSubscriptionsTabHeaderTitleVisible: 'layout.preference.tabs.subscriptions.header.title.visible',
  preferenceSubscriptionsTabUnsubscribeAllVisible: 'layout.preference.tabs.subscriptions.unsubscribeAll.visible',
  preferenceSubscriptionsTabUnsubscribeAllPosition: 'layout.preference.tabs.subscriptions.unsubscribeAll.position',
  preferenceSubscriptionsTabUnsubscribeAllTitleUseDefaultText:
    'layout.preference.tabs.subscriptions.unsubscribeAll.title.useDefaultText',
  preferenceSubscriptionsTabUnsubscribeAllDescriptionVisible:
    'layout.preference.tabs.subscriptions.unsubscribeAll.description.visible',
  preferenceSubscriptionsTabUnsubscribeAllSwitchButtonLabelsVisible:
    'layout.preference.tabs.subscriptions.unsubscribeAll.switchButton.visible',
  preferenceSubscriptionsTabUnsubscribeAllSwitchButtonLabelsUseDefault:
    'layout.preference.tabs.subscriptions.unsubscribeAll.switchButton.useDefaultText',
  preferenceSubscriptionsTabUnsubscribeAllImpact: 'layout.preference.tabs.subscriptions.unsubscribeAll.impact',
  preferenceSubscriptionsTabListItems: 'layout.preference.tabs.subscriptions.list.items',
  preferenceSubscriptionsTabListSwitchButtonLabelsVisible:
    'layout.preference.tabs.subscriptions.list.switchButtons.visible',
  preferenceSubscriptionsTabListSwitchButtonLabelsUseDefault:
    'layout.preference.tabs.subscriptions.list.switchButtons.useDefaultText',
  preferenceSubscriptionTabActionButtonUseDefault: 'layout.preference.tabs.subscriptions.actionButtonUseDefaultText',
  preferenceRequestsTabHomeHeaderTitleVisible: 'layout.preference.tabs.requests.home.header.title.visible',
  preferenceRequestsTabHomeRightsTitleUseDefaultText:
    'layout.preference.tabs.requests.home.rights.title.useDefaultText',
  preferenceRequestsTabHomeRightsFormsMode: 'layout.preference.tabs.requests.home.rights.forms.mode',
  preferenceRequestsTabHomeRightsFormsSingleFormID: 'layout.preference.tabs.requests.home.rights.forms.singleFormID',
  preferenceRequestsTabHomeRightsFormsDetails: 'layout.preference.tabs.requests.home.rights.forms.details',
  preferenceRequestsTabHomeRightsFormsCustomFormMappingsRequestType:
    'layout.preference.tabs.requests.home.rights.forms.details[].requestType',
  preferenceRequestsTabHomeRightsFormsCustomFormMappingsDefaultFormID:
    'layout.preference.tabs.requests.home.rights.forms.details[].defaultFormID',
  preferenceRequestsTabHomeRightsFormsCustomFormMappingsRightMappings:
    'layout.preference.tabs.requests.home.rights.forms.details[].rightFormMappings',
  preferenceRequestsTabHomeRightsFormRightCode:
    'layout.preference.tabs.requests.home.rights.forms.details[].rightFormMappings[].rightCode',
  preferenceRequestsTabHomeRightsFormFormID:
    'layout.preference.tabs.requests.home.rights.forms.details[].rightFormMappings[].formID',
  preferenceRequestsTabRightFormEnableRecaptcha: 'layout.preference.tabs.requests.rightForm.enableRecaptcha',
  preferenceRequestsTabRightFormActionButtonUseDefaultText:
    'layout.preference.tabs.requests.rightForm.actionButton.useDefaultText',
  preferenceRequestsTabSubmittedActionButtonVisible: 'layout.preference.tabs.requests.submitted.actionButton.visible',
  preferenceRequestsTabSubmittedActionButtonUseDefaultText:
    'layout.preference.tabs.requests.submitted.actionButton.useDefaultText',

  // Layout - Progressive Experience
  progressiveExperienceType: 'layout.progressiveConsent.type',
  progressiveExperienceConsentLayout: 'layout.progressiveConsent.consentLayout',
  progressiveExperiencePosition: 'layout.progressiveConsent.position',
  progressiveExperienceEmbedElementId: 'layout.progressiveConsent.embedElementId',
  progressiveExperienceFont: 'layout.progressiveConsent.font',
  progressiveExperienceHeaderVisible: 'layout.progressiveConsent.headerVisible',
  progressiveExperienceDescriptionVisible: 'layout.progressiveConsent.descriptionVisible',
  progressiveExperienceSubscriptionTopicCodes: 'layout.progressiveConsent.subscriptionTopicCodes',
  progressiveExperienceActionButtonsPrimaryVisible: 'layout.progressiveConsent.actionButtons.primary.visible',
  progressiveExperienceActionButtonsPrimaryAction: 'layout.progressiveConsent.actionButtons.primary.action',
  progressiveExperienceActionButtonsPrimaryPurposeCodes: 'layout.progressiveConsent.actionButtons.primary.purposeCodes',
  progressiveExperienceActionButtonsSecondaryVisible: 'layout.progressiveConsent.actionButtons.secondary.visible',
  progressiveExperienceActionButtonsSecondaryAction: 'layout.progressiveConsent.actionButtons.secondary.action',
  progressiveExperienceActionButtonsSecondaryPurposeCodes:
    'layout.progressiveConsent.actionButtons.secondary.purposeCodes',
  progressiveExperienceActionButtonsTertiaryVisible: 'layout.progressiveConsent.actionButtons.tertiary.visible',
  progressiveExperienceActionButtonsTertiaryAction: 'layout.progressiveConsent.actionButtons.tertiary.action',
  progressiveExperienceActionButtonsTertiaryPurposeCodes:
    'layout.progressiveConsent.actionButtons.tertiary.purposeCodes',
  progressiveExperienceSwitchButtonsVisible: 'layout.progressiveConsent.switchButtons.labelVisible',
  progressiveExperienceSwitchButtonsDisplay: 'layout.progressiveConsent.switchButtons.display',
  progressiveExperienceSwitchButtonsUseDefaultText: 'layout.progressiveConsent.switchButtons.useDefaultText',
  progressiveExperienceSwitchButtonsPurposeCodes: 'layout.progressiveConsent.switchButtons.purposeCodes',

  // Translations - Preference
  preferenceWelcomeTabHeaderTitle: 'translations.en.preference.header.title',
  preferenceWelcomeTabNavigationWelcomeTitle: 'translations.en.preference.navigation.welcomeTitle',
  preferenceWelcomeTabNavigationPrivacyPolicyTitle: 'translations.en.preference.navigation.privacyPolicyTitle',
  preferenceWelcomeTabNavigationPurposesTitle: 'translations.en.preference.navigation.purposesTitle',
  preferenceWelcomeTabNavigationSubscriptionsTitle: 'translations.en.preference.navigation.subscriptionsTitle',
  preferenceWelcomeTabNavigationRequestsTitle: 'translations.en.preference.navigation.requestsTitle',
  preferenceWelcomeTabFooterActionButtonText: 'translations.en.preference.footer.actionButtonText',
  preferenceWelcomeTabWelcomeMsgTitle: 'translations.en.preference.tabs.welcome.welcomeMsg.title',
  preferenceWelcomeTabWelcomeMsgSubtitle: 'translations.en.preference.tabs.welcome.welcomeMsg.subtitle',
  preferenceWelcomeTabQuickLinksLinkOneTitle: 'translations.en.preference.tabs.welcome.quickLinks.links[0].text',
  preferenceWelcomeTabQuickLinksLinkTwoTitle: 'translations.en.preference.tabs.welcome.quickLinks.links[1].text',
  preferenceWelcomeTabQuickLinksLinkThreeTitle: 'translations.en.preference.tabs.welcome.quickLinks.links[2].text',
  preferenceWelcomeTabAboutTitle: 'translations.en.preference.tabs.welcome.about.title',
  preferenceWelcomeTabAboutDescription: 'translations.en.preference.tabs.welcome.about.description',
  preferencePurposesTabHeaderTitle: 'translations.en.preference.tabs.purposes.header.title',
  preferencePurposesTabHeaderDescription: 'translations.en.preference.tabs.purposes.header.description',
  preferencePurposesTabListHeaderTitle: 'translations.en.preference.tabs.purposes.purposeListHeader.title',
  preferencePurposesTabListHeaderVendorsLinkText:
    'translations.en.preference.tabs.purposes.purposeListHeader.links[0].text',
  preferencePurposesTabListHeaderBulkActionButtonsAcceptAllText:
    'translations.en.preference.tabs.purposes.purposeListHeader.bulkActionButtons.acceptAllText',
  preferencePurposesTabListHeaderBulkActionButtonsRejectAllText:
    'translations.en.preference.tabs.purposes.purposeListHeader.bulkActionButtons.rejectAllText',
  preferencePurposesTabListSwitchButtonsOnText:
    'translations.en.preference.tabs.purposes.purposeList.switchButtonLabels.onText',
  preferencePurposesTabListSwitchButtonsOffText:
    'translations.en.preference.tabs.purposes.purposeList.switchButtonLabels.offText',
  preferencePurposesTabListSwitchButtonLabelsAlwaysOnText:
    'translations.en.preference.tabs.purposes.purposeList.switchButtonLabels.alwaysOnText',
  preferencePurposesTabListVendorsLinkText: 'translations.en.preference.tabs.purposes.purposeList.vendors.linkText',
  preferencePurposesTabListVendorsDescription:
    'translations.en.preference.tabs.purposes.purposeList.vendors.description',
  preferencePurposesTabActionButtonText: 'translations.en.preference.tabs.purposes.actionButtonText',
  preferenceSubscriptionsTabHeaderTitleText: 'translations.en.preference.tabs.subscriptions.header.title',
  preferenceSubscriptionsTabHeaderDescriptionText: 'translations.en.preference.tabs.subscriptions.header.description',
  preferenceSubscriptionsTabUnsubscribeAllTitle: 'translations.en.preference.tabs.subscriptions.unsubscribeAll.title',
  preferenceSubscriptionsTabUnsubscribeAllDescription:
    'translations.en.preference.tabs.subscriptions.unsubscribeAll.description',
  preferenceSubscriptionsTabUnsubscribeAllSwitchLabelsOnText:
    'translations.en.preference.tabs.subscriptions.unsubscribeAll.switchLabels.onText',
  preferenceSubscriptionsTabUnsubscribeAllSwitchLabelsOffText:
    'translations.en.preference.tabs.subscriptions.unsubscribeAll.switchLabels.offText',
  preferenceSubscriptionsTabListSwitchLabelsOnText:
    'translations.en.preference.tabs.subscriptions.listSwitchLabels.onText',
  preferenceSubscriptionsTabListSwitchLabelsOffText:
    'translations.en.preference.tabs.subscriptions.listSwitchLabels.offText',
  preferenceSubscriptionsTabActionButtonText: 'translations.en.preference.tabs.subscriptions.actionButtonText',
  preferenceRequestsTabHomeHeaderDescription: 'translations.en.preference.tabs.requests.home.header.description',
  preferenceRequestsTabHomeHeaderTitle: 'translations.en.preference.tabs.requests.home.header.title',
  preferenceRequestsTabHomeDsrPortalLinkTitle: 'translations.en.preference.tabs.requests.home.dsrPortalLink.title',
  preferenceRequestsTabHomeDsrPortalLinkDescription:
    'translations.en.preference.tabs.requests.home.dsrPortalLink.description',
  preferenceRequestsTabHomeRightsTitle: 'translations.en.preference.tabs.requests.home.rights.title',
  preferenceRequestsTabRightFormActionButtonText: 'translations.en.preference.tabs.requests.rightForm.actionButtonText',
  preferenceRequestsTabRightFormRecaptchaFailureText:
    'translations.en.preference.tabs.requests.rightForm.recaptchaFailureText',
  preferenceRequestsTabSubmittedTitle: 'translations.en.preference.tabs.requests.submitted.title',
  preferenceRequestsTabSubmittedSubtitle: 'translations.en.preference.tabs.requests.submitted.subtitle',
  preferenceRequestsTabSubmittedDescription: 'translations.en.preference.tabs.requests.submitted.description',
  preferenceRequestsTabSubmittedActionButtonText: 'translations.en.preference.tabs.requests.submitted.actionButtonText',

  // Translations - Banner
  bannerHeaderTitle: 'translations.en.banner.header.title',
  bannerDescription: 'translations.en.banner.description',
  bannerPrimaryButtonText: 'translations.en.banner.buttons.primary.text',
  bannerSecondaryButtonText: 'translations.en.banner.buttons.secondary.text',
  bannerTertiaryButtonText: 'translations.en.banner.buttons.tertiary.text',

  // Translations - Modal
  modalHeaderTitle: 'translations.en.modal.header.title',
  modalDescriptionTitle: 'translations.en.modal.description.title',
  modalDescriptionText: 'translations.en.modal.description.body',
  modalListHeaderText: 'translations.en.modal.purposeListHeader.text',
  modalListHeaderVendorsLinkText: 'translations.en.modal.purposeListHeader.links[0].text',
  modalListHeaderBulkActionButtonsAcceptAllText:
    'translations.en.modal.purposeListHeader.bulkActionButtons.acceptAllText',
  modalListHeaderBulkActionButtonsRejectAllText:
    'translations.en.modal.purposeListHeader.bulkActionButtons.rejectAllText',
  modalPurposeListSwitchButtonLabelsOnText: 'translations.en.modal.purposeList.switchButtonLabels.onText',
  modalPurposeListSwitchButtonLabelsOffText: 'translations.en.modal.purposeList.switchButtonLabels.offText',
  modalPurposeListSwitchButtonLabelsAlwaysOnText: 'translations.en.modal.purposeList.switchButtonLabels.alwaysOnText',
  modalPurposeListVendorsLinkText: 'translations.en.modal.purposeList.vendors.linkText',
  modalPurposeListVendorsDescription: 'translations.en.modal.purposeList.vendors.description',
  modalFooterActionButtonText: 'translations.en.modal.footer.actionButtonText',

  // Translations - Progressive Experience
  progressiveExperienceHeader: 'translations.en.progressiveConsent.header',
  progressiveExperienceDescription: 'translations.en.progressiveConsent.description',
  progressiveExperienceActionButtonsPrimaryText: 'translations.en.progressiveConsent.actionButtons.primaryText',
  progressiveExperienceActionButtonsSecondaryText: 'translations.en.progressiveConsent.actionButtons.secondaryText',
  progressiveExperienceActionButtonsTertiaryText: 'translations.en.progressiveConsent.actionButtons.tertiaryText',
  progressiveExperienceSwitchButtonsOnText: 'translations.en.progressiveConsent.switchButtons.onText',
  progressiveExperienceSwitchButtonsOffText: 'translations.en.progressiveConsent.switchButtons.offText',

  // Reset keys - Banner (used by the "Reset to Default" button in each form)
  resetBannerHeaderLayout: 'layout.banner.header',
  resetBannerButtonsLayout: 'layout.banner.buttons',
  resetBannerDescriptionLayout: 'layout.banner.description',
  resetBannerFooterLayout: 'layout.banner.footer',
  resetBannerHeaderTranslations: 'translations.en.banner.header',
  resetBannerButtonsTranslations: 'translations.en.banner.buttons',
  resetBannerDescriptionTranslations: 'translations.en.banner.description',
  resetBannerFooterTranslations: 'translations.en.banner.footer',

  // Reset keys - Modal
  resetModalHeaderLayout: 'layout.modal.header',
  resetModalDescriptionLayout: 'layout.modal.description',
  resetModalDescriptionTitleLayout: 'layout.modal.description',
  resetModalListHeaderLayout: 'layout.modal.purposeListHeader',
  resetModalPurposeListLayout: 'layout.modal.purposeList',
  resetModalFooterLayout: 'layout.modal.footer',
  resetModalHeaderTranslations: 'translations.en.modal.header',
  resetModalDescriptionTranslations: 'translations.en.modal.description',
  resetModalDescriptionTitleTranslations: 'translations.en.modal.description.title',
  resetModalListHeaderTranslations: 'translations.en.modal.purposeListHeader',
  resetModalPurposeListTranslations: 'translations.en.modal.purposeList',
  resetModalFooterTranslations: 'translations.en.modal.footer',

  // Reset keys - Preference
  resetPreferenceWelcomeTabHeaderLayout: 'layout.preference.header',
  resetPreferenceWelcomeTabFooterLayout: 'layout.preference.footer',
  resetPreferenceWelcomeTabWelcomeMsgLayout: 'layout.preference.tabs.welcome.welcomeMsg',
  resetPreferenceWelcomeTabQuickLinksLayout: 'layout.preference.tabs.welcome.quickLinks',
  resetPreferenceWelcomeTabAboutLayout: 'layout.preference.tabs.welcome.about',
  resetPreferenceWelcomeTabHeaderTranslations: 'translations.en.preference.header',
  resetPreferenceWelcomeTabNavigationTranslations: 'translations.en.preference.navigation',
  resetPreferenceWelcomeTabFooterTranslations: 'translations.en.preference.footer',
  resetPreferenceWelcomeTabExitButtonTranslations: 'translations.en.preference.footer.actionButtonText',
  resetPreferenceWelcomeTabWelcomeMsgTranslations: 'translations.en.preference.tabs.welcome.welcomeMsg',
  resetPreferenceWelcomeTabQuickLinksTranslations: 'translations.en.preference.tabs.welcome.quickLinks',
  resetPreferenceWelcomeTabAboutTranslations: 'translations.en.preference.tabs.welcome.about',
  resetPreferencePurposesTabHeaderTitleTranslations: 'translations.en.preference.tabs.purposes.header.title',
  resetPreferencePurposesTabHeaderDescriptionTranslations:
    'translations.en.preference.tabs.purposes.header.description',
  resetPreferencePurposesTabListHeaderTranslations: 'translations.en.preference.tabs.purposes.purposeListHeader',
  resetPreferencePurposesTabListTranslations: 'translations.en.preference.tabs.purposes.purposeList',
  resetPreferencePurposesTabActionButtonTranslations: 'translations.en.preference.tabs.purposes.actionButtonText',
  resetPreferencePurposesTabHeaderLayout: 'layout.preference.tabs.purposes.header',
  resetPreferencePurposesTabListHeaderLayout: 'layout.preference.tabs.purposes.purposeListHeader',
  resetPreferencePurposesTabListLayout: 'layout.preference.tabs.purposes.purposeList',
  resetPreferencePurposesTabFooterLayout: 'layout.preference.tabs.purposes.actionButtonUseDefaultText',
  resetPreferenceSubscriptionsTabHeaderTitleLayout: 'layout.preference.tabs.subscriptions.header.title',
  resetPreferenceSubscriptionsTabHeaderDescriptionLayout: 'layout.preference.tabs.subscriptions.header.description',
  resetPreferenceSubscriptionsTabUnsubscribeAllLayout: 'layout.preference.tabs.subscriptions.unsubscribeAll',
  resetPreferenceSubscriptionsTabListLayout: 'layout.preference.tabs.subscriptions.list',
  resetPreferenceSubscriptionsTabActionButtonLayout: 'layout.preference.tabs.subscriptions.actionButtonUseDefaultText',
  resetPreferenceSubscriptionsTabHeaderTitleTranslations: 'translations.en.preference.tabs.subscriptions.header.title',
  resetPreferenceSubscriptionsTabHeaderDescriptionTranslations:
    'translations.en.preference.tabs.subscriptions.header.description',
  resetPreferenceSubscriptionsTabUnsubscribeAllTranslations:
    'translations.en.preference.tabs.subscriptions.unsubscribeAll',
  resetPreferenceSubscriptionsTabListTranslations: 'translations.en.preference.tabs.subscriptions.listSwitchLabels',
  resetPreferenceSubscriptionsTabActionButtonTranslations:
    'translations.en.preference.tabs.subscriptions.actionButtonText',

  resetPreferenceRequestsTabHomeHeaderTitleLayout: 'layout.preference.tabs.requests.home.header',
  resetPreferenceRequestsTabHomeRightsLayout: 'layout.preference.tabs.requests.home.rights',
  resetPreferenceRequestsTabFormRecaptchaLayout: 'layout.preference.tabs.requests.rightForm.enableRecaptcha',
  resetPreferenceRequestsTabFormActionButtonLayout: 'layout.preference.tabs.requests.rightForm.actionButton',
  resetPreferenceRequestsTabSubmittedActionButtonLayout: 'layout.preference.tabs.requests.submitted.actionButton',
  resetPreferenceRequestsTabHomeHeaderTitleTranslations: 'translations.en.preference.tabs.requests.home.header.title',
  resetPreferenceRequestsTabHomeHeaderDescriptionTranslations:
    'translations.en.preference.tabs.requests.home.header.description',
  resetPreferenceRequestsTabHomeDsrLinkTranslations: 'translations.en.preference.tabs.requests.home.dsrPortalLink',
  resetPreferenceRequestsTabHomeRightsTranslations: 'translations.en.preference.tabs.requests.home.rights',
  resetPreferenceRequestsTabFormContentTranslations:
    'translations.en.preference.tabs.requests.rightForm.recaptchaFailureText',
  resetPreferenceRequestsTabFormActionButtonTranslations:
    'translations.en.preference.tabs.requests.rightForm.actionButtonText',
  resetPreferenceRequestsTabSubmittedHeaderTranslations: 'translations.en.preference.tabs.requests.submitted.title',
  resetPreferenceRequestsTabSubmittedSubtitleTranslations:
    'translations.en.preference.tabs.requests.submitted.subtitle',
  resetPreferenceRequestsTabSubmittedDescriptionTranslations:
    'translations.en.preference.tabs.requests.submitted.description',
  resetPreferenceRequestsTabSubmittedActionButtonTextTranslations:
    'translations.en.preference.tabs.requests.submitted.actionButtonText',

  // Reset keys - Progressive Experience
  resetProgressiveExperienceContainerType: 'layout.progressiveConsent.type',
  resetProgressiveExperienceContainerPosition: 'layout.progressiveConsent.position',
  resetProgressiveExperienceContainerConsentLayout: 'layout.progressiveConsent.consentLayout',
  resetProgressiveExperienceHeaderLayout: 'layout.progressiveConsent.header',
  resetProgressiveExperienceDescriptionLayout: 'layout.progressiveConsent.description',

  // Error section keys
  preferenceRequestsRightsError: 'layout.preference.tabs.requests.home.rights',
} as const

export const pageLabelFormKeys = {
  [PreferenceCenterPage.Welcome]: experienceUpsertFormKeys.preferenceWelcomeTabNavigationWelcomeTitle,
  [PreferenceCenterPage.PrivacyPolicy]: experienceUpsertFormKeys.preferenceWelcomeTabNavigationPrivacyPolicyTitle,
  [PreferenceCenterPage.Purpose]: experienceUpsertFormKeys.preferenceWelcomeTabNavigationPurposesTitle,
  [PreferenceCenterPage.Subscriptions]: experienceUpsertFormKeys.preferenceWelcomeTabNavigationSubscriptionsTitle,
  [PreferenceCenterPage.RequestsHome]: experienceUpsertFormKeys.preferenceWelcomeTabNavigationRequestsTitle,
  [PreferenceCenterPage.RequestsForm]: '',
  [PreferenceCenterPage.RequestsSubmitted]: '',
}

// Section that the builder should jump to when there is a formik error at that key
export const errorSections: { [errorFormKey: string]: ExperienceBuilderSection } = {
  [experienceUpsertFormKeys.preferenceRequestsRightsError]:
    PreferenceExperienceBuilderSection.RequestsHomeRightsListRequestTypes,
  [experienceUpsertFormKeys.preferenceWelcomeTabQuickLinksLinkOneUrl]:
    PreferenceExperienceBuilderSection.WelcomeTabQuickLinks,
  [experienceUpsertFormKeys.preferenceWelcomeTabQuickLinksLinkTwoUrl]:
    PreferenceExperienceBuilderSection.WelcomeTabQuickLinksLinkTwo,
  [experienceUpsertFormKeys.preferenceWelcomeTabQuickLinksLinkThreeUrl]:
    PreferenceExperienceBuilderSection.WelcomeTabQuickLinksLinkThree,
}

// Enum Schemas
const SwitchButtonDisplaySchema = yup.string().oneOf(Object.values(SwitchButtonDisplay))
const BannerButtonActionSchema = yup.string().oneOf(Object.values(BannerButtonAction))
const QuickLinkActionSchema = yup.string().oneOf(Object.values(QuickLinkAction))
const PreferenceCenterPageSchema = yup.string().oneOf(Object.values(PreferenceCenterPage))
const RightsFormModeSchema = yup.string().oneOf(Object.values(RightsFormMode))
const CanonicalRightCodeSchema = yup.string().oneOf(Object.values(CanonicalRightCode))

// Simple Nested Schemas
const HeaderExperienceLayoutConfigSchema = yup.object().shape({
  visible: yup.boolean().notRequired(),
  closeButtonVisible: yup.boolean().notRequired(),
})

const FooterExperienceLayoutConfigSchema = yup.object().shape({
  ketchBadgeVisible: yup.boolean().notRequired(),
  gpcBadgeVisible: yup.boolean().notRequired(),
})

const TextBlockTitleExperienceLayoutConfigSchema = yup.object().shape({
  visible: yup.boolean().notRequired(),
})

const TextBlockExperienceLayoutConfigSchema = yup.object().shape({
  title: TextBlockTitleExperienceLayoutConfigSchema.notRequired(),
})

const PreferenceTabHeaderExperienceLayoutConfigSchema = yup.object().shape({
  title: TextBlockTitleExperienceLayoutConfigSchema.notRequired(),
})

const SwitchButtonsExperienceLayoutConfigSchema = yup.object().shape({
  visible: yup.boolean().notRequired(),
  display: SwitchButtonDisplaySchema.notRequired(),
  useDefaultText: yup.boolean().notRequired(),
})

const BannerActionButtonExperienceLayoutConfigSchema = yup.object().shape({
  visible: yup.boolean().notRequired(),
  action: BannerButtonActionSchema.notRequired(),
  url: yup
    .string()
    .url(validationMessages.VALID_URL)
    .when('action', {
      is: BannerButtonAction.OpenURL,
      then: schema => schema.required(validationMessages.REQUIRED),
      otherwise: schema => schema.notRequired(),
    }),
})

const BannerButtonsExperienceLayoutConfigSchema = yup.object().shape({
  primary: BannerActionButtonExperienceLayoutConfigSchema.notRequired(),
  secondary: BannerActionButtonExperienceLayoutConfigSchema.notRequired(),
  tertiary: BannerActionButtonExperienceLayoutConfigSchema.notRequired(),
  close: yup.boolean().notRequired(),
})

const ModalListHeaderExperienceLayoutConfigSchema = yup.object().shape({
  visible: yup.boolean().notRequired(),
  useDefault: yup.boolean().notRequired(),
})

const ModalPurposeListExperienceLayoutConfigSchema = yup.object().shape({
  legalBasisVisible: yup.boolean().notRequired(),
  switchButtonLabels: SwitchButtonsExperienceLayoutConfigSchema.notRequired(),
})

const QuickLinkExperienceLayoutConfigSchema = yup.object().shape({
  action: QuickLinkActionSchema.notRequired(),
  page: PreferenceCenterPageSchema.notRequired(),
  url: yup
    .string()
    .url(validationMessages.VALID_URL)
    .when('visible', {
      is: true,
      then: schema =>
        schema.when('action', {
          is: QuickLinkAction.OpenUrl,
          then: schema => schema.required(validationMessages.REQUIRED),
          otherwise: schema => schema.notRequired(),
        }),
      otherwise: schema => schema.notRequired(),
    }),
})

const WelcomeTabWelcomeMsgExperienceLayoutConfigSchema = yup.object().shape({
  visible: yup.boolean().notRequired(),
})

const WelcomeTabQuickLinksExperienceLayoutConfigSchema = yup.object().shape({
  visible: yup.boolean().notRequired(),
  links: yup.array().when('visible', {
    is: true,
    then: schema => schema.of(QuickLinkExperienceLayoutConfigSchema),
    otherwise: schema => schema.notRequired(),
  }),
})

const WelcomeTabAboutExperienceLayoutConfigSchema = yup.object().shape({
  visible: yup.boolean().notRequired(),
})

const PreferencePrivacyPolicyTabExperienceLayoutConfigSchema = yup.object().shape({
  visible: yup.boolean().notRequired(),
  policyDocumentId: yup.string().notRequired(),
})

const PurposesTabListHeaderExperienceLayoutConfigSchema = yup.object().shape({
  titleVisible: yup.boolean().notRequired(),
  useDefaultTitle: yup.boolean().notRequired(),
})

const PurposesTabListExperienceLayoutConfigSchema = yup.object().shape({
  legalBasisVisible: yup.boolean().notRequired(),
  switchButtonLabels: SwitchButtonsExperienceLayoutConfigSchema.notRequired(),
})

const PreferenceSubscriptionsTabExperienceLayoutConfigSchema = yup.object().shape({
  header: PreferenceTabHeaderExperienceLayoutConfigSchema.notRequired(),
  globalControlSwitchLabel: SwitchButtonsExperienceLayoutConfigSchema.notRequired(),
  listSwitchLabels: SwitchButtonsExperienceLayoutConfigSchema.notRequired(),
})

const RightFormMappingSchema = yup.object().shape({
  rightCode: yup.string().required(validationMessages.REQUIRED),
  formID: yup.string().required(validationMessages.REQUIRED),
})

const CustomRequestMappingSchema = yup.object().shape({
  canonicalRight: CanonicalRightCodeSchema.required(),
  defaultFormID: yup.string().required(validationMessages.REQUIRED),
  rightFormMappings: yup.array().of(RightFormMappingSchema).notRequired(),
})

const RightsFormsExperienceLayoutConfigSchema = yup.object().shape({
  mode: RightsFormModeSchema.required(),
  singleFormID: yup.string().when('mode', {
    is: 'single',
    then: schema => schema.required(validationMessages.REQUIRED),
    otherwise: schema => schema.notRequired(),
  }),
  details: yup.array().when('mode', {
    is: 'custom',
    then: schema => schema.of(CustomRequestMappingSchema).required(),
    otherwise: schema => schema.notRequired(),
  }),
})

const RightsTitleExperienceLayoutConfigSchema = yup.object().shape({
  useDefault: yup.boolean().notRequired(),
})

const RequestsTabRightsExperienceLayoutConfigSchema = yup.object().shape({
  title: RightsTitleExperienceLayoutConfigSchema.notRequired(),
  forms: RightsFormsExperienceLayoutConfigSchema.notRequired(),
})

const RequestsTabHomeExperienceLayoutConfigSchema = yup.object().shape({
  header: PreferenceTabHeaderExperienceLayoutConfigSchema.notRequired(),
  rights: RequestsTabRightsExperienceLayoutConfigSchema.notRequired(),
})

const RequestsTabRightFormExperienceLayoutConfigSchema = yup.object().shape({
  enableRecaptcha: yup.boolean().notRequired(),
})

// More Complex Nested Schemas
const BannerExperienceLayoutConfigSchema = yup.object().shape({
  header: HeaderExperienceLayoutConfigSchema.notRequired(),
  buttons: BannerButtonsExperienceLayoutConfigSchema.notRequired(),
  footer: FooterExperienceLayoutConfigSchema.notRequired(),
})

const ModalExperienceLayoutConfigSchema = yup.object().shape({
  header: HeaderExperienceLayoutConfigSchema.notRequired(),
  description: TextBlockExperienceLayoutConfigSchema.notRequired(),
  listHeader: ModalListHeaderExperienceLayoutConfigSchema.notRequired(),
  purposeList: ModalPurposeListExperienceLayoutConfigSchema.notRequired(),
  footer: FooterExperienceLayoutConfigSchema.notRequired(),
})

const PreferenceWelcomeTabExperienceLayoutConfigSchema = yup.object().shape({
  footer: FooterExperienceLayoutConfigSchema.notRequired(),
  welcomeMsg: WelcomeTabWelcomeMsgExperienceLayoutConfigSchema.notRequired(),
  quickLinks: WelcomeTabQuickLinksExperienceLayoutConfigSchema.notRequired(),
  about: WelcomeTabAboutExperienceLayoutConfigSchema.notRequired(),
})

const PreferencePurposesTabExperienceLayoutConfigSchema = yup.object().shape({
  header: PreferenceTabHeaderExperienceLayoutConfigSchema.notRequired(),
  listHeader: PurposesTabListHeaderExperienceLayoutConfigSchema.notRequired(),
  list: PurposesTabListExperienceLayoutConfigSchema.notRequired(),
})

const PreferenceRequestsTabExperienceLayoutConfigSchema = yup.object().shape({
  home: RequestsTabHomeExperienceLayoutConfigSchema.notRequired(),
  rightForm: RequestsTabRightFormExperienceLayoutConfigSchema.notRequired(),
})

const PreferenceTabsExperienceLayoutConfigSchema = yup.object().shape({
  welcome: PreferenceWelcomeTabExperienceLayoutConfigSchema.notRequired(),
  privacyPolicy: PreferencePrivacyPolicyTabExperienceLayoutConfigSchema.notRequired(),
  purposes: PreferencePurposesTabExperienceLayoutConfigSchema.notRequired(),
  subscriptions: PreferenceSubscriptionsTabExperienceLayoutConfigSchema.notRequired(),
  requests: PreferenceRequestsTabExperienceLayoutConfigSchema.notRequired(),
})

const PreferenceExperienceLayoutConfigSchema = yup.object().shape({
  tabs: PreferenceTabsExperienceLayoutConfigSchema,
})

const HeaderExperienceTranslationConfigSchema = yup.object().shape({
  title: yup.string().optional(),
})

const FooterExperienceTranslationConfigSchema = yup.object().shape({
  actionButtonText: yup.string().optional(),
})

const SwitchButtonsExperienceTranslationConfigSchema = yup.object().shape({
  onText: yup.string().optional(),
  offText: yup.string().optional(),
})

const TextBlockExperienceTranslationConfigSchema = yup.object().shape({
  title: yup.string().optional(),
  body: yup.string().optional(),
})

const PreferenceTabHeaderExperienceTranslationConfigSchema = yup.object().shape({
  description: yup.string().optional(),
  title: yup.string().optional(),
})

const BannerActionButtonExperienceTranslationConfigSchema = yup.object().shape({
  text: yup.string().optional(),
})

const BannerButtonsExperienceTranslationConfigSchema = yup.object().shape({
  primary: BannerActionButtonExperienceTranslationConfigSchema.optional(),
  secondary: BannerActionButtonExperienceTranslationConfigSchema.optional(),
  tertiary: BannerActionButtonExperienceTranslationConfigSchema.optional(),
})

const BannerExperienceTranslationConfigSchema = yup.object().shape({
  header: HeaderExperienceTranslationConfigSchema.optional(),
  description: yup.string().optional(),
  buttons: BannerButtonsExperienceTranslationConfigSchema.optional(),
  footer: FooterExperienceTranslationConfigSchema.optional(),
})

const ModalListHeaderExperienceTranslationConfigSchema = yup.object().shape({
  text: yup.string().optional(),
})

const ModalPurposeListExperienceTranslationConfigSchema = yup.object().shape({
  switchButtonLabels: SwitchButtonsExperienceTranslationConfigSchema.optional(),
})

const ModalExperienceTranslationConfigSchema = yup.object().shape({
  header: HeaderExperienceTranslationConfigSchema.optional(),
  description: TextBlockExperienceTranslationConfigSchema.optional(),
  listHeader: ModalListHeaderExperienceTranslationConfigSchema.optional(),
  purposeList: ModalPurposeListExperienceTranslationConfigSchema.optional(),
  footer: FooterExperienceTranslationConfigSchema.optional(),
})

const WelcomeTabHeaderExperienceTranslationConfigSchema = yup.object().shape({
  title: yup.string().optional(),
})

const WelcomeTabNavigationExperienceTranslationConfigSchema = yup.object().shape({
  welcomeTitle: yup.string().optional(),
  privacyPolicyTitle: yup.string().optional(),
  purposesTitle: yup.string().optional(),
  subscriptionsTitle: yup.string().optional(),
  requestsTitle: yup.string().optional(),
})

const WelcomeTabWelcomeMsgExperienceTranslationConfigSchema = yup.object().shape({
  title: yup.string().optional(),
  subtitle: yup.string().optional(),
})

const QuickLinkExperienceTranslationConfigSchema = yup.object().shape({
  text: yup.string().optional(),
})

const WelcomeTabQuickLinksExperienceTranslationConfigSchema = yup.array().of(QuickLinkExperienceTranslationConfigSchema)

const WelcomeTabAboutExperienceTranslationConfigSchema = yup.object().shape({
  title: yup.string().optional(),
  description: yup.string().optional(),
})

const PreferenceWelcomeTabExperienceTranslationConfigSchema = yup.object().shape({
  header: WelcomeTabHeaderExperienceTranslationConfigSchema.optional(),
  navigation: WelcomeTabNavigationExperienceTranslationConfigSchema.optional(),
  footer: FooterExperienceTranslationConfigSchema.optional(),
  welcomeMsg: WelcomeTabWelcomeMsgExperienceTranslationConfigSchema.optional(),
  quickLinks: yup.object().shape({
    links: WelcomeTabQuickLinksExperienceTranslationConfigSchema.optional(),
  }),
  about: WelcomeTabAboutExperienceTranslationConfigSchema.optional(),
})

const PurposesTabListHeaderExperienceTranslationConfigSchema = yup.object().shape({
  title: yup.string().optional(),
})

const PurposesTabListExperienceTranslationConfigSchema = yup.object().shape({
  switchButtonLabels: SwitchButtonsExperienceTranslationConfigSchema.optional(),
})

const PreferencePurposesTabExperienceTranslationConfigSchema = yup.object().shape({
  header: PreferenceTabHeaderExperienceTranslationConfigSchema.optional(),
  listHeader: PurposesTabListHeaderExperienceTranslationConfigSchema.optional(),
  list: PurposesTabListExperienceTranslationConfigSchema.optional(),
  actionButtonText: yup.string().optional(),
})

const PreferenceSubscriptionsTabExperienceTranslationConfigSchema = yup.object().shape({
  header: PreferenceTabHeaderExperienceTranslationConfigSchema.optional(),
  globalControlSwitchLabel: SwitchButtonsExperienceTranslationConfigSchema.optional(),
  listSwitchLabels: SwitchButtonsExperienceTranslationConfigSchema.optional(),
  actionButtonText: yup.string().optional(),
})

const RequestsTabDsrLinkExperienceTranslationConfigSchema = yup.object().shape({
  title: yup.string().optional(),
  subtitle: yup.string().optional(),
})

const RequestsTabRightsExperienceTranslationConfigSchema = yup.object().shape({
  title: yup.string().optional(),
})

const RequestsTabHomeExperienceTranslationConfigSchema = yup.object().shape({
  header: PreferenceTabHeaderExperienceTranslationConfigSchema.optional(),
  dsrPortalLink: RequestsTabDsrLinkExperienceTranslationConfigSchema.optional(),
  rights: RequestsTabRightsExperienceTranslationConfigSchema.optional(),
})

const RequestsTabSubmittedExperienceTranslationConfigSchema = yup.object().shape({
  title: yup.string().optional(),
  subtitle: yup.string().optional(),
  description: yup.string().optional(),
})

const PreferenceRequestsTabExperienceTranslationConfigSchema = yup.object().shape({
  home: RequestsTabHomeExperienceTranslationConfigSchema.optional(),
  submitted: RequestsTabSubmittedExperienceTranslationConfigSchema.optional(),
})

const PreferenceTabsExperienceTranslationConfigSchema = yup.object().shape({
  welcome: PreferenceWelcomeTabExperienceTranslationConfigSchema.optional(),
  purposes: PreferencePurposesTabExperienceTranslationConfigSchema.optional(),
  subscriptions: PreferenceSubscriptionsTabExperienceTranslationConfigSchema.optional(),
  requests: PreferenceRequestsTabExperienceTranslationConfigSchema.optional(),
})

const PreferenceExperienceTranslationConfigSchema = yup.object().shape({
  tabs: PreferenceTabsExperienceTranslationConfigSchema.optional(),
})

const ExperienceTranslationConfigSchema = yup.object().shape({
  banner: BannerExperienceTranslationConfigSchema.optional(),
  modal: ModalExperienceTranslationConfigSchema.optional(),
  preference: PreferenceExperienceTranslationConfigSchema.optional(),
})

export const useExperienceValidationSchema = () => {
  return useMemo(() => {
    return yup.object().shape({
      name: yup.string().required('Please enter a name'),
      type: yup.string().required(),
      layout: yup.object().when('type', {
        is: 'consent',
        then: schema =>
          schema.shape({
            banner: BannerExperienceLayoutConfigSchema.notRequired(),
            modal: ModalExperienceLayoutConfigSchema.notRequired(),
          }),
        otherwise: schema =>
          schema.when('type', {
            is: 'progressive',
            then: schema =>
              schema.shape({
                progressive: schema.notRequired(),
              }),
            otherwise: schema =>
              schema.shape({
                preference: PreferenceExperienceLayoutConfigSchema.notRequired(),
              }),
          }),
      }),
      translations: yup.lazy(obj =>
        yup.object().shape(
          Object.keys(obj).reduce(
            (acc, key) => ({
              ...acc,
              [key]: ExperienceTranslationConfigSchema.optional(),
            }),
            {},
          ),
        ),
      ),
    })
  }, [])
}
