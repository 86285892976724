import {
  PutProcessingActivityAssessmentRequirementRequestBodyDTO,
  PutProcessingActivityAssessmentRequirementResponseBodyDTO,
} from '@ketch-com/figurehead'
import { API } from '../../../utils/api/api'
import { formatRequestString } from '../../../utils/helpers/api/queryString'

type Params = {
  formData: PutProcessingActivityAssessmentRequirementRequestBodyDTO
  activityId: string
  isV2FeatureFlagEnabled?: boolean
}

export const updateProcessingActivityAssessmentRequirement = ({
  formData,
  activityId,
  isV2FeatureFlagEnabled,
}: Params) => {
  const entityUrl = isV2FeatureFlagEnabled
    ? `/api/risk-review/processing-activities/${activityId}/assessment-requirement`
    : `/api/processing-activities/${activityId}/assessment-requirement`
  return API.put<PutProcessingActivityAssessmentRequirementResponseBodyDTO>(
    formatRequestString({
      entityUrl,
    }),
    formData,
  )
}
