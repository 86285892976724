import { APIRequestParams } from 'api/common/utils'
import { WebTagApproveBulkUpdateFormDTO } from 'interfaces/webtags/WebTags'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  organizationCode: string
  formData: WebTagApproveBulkUpdateFormDTO
}>

type Response = {}

export const bulkApproveWebTags = ({ organizationCode, formData }: Params) =>
  API.put<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/tags/approve`,
    }),
    formData,
  )
