import { ContentGroup } from '@ketch-com/deck'
import { Box } from '@mui/material'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import TextAreaInfoRow from 'components/InfoRow/TextAreaInfoRow'
import { DisclosureTranslations } from 'pages/dataSystems/DataSystem/components/DisclosureTranslations'
import { useDataSystemsContext } from 'pages/dataSystems/DataSystem/context/DataSystemContext'
import { RoutesManager } from 'utils/routing/routesManager'

export const DisclosuresTab: React.FC = () => {
  const { appDescriptor, updatedDataSystem, handleDisclosuresUpdate } = useDataSystemsContext()

  const breadcrumbs = [
    { title: 'Data Map', link: RoutesManager.systems.root.getURL() },
    { title: 'Systems', link: RoutesManager.systems.systems.root.getURL() },
    { title: appDescriptor?.dataSystem?.name, link: RoutesManager.systems.id.root.getURL({ id: appDescriptor?.id }) },
    { title: 'Disclosure' },
  ]

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <Box display="flex" flexDirection="column" gap={4} mb={6}>
        <ContentGroup
          title="Disclosure"
          subTitle="Text that will appear in experiences which disclose this system."
          titleVariant="h3"
        />
        <TextAreaInfoRow
          title={'Disclosed Description'}
          value={updatedDataSystem?.disclosures?.en?.description}
          setValue={disclosureDescription => handleDisclosuresUpdate('en', disclosureDescription)}
        />
        <DisclosureTranslations />
      </Box>
    </>
  )
}
