import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'
import { useFeatureFlag } from 'utils/hooks'
import { createUseQuery, CustomQueryConfig } from '../../common/query'
import { ApiQueryKeys } from '../../common/queryKeys'
import { fetchProcessingActivity } from '../fetchers/fetchProcessingActivity'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.processingActivity,
  queryFn: fetchProcessingActivity,
  select: res => res?.data.processingActivity || {},
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useProcessingActivity = (config: Config) => {
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isV2FeatureFlagEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.ASSESSMENT,
    FEATURE_FLAGS_VALUES.ASSESSMENTS_VERSION,
  )
  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
      isV2FeatureFlagEnabled,
    },
  })
}
