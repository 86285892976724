import React, { ChangeEvent, HTMLAttributes, useCallback, useEffect, useRef } from 'react'
import {
  ActionSheetItem,
  Button,
  DropListButton,
  Icon,
  InlineEdit,
  ListItemText,
  PopUp,
  SwitchToggle,
  theme,
} from '@ketch-com/deck'
import { AutocompleteRenderOptionState, Box, Typography } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import { useAssetV2 } from 'api/assets/queries/useAssetV2'
import { Input } from 'components/ui-kit/input/Input'
import { Error } from 'components/ui-kit/form/common/error/Error'
import { PERIOD_OPTIONS } from 'pages/assetManager/databases/upsert/components/EditAutoDiscoveryFormSection/constants'
import { getUtcOffsetMessage } from 'pages/assetManager/databases/upsert/components/EditAutoDiscoveryFormSection/utils'
import { CLASSIFICATION_SCHEDULE_FREQUENCY_OPTIONS, dayMapping, EXECUTION_DURATION_OPTIONS } from '../constants'
import { showToast } from 'components/modals/AlertComponent'
import { onlyNumericInput } from '../utils/onlyNumericInput'
import { RadioGroup } from 'components/RadioGroup/RadioGroup'
import { formatTo12HourTime } from '../utils/timeFormatter'
import { convertUTCToLocalTime } from '../utils/convertUtcToLocalTime'
import { useEditClassificationSchedule } from '../hooks/useEditClassificationSchedule'
import { ClassificationRefreshScheduleDay } from '@ketch-com/windlass/dist/appliance/databases'
import { AssetScheduleDTO } from '@ketch-com/figurehead'
import { validationRegExp } from 'utils/constants/forms/validationRegExp'
import { CSSTransition } from 'react-transition-group'
export interface EditSamplingModalProps {
  assetCode: string
  resourceTypeCode: string
  schedulingData: AssetScheduleDTO
  connectionId: string
  refetchInstalledDatasystemAssets: (option?: any) => Promise<any>
  onClose: () => void
}

interface FrequencyOption {
  id: string
  name: string
}

const useStyles = makeStyles(
  ({ spacing }) =>
    createStyles({
      timeInput: {
        maxWidth: '72px',
        marginRight: spacing(2),
      },
      dayOfWeekRadioGroup: {
        marginBottom: spacing(2),
      },
      hidden: {
        opacity: 0,
      },
    }),
  { name: 'EditAutoDiscoveryFormSection' },
)

export const EditSamplingModal: React.FC<EditSamplingModalProps> = ({
  assetCode,
  resourceTypeCode,
  onClose,
  schedulingData,
  refetchInstalledDatasystemAssets,
  connectionId,
}) => {
  const classes = useStyles()
  const {
    data: assetSummary,
    refetch,
    isLoading,
  } = useAssetV2({
    params: {
      assetCode,
      resourceTypeCode,
    },
  })
  const {
    errors,
    disableSchedule,
    setDisableSchedule,
    onSubmit,
    isUpdatingDatabase,
    values,
    resetForm,
    setFieldValue,
    handleChange,
    touched,
    setTouched,
  } = useEditClassificationSchedule({
    assetSummary,
    connectionId,
    onSubmitSuccess: () => {
      const result = Promise.all([refetchInstalledDatasystemAssets(), refetch()])
      handleClose()
      return result
    },
  })

  const handleClose = useCallback(() => {
    resetForm()
    if (onClose) {
      onClose()
    }
  }, [onClose, resetForm])

  const handleSave = useCallback(async () => {
    onSubmit(values)
  }, [onSubmit, values])

  const [frequency, setFrequency] = React.useState<FrequencyOption | undefined>(
    schedulingData?.frequency
      ? CLASSIFICATION_SCHEDULE_FREQUENCY_OPTIONS.find(option => option.id === `${schedulingData.frequency}`)
      : CLASSIFICATION_SCHEDULE_FREQUENCY_OPTIONS[0],
  )
  const [executionDuration, setExecutionDuration] = React.useState<string | undefined>(
    schedulingData?.executionDuration ? schedulingData.executionDuration : '6h',
  )
  const [sampleSize, setSampleSize] = React.useState<string | undefined>(
    schedulingData?.sampleSize ? schedulingData?.sampleSize.toString() : undefined,
  )
  const [sampleSizeError, setSampleSizeError] = React.useState<boolean>(false)

  const handleStartTimeInputOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // allow empty value to be set
    if (e.target.value === '') {
      setFieldValue('schedule.time', e.target.value)
      return
    }

    const isNumeric = validationRegExp.NUMERIC_INTEGER_STRING.test(
      e.target.value
        .split('')
        .filter(char => char !== ':')
        .join(''),
    )

    if (!isNumeric) {
      showToast({ content: 'Numbers only please', type: 'error' })
      return
    }

    if (e.target.value.length > 5) {
      showToast({ content: 'Invalid input', type: 'error' })
      return
    }
    setFieldValue('schedule.time', e.target.value)
  }

  const handleOnFrequencyChange = useCallback(
    (value: FrequencyOption) => {
      setFrequency(value)
      setFieldValue('schedule.frequency', value.id)
      // Set default day to Monday when frequency is set to weekly
      if (value.id === '2' && !values.schedule.day) {
        setFieldValue('schedule.day', ClassificationRefreshScheduleDay.Monday)
      }
    },
    [setFieldValue, values.schedule.day],
  )

  const handleOnExecutionDurationChange = useCallback(
    (value: string) => {
      setExecutionDuration(value)
      setFieldValue('schedule.executionDuration', value)
    },
    [setFieldValue],
  )

  const handleOnSampleSizeChange = useCallback(
    (text?: string) => {
      if (text === '' || text === undefined) {
        setSampleSizeError(false)
        setSampleSize(text)
        setFieldValue('schedule.sampleSize', undefined)

        return
      }
      const parseIntvalue = typeof text === 'string' ? parseInt(text) : NaN

      if (isNaN(parseIntvalue) || parseIntvalue < 100 || parseIntvalue > 100000) {
        showToast({ content: 'Sample size must be a number between 100 and 100000', type: 'error' })
        setSampleSizeError(true)
        setSampleSize(text)
        return
      }
      setSampleSizeError(false)
      setSampleSize(text)
      setFieldValue('schedule.sampleSize', parseIntvalue)
    },
    [setFieldValue],
  )

  const renderOptionHandler = useCallback(
    (props: HTMLAttributes<HTMLLIElement>, option: FrequencyOption | string, state: AutocompleteRenderOptionState) => {
      const { selected } = state
      if (typeof option === 'string') {
        return (
          <ActionSheetItem selected={selected} {...props}>
            <ListItemText selected={selected}>{option}</ListItemText>
          </ActionSheetItem>
        )
      }
      return (
        <ActionSheetItem selected={selected} {...props}>
          <ListItemText selected={selected}>{option.name}</ListItemText>
        </ActionSheetItem>
      )
    },
    [],
  )

  useEffect(() => {
    setFieldValue('schedule.executionDuration', schedulingData?.executionDuration)
    setFieldValue('schedule.sampleSize', schedulingData?.sampleSize)

    if (!values.schedule.time && schedulingData?.time && !touched.schedule?.time) {
      const time = formatTo12HourTime(schedulingData?.time).split(' ')
      const period = time[1] === 'AM' ? '1' : '2'

      setFieldValue('schedule.time', time[0])
      setFieldValue('schedule.period', period)
    }
    if (!values.schedule.day && schedulingData?.day && !touched.schedule?.day) {
      setFieldValue('schedule.day', schedulingData?.day ? dayMapping[`${schedulingData.day}`] : undefined)
    }
  }, [
    schedulingData,
    setFieldValue,
    values.schedule.time,
    touched.schedule?.time,
    values.schedule.day,
    touched.schedule?.day,
  ])

  const defaultUtcInLocalTime = convertUTCToLocalTime('24:00').split(' ')
  const timePlaceholder = defaultUtcInLocalTime[0]
  const periodPlaceholder = defaultUtcInLocalTime[1] === 'am' ? '1' : '2'

  const hasErrors = Object.keys(errors).length > 0

  const handleToggleSchedule = useCallback(
    (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setDisableSchedule(!checked)
    },
    [setDisableSchedule],
  )

  const nodeRef = useRef(null)

  return (
    <PopUp
      variant="modal"
      popUpWidth={'600px'}
      isLoading={isLoading}
      title="Classification Schedule Settings"
      onClose={handleClose}
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'flex-start',
          paddingTop: '75px',
        },
      }}
      PaperProps={{
        sx: { overflow: 'hidden', width: '600px' }, // Prevent overflow issues
      }}
      popUpActionChildren={
        <>
          <Button size="large" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button size="large" color="primary" disabled={sampleSizeError || hasErrors} onClick={handleSave}>
            Save
          </Button>
        </>
      }
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <Box
          display="flex"
          gap={2}
          sx={{
            backgroundColor: disableSchedule ? theme.palette.Common.AppBackground : theme.palette.Success.Disabled,
          }}
          width="100%"
          borderRadius={3}
          height="40px"
          alignItems="center"
          paddingX={1}
        >
          <SwitchToggle name="schedule.disableSchedule" onChange={handleToggleSchedule} checked={!disableSchedule} />
          <Typography
            variant="label"
            color={disableSchedule ? theme.palette.Text.Disabled : theme.palette.Success.Primary}
          >
            Classification
          </Typography>
        </Box>

        <CSSTransition
          in={!disableSchedule}
          timeout={300}
          classNames={{
            enter: 'expand-enter',
            enterActive: 'expand-enter-active',
            exit: 'expand-exit',
            exitActive: 'expand-exit-active',
          }}
          unmountOnExit
          nodeRef={nodeRef}
        >
          <Box
            ref={nodeRef}
            display="flex"
            flexDirection="column"
            gap={2}
            sx={{
              overflow: 'hidden',
              '&.expand-enter': {
                height: 0,
              },
              '&.expand-enter-active': {
                height: 385,
                transition: 'height 300ms ease-in-out',
              },
              '&.expand-exit': {
                height: 385,
              },
              '&.expand-exit-active': {
                height: 0,
                transition: 'height 300ms ease-in-out',
              },
            }}
          >
            <Box display="flex" gap={2}>
              <Box display="flex" flexDirection="column" gap={0.5}>
                <Typography variant="label">Frequency</Typography>
                <DropListButton
                  sx={{
                    width: '240px',
                  }}
                  disabled={disableSchedule}
                  disableClearable={frequency !== null}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  getOptionLabel={option => option.name}
                  options={CLASSIFICATION_SCHEDULE_FREQUENCY_OPTIONS}
                  onChange={(_, value) => value && handleOnFrequencyChange(value)}
                  value={frequency}
                  renderOption={renderOptionHandler}
                  placeholder="Select Frequency"
                  size="medium"
                />
              </Box>
              {frequency?.id === '3' ? (
                <Box display="flex" alignItems="center" mt={2.75} gap={0.5}>
                  <Icon name="FInfo" width={12} height={12} iconColor={theme.palette.Text.Secondary} />
                  <Typography variant="footnote">
                    Classification will be triggered on the first day of every month.
                  </Typography>
                </Box>
              ) : null}

              {frequency?.id === '2' ? (
                <Box display="flex" flexDirection="column" gap={0.5}>
                  <Typography variant="label">Starts On</Typography>
                  <DropListButton
                    sx={{
                      width: '240px',
                    }}
                    disabled={disableSchedule}
                    getOptionLabel={option => option.name}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={Object.values(ClassificationRefreshScheduleDay).map(day => ({
                      name: day,
                      id: day,
                    }))}
                    disableClearable
                    onChange={(_, value) => {
                      value && setFieldValue('schedule.day', value.id)
                    }}
                    renderOption={renderOptionHandler}
                    value={
                      values?.schedule?.day
                        ? {
                            name: values.schedule.day,
                            id: values.schedule.day,
                          }
                        : {
                            name: ClassificationRefreshScheduleDay.Monday,
                            id: ClassificationRefreshScheduleDay.Monday,
                          }
                    }
                  />
                </Box>
              ) : null}
            </Box>

            {/* Time Input */}
            <>
              <Box display="flex" flexDirection="column">
                <Box display="flex" alignItems="center">
                  <Input
                    disabled={isUpdatingDatabase || frequency?.id === '0' || disableSchedule}
                    className={classes.timeInput}
                    onChange={handleStartTimeInputOnChange}
                    placeholder={timePlaceholder}
                    name="schedule.time"
                    type="text"
                    onBlur={() => setTouched({ ...touched, schedule: { ...touched.schedule, time: true } })}
                    value={values?.schedule?.time || ''}
                    valid={touched?.schedule?.time ? !errors.schedule?.time : true}
                  />
                  <RadioGroup
                    name="schedule.period"
                    row
                    onChange={handleChange}
                    value={values?.schedule?.period || periodPlaceholder}
                    options={PERIOD_OPTIONS.map(({ id, name }) => ({
                      disabled: isUpdatingDatabase || frequency?.id === '0' || disableSchedule,
                      title: name,
                      value: id,
                    }))}
                  />
                </Box>
              </Box>

              {touched?.schedule?.time && errors.schedule?.time ? <Error>{errors?.schedule?.time}</Error> : null}

              <Box display="flex" flexDirection="column" gap={1}>
                <Box display="flex" alignItems="center">
                  <Typography variant="fadedLabel">{getUtcOffsetMessage()}</Typography>
                </Box>
              </Box>
            </>

            <Box display="flex" flexDirection="column" gap={2} mt={1}>
              <Box display="flex" flexDirection="column" gap={0.5}>
                <Typography variant="label">Execution Duration</Typography>
                <DropListButton
                  sx={{
                    width: '240px',
                  }}
                  disabled={frequency?.id === '0' || disableSchedule}
                  disableClearable={executionDuration !== null}
                  isOptionEqualToValue={(option, value) => option === value}
                  getOptionLabel={option => option}
                  options={EXECUTION_DURATION_OPTIONS}
                  onChange={(_, value) => value && handleOnExecutionDurationChange(value)}
                  value={executionDuration}
                  renderOption={renderOptionHandler}
                  placeholder={executionDuration || 'Select Duration'}
                  size="medium"
                />
              </Box>

              <Box display="flex" flexDirection="column" gap={0.5}>
                <Typography variant="label">Sample Size</Typography>
                <InlineEdit
                  sx={{
                    width: '76px',
                  }}
                  inputStyle={{
                    width: '100%',
                    border: sampleSizeError ? `1px solid ${theme.palette.chileanFire.main}` : undefined,
                  }}
                  placeholder="1000"
                  disabled={frequency?.id === '0' || disableSchedule}
                  value={sampleSize === undefined ? '1000' : sampleSize}
                  onChange={e => {
                    handleOnSampleSizeChange(e.target.value)
                  }}
                  onBlur={() => {
                    if (sampleSize) return
                    setSampleSize('1000')
                  }}
                  onKeyDown={onlyNumericInput}
                />

                <Box display="flex" alignItems="center" mt={1} gap={0.5} paddingTop={0.5}>
                  <Typography
                    variant="body"
                    color={sampleSizeError ? theme.palette.chileanFire.main : theme.palette.Text.Secondary}
                  >
                    Sample size must be a number between 100 and 100000. The default sample size is 1000.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </CSSTransition>
      </Box>
    </PopUp>
  )
}
