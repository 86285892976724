import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { GridColDef } from '@mui/x-data-grid-premium'

import { ObjectLiteral } from 'interfaces'
import { ApplicationDTO } from 'interfaces/applications/application'
import { DeploymentPlanDTO } from 'interfaces/deploymentPlans-v2/deploymentPlan'
import { APPLICATION_PLATFORM_NAME } from 'interfaces/applications/applicationPlatform'
import { RoutesManager } from 'utils/routing/routesManager'
import { useApplications } from 'api/applications/queries/useApplications'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { Button, Chip, ContentGroup, DataGrid, theme } from '@ketch-com/deck'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'

import { ReactComponent as EmptyStateDeploymentPlansIcon } from 'assets/icons/emptyState/EmptyStateDeploymentPlans.svg'
import { DeploymentPlanApplicationEnvironmentDTO } from 'interfaces/deploymentPlans/deploymentPlan'
import { Typography } from '@mui/material'

type Props = {
  deploymentPlan: DeploymentPlanDTO
}

export const DeploymentPlanDeployments: React.FC<Props> = ({ deploymentPlan }) => {
  const navigate = useNavigate()
  const { applicationEnvironments } = deploymentPlan

  const { data: applications, isLoading: isApplicationsLoading } = useApplications({
    enabled: !!applicationEnvironments?.length,
  })

  const applicationsMap = useMemo(
    () =>
      applications.reduce<ObjectLiteral<ApplicationDTO>>(
        (acc, application) => ({
          ...acc,
          [application.ID]: application,
        }),
        {},
      ),
    [applications],
  )

  const columns = React.useMemo(
    (): GridColDef<DeploymentPlanApplicationEnvironmentDTO>[] => [
      {
        align: 'left',
        field: 'applicationName',
        flex: 1,
        headerAlign: 'left',
        headerName: 'Property',
        sortable: false,
        width: 350,
        maxWidth: 350,
        renderCell: ({ row: { applicationName, applicationID } }) => (
          <NameAndCodeCellRenderer name={applicationName} code={applicationsMap[applicationID]?.code} />
        ),
      },
      {
        align: 'left',
        field: 'name',
        flex: 1,
        width: 200,
        maxWidth: 200,
        headerAlign: 'left',
        headerName: 'Type',
        sortable: false,
        renderCell: ({ row: { applicationPlatform } }) => (
          <Chip
            size="small"
            label={
              <Typography variant="smallLabel" color={theme.palette.Text.Secondary}>
                {APPLICATION_PLATFORM_NAME[applicationPlatform]}
              </Typography>
            }
          />
        ),
      },
      {
        align: 'left',
        field: 'environment',
        width: 200,
        headerAlign: 'left',
        headerName: 'Environment',
        sortable: false,
        renderCell: ({ row: { code } }) => <Typography padding="16px 0">{code}</Typography>,
      },
      {
        align: 'left',
        field: 'pattern',
        width: 200,
        flex: 1,
        headerAlign: 'left',
        headerName: 'Pattern',
        sortable: false,
        renderCell: ({ row: { pattern } }) => <Typography>{pattern}</Typography>,
      },
      {
        align: 'right',
        field: 'viewButton',
        flex: 1,
        headerAlign: 'right',
        headerName: '',
        sortable: false,
        renderCell: ({ row: { applicationID } }) => (
          <Button
            color="tertiary"
            onClick={() => {
              navigate(
                RoutesManager.deployment.applications.view.root.getURL({
                  code: applicationsMap[applicationID]?.code,
                }),
              )
            }}
          >
            View
          </Button>
        ),
      },
    ],
    [navigate, applicationsMap],
  )

  if (isApplicationsLoading) {
    return <Spinner padding={50} />
  }

  return (
    <>
      <ContentGroup title="Deployment Summary" titleVariant="h3" variant="inner-page-header" />
      {applicationEnvironments?.length ? (
        <>
          <DataGrid
            rows={applicationEnvironments}
            columns={columns}
            autosizeOnMount
            disableChildrenSorting
            disableColumnMenu
            disableColumnPinning
            disableColumnReorder
            disableColumnResize
            disableRowHoverStates
            disableRowSelectionOnClick
            getRowHeight={() => 'auto'}
            getRowId={row => row.code}
            hideFooter
            sx={{
              padding: 'unset',
              mt: '24px',
            }}
          />
        </>
      ) : (
        <EmptyState
          icon={<EmptyStateDeploymentPlansIcon />}
          bgVariant="grey"
          variant="page"
          title="No deployments use this deployment plan"
          description="Once deployed, relevant deployments will be displayed here."
        />
      )}
    </>
  )
}
