import { Chip, Icon, theme, Spinner } from '@ketch-com/deck'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import type { CloudinaryResource } from 'interfaces/cloudinary/cloudinary'
import { useCloudinaryResourceDetails } from 'api/cloudinary/queries/useCloudinaryResourceDetails'
import { Tooltip, Box } from '@mui/material'

type ViewFileProps = {
  cloudinaryResource: CloudinaryResource
  size?: 'small' | 'medium'
}

export const ViewFile = ({ cloudinaryResource, size = 'medium' }: ViewFileProps) => {
  const secure_url = cloudinaryResource?.secure_url || ''
  const public_id = cloudinaryResource?.public_id || ''
  const { data: cloudinaryResourceDetails, isLoading: isCloudinaryResourceDetailsLoading } =
    useCloudinaryResourceDetails({
      params: {
        public_id,
      },
    })

  const moderationStatus = cloudinaryResourceDetails?.moderation_status
  const isModerationApproved = moderationStatus === 'approved'
  const isModerationRejected = moderationStatus === 'rejected'
  const isModerationPending = moderationStatus === 'pending'

  const canViewFile = !isModerationPending && !isModerationRejected
  const isModerationFailedOrUnknown = !isModerationApproved && !isModerationPending

  if (secure_url && public_id)
    return (
      <Box display="flex" alignItems="center" gap={0.5}>
        <Chip
          color={isModerationFailedOrUnknown ? 'error' : 'primary'}
          disabled={!canViewFile}
          clickable={canViewFile && !isModerationFailedOrUnknown}
          label={isCloudinaryResourceDetailsLoading ? <Spinner size="16px" thickness={1.5} /> : 'View File'}
          deleteIcon={
            <Icon
              name={'OShare'}
              iconColor={isModerationFailedOrUnknown ? theme.palette.Error.PrimaryLight : theme.palette.royalBlue.main}
            />
          }
          onDelete={canViewFile ? () => window.open(secure_url, '_blank', 'noreferrer') : () => {}}
          onClick={canViewFile ? () => window.open(secure_url, '_blank', 'noreferrer') : () => {}}
          size={size}
          sx={{ width: 'fit-content', cursor: 'pointer' }}
        />
        {!canViewFile || isModerationFailedOrUnknown ? (
          <Tooltip
            title={
              isModerationPending
                ? 'This file is currently being scanned for malware. You will be able to view it once the scan is complete.'
                : isModerationRejected
                ? 'This file has been flagged as malware and cannot be viewed for security reasons.'
                : 'Warning: This file has not been scanned for malware.'
            }
          >
            <Box mt={0.5}>
              <Icon name="FInfo" iconColor={theme.palette.Text.Secondary} height={18} width={18} />
            </Box>
          </Tooltip>
        ) : null}
      </Box>
    )
  return <EmptyValueRenderer />
}
