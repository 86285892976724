import { ThemeType } from './enums'
import {
  BannerContainerLayout,
  BannerContainerSize,
  BannerModalLogoPosition,
  ExitButtonPosition,
  ListLayout,
} from '@ketch-sdk/ketch-types'

export const ThemeTypeLabels: { [themeType in ThemeType]: string } = {
  [ThemeType.Banner]: 'Banner',
  [ThemeType.Modal]: 'Modal',
  [ThemeType.Preference]: 'Preferences',
}

export const BannerContainerLayoutLabels = {
  [BannerContainerLayout.Horizontal]: 'Horizontal',
  [BannerContainerLayout.Vertical]: 'Vertical',
  [BannerContainerLayout.Centered]: 'Centered',
}

export const BannerContainerSizeLabels = {
  [BannerContainerSize.Standard]: 'Standard',
  [BannerContainerSize.Compact]: 'Compact',
}

export const BannerModalLogoPositionLabels = {
  [BannerModalLogoPosition.Top]: 'Top',
  [BannerModalLogoPosition.Left]: 'Left',
}

export const ListLayoutLabels = {
  [ListLayout.Expandable]: 'Expandable',
  [ListLayout.Cards]: 'Cards',
}

export const ExitButtonPositionLabels = {
  [ExitButtonPosition.topRight]: 'Top Right',
  [ExitButtonPosition.bottomLeft]: 'Bottom Left',
}
