import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormikProvider, useFormik } from 'formik'
import { Box } from '@mui/material'
import { UpsertLayout, UpsertLayoutMode, ContentGroup, Spinner } from '@ketch-com/deck'

import { MaybeNull } from 'interfaces'
import { RegionDTO } from 'interfaces/regions/region'
import { RegulationDTO } from 'interfaces/regulations/regulation'
import { PolicyScopeTemplateDTO } from 'interfaces/policyScopes/policyScope'
import { RightDTO } from 'interfaces/rights/right'
import { PolicyScopeFormValues } from '../interfaces'

import { RoutesManager } from 'utils/routing/routesManager'
import { PolicyScopeDetails } from './PolicyScopeDetails'
import { PolicyScopeRegionsAndRegulations } from './PolicyScopeRegionsAndRegulations'
import { PolicyScopeRights } from './PolicyScopeRights'
import { NavigationBreadCrumbs } from '../../../../../components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { useInitialValues } from '../utils/getInitialValues'
import { PolicyScopePurposes } from './PolicyScopePurposes/PolicyScopePurposes'
import { useValidationSchema } from '../utils/useValidationSchema'
import { PolicyScopeFormStep } from '../utils/constants'

type Props = {
  onSubmit: (values: PolicyScopeFormValues) => Promise<void>
  regulations: RegulationDTO[]
  regionGroups: RegionDTO[]
  policyScopeTemplate: MaybeNull<PolicyScopeTemplateDTO>
  rights: RightDTO[]
  isReady: boolean
}

const FORM_STEPS = Object.values(PolicyScopeFormStep)

const CreatePolicyScope: React.FC<Props> = ({
  onSubmit,
  regulations,
  regionGroups,
  policyScopeTemplate,
  rights,
  isReady,
}) => {
  const navigate = useNavigate()
  const [currentFormStep, setCurrentFormStep] = useState(0)
  const initialValues = useInitialValues({ policyScopeTemplate })
  const { validationSchema, isStepValid } = useValidationSchema()
  const isLastStep = FORM_STEPS.length - 1 === currentFormStep

  const formikProps = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
  })
  const { submitForm, isSubmitting, validateForm } = formikProps

  const onAccept = async () => {
    // Determine if the current step is valid
    const errors = await validateForm()
    const stepValid = isStepValid(FORM_STEPS[currentFormStep], errors, formikProps)

    if (!stepValid) {
      return
    }

    // If the last step is valid, submit the form
    if (isLastStep) {
      submitForm()
    } else {
      // Otherwise, move to the next step
      setCurrentFormStep(prev => prev + 1)
    }
  }

  const breadcrumbs = [
    { title: 'Privacy Program', link: RoutesManager.policyCenter.root.getURL() },
    { title: 'Jurisdictions', link: RoutesManager.policyCenter.policyScopes.root.getURL() },
    { title: 'Create Jurisdiction' },
  ]

  const renderedChildren = [
    <PolicyScopeDetails />,
    <PolicyScopeRegionsAndRegulations regulations={regulations} regionGroups={regionGroups} />,
    <PolicyScopeRights rights={rights} />,
    <PolicyScopePurposes />,
  ]

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <FormikProvider value={formikProps}>
        <UpsertLayout
          sx={{
            '&.UpsertLayout-container': {
              '& .UpsertLayout-stepper': {
                // 8px gap between layout stepper and content
                top: '8px',
              },
            },
          }}
          formSteps={FORM_STEPS}
          mode={UpsertLayoutMode.create}
          currentFormStep={currentFormStep}
          showStepper
          renderFormTitle={() => <ContentGroup title="Create Jurisdiction" titleVariant="h2" isDivider />}
          onAccept={onAccept}
          onCancel={() => {
            navigate(RoutesManager.policyCenter.policyScopes.root.getURL())
          }}
          acceptButtonProps={{
            pending: isSubmitting,
          }}
          cancelButtonProps={{
            pending: isSubmitting,
          }}
          onThirdActionButtonHandler={() => {
            navigate(RoutesManager.policyCenter.policyScopes.library.root.getURL())
          }}
          thirdActionButtonText="Back To Templates"
          thirdButtonProps={{
            color: 'tertiary',
            size: 'large',
            pending: isSubmitting,
          }}
        >
          <form autoComplete="off">
            {isReady ? (
              renderedChildren.filter((_, i) => currentFormStep >= i)
            ) : (
              <Box
                sx={{
                  margin: '100px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Spinner size="32px" thickness={2.5} />
              </Box>
            )}
          </form>
        </UpsertLayout>
      </FormikProvider>
    </>
  )
}

export { CreatePolicyScope }
