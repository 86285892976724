import { getIn, useFormikContext } from 'formik'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { HTMLAttributes, useCallback, useMemo } from 'react'
import {
  DeploymentPlanFormSpecialValues,
  noExperienceOption,
} from 'pages/consentAndRights/deploymentPlans-v2/upsert/utils'

import { useIsEntitled } from 'utils/hooks'
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { PolicyScopeDTO } from 'interfaces/policyScopes/policyScope'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { ActionSheetItem, Icon, ListItemText, TableCell, theme, UpsertLayoutMode } from '@ketch-com/deck'
import { NameAndCodeCellRenderer } from 'components/renderers/NameAndCodeCellRenderer'
import { FormDroplistButton } from 'components/form/FormDroplistButton'
import { EXPERIENCE_DEFAULT_DISPLAY_OPTIONS } from 'interfaces/experiences-v2/experienceDefaultDisplay'
import { FormDeploymentExperienceSelector } from 'pages/consentAndRights/deploymentPlans-v2/upsert/components/deploymentPlanExperiences/FormDeploymentExperienceSelector'
import { ExperienceType } from 'interfaces/experiences-v2/experienceType'
import { Box, Tooltip, Typography } from '@mui/material'
import { ListItem } from 'pages/consentAndRights/deploymentPlans-v2/upsert/components/deploymentPlanExperiences/DeploymentExperienceSelector'

export const useDeploymentPlanExperiencesColumns = (
  experiences: ExperienceV2DTO[],
  upsertMode: UpsertLayoutMode,
): GridColDef<PolicyScopeDTO>[] => {
  const { isEntitled } = useIsEntitled()
  const isEntitledToContentAndDisclosure = isEntitled(ENTITLEMENTS.EXP_SERVER_CONSENT_AND_DISCLOSURE)
  const { values } = useFormikContext()

  const useSmallWidth = upsertMode === UpsertLayoutMode.create

  const autoInitiatedExperienceOptions = useMemo(() => {
    return experiences.reduce<Array<{ id: string; name: string }>>(
      (acc, experience) =>
        experience.type === ExperienceType.Consent
          ? [...acc, { id: experience.id || '', name: experience.name || '' }]
          : acc,
      [noExperienceOption],
    )
  }, [experiences])

  const renderOption = useCallback(
    (props: HTMLAttributes<HTMLLIElement>, opt: unknown, _: any, isSelected?: boolean) => {
      const option = opt as ListItem

      if (option.id === noExperienceOption.id) return null

      return (
        <ActionSheetItem {...props} key={option.id} selected={isSelected}>
          <ListItemText selected={isSelected}>
            <Typography variant={isSelected ? 'label' : 'body'}>{option.name}</Typography>
          </ListItemText>
        </ActionSheetItem>
      )
    },
    [],
  )

  return [
    {
      field: 'policyScope',
      headerName: 'Jurisdiction',
      sortable: false,
      minWidth: useSmallWidth ? 200 : 250,
      align: 'left',
      headerAlign: 'left',
      flex: 1,
      renderCell: ({ row: { name, code } }: GridRenderCellParams<PolicyScopeDTO>) => {
        return (
          <TableCell sx={{ padding: '8px 0' }}>
            <NameAndCodeCellRenderer name={name} code={code} />
          </TableCell>
        )
      },
    },
    ...(isEntitledToContentAndDisclosure
      ? [
          {
            align: 'left',
            field: 'autoInitiatedExperience',
            headerAlign: 'left',
            renderHeader: () => (
              <Box display="flex" justifyContent="center" alignItems="center" gap={0.5}>
                <Typography variant="smallLabel">Auto Initiated</Typography>
                <Tooltip
                  title={
                    <Typography>
                      An <Typography variant="label">Auto Initiated</Typography> experience will be displayed
                      automatically based on Ketch’s consent resolution logic as described{' '}
                      <Typography
                        variant="bodyLink"
                        color={theme.palette.Action.Primary}
                        component="a"
                        href="https://docs.ketch.com/ketch/docs/experience-rendering-logic"
                        target="_blank"
                      >
                        here
                      </Typography>
                      .
                    </Typography>
                  }
                  placement="top"
                >
                  <Box height={20} width={20}>
                    <Icon name="FInfo" iconColor={theme.palette.Text.Secondary} width={20} height={20} />
                  </Box>
                </Tooltip>
              </Box>
            ),
            minWidth: useSmallWidth ? 250 : 300,
            flex: 1,
            sortable: false,
            renderCell: ({ row: { code } }: GridRenderCellParams<PolicyScopeDTO>) => {
              const name = `policyScopeExperiences.${code}.${ExperienceType.Consent}.experienceID`
              return (
                <TableCell sx={{ padding: '8px 0' }}>
                  <FormDroplistButton
                    width={useSmallWidth ? '200px' : '225px'}
                    required
                    size="small"
                    formPropertyName={name}
                    placeholder="Select Experience"
                    items={autoInitiatedExperienceOptions}
                    valueKey="id"
                    // When clear is clicked, set to "No Experience" instead of null
                    clearValue={noExperienceOption}
                    renderOption={renderOption}
                  />
                </TableCell>
              )
            },
          } as GridColDef<PolicyScopeDTO>,
          {
            align: 'left',
            field: 'autoInitiatedExperienceDefault',
            headerAlign: 'left',
            headerName: 'Auto Initiated Default Display',
            minWidth: useSmallWidth ? 250 : 300,
            flex: 1,
            sortable: false,
            renderCell: ({ row: { code } }: GridRenderCellParams<PolicyScopeDTO>) => {
              const autoInitiatedExperienceValue = getIn(
                values,
                `policyScopeExperiences.${code}.${ExperienceType.Consent}.experienceID`,
              )
              const isNoExperienceSelected =
                autoInitiatedExperienceValue === DeploymentPlanFormSpecialValues.NO_EXPERIENCE

              return (
                <TableCell sx={{ padding: '8px 0' }}>
                  <FormDroplistButton
                    width="200px"
                    required
                    size="small"
                    formPropertyName={`policyScopeExperiences.${code}.${ExperienceType.Consent}.experienceDefault`}
                    placeholder="Select Default Display"
                    items={EXPERIENCE_DEFAULT_DISPLAY_OPTIONS}
                    valueKey="id"
                    disabled={isNoExperienceSelected}
                    disableClearable
                  />
                </TableCell>
              )
            },
          } as GridColDef<PolicyScopeDTO>,
        ]
      : []),
    {
      align: 'left',
      field: 'userInitiatedExperience',
      headerAlign: 'left',
      renderHeader: () => (
        <Box display="flex" justifyContent="center" alignItems="center" gap={0.5}>
          <Typography variant="smallLabel">User Initiated</Typography>
          <Tooltip
            title={
              <Typography>
                A <Typography variant="label">User-Initiated</Typography> experience is displayed when a user takes
                action to trigger it, such as clicking a footer link. To learn more about User-Initiated experiences,
                see{' '}
                <Typography
                  variant="bodyLink"
                  color={theme.palette.Action.Primary}
                  component="a"
                  href="https://docs.ketch.com/ketch/docs/preference-management-center-configuration"
                  target="_blank"
                >
                  here
                </Typography>
                .
              </Typography>
            }
            placement="top"
          >
            <Box height={20} width={20}>
              <Icon name="FInfo" iconColor={theme.palette.Text.Secondary} width={20} height={20} />
            </Box>
          </Tooltip>
        </Box>
      ),
      minWidth: useSmallWidth ? 250 : 300,
      flex: 1,
      sortable: false,
      renderCell: ({ row: { code } }: GridRenderCellParams<PolicyScopeDTO>) => (
        <TableCell sx={{ padding: '8px 0' }}>
          <FormDeploymentExperienceSelector
            name={`policyScopeExperiences.${code}.${ExperienceType.Preference}.experienceID`}
            experiences={experiences}
            width={useSmallWidth ? '200px' : '225px'}
          />
        </TableCell>
      ),
    },
  ]
}
