import { Box } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import {} from '@ketch-com/figurehead'
import { AssessmentEditPager } from 'pages/policyCenter/assessments/edit/components/AssessmentEditPager'
import { EmptyState, Spinner, theme } from '@ketch-com/deck'
import { AssessmentSection } from './components/AssessmentSection'
import { AssessmentDetailsHeader } from './components/AssessmentDetailsHeader'
import { PortalHeader } from '../PortalHeader'
import { PortalPage } from '../common/component/PortalPage'
import { useExternalAssessment } from 'api/assessments/queries/useExternalAssessment'
import { useParams } from 'react-router-dom'
import {
  AssessmentAttributeEvaluationSubject,
  AssessmentSection as AssessmentSectionType,
} from '@ketch-com/windlass/dist/assessments/externalUser'

const checkIfSectionHasBlocks = (sections: AssessmentSectionType[]) => {
  let hasSectionBlocks = false
  sections.forEach((section, idx) => {
    if (section.blocks?.length) {
      hasSectionBlocks = true
    }
  })
  return hasSectionBlocks
}

export const AssessmentDetailsPage: React.FC<{}> = () => {
  const { assessmentId } = useParams()

  const {
    data: assessmentDocument,
    isLoading,
    refetch: refetchAssessment,
  } = useExternalAssessment({
    params: {
      id: assessmentId,
    },
  })
  const assessment = useMemo(() => assessmentDocument?.data || {}, [assessmentDocument])

  const [openSections, setOpenSections] = useState<string[]>([])
  const [hasSetOpenSectionsInitialState, setHasSetOpenSectionsInitialState] = useState(false)
  useEffect(() => {
    if (!hasSetOpenSectionsInitialState && assessment?.attributes?.sections) {
      setOpenSections((assessment?.attributes?.sections || []).map(s => s.name || ''))
      setHasSetOpenSectionsInitialState(true)
    }
  }, [assessment, hasSetOpenSectionsInitialState])

  const sections = assessment.attributes?.sections ?? []

  const toggleOpen = (isOpen: boolean, sectionName: string) => {
    if (isOpen) {
      setOpenSections(openSections.filter(s => s !== sectionName))
    } else {
      setOpenSections([...openSections, sectionName])
    }
  }

  const [autoSavingQuestions, setAutoSavingQuestions] = useState<string[]>([])
  const startAutoSave = (id: string) => setAutoSavingQuestions([...autoSavingQuestions, id])
  const finishAutoSave = (id: string) => setAutoSavingQuestions(autoSavingQuestions.filter(q => q !== id))

  if (isLoading || !assessment)
    return (
      <>
        <PortalHeader />
        <Box display="flex" alignItems="center" justifyContent="center" height="100%" flex={1}>
          <Spinner size="32px" thickness={2.5} />
        </Box>
      </>
    )

  const dataSubjectCode =
    assessment.attributes?.evaluationSubject === AssessmentAttributeEvaluationSubject.system
      ? assessment.attributes.systemName
      : assessment.attributes?.processingActivityName

  return (
    <PortalPage>
      <Box marginBottom={14}>
        <AssessmentDetailsHeader
          assessmentName={assessment.attributes?.name || ''}
          dataSubjectCode={dataSubjectCode || ''}
          dataSubjectIcon={assessment.attributes?.systemLogoUrl}
          status={assessment.attributes?.status!}
          questionCount={{
            total: assessment.attributes?.totalQuestions!,
            complete: assessment.attributes?.completedQuestions!,
          }}
          autoSaving={autoSavingQuestions.length > 0}
        />
        <Box display="flex" gap={1} justifyContent="center">
          {!sections?.length ? null : <AssessmentEditPager setOpenSections={setOpenSections} sections={sections} />}
          <Box>
            {!!checkIfSectionHasBlocks(sections) ? (
              sections.map((section, index) => {
                const { name = '' } = section
                const isOpen = openSections.includes(name)
                return (
                  <AssessmentSection
                    section={section}
                    assessment={assessment}
                    sectionIndex={index + 1}
                    key={index}
                    isOpen={isOpen}
                    toggleOpen={() => toggleOpen(isOpen, name)}
                    startAutoSave={startAutoSave}
                    finishAutoSave={finishAutoSave}
                    refetchAssessment={refetchAssessment}
                  />
                )
              })
            ) : (
              <Box mt={4} width={976}>
                <EmptyState
                  iconName="OGroup"
                  size="large"
                  title="No Question Assigned"
                  subTitle="There are no questions assigned to you at the moment."
                  colorTheme={theme.palette.Text.Secondary}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </PortalPage>
  )
}
