import Box from '@mui/material/Box'
import { useContext, useEffect, useState } from 'react'
import { useInfiniteAssessments } from 'api/assessments/queries/useInfiniteAssessments'
import { Button, DataGrid, PopUp, Spinner } from '@ketch-com/deck'
import { useInfiniteAssessmentTemplates } from 'api/assessmentTemplates/queries/useInfiniteAssessmentTemplates'
import { ArchiveStatusDTO, AssessmentTemplateStatusDTO } from '@ketch-com/figurehead'
import { useCreateAssessment } from 'api/assessments/mutations/useCreateAssessment'
import { showToast } from 'components/modals/AlertComponent'
import {
  AssessmentCreateFormValues,
  getCreateAssessmentValidationSchema,
  getInitialAssessmentCreateFormValues,
  useMapAssessmentCreateFormValuesToPayload,
} from '../../../assessments/create/formHelpers'
import { Formik } from 'formik'
import { ProcessingActivityContext } from '../context/ProcessingActivityContext'
import { FormInput } from 'components/form/FormInput'
import { FormDroplistButton } from 'components/form/FormDroplistButton'
import { SearchTextInput } from 'components/searchTextInput/SearchTextInput'
import { useAssessmentsModalColumns } from './assessmentsModalUtils'

export enum AssessmentModalState {
  Closed = 'closed',
  Select = 'select',
  Create = 'create',
}

type Props = {
  modalState: AssessmentModalState
  leftButtonVariant: 'primary' | 'secondary' | 'tertiary'
  rightButtonVariant: 'primary' | 'secondary' | 'tertiary'
  leftButtonLabel: string
  rightButtonLabel: string
  onLeftButtonClick: () => void
  onRightButtonClick: () => void
  onCloseButtonClick: () => void
}

export const AssessmentModal: React.FC<Props> = ({
  modalState,
  leftButtonVariant,
  rightButtonVariant,
  leftButtonLabel,
  rightButtonLabel,
  onLeftButtonClick,
  onRightButtonClick,
  onCloseButtonClick,
}) => {
  const {
    processingActivity,
    handleAssessmentUpdate,
    isAssessmentUpdating,
    isProcessingActivityFetching,
    refetchProcessingActivityLatest,
  } = useContext(ProcessingActivityContext)

  // States for select mode
  const [search, setSearch] = useState('')

  // Fetch assessments
  const {
    data: assessments,
    isLoading: isAssessmentsListLoading,
    refetch,
  } = useInfiniteAssessments({ status: '', name: search })

  // Refetch whenever search string changes
  useEffect(() => {
    refetch()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const { mutate: handleCreate } = useCreateAssessment({
    onSuccess: async ({ data: assessment }) => {
      try {
        await handleAssessmentUpdate({
          params: {
            activityId: processingActivity.id || '',
            formData: {
              assessmentId: assessment.data?.id,
            },
          },
        })
        // Refetch
        await refetchProcessingActivityLatest()
        showToast({ content: 'Assessment created', type: 'success' })
      } catch {
        showToast({ content: 'Error creating assessment', type: 'error' })
      }
    },
    onError: () => {
      showToast({ content: 'Error creating assessment', type: 'error' })
    },
  })

  const { data: templates, isLoading: isTemplatesLoading } = useInfiniteAssessmentTemplates({
    approvalStatus: AssessmentTemplateStatusDTO.ApprovedAssessmentTemplateStatus,
    archiveStatus: ArchiveStatusDTO.CurrentArchiveStatus,
    customLimit: 1000, // Potentially naive? Time shall tell
  })

  const handleSelectClick = async (activityId: string, assessmentId: string) => {
    try {
      await handleAssessmentUpdate({
        params: {
          activityId,
          formData: {
            assessmentId,
          },
        },
      })
      // Refetch
      await refetchProcessingActivityLatest()
      await onCloseButtonClick()
      showToast({ content: 'Assessment updated', type: 'success' })
    } catch {
      showToast({ content: 'Assessment update failed', type: 'error' })
    }
  }

  const mapAssessmentCreateFormValuesToPayload = useMapAssessmentCreateFormValuesToPayload()

  const handleSubmit = (values: Omit<AssessmentCreateFormValues, 'initialAssigneeType'>) => {
    handleCreate({
      params: { data: mapAssessmentCreateFormValuesToPayload({ ...values, initialAssigneeType: 'internal' }) },
    })
    onCloseButtonClick()
  }

  const columns = useAssessmentsModalColumns({
    processingActivity,
    handleSelectClick,
    isAssessmentUpdating: isAssessmentUpdating || isProcessingActivityFetching,
  })
  return (
    <Formik
      initialValues={getInitialAssessmentCreateFormValues()}
      onSubmit={handleSubmit}
      validationSchema={getCreateAssessmentValidationSchema()}
    >
      {({ submitForm, isSubmitting }) => (
        <PopUp
          title={modalState === AssessmentModalState.Select ? 'Select Assessment' : 'Create New Assessment'}
          subTitle={
            modalState === AssessmentModalState.Select
              ? 'Choose an existing assessment to evaluate this processing activity, or create a new one.\n' +
                'You can always change it later.'
              : ''
          }
          popUpWidth={modalState === AssessmentModalState.Select ? '845px' : '576px'}
          onClose={onCloseButtonClick}
          popUpActionChildren={
            <>
              <Button
                color={leftButtonVariant}
                size="large"
                onClick={onLeftButtonClick}
                pending={isAssessmentUpdating || isProcessingActivityFetching}
              >
                {leftButtonLabel}
              </Button>
              <Button
                color={rightButtonVariant}
                size="large"
                onClick={modalState === AssessmentModalState.Select ? onRightButtonClick : submitForm}
                pending={isAssessmentUpdating || isProcessingActivityFetching}
              >
                {rightButtonLabel}
              </Button>
            </>
          }
        >
          {modalState === AssessmentModalState.Select ? (
            <Box display="flex" flexDirection="column" gap={2}>
              <SearchTextInput size="small" onChange={newSearch => setSearch(newSearch)} value={search} />

              <Box height="60vh">
                <DataGrid
                  autosizeOnMount
                  autosizeOptions={{
                    includeHeaders: true,
                    includeOutliers: false,
                    columns: columns.map(column => column.field),
                    expand: true,
                  }}
                  getRowHeight={() => 'auto'}
                  columns={columns}
                  disableChildrenSorting
                  disableColumnMenu
                  disableColumnPinning
                  disableColumnResize
                  disableColumnReorder
                  disableRowHoverStates
                  disableRowSelectionOnClick
                  getRowId={row => row.id}
                  rows={assessments}
                  loading={isAssessmentsListLoading}
                  hideFooter
                  noRowsOverlayProps={{
                    title: 'No results found',
                    subTitle:
                      "Sorry, we couldn't find any results that match your filter criteria. Please adjust your filters and try again.",
                    buttonTitle: 'Reset Filters',
                  }}
                />
              </Box>
            </Box>
          ) : (
            <Box display="flex" flexDirection="column" gap={3}>
              <Box display="flex" flexDirection="column" pt={2.25}>
                <FormInput
                  formPropertyName="name"
                  required
                  label="Name"
                  fullWidth
                  placeholder="Example: Data Processing Evaluation"
                  disabled={isSubmitting}
                />
              </Box>
              <Box display="flex" flexDirection="column" pb={7.5}>
                {isTemplatesLoading ? (
                  <Box display="flex" alignItems="center" justifyContent="center" padding={2}>
                    <Spinner size="32px" thickness={2.5} />
                  </Box>
                ) : (
                  <FormDroplistButton
                    disableClearable
                    formPropertyName="template"
                    items={templates.map(template => ({
                      id: template.attributes?.latestPublishedID,
                      name: template.attributes?.name,
                    }))}
                    label="Template"
                    required
                    placeholder="Select Template"
                    fullWidth
                    valueKey="id"
                    disabled={isSubmitting}
                  />
                )}
              </Box>
            </Box>
          )}
        </PopUp>
      )}
    </Formik>
  )
}
