import { FormSectionSwitch } from '../FormSectionSwitch'
import Box from '@mui/material/Box'
import { SidebarFormGroup } from '../SidebarFormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Typography } from '@mui/material'
import { FormTextInput } from '../FormTextInput'
import { FormCheckbox } from '../FormCheckbox'
import { experienceUpsertFormKeys } from '../../../../utils/useExperienceValidationSchema'
import { SidebarFormTranslations } from '../SidebarFormTranslations'
import { getIn, useFormikContext } from 'formik'
import { Tab, TabGroup, TabPanel, theme } from '@ketch-com/deck'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { SyntheticEvent, useContext, useState } from 'react'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences-v2/upsert/context/ExperienceUpsertContext'
import { getFormNameForLocale } from 'pages/consentAndRights/experiences-v2/upsert/utils/utils'
import { defaultExperienceV2DTO } from 'pages/consentAndRights/experiences-v2/upsert/utils/defaults'
import { PreferenceExperienceBuilderSection } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/utils/enums'

export const PreferenceExperiencePurposesTabListHeaderForm: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<ExperienceV2DTO>()
  const { languages, isLanguagesLoading, setExperienceBuilderSection } = useContext(ExperienceUpsertContext)
  const [tab, setTab] = useState(0)
  const hasLanguages = !isLanguagesLoading && !!languages.length
  const showTitleForm = getIn(values, experienceUpsertFormKeys.preferencePurposesTabListHeaderTitleVisible)
  const showCustomTitleForm = !getIn(values, experienceUpsertFormKeys.preferencePurposesTabListHeaderUseDefaultTitle)
  const showBulkActionButtonsForm = getIn(
    values,
    experienceUpsertFormKeys.preferencePurposesTabListHeaderBulkActionButtonsVisible,
  )
  const showCustomBulkActionButtonsTextForm = !getIn(
    values,
    experienceUpsertFormKeys.preferencePurposesTabListHeaderBulkActionButtonsUseDefaultText,
  )
  const showVendorsLinkForm = getIn(values, experienceUpsertFormKeys.preferencePurposesTabListHeaderVendorsLinkVisible)
  const showCustomVendorsLinkTextForm = !getIn(
    values,
    experienceUpsertFormKeys.preferencePurposesTabListHeaderVendorsLinkUseDefaultText,
  )

  const handleTitleDefaultChange = (event: SyntheticEvent<Element, Event>, checked: boolean) => {
    if (checked && !isLanguagesLoading) {
      // Reset to english default
      const englishFieldToReset = experienceUpsertFormKeys.preferencePurposesTabListHeaderTitle
      const defaultEnglishValue = getIn(defaultExperienceV2DTO, englishFieldToReset)
      setFieldValue(englishFieldToReset, defaultEnglishValue)

      // Reset to default for each locale
      languages.forEach(({ language: { code } }) => {
        const localeFieldToReset = getFormNameForLocale(englishFieldToReset, code)
        const defaultLocaleValue = getIn(defaultExperienceV2DTO, localeFieldToReset)
        setFieldValue(localeFieldToReset, defaultLocaleValue)
      })
    }
  }

  const handleVendorsLinkDefaultChange = (event: SyntheticEvent<Element, Event>, checked: boolean) => {
    if (checked && !isLanguagesLoading) {
      // Reset to english default
      const englishFieldToReset = experienceUpsertFormKeys.preferencePurposesTabListHeaderVendorsLinkText
      const defaultEnglishValue = getIn(defaultExperienceV2DTO, englishFieldToReset)
      setFieldValue(englishFieldToReset, defaultEnglishValue)

      // Reset to default for each locale
      languages.forEach(({ language: { code } }) => {
        const localeFieldToReset = getFormNameForLocale(englishFieldToReset, code)
        const defaultLocaleValue = getIn(defaultExperienceV2DTO, localeFieldToReset)
        setFieldValue(localeFieldToReset, defaultLocaleValue)
      })
    }
  }

  const handleBulkActionsDefaultChange = (event: SyntheticEvent<Element, Event>, checked: boolean) => {
    if (checked && !isLanguagesLoading) {
      // Reset accept all to english default
      const acceptAllEnglishField =
        experienceUpsertFormKeys.preferencePurposesTabListHeaderBulkActionButtonsAcceptAllText
      const acceptAllDefaultEnglish = getIn(defaultExperienceV2DTO, acceptAllEnglishField)
      setFieldValue(acceptAllEnglishField, acceptAllDefaultEnglish)

      // Reset reject all to english default
      const rejectAllEnglishField =
        experienceUpsertFormKeys.preferencePurposesTabListHeaderBulkActionButtonsRejectAllText
      const rejectAllDefaultEnglish = getIn(defaultExperienceV2DTO, rejectAllEnglishField)
      setFieldValue(rejectAllEnglishField, rejectAllDefaultEnglish)

      // Reset to default for each locale
      languages.forEach(({ language: { code } }) => {
        // Reset accept all translations
        const acceptAllLocaleField = getFormNameForLocale(acceptAllEnglishField, code)
        const acceptAllDefaultLocale = getIn(defaultExperienceV2DTO, acceptAllLocaleField)
        setFieldValue(acceptAllLocaleField, acceptAllDefaultLocale)

        // Reset reject all translations
        const rejectAllLocaleField = getFormNameForLocale(rejectAllEnglishField, code)
        const rejectAllDefaultLocale = getIn(defaultExperienceV2DTO, rejectAllLocaleField)
        setFieldValue(rejectAllLocaleField, rejectAllDefaultLocale)
      })
    }
  }

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabGroup value={tab} onChange={(event: React.SyntheticEvent, newValue: number) => setTab(newValue)}>
          <Tab size={'medium'} label="Title" />
          <Tab size={'medium'} label="Links" />
          <Tab size={'medium'} label="Bulk Action Buttons" />
        </TabGroup>
      </Box>

      {/* Title Form */}
      <TabPanel value={tab} index={0} sx={{ px: 0 }}>
        <FormSectionSwitch
          name={experienceUpsertFormKeys.preferencePurposesTabListHeaderTitleVisible}
          title={'List Title'}
        />
        {showTitleForm && (
          <Box p={'16px 18px'}>
            <SidebarFormGroup sx={{ paddingTop: 0 }} hasBorderBottom={showCustomTitleForm && hasLanguages}>
              <Box>
                <FormControlLabel
                  control={
                    <FormCheckbox
                      name={experienceUpsertFormKeys.preferencePurposesTabListHeaderUseDefaultTitle}
                      afterChange={handleTitleDefaultChange}
                    />
                  }
                  label={<Typography variant={'label'}>Use Default Text</Typography>}
                />
                <Typography component={'p'} variant={'body'} color={theme.palette.Text.Secondary}>
                  The default text “Purposes” has translations for all available languages.
                </Typography>
              </Box>
              {showCustomTitleForm && (
                <FormTextInput
                  label={'Title Text'}
                  name={experienceUpsertFormKeys.preferencePurposesTabListHeaderTitle}
                  fullWidth
                  charLimit={30}
                />
              )}
            </SidebarFormGroup>
            {showCustomTitleForm && (
              <SidebarFormTranslations
                title={'List Title Text Translations'}
                name={experienceUpsertFormKeys.preferencePurposesTabListHeaderTitle}
                variant={'text'}
                charLimit={30}
              />
            )}
          </Box>
        )}
      </TabPanel>

      {/* Links Form */}
      <TabPanel value={tab} index={1} sx={{ px: 0 }}>
        <FormSectionSwitch
          name={experienceUpsertFormKeys.preferencePurposesTabListHeaderVendorsLinkVisible}
          title={'Vendors Link'}
        />
        {showVendorsLinkForm && (
          <Box p={'16px 18px'}>
            <SidebarFormGroup sx={{ paddingTop: 0 }} hasBorderBottom={showCustomVendorsLinkTextForm && hasLanguages}>
              <Typography>
                To configure Vendors, use the{' '}
                <Typography
                  component="span"
                  onClick={() => setExperienceBuilderSection(PreferenceExperienceBuilderSection.PurposesTabList)}
                  sx={{
                    color: 'primary.main',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    '&:hover': { textDecoration: 'none' },
                  }}
                >
                  purpose list configuration panel
                </Typography>
                .
              </Typography>
              <Box>
                <FormControlLabel
                  control={
                    <FormCheckbox
                      name={experienceUpsertFormKeys.preferencePurposesTabListHeaderVendorsLinkUseDefaultText}
                      afterChange={handleVendorsLinkDefaultChange}
                    />
                  }
                  label={<Typography variant={'label'}>Use Default Text</Typography>}
                />
                <Typography component={'p'} variant={'body'} color={theme.palette.Text.Secondary}>
                  The default text "Vendors" has translations for all available languages.
                </Typography>
              </Box>
              {showCustomVendorsLinkTextForm && (
                <FormTextInput
                  label="Vendors Link Text"
                  name={experienceUpsertFormKeys.preferencePurposesTabListHeaderVendorsLinkText}
                  fullWidth
                  charLimit={30}
                />
              )}
            </SidebarFormGroup>
            {showCustomVendorsLinkTextForm && hasLanguages && (
              <SidebarFormTranslations
                title="Vendors Link Text Translations"
                name={experienceUpsertFormKeys.preferencePurposesTabListHeaderVendorsLinkText}
                variant="text"
                charLimit={30}
              />
            )}
          </Box>
        )}
      </TabPanel>

      {/* Bulk Action Buttons Form */}
      <TabPanel value={tab} index={2} sx={{ px: 0 }}>
        <FormSectionSwitch
          name={experienceUpsertFormKeys.preferencePurposesTabListHeaderBulkActionButtonsVisible}
          title={'Bulk Action Buttons'}
        />
        {showBulkActionButtonsForm && (
          <Box p={'16px 18px'}>
            <SidebarFormGroup
              sx={{ paddingTop: 0 }}
              hasBorderBottom={showCustomBulkActionButtonsTextForm && hasLanguages}
            >
              <Box>
                <FormControlLabel
                  control={
                    <FormCheckbox
                      name={experienceUpsertFormKeys.preferencePurposesTabListHeaderBulkActionButtonsUseDefaultText}
                      afterChange={handleBulkActionsDefaultChange}
                    />
                  }
                  label={<Typography variant={'label'}>Use Default Text</Typography>}
                />
                <Typography component={'p'} variant={'body'} color={theme.palette.Text.Secondary}>
                  The default text "Accept All" and "Reject All" have translations for all available languages.
                </Typography>
              </Box>
              {showCustomBulkActionButtonsTextForm && (
                <>
                  <FormTextInput
                    label={'Accept All Text'}
                    name={experienceUpsertFormKeys.preferencePurposesTabListHeaderBulkActionButtonsAcceptAllText}
                    fullWidth
                    charLimit={30}
                  />
                  <FormTextInput
                    label={'Reject All Text'}
                    name={experienceUpsertFormKeys.preferencePurposesTabListHeaderBulkActionButtonsRejectAllText}
                    fullWidth
                    charLimit={30}
                  />
                </>
              )}
            </SidebarFormGroup>
            {showCustomBulkActionButtonsTextForm && hasLanguages && (
              <SidebarFormTranslations
                title={'Bulk Action Buttons Text Translations'}
                bulkActionButtonsAcceptAllName={
                  experienceUpsertFormKeys.preferencePurposesTabListHeaderBulkActionButtonsAcceptAllText
                }
                bulkActionButtonsRejectAllName={
                  experienceUpsertFormKeys.preferencePurposesTabListHeaderBulkActionButtonsRejectAllText
                }
                variant={'bulkActionButtons'}
                charLimit={30}
              />
            )}
          </Box>
        )}
      </TabPanel>
    </>
  )
}
