import { RouteObject, createBrowserRouter } from 'react-router-dom'

// components
import { Auth0ProviderLayout } from 'components/providers/Auth0ProviderLayout'
import { AppLayout } from 'pages/AppLayout'
import { SignInErrorWithinAuth0Provider } from 'pages/auth/signInError/SignInErrorWithinAuth0Provider'
import { RedirectToHomePageBasedOnPermissions, Callback, Invite } from './components'
import { Register } from 'pages/registration/register/Register'
import { SignUp } from 'pages/onboarding/signUp/SignUp'

// utils
import { RoutesManager } from './routesManager'
import { relativePath } from './relativePath'

// routes
import { assetManagerRoutesChildren } from 'pages/assetManager/AssetManagerRoutes'
import { developerRoutesChildren } from 'pages/developers/DevelopersRoutes'
import { orchestrationRoutesChildren } from 'pages/orchestration/OrchestrationRoutes'
import { settingsRoutesChildren } from 'pages/settings/SettingsRoutes'
import { updatesRoutesChildren } from 'pages/updates/UpdatesRoutes'
import { developmentUtilsRoutesChildren } from 'pages/developmentUtils/DevelopmentUtilsRoutes'
import { RedirectWithToast } from 'pages/auth/RedirectWithToast'
import { AppCrashView } from 'components/ui-routing/AppCrashView'
import { homeRoutesChildren } from 'pages/home/HomeRoutes'
import { privacyProgramRoutesChildren } from 'pages/policyCenter/PrivacyProgramRoutes'
import { consentRightsRoutesChildren } from 'pages/consentAndRights/ConsentRightsRoutes'
import { DataMapRoutesChildren } from 'pages/dataSystems/DatamapRoutes'
import { reportsRoutesChildren } from 'pages/insights/ReportsRoutes'
import { PrivacyPortalOverview } from 'pages/externalPrivacyPortal/overview/PrivacyPortalOverview'
import { AssessmentDetailsPage } from 'pages/externalPrivacyPortal/assessmentDetails/AssessmentDetailsPage'
import { ExternalAuthLogin } from 'pages/externalPrivacyPortal/auth/ExternalAuthLogin'
import { ExternalAuthCallbackPage } from 'pages/externalPrivacyPortal/auth/ExternalAuthCallbackPage'

const collaborationWorkspaceOverviewRoute: RouteObject[] = [
  {
    element: <PrivacyPortalOverview />,
    path: RoutesManager.externalPrivacyPortal.overview.root.pattern,
  },
  {
    element: <AssessmentDetailsPage />,
    path: RoutesManager.externalPrivacyPortal.assessment.root.pattern,
  },
  {
    element: <ExternalAuthLogin />,
    path: RoutesManager.externalPrivacyPortal.login.pattern,
  },
  { element: <ExternalAuthCallbackPage />, path: RoutesManager.externalPrivacyPortal.magicLinkCallback.pattern },
]

const routes: RouteObject[] = [
  {
    path: '/',
    element: <Auth0ProviderLayout />,
    errorElement: <SignInErrorWithinAuth0Provider message="Oops, something went wrong" buttonText="Try Again" />,
    children: [
      {
        errorElement: <SignInErrorWithinAuth0Provider message="Oops, something went wrong" buttonText="Try Again" />,
        element: <AppLayout />,
        children: [
          {
            path: '',
            index: true,
            element: <RedirectToHomePageBasedOnPermissions />,
          },
          {
            errorElement: <AppCrashView />,
            children: [
              {
                path: relativePath(RoutesManager.home.root.pattern),
                children: [...homeRoutesChildren],
              },
              {
                path: relativePath(RoutesManager.insights.root.pattern),
                children: [...reportsRoutesChildren],
              },
              {
                path: relativePath(RoutesManager.policyCenter.root.pattern),
                children: [...privacyProgramRoutesChildren],
              },
              {
                path: relativePath(RoutesManager.assetManager.root.pattern),
                children: [...assetManagerRoutesChildren],
              },
              {
                path: relativePath(RoutesManager.developers.root.pattern),
                children: [...developerRoutesChildren],
              },
              {
                path: relativePath(RoutesManager.deployment.root.pattern),
                children: [...consentRightsRoutesChildren],
              },
              {
                path: relativePath(RoutesManager.orchestration.root.pattern),
                children: [...orchestrationRoutesChildren],
              },
              {
                path: relativePath(RoutesManager.settings.root.pattern),
                children: [...settingsRoutesChildren],
              },
              {
                path: relativePath(RoutesManager.systems.root.pattern),
                children: [...DataMapRoutesChildren],
              },
              {
                path: relativePath(RoutesManager.updates.root.pattern),
                children: [...updatesRoutesChildren],
              },

              {
                path: relativePath(RoutesManager.developmentUtils.root.pattern),
                children: [...developmentUtilsRoutesChildren],
              },
            ],
          },
        ],
      },
      {
        element: <Callback />,
        path: 'callback',
      },
      {
        path: 'auth/invite',
        element: <Invite />,
      },
      {
        element: <Register />,
        path: 'registration/register',
      },
      {
        element: <SignInErrorWithinAuth0Provider message="Oops, something went wrong" buttonText="Try Again" />,
        path: 'auth/error',
      },
      {
        element: <SignUp />,
        path: RoutesManager.onboarding.signUp.root.pattern,
      },
      ...collaborationWorkspaceOverviewRoute,
    ],
  },
  {
    element: <RedirectWithToast />,
    path: '*',
  },
]

export const router = createBrowserRouter(routes)
