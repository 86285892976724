import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PortalPage } from '../common/component/PortalPage'
import { Spinner } from '@ketch-com/deck'
import { useFetchExternalToken } from 'api/assessments/queries/useFetchExternalToken'
import { RoutesManager } from 'utils/routing/routesManager'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { Box } from '@mui/material'

export const ExternalAuthCallbackPage: React.FC = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const code = searchParams.get('code')
  const state = searchParams.get('state')

  useFetchExternalToken({
    params: {
      code: code || '',
      state: state || '',
    },
    onSuccess: data => {
      const { accessToken, email } = data.data
      if (!accessToken || !email) {
        console.error('Missing accessToken or email, unable to store authentication data.')
        return
      }

      localStorage.setItem('collaborationToken', accessToken)
      localStorage.setItem('externalUserEmail', email)

      // redirect to overview page
      navigate(RoutesManager.externalPrivacyPortal.overview.root.getURL())
    },
    onError: error => {
      showToast({ content: 'Failed to fetch token', type: 'error' })
      console.error('token query error', error)
      // redirect to login page
      navigate(RoutesManager.externalPrivacyPortal.login.getURL())
    },
  })
  return (
    <PortalPage withoutAuth>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" flexGrow={1} height="100%">
        <Spinner size="36px" thickness={4} />
      </Box>
    </PortalPage>
  )
}
