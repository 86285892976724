import React, { useContext, useEffect, useState } from 'react'
import { useFormikContext } from 'formik'
import { DataGrid, TableCell, SwitchToggle, DropListButton, ActionSheetItem, ListItemText } from '@ketch-com/deck'
import { RightDTO } from 'interfaces/rights/right'
import { FormCheckbox } from 'components/form/FormCheckbox'
import { FormInlineEdit } from 'components/form/FormInlineEdit'
import { CustomRightNameDTO } from '@ketch-com/figurehead'

import Box from '@mui/material/Box'
import { optOutRights, optOutRightsOverrideOrgs } from '../../../constants'
import { PolicyScopeFormValues } from '../../../interfaces'
import { PolicyScopeUpsertContext } from '../../../context/PolicyScopeUpsertContext'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import Typography from '@mui/material/Typography'
import { useUserMe } from 'api/users/queries/useUserMe'

type DropListItemType = CustomRightNameDTO & { subTitle?: string; selected?: boolean }

type Props = {
  rights: RightDTO[]
  setRightToConfirmCode: (code: string) => void
}

export const RightsTableWithAppeals: React.FC<Props> = ({ rights, setRightToConfirmCode }) => {
  const { isEditMode, customRightNamesList } = useContext(PolicyScopeUpsertContext)
  const { values, setFieldValue } = useFormikContext<PolicyScopeFormValues>()
  const { rightsFulfillment } = values
  const { data: user } = useUserMe()
  const rows = rightsFulfillment
    // Unchecked-by-default rows should be at the bottom
    .sort((a, b) => (optOutRights.includes(b.rightCode) ? -1 : 1))
    // Mui DataGrid requires an id for each row
    .map((right, index) => ({ id: index, ...right }))

  const [selectedValues, setSelectedValues] = useState(Array(rows.length).fill(''))

  const [usedDisplayNames, setUsedDisplayNames] = useState(new Set())
  const dropdownListItems = customRightNamesList.map(customRightName => ({
    content: customRightName.name,
    active: false,
    ...customRightName,
  }))

  const handleChange = (index: number, newValue: any) => {
    const newSelectedValues = [...selectedValues]
    newSelectedValues[index] = newValue
    setSelectedValues(newSelectedValues)
  }

  useEffect(() => {
    const handleDropDownPopulation = () => {
      const set = new Set()
      rows.forEach((row, index) => {
        if (row?.customRightNameID) {
          const customRightNameItemData = dropdownListItems.find(item => item.id === row.customRightNameID)
          set.add(customRightNameItemData?.content)
          const newSelectedValues = [...selectedValues]
          newSelectedValues[index] = customRightNameItemData?.content
          setSelectedValues(newSelectedValues)
        }
        setUsedDisplayNames(set)
      })
    }
    handleDropDownPopulation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  type RowType = (typeof rows)[0]

  const gridColumns = [
    {
      field: 'Right',
      headerName: 'Right',
      renderCell: ({ row }: GridRenderCellParams<RowType>) => {
        const { id, rightCode } = row

        const right = rights.find(({ code }) => code === rightCode)
        return (
          <TableCell columnGap={1.5}>
            <Box sx={{ whiteSpace: 'normal' }}>
              <FormCheckbox
                label={right?.name}
                inlineLabel
                name={`rightsFulfillment[${id}].checked`}
                onChange={(_, checked) => {
                  // NOTE: if the right is in the optOutRights array and it's not checked show the modal for confirmation,
                  // unless this org is in the optOutRightsOverrideOrgs array
                  if (
                    optOutRights?.includes(rightCode) &&
                    !rightsFulfillment?.[id]?.checked &&
                    !optOutRightsOverrideOrgs.includes(user?.organization?.code || '')
                  ) {
                    setRightToConfirmCode(rightCode)
                  } else {
                    // NOTE: if the right is being unchecked set allowAppeal to false
                    if (!checked) setFieldValue(`rightsFulfillment[${id}].allowAppeal`, false)
                    setFieldValue(`rightsFulfillment[${id}].checked`, checked)
                  }
                }}
              />
            </Box>
          </TableCell>
        )
      },
      sortable: false,
      width: 200,
    },
    {
      field: 'displayName',
      headerName: 'Display Name',
      renderCell: ({ row }: GridRenderCellParams<RowType>) => {
        const { id, rightCode, customRightNameID } = row
        let rightName = rights.find(({ code }) => code === rightCode)
        const defaultRightDisplayName = rightName?.translations?.en.name
        const customRightNameData = customRightNamesList.find(item => item.id === customRightNameID)

        const defaultItem = {
          name: defaultRightDisplayName,
          subTitle: 'Default Display Name',
          selected: true,
          id: defaultRightDisplayName,
        }

        const dropListOptions: Array<DropListItemType> = [defaultItem, ...customRightNamesList]

        const selectedOption = dropListOptions.find(elem => elem.name === selectedValues[id]) || customRightNameData
        return (
          <TableCell columnGap={1.5} width="100%">
            <Box sx={{ whiteSpace: 'normal' }} width="100%">
              <DropListButton
                key={row.id}
                disableClearable
                fullWidth
                getOptionLabel={option => option.name || ''}
                renderOption={(props, option: DropListItemType, { selected }) => (
                  <ActionSheetItem {...props} key={option.id} selected={selected}>
                    <ListItemText
                      primary={option.name}
                      secondary={option.subTitle}
                      selected={selected}
                      sx={{ maxHeight: 'none' }}
                    />
                  </ActionSheetItem>
                )}
                options={dropListOptions.filter(item => !usedDisplayNames.has(item.name))}
                onChange={(_, value) => {
                  setUsedDisplayNames(prevSet => {
                    const newSet = new Set(prevSet)
                    // if you have a selection and it's a new selection
                    if (selectedValues[id] && selectedValues[id] !== value.name) {
                      // remove the old from the set and add new selection
                      newSet.delete(selectedValues[id])
                    }
                    newSet.add(value.name)
                    handleChange(id, value.name)
                    setFieldValue(`rightsFulfillment[${id}].customRightNameID`, value.id)

                    return newSet
                  })
                }}
                value={selectedOption || defaultItem}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                placeholder={rightCode === '' ? 'All Rights' : ''}
              />
            </Box>
          </TableCell>
        )
      },
      sortable: false,
      width: 250,
      flex: 1,
    },
    {
      field: 'Description',
      headerName: 'Description',
      renderCell: ({ row }: GridRenderCellParams<RowType>) => {
        const right = rights.find(({ code }) => code === row.rightCode)
        return (
          <TableCell>
            <Typography>{right?.description}</Typography>
          </TableCell>
        )
      },
      ...(!isEditMode && {
        width: 450,
      }),
      sortable: false,
      flex: 1,
    },
    {
      field: 'Fulfillment Time',
      headerName: 'Fulfillment Time',
      renderCell: ({ row }: GridRenderCellParams<RowType>) => {
        return (
          <TableCell sx={{ padding: '8px 0', display: 'flex', alignItems: 'flex-end' }}>
            {row.checked ? (
              <FormInlineEdit
                required
                formPropertyName={`rightsFulfillment[${row.id}].fulfillment`}
                id={`rightsFulfillment[${row.id}].fulfillment`}
                type="number"
                placeholder="fulfillment"
                shouldUpdateDebounced
                onKeyDown={e => e.stopPropagation()}
              />
            ) : (
              <Typography color={theme => theme.palette.lightGrey.main}>None</Typography>
            )}
          </TableCell>
        )
      },
      width: 127,
      sortable: false,
    },
    {
      field: 'Right to Appeal',
      headerName: 'Right to Appeal',
      renderCell: ({ row }: GridRenderCellParams<RowType>) => {
        return (
          <TableCell>
            <Box display="flex" alignItems="flex-start" gap={1} height="100%">
              <SwitchToggle
                disabled={!row.checked}
                name={`rightsFulfillment[${row.id}].allowAppeal`}
                onChange={(_, checked) => {
                  if (checked) {
                    setFieldValue(`rightsFulfillment[${row.id}].allowAppeal`, true)
                  } else {
                    setFieldValue(`rightsFulfillment[${row.id}].allowAppeal`, false)
                  }
                }}
              />
            </Box>
          </TableCell>
        )
      },
      width: 127,
      sortable: false,
    },
  ]

  return (
    <DataGrid
      rowCount={rows.length}
      rows={rows}
      columns={gridColumns}
      autosizeOnMount
      disableColumnMenu
      disableColumnPinning
      disableColumnReorder
      disableChildrenSorting
      disableColumnResize
      disableRowHoverStates
      hideFooter
      disableRowSelectionOnClick
      getRowId={({ id }) => id}
      sx={{
        padding: 0,
        '& .MuiDataGrid-columnHeader[role="columnheader"]:hover': {
          backgroundImage: 'unset',
        },
      }}
      getRowHeight={() => 'auto'}
    />
  )
}
