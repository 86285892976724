import { useState, useMemo, useEffect } from 'react'
import { createSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { FormMode } from 'interfaces/formModes/formMode'
import { useEnabledOrganizationLanguages } from 'pages/consentAndRights/forms/fields/upsert/hooks'
import { useIsEditMode, useQueryParams } from 'utils/hooks'
import { useFormFields } from 'api/formFields/queries/useFormFields'
import {
  useFormTemplateValidationSchema,
  useGenerateEmptyFormTemplateSection,
  useGetFormTemplateInitialValues,
} from '.'
import { useLanguages } from 'api/languages/queries/useLanguages'
import { useFormTemplate } from 'api/formTemplates/queries/useFormTemplate'
import { FormTemplateDTO } from '@ketch-com/figurehead'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useCreateFormTemplate } from 'api/formTemplates/mutations/useCreateFormTemplate'
import { useUpdateFormTemplate } from 'api/formTemplates/mutations/useUpdateFormTemplate'
import { FormikHelpers } from 'formik'
import { cloneDeep } from 'lodash'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { mandatoryDefaultFieldsCodes } from '../constants'
import { formTemplateSectionIsEmpty } from '../utils'
import { PreviewFrom } from '../../preview/interfaces'
import { useDataSubjectTypes } from 'api/dataSubjectTypes/queries/useDataSubjectTypes'

type FormikSubmitHandler = (
  values: FormTemplateDTO,
  formikHelpers: FormikHelpers<FormTemplateDTO>,
) => void | Promise<any>

export const useFormTemplateUpsertContainerUtils = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const isEditMode = useIsEditMode()
  const queryClient = useQueryClient()
  const { id: formTemplateIdFromUrlParams } = useParams<{ id?: string }>()
  const [activeSectionIndex, setActiveSectionIndex] = useState<number | undefined | 'form-title'>(undefined)
  const [selectedFieldCodeForAddition, setSelectedFieldCodeForAddition] = useState<string | undefined>(undefined)
  const { data: languages, isLoading: isLoadingLanguages } = useLanguages()
  const { enabledLanguages, isLoadingEnabledLanguages } = useEnabledOrganizationLanguages()
  const generateEmptyFormTemplateSection = useGenerateEmptyFormTemplateSection()
  const [isFormTemplateUsedInExperiencesModalOpen, setIsFormTemplateUsedInExperiencesModalOpen] = useState(false)
  const [isFormTemplateSaveChangesModalOpen, setIsFormTemplateSaveChangesModalOpen] = useState(false)

  const { removeAllQueryParams } = useQueryParams()

  // fetch data

  const { data: formFields, isLoading: isLoadingFormFields } = useFormFields({
    itemsPerPage: 1000,
    params: {
      includeMetadata: true,
    },
  })

  const { data: formTemplate, isLoading: isLoadingFormTemplate } = useFormTemplate({
    enabled: isEditMode && !!formTemplateIdFromUrlParams,
    params: {
      formTemplateId: formTemplateIdFromUrlParams || '',
      includeMetadata: true,
    },
  })

  const { data: dataSubjectTypes, isLoading: isDataSubjectTypesLoading } = useDataSubjectTypes({
    params: {
      limit: 200,
    },
  })

  // mutations

  const { mutateAsync: handleCreateFormTemplate, isLoading: isCreatingFormTemplate } = useCreateFormTemplate({
    onSuccess: async () => {
      showToast({ content: 'Form created', type: 'success' })
      await queryClient.refetchQueries([ApiQueryKeys.formTemplatesPaginated])
      navigate(RoutesManager.deployment.forms.templates.root.getURL())
    },
  })

  const { mutateAsync: handleUpdateFormTemplate, isLoading: isUpdatingFormTemplate } = useUpdateFormTemplate({
    onSuccess: async () => {
      showToast({ content: 'Form updated', type: 'success' })
      await queryClient.refetchQueries([
        ApiQueryKeys.formTemplate,
        {
          formTemplateId: formTemplateIdFromUrlParams,
          includeMetadata: true,
        },
      ])
      setIsFormTemplateSaveChangesModalOpen(false)
      navigate({
        pathname: RoutesManager.deployment.forms.templates.upsert.preview.root.getURL({
          formMode: FormMode.EDIT,
          id: formTemplateIdFromUrlParams || '',
        }),
        search: createSearchParams({
          from: PreviewFrom.Detail,
        }).toString(),
      })
    },
  })

  const handleSubmit: FormikSubmitHandler = async (values, formikHelpers) => {
    const copyValues = cloneDeep(values)
    const formData = {
      ...copyValues,
      sections: values?.sections?.filter(s => !formTemplateSectionIsEmpty(s)),
    }

    try {
      isEditMode
        ? await handleUpdateFormTemplate({
            params: {
              formData: {
                formTemplate: formData,
              },
            },
          })
        : await handleCreateFormTemplate({
            params: {
              formData: {
                formTemplate: formData,
              },
            },
          })
      formikHelpers.setSubmitting(false)
    } catch (error) {
      formikHelpers.setSubmitting(false)
      formikHelpers.setStatus({ error: 'Submission failed: ' + error })
    }
  }

  const getFormTemplateInitialValues = useGetFormTemplateInitialValues()

  const initialValues = useMemo(
    () =>
      getFormTemplateInitialValues({
        enabledLanguages,
        formFields,
        formTemplate,
        // formTemplate: queries?.formDTO ? (JSON.parse(queries.formDTO) as FormTemplateDTO) : formTemplate,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enabledLanguages, getFormTemplateInitialValues, formFields, formTemplate],
  )

  const validationSchema = useFormTemplateValidationSchema()

  // clear query params on hydration with useEffect

  useEffect(() => {
    if (!location.pathname.includes('preview')) {
      removeAllQueryParams()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // component utils

  const mandatoryFormFieldIds = useMemo(
    () => formFields?.filter(field => mandatoryDefaultFieldsCodes.includes(field?.code || ''))?.map(field => field.id),
    [formFields],
  )

  const breadcrumbs = isEditMode
    ? [
        { title: 'Consent & Rights', link: RoutesManager.deployment.applications.root.getURL() },
        { title: 'Forms', link: RoutesManager.deployment.forms.templates.root.getURL() },
        {
          title: formTemplate?.name || formTemplate?.title,
          link: RoutesManager.deployment.forms.templates.upsert.root.getURL({
            id: formTemplate?.id,
            formMode: FormMode.EDIT,
          }),
        },
        { title: 'Edit Custom Form' },
      ]
    : [
        { title: 'Consent & Rights', link: RoutesManager.deployment.applications.root.getURL() },
        { title: 'Forms', link: RoutesManager.deployment.forms.templates.root.getURL() },
        { title: 'Create Custom Form' },
      ]

  const isReady =
    !isLoadingEnabledLanguages &&
    !isLoadingFormFields &&
    !isLoadingLanguages &&
    !isLoadingFormTemplate &&
    !isDataSubjectTypesLoading

  const payload = {
    activeSectionIndex,
    breadcrumbs,
    enabledLanguages,
    formFields,
    formTemplate,
    generateEmptyFormTemplateSection,
    handleSubmit,
    initialValues,
    isCreatingFormTemplate,
    isFormTemplateUsedInExperiencesModalOpen,
    isEditMode,
    isReady,
    isUpdatingFormTemplate,
    isFormTemplateSaveChangesModalOpen,
    languages,
    mandatoryFormFieldIds,
    navigate,
    selectedFieldCodeForAddition,
    setActiveSectionIndex,
    setIsFormTemplateUsedInExperiencesModalOpen,
    setSelectedFieldCodeForAddition,
    setIsFormTemplateSaveChangesModalOpen,
    validationSchema,
    dataSubjectTypes,
  }

  return payload
}

export type UseFormFieldUpsertContainerUtilsReturnType = ReturnType<typeof useFormTemplateUpsertContainerUtils>
