import { IRegionFE } from 'interfaces'
import { RegionDTO } from 'interfaces/regions/region'
import { transformRegionsForFeTree } from 'pages/policyCenter/policyScopes/utils'
import { PolicyScopeFormStep } from 'pages/policyCenter/policyScopes/upsert/utils/constants'

export const getRegionsHierarchy = (regionGroups: RegionDTO[]): RegionDTO[] => {
  const regionsHierarchyTransformed: IRegionFE[] = []

  regionGroups?.forEach?.(region => transformRegionsForFeTree(region, regionsHierarchyTransformed))

  return regionsHierarchyTransformed
}

export const getFormSteps = () => [
  PolicyScopeFormStep.DETAILS,
  PolicyScopeFormStep.REGULATIONS,
  PolicyScopeFormStep.RIGHTS,
  PolicyScopeFormStep.PURPOSES,
]

export { useValidationSchema } from './useValidationSchema'
export { mapValuesToPayload } from './mapValuesToPayload'
export { getCreatePolicyScopeInitialValues } from './getCreatePolicyScopeInitialValues'
