import React, { useState } from 'react'
import { useDsrConfigCount } from 'api/assets/queries/useDsrConfigCount'
import { useAssetV2 } from 'api/assets/queries/useAssetV2'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { RightType } from 'interfaces/events'
import { DatabaseScenario } from 'pages/dataSystems/DataSystem/rightsOrchestrationTab/DatabaseScenario/DatabaseScenario'
import { Logger } from 'utils/Logger'
import { Alert } from '@ketch-com/deck'
import { useDsrConfigSelectedAssetContext } from 'pages/dataSystems/AssetMigration/ScenarioDetails/context/DsrConfigSelectedAssetContext'

export const DatabaseScenarioContainer = () => {
  const [rightType, setRightType] = useState(RightType.RIGHT_TYPE_UNSPECIFIED)
  const { selectedAsset } = useDsrConfigSelectedAssetContext()
  const assetId = selectedAsset?.resource?.id
  const resourceTypeCode = selectedAsset?.resource?.resourceType!
  /*
    No pagination. Max limit is 100 scenarios for now.
    https://ketch-com.atlassian.net/browse/KD-5597
  */
  const {
    data: dsrConfigCount,
    isLoading: isDsrConfigCountLoading,
    error: dsrError,
  } = useDsrConfigCount({
    params: {
      assetId: assetId,
      start: 0,
      limit: 100,
      rightType,
      canonicalRightCode: 'get,delete',
    },
  })
  const {
    data: assetSummary,
    isLoading: isAssetSummaryLoading,
    error: assetError,
  } = useAssetV2({
    params: {
      assetCode: assetId ? assetId : '',
      resourceTypeCode,
    },
    enabled: !!assetId,
  })

  const isReady = !isDsrConfigCountLoading && !isAssetSummaryLoading
  if (!isReady) {
    return <Spinner size={16} />
  } else if (assetError) {
    Logger.error('Failed to fetch asset', assetError)
    return (
      <Alert
        severity="warning"
        title="Failed to fetch asset. Trying again..."
        message="Contact support if the problem persists"
      />
    )
  } else if (dsrError) {
    Logger.error('Failed to fetch dsr configurations', dsrError)
    return (
      <Alert
        severity="warning"
        title="Failed to fetch configurations for the Data Subject Rights. Trying again..."
        message="Contact support if the problem persists"
      />
    )
  } else if (!assetSummary.asset || !assetSummary.asset.resource) {
    Logger.error(`Malformed Asset detected. --> ${assetId}`)
    return null
  }
  return (
    <DatabaseScenario
      dsrConfigCount={dsrConfigCount}
      rightType={rightType}
      setRightType={setRightType}
      assetSummary={assetSummary}
    />
  )
}
