import { Icon } from '@ketch-com/deck'
import { Box, Typography, styled } from '@mui/material'
import { getIn } from 'formik'
import { BuilderContext } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/Builder'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'
import { useContext } from 'react'
import { BuilderSwitch, SwitchVariant } from '../../../../../BuilderSwitch'
import { ItemStyle, ListLayout } from '@ketch-sdk/ketch-types'
import { clamp } from 'lodash'
import { BUILDER_CONSTANTS } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/utils/enums'
import { SectionEmptyState } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/components/SectionEmptyState'

type SampleListItemData = {
  purpose: string
  switchVariant: SwitchVariant
  legalBasisText: string
  open: boolean
}

const sampleListData: SampleListItemData[] = [
  {
    purpose: 'Analytics',
    switchVariant: SwitchVariant.alwaysOn,
    legalBasisText: 'Legal Basis: Consent - Opt Out',
    open: true,
  },
  {
    purpose: 'Essential Services',
    switchVariant: SwitchVariant.on,
    legalBasisText: 'Legal Basis: Consent - Opt Out',
    open: false,
  },
  {
    purpose: 'Personalization',
    switchVariant: SwitchVariant.off,
    legalBasisText: 'Legal Basis: Consent - Opt In',
    open: false,
  },
]

const PurposeItemsBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  marginBottom: '12px',
})

const PurposeItemBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '12px 12px 12px 8px',
})

const PurposeItemContentBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  paddingLeft: '32px',
})

const PurposeItemHeaderBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const PurposeItemHeaderLeftBox = styled(Box)({
  display: 'flex',
  justifyItems: 'center',
  alignItems: 'center',
  gap: '8px',
})

const PurposeItemLegalBasisBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
})

const PurposeItemLinksBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
})

const PurposeItemLinkBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
})

export const PurposesList: React.FC = () => {
  const { themeConfig, experienceConfig, isTheme } = useContext(BuilderContext)

  // Theme config
  const listItemStyle = getIn(themeConfig, themeUpsertFormKeys.preferencePurposesTabListItemsStyle)
  const listItemLayout = getIn(themeConfig, themeUpsertFormKeys.preferencePurposesTabListItemsLayout)
  const listItemBackgroundColor = getIn(themeConfig, themeUpsertFormKeys.preferencePurposesTabListItemsPurposeFillColor)
  const listItemOutlineColor = getIn(themeConfig, themeUpsertFormKeys.preferencePurposesTabListItemsPurposeOutlineColor)
  const listItemTextColor = getIn(themeConfig, themeUpsertFormKeys.preferencePurposesTabListItemsPurposeContentColor)
  const listItemLinkColor = getIn(themeConfig, themeUpsertFormKeys.preferencePurposesTabListItemsPurposeLinksColor)
  const listItemArrowColor = getIn(themeConfig, themeUpsertFormKeys.preferencePurposesTabListItemsArrowIconColor)
  const listItemCornerRadius =
    clamp(
      getIn(themeConfig, themeUpsertFormKeys.preferencePurposesTabListItemsPurposeCornerRadius),
      BUILDER_CONSTANTS.MIN_BORER_RADIUS,
      BUILDER_CONSTANTS.MAX_BORDER_RADIUS,
    ) + 'px'
  const switchButtonsOnBackgroundColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferencePurposesTabListSwitchButtonsOnBackgroundColor,
  )
  const switchButtonsOnTextColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferencePurposesTabListSwitchButtonsOnTextColor,
  )
  const switchButtonsOffBackgroundColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferencePurposesTabListSwitchButtonsOffBackgroundColor,
  )
  const switchButtonsOffTextColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferencePurposesTabListSwitchButtonsOffTextColor,
  )

  // For list layout === expandable (default), the outlined style corresponds to an underline (bottom border online), vs for
  // list layout === cards, the outlined style corresponds to a full border.
  const isOutlined = listItemLayout === ListLayout.Cards && listItemStyle === ItemStyle.Outlined
  const isUnderlined = listItemLayout === ListLayout.Expandable && listItemStyle === ItemStyle.Outlined
  const hasBorderRadius = listItemStyle === ItemStyle.Filled

  // Experience config
  const showLegalBases = getIn(experienceConfig, experienceUpsertFormKeys.preferencePurposesTabListLegalBasisVisible)
  const showSwitchButtonLabels = getIn(
    experienceConfig,
    experienceUpsertFormKeys.preferencePurposesTabListSwitchButtonLabelsVisible,
  )
  const switchButtonLabelsOnText = getIn(
    experienceConfig,
    experienceUpsertFormKeys.preferencePurposesTabListSwitchButtonsOnText,
  )
  const switchButtonLabelsOffText = getIn(
    experienceConfig,
    experienceUpsertFormKeys.preferencePurposesTabListSwitchButtonsOffText,
  )
  const showVendorsLink = getIn(experienceConfig, experienceUpsertFormKeys.preferencePurposesTabListVendorsVisible)
  const vendorsLinkText = getIn(experienceConfig, experienceUpsertFormKeys.preferencePurposesTabListVendorsLinkText)

  return (
    <PurposeItemsBox>
      {sampleListData.map(({ purpose, legalBasisText, switchVariant, open }) => (
        <PurposeItemBox
          sx={{
            background: listItemStyle === ItemStyle.Filled ? listItemBackgroundColor : '',
            border: isOutlined ? `1.5px solid ${listItemOutlineColor}` : '',
            borderBottom: isUnderlined ? `1.5px solid ${listItemOutlineColor}` : '',
            borderRadius: hasBorderRadius ? listItemCornerRadius : '',
          }}
        >
          {/* Header */}
          <PurposeItemHeaderBox>
            <PurposeItemHeaderLeftBox>
              <Icon name={open ? 'OArrowCDown' : 'OArrowCRight'} iconColor={listItemArrowColor} />
              <Box display={'flex'} flexDirection={'column'}>
                <Typography fontSize="16px" fontWeight={700} color={listItemTextColor}>
                  {purpose}
                </Typography>
                {showLegalBases && (
                  <Typography fontSize="12px" fontWeight={400} color={listItemTextColor} sx={{ opacity: '0.65' }}>
                    {legalBasisText}
                  </Typography>
                )}
              </Box>
            </PurposeItemHeaderLeftBox>
            <Box>
              <BuilderSwitch
                variant={switchVariant}
                onBackgroundColor={switchButtonsOnBackgroundColor}
                onTextColor={switchButtonsOnTextColor}
                offBackgroundColor={switchButtonsOffBackgroundColor}
                offTextColor={switchButtonsOffTextColor}
                onText={switchButtonLabelsOnText}
                offText={switchButtonLabelsOffText}
                showText={showSwitchButtonLabels}
              />
            </Box>
          </PurposeItemHeaderBox>

          {open && (
            <PurposeItemContentBox>
              {/* Description */}
              <Box>
                <p
                  style={{
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '21px',
                    padding: 0,
                    margin: 0,
                    color: listItemTextColor,
                  }}
                >
                  Data may be utilized in various ways to derive additional meaning and purpose. This can include the
                  use of data in aggregate views or tactical detailed analysis, the use of data in algorithms and
                  models, and the use of data for reporting and dashboards.
                </p>
              </Box>

              {/* Legal basis */}
              {showLegalBases && (
                <PurposeItemLegalBasisBox>
                  <Typography variant="label" color={listItemTextColor}>
                    Legal Basis
                  </Typography>
                  <Typography variant="body" color={listItemTextColor}>
                    Data subject has received adequate disclosure describing the processing for one or more specific
                    purposes, and can subsequently opt-out.
                  </Typography>
                </PurposeItemLegalBasisBox>
              )}

              {/* Links */}
              <PurposeItemLinksBox>
                <PurposeItemLinkBox>
                  <Typography variant="label" color={listItemLinkColor}>
                    Cookies
                  </Typography>
                  <Icon name={'OArrowRight'} width={20} height={20} iconColor={listItemLinkColor} />
                </PurposeItemLinkBox>
                {showVendorsLink ? (
                  <PurposeItemLinkBox>
                    <Typography variant="label" color={listItemLinkColor}>
                      {vendorsLinkText}
                    </Typography>
                    <Icon name={'OArrowRight'} width={20} height={20} iconColor={listItemLinkColor} />
                  </PurposeItemLinkBox>
                ) : (
                  <SectionEmptyState section="Vendors Link" hidden={isTheme} compact width="fit-content" />
                )}
              </PurposeItemLinksBox>
            </PurposeItemContentBox>
          )}
        </PurposeItemBox>
      ))}
    </PurposeItemsBox>
  )
}
