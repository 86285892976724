import { formatRequestString } from '../../../utils/helpers/api/queryString'
import { API } from '../../../utils/api/api'

type Params = {
  activityId: string
  isV2FeatureFlagEnabled?: boolean
}

export const deleteProcessingActivity = ({ activityId, isV2FeatureFlagEnabled }: Params) => {
  const entityUrl = isV2FeatureFlagEnabled
    ? `/api/risk-review/processing-activities/${activityId}`
    : `/api/processing-activities/${activityId}`
  return API.delete<{}>(
    formatRequestString({
      entityUrl,
    }),
  )
}
