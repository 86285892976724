import { ActionSheetItem, ActionSheetWrapper, Button, Icon } from '@ketch-com/deck'
import { ListAssetItemDTO } from '@ketch-com/figurehead'
import { useCallback, useMemo, useState } from 'react'
import { FilterContextProps } from '../context/AssetsTabFilterContext'
import { AssetsTableActionModalType, ClassificationList } from '../../types'
import { useDataSystemsContext } from '../../context/DataSystemContext'
import { useDataPreview } from 'api/assets/queries/useDataPreview'
import { Link } from 'components/ui-kit/link/Link'
import { resourceSupportPreviewMapping } from '../../constants'

export interface AssetsTabTableRowEditMenuProps {
  row: ListAssetItemDTO
  triggerModal: FilterContextProps['setShowEditModal']
}

interface MenuOption {
  label: string
  value: string
}

const dataSensitivity: MenuOption = { label: 'Edit Data Sensitivity', value: 'dataSensitivities' }
const dataCategory: MenuOption = { label: 'Edit Data Categories', value: 'dataCategories' }
const dataType: MenuOption = { label: 'Edit Data Types', value: 'dataTypes' }
const dataOwner: MenuOption = { label: 'Edit Data Owner', value: 'dataOwner' }
const schedule: MenuOption = { label: 'Edit Schedule', value: 'classificationSchedule' }

const menuOptionsByCanonnicalType: Record<string, MenuOption[]> = {
  CANONICAL_RESOURCE_TYPE_DATABASE: [dataOwner, dataSensitivity, dataCategory, schedule],
  CANONICAL_RESOURCE_TYPE_SCHEMA: [dataSensitivity, dataCategory],
  CANONICAL_RESOURCE_TYPE_DATASET: [dataSensitivity, dataCategory],
  CANONICAL_RESOURCE_TYPE_OBJECT: [dataSensitivity, dataCategory],
  CANONICAL_RESOURCE_TYPE_FOLDER: [dataSensitivity, dataCategory],
  CANONICAL_RESOURCE_TYPE_FILE: [dataSensitivity, dataCategory, dataType],
  CANONICAL_RESOURCE_TYPE_ATTRIBUTE: [dataSensitivity, dataCategory, dataType],
}

export const AssetsTabTableRowEditMenu: React.FC<AssetsTabTableRowEditMenuProps> = ({ row, triggerModal }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const { isSamplesPreviewEnabled, showClassificationSchedule } = useDataSystemsContext()

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const resourceTypeCode = row.resource?.resourceType
  const doesResourceSupportPreview = resourceTypeCode && resourceSupportPreviewMapping[resourceTypeCode]
  const isDataPreviewSupported = isSamplesPreviewEnabled && doesResourceSupportPreview
  const showDataPreview = !!(isDataPreviewSupported && row.resource?.connection?.id && row.resource?.id)

  const { data: dataPreview } = useDataPreview({
    enabled: showDataPreview,
    params: {
      applianceID: row.resource?.connection?.id,
      tableResourceID: row.resource?.id,
    },
  })

  const menuItems = useMemo(() => {
    const list = !row.resource?.canonicalResourceType
      ? []
      : showClassificationSchedule
      ? [...menuOptionsByCanonnicalType[row.resource.canonicalResourceType]]
      : menuOptionsByCanonnicalType[row.resource.canonicalResourceType].filter(
          item => item.value !== 'classificationSchedule',
        )

    if (showDataPreview && !list.some(item => item.value === 'dataPreview')) {
      list.push({ label: 'View Data Preview', value: 'dataPreview' })
    }
    return list
  }, [row.resource?.canonicalResourceType, showClassificationSchedule, showDataPreview])

  const handleSelectTriggerType = useCallback(
    (item: MenuOption) => {
      if (item.value === 'classificationSchedule') {
        triggerModal({
          editModalType: AssetsTableActionModalType.EditSampling,
          modalProps: {
            assetCode: row.resource!.id!,
            resourceTypeCode: row.resource!.resourceType!,
            schedulingData: row.schedule,
            connectionId: row.resource?.connection?.id,
            onClose: () => {
              triggerModal(undefined)
            },
          },
        })
      } else if (item.value === 'dataOwner') {
        triggerModal({
          editModalType: AssetsTableActionModalType.editDataOwner,
          modalProps: {
            assetCode: row.resource?.id!,
            resourceTypeCode: row.resource?.resourceType!,
            dataOwner: row.resource?.dataOwner,
            onClose: () => {
              triggerModal(undefined)
            },
          },
        })
      } else if (item.value === 'dataSensitivities') {
        triggerModal({
          editModalType: AssetsTableActionModalType.editSensitivity,
          modalProps: {
            canonicalResourceType: row.resource!.canonicalResourceType!,
            resourceName: row.resource!.name || '',
            sensitivityClassification: row.dataSensitivities,
            resourceType: row.resource?.resourceType,
            assetIds: [row.resource?.id],
            onClose: () => {
              triggerModal(undefined)
            },
          },
        })
      } else {
        const keyVal = item.value as keyof ListAssetItemDTO
        triggerModal({
          editModalType:
            keyVal === 'dataCategories'
              ? AssetsTableActionModalType.editDataCategory
              : AssetsTableActionModalType.editDataType,
          modalProps: {
            canonicalResourceType: row.resource!.canonicalResourceType!,
            resourceName: row.resource!.name || '',
            classificationName: item.label,
            classificationType: keyVal,
            classificationList: row[keyVal] ? (row[keyVal] as ClassificationList) : [],
            resourceType: row.resource?.resourceType,
            assetIds: [row.resource?.id],
            onClose: () => {
              triggerModal(undefined)
            },
          },
        })
      }
      handleClose()
    },
    [row, triggerModal],
  )

  return (
    <>
      <Button variant="icon" color="ghost" onClick={handleButtonClick}>
        <Icon name="OMore" />{' '}
      </Button>
      <ActionSheetWrapper anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
        {menuItems.map(item => {
          if (item.value === 'dataPreview') {
            return (
              <Link outer to={dataPreview?.previewURL || ''} target="_blank">
                <ActionSheetItem sx={{ width: '100%' }} key={item.value} data-test-id={`menu-item-${item.value}`}>
                  {item.label}
                </ActionSheetItem>
              </Link>
            )
          }

          return (
            <ActionSheetItem
              key={item.value}
              data-test-id={`menu-item-${item.value}`}
              onClick={() => {
                handleSelectTriggerType(item)
              }}
            >
              {item.label}
            </ActionSheetItem>
          )
        })}
      </ActionSheetWrapper>
    </>
  )
}
