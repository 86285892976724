import { Box, Typography } from '@mui/material'
import { AssessmentSectionBlockDTO } from '@ketch-com/figurehead'
import {
  Assessment,
  AssessmentDocument,
  AssessmentSection as AssessmentSectionType,
} from '@ketch-com/windlass/dist/assessments/externalUser'

import { Accordion, AccordionDetails, AccordionSummary, theme } from '@ketch-com/deck'
import { AssesssmentQuestion } from './AssessmentQuestion'
import { ExternalAssessmentResponseProvider } from '../context/ExternalAssessmentResponseContext'
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query'
import { AxiosResponse } from 'axios'

export type AssessmentSectionProps = {
  section: AssessmentSectionType
  sectionIndex: number
  assessment?: Assessment
  isOpen: boolean
  toggleOpen: () => void
  startAutoSave?: (id: string) => void
  finishAutoSave?: (id: string) => void
  showQuestionDetails?: boolean
  refetchAssessment: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<AxiosResponse<AssessmentDocument, any>, unknown>>
}

export const AssessmentSection: React.FC<AssessmentSectionProps> = ({
  section,
  assessment = {},
  sectionIndex,
  isOpen,
  toggleOpen,
  refetchAssessment,
  startAutoSave = () => {},
  finishAutoSave = () => {},
  showQuestionDetails = false,
}) => {
  const blocks = section.blocks ?? ([] as AssessmentSectionBlockDTO[])

  return (
    <Box mt={4} width={976}>
      <Accordion expanded={isOpen}>
        <AccordionSummary
          onClick={toggleOpen}
          size="xlarge"
          sx={{
            '&.Mui-focusVisible': {
              backgroundColor: theme.palette.Common.Container,
            },
          }}
        >
          <Typography variant="h3">{section.name}</Typography>
        </AccordionSummary>
        <AccordionDetails size="xlarge">
          <Box display="flex" flexDirection="column">
            {blocks.map((block, index) => {
              const questionNumber = `${sectionIndex}.${(section.blocks?.findIndex(sb => sb.id === block.id) || 0) + 1}`
              return (
                <ExternalAssessmentResponseProvider
                  key={questionNumber}
                  questionNumber={questionNumber}
                  block={block}
                  assessment={assessment}
                  startAutoSave={startAutoSave}
                  finishAutoSave={finishAutoSave}
                  refetchAssessments={refetchAssessment}
                >
                  <AssesssmentQuestion showQuestionDetails={showQuestionDetails} isFirst={index === 0} index={index} />
                  {/* Divider between questions */}
                  {index !== blocks.length - 1 && (
                    <Box my={4} id="divider">
                      <Box mx="18px" borderTop={`1px solid ${theme.palette.Common.Divider}`} />
                    </Box>
                  )}
                </ExternalAssessmentResponseProvider>
              )
            })}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}
