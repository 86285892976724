import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'
import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { RoutesManager } from 'utils/routing/routesManager'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { getBannerText } from 'components/ui-layouts/issuesMessage/IssuesMessage'
import { RightsQueueViewDetails } from 'pages/orchestration/rightsQueue/viewV2/components/RightsQueueViewDetails'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { SubjectCommunicationTab } from './components/SubjectCommunicationTab'
import { RightsQueueOverviewTab } from './components/RightsQueueOverviewTab'
import { RightsQueueWorkflowTab } from './components/RightsQueueWorkflowTab'
import { useAppSelector } from 'store/hooks'
import { getNavigationState } from 'store/navigationSlice/selectors'
import { withRightsQueueViewContext } from './context/RightsQueueViewContext'
import { RightsQueueViewContext } from 'pages/orchestration/rightsQueue/viewV2/context/RightsQueueViewContext'
import { RightInvocationAssignWorkflowModal } from './components/RightsQueueWorkflowTab/components/assignWorkflow/RightInvocationAssignWorkflowModal'
import { RightsQueueViewActionsV2 } from './components'
import { Banner, Button, Chip, Spinner, ViewLayout } from '@ketch-com/deck'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { isTwilioConversationsEnabled } from 'utils/helpers/isTwilioConversationsEnabled'
import { SendbirdSubjectCommunicationTab } from './components/SendbirdSubjectCommunicationTab/SendbirdSubjectCommunicationTab'
import { useUserMe } from 'api/users/queries/useUserMe'

// Orgs which we disable subject communication for
// NOTE: This same change must be made in the porthole frontend
// https://github.com/ketch-com/porthole/blob/main/frontend/src/routes/RequestDetail/components/RequestDetailsWithChat.tsx#L16
const DisabledSubjectCommunicationOrgs = ['rover']

const RightsQueueViewV2WithoutContext: React.FC = () => {
  const {
    isReady,
    isRightInvocationCreatedAfterFeatureReleaseDate,
    isKetchFree,
    rightInvocation,
    unreadCount,
    sendbirdUnreadCount,
    users,
    workflowExecution,
    isAssignOpen,
    setAssignOpen,
  } = useContext(RightsQueueViewContext)

  const location = useLocation()
  const { pathname } = location
  const navigate = useNavigate()
  const { isEntitled } = useIsEntitled()
  const { isPermitted } = useIsPermitted()
  const user = useUserMe()
  const orgCode = user.data?.organization.code
  const reduxNavigationState = useAppSelector(getNavigationState)
  const rightName = rightInvocation?.rightName
  const rightCode = rightInvocation?.rightCode
  const hasIssues = rightInvocation?.hasIssues
  const isBasicApplicationUser = isEntitled(ENTITLEMENTS.PROCESSOR_BASIC)
  const canViewSubjectCommunication = isPermitted(PERMISSIONS.SUBJECT_CHAT_READ)
  const isSubjectCommunicationDisabledForOrg = orgCode && DisabledSubjectCommunicationOrgs.includes(orgCode)

  // Show sendbird chat whenever there is not a conversationId
  const renderSendbirdChat = !rightInvocation?.conversationId

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.orchestration.root.getURL() },
    { title: 'Rights Queue', link: RoutesManager.orchestration.rightsQueue.root.getURL() },
    {
      title: rightName || rightCode || rightInvocation?.id,
      link: RoutesManager.orchestration.rightsQueue.view.root.getURL({ id: rightInvocation?.id }),
    },
  ]

  const isSubjectCommunicationTabActive = location.pathname.includes('subject-communication')
  /** facilitates navigating back to the page of results the user was on when they clicked the DSR line item to view the details */
  const backTo = !!reduxNavigationState?.goBack
    ? reduxNavigationState.goBack
    : RoutesManager.orchestration.rightsQueue.root.getURL()

  const shouldRenderSubjectCommunicationTab =
    canViewSubjectCommunication &&
    isRightInvocationCreatedAfterFeatureReleaseDate &&
    isTwilioConversationsEnabled() &&
    !isSubjectCommunicationDisabledForOrg

  const [currentTab, setCurrentTab] = useState(0)

  const tabs = useMemo(
    () => [
      {
        label: 'Overview',
        link: RoutesManager.orchestration.rightsQueue.view.overview.root.getURL({ id: rightInvocation?.id }),
      },
      ...(!isBasicApplicationUser
        ? [
            {
              label: 'Workflow',
              link: RoutesManager.orchestration.rightsQueue.view.workflow.root.getURL({ id: rightInvocation?.id }),
            },
          ]
        : []),
      ...(shouldRenderSubjectCommunicationTab && !isBasicApplicationUser
        ? [
            {
              label: 'Subject Communication',
              link: RoutesManager.orchestration.rightsQueue.view.subjectCommunication.root.getURL({
                id: rightInvocation?.id,
              }),

              icon:
                (renderSendbirdChat && !!sendbirdUnreadCount) || (!renderSendbirdChat && !!unreadCount) ? (
                  <Chip
                    label={renderSendbirdChat ? sendbirdUnreadCount : unreadCount}
                    size="small"
                    disabled={!isSubjectCommunicationTabActive}
                  />
                ) : undefined,
              iconPosition: 'end' as 'start' | 'end' | 'top' | 'bottom' | undefined,
            },
          ]
        : []),
    ],
    [
      isBasicApplicationUser,
      isSubjectCommunicationTabActive,
      rightInvocation?.id,
      shouldRenderSubjectCommunicationTab,
      unreadCount,
      sendbirdUnreadCount,
      renderSendbirdChat,
    ],
  )

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    navigate(tabs[newValue].link)
  }

  useEffect(() => {
    tabs.forEach((tab, index) => {
      if (pathname.includes(tab.link)) {
        setCurrentTab(index)
      }
    })
  }, [tabs, currentTab, pathname])

  if (!isReady || !rightInvocation) {
    return (
      <>
        <NavigationBreadCrumbs type="dark" items={breadcrumbs.filter(b => !!b?.title)} />
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flex={1}>
          <Spinner size="32px" thickness={2.5} />
        </Box>
      </>
    )
  }
  const banners = (
    <>
      {!!hasIssues && !isKetchFree && (
        <Banner
          sx={{
            width: '100%',
          }}
          title={getBannerText(ApplicationEntity.RIGHTS_QUEUE)}
          action={
            <Button
              variant="link"
              onClick={() => {
                navigate(RoutesManager.orchestration.rightsQueue.view.workflow.root.getURL({ id: rightInvocation?.id }))
                setAssignOpen(true)
              }}
            >
              Update
            </Button>
          }
          severity="error"
        />
      )}
    </>
  )

  return (
    <>
      <NavigationBreadCrumbs type="dark" items={breadcrumbs.filter(b => !!b?.title)} />
      <Box display="flex" flexDirection="column" gap="24px" alignItems="center" flex={1}>
        {banners}
        <ViewLayout
          contentGroupProps={{
            title: rightName || rightCode || rightInvocation?.id,
            showBackButton: true,
            propsBackButton: {
              onClick: () => navigate(backTo),
            },
            infoBlockComponent: rightInvocation && (
              <RightsQueueViewDetails rightInvocation={rightInvocation} workflowExecution={workflowExecution} />
            ),
            actionRightBlockComponent: <RightsQueueViewActionsV2 />,
          }}
          tabsComponentProps={{
            tabItems: tabs,
            value: currentTab,
            onChange: handleTabChange,
          }}
        >
          <Routes>
            <Route path="/" element={<RightsQueueOverviewTab />} />

            <Route
              path={RoutesManager.orchestration.rightsQueue.view.overview.root.pattern}
              element={<RightsQueueOverviewTab />}
            />

            {shouldRenderSubjectCommunicationTab && (
              <Route
                path={RoutesManager.orchestration.rightsQueue.view.subjectCommunication.root.pattern}
                element={renderSendbirdChat ? <SendbirdSubjectCommunicationTab /> : <SubjectCommunicationTab />}
              />
            )}

            {!isBasicApplicationUser && (
              <Route
                path={RoutesManager.orchestration.rightsQueue.view.workflow.root.pattern}
                element={<RightsQueueWorkflowTab users={users} rightInvocation={rightInvocation} />}
              />
            )}
          </Routes>
        </ViewLayout>
      </Box>
      {isAssignOpen && (
        <RightInvocationAssignWorkflowModal
          onClose={() => {
            setAssignOpen(false)
          }}
        />
      )}
    </>
  )
}

export const RightsQueueViewV2 = withRightsQueueViewContext(RightsQueueViewV2WithoutContext)
