import React from 'react'
import { useField } from 'formik'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { DeploymentExperienceSelector } from 'pages/consentAndRights/deploymentPlans-v2/upsert/components/deploymentPlanExperiences/DeploymentExperienceSelector'

type Props = {
  experiences: ExperienceV2DTO[]
  name: string
  label?: string
  size?: 'small' | 'medium'
  inFormRow?: boolean
  showLabel?: boolean
  width?: string
  onItemClick?: () => void
  required?: boolean
}

export const FormDeploymentExperienceSelector: React.FC<Props> = ({
  experiences,

  label,
  name,
  size = 'small',
  width,
  showLabel = true,
  onItemClick = () => {},
  required = true,
}) => {
  const [field, , { setValue }] = useField(name)

  return (
    <DeploymentExperienceSelector
      experiences={experiences}
      size={size}
      width={width}
      label={showLabel ? label : undefined}
      onChange={ID => {
        if (ID) setValue(ID)
        onItemClick?.()
      }}
      experienceId={field.value}
      required={required}
    />
  )
}
