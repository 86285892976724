import React, { useMemo } from 'react'

import { PolicyDocumentDTO, PolicyDocumentType } from 'interfaces/policyDocuments/policyDocument'
import { DeploymentPlanFormSpecialValues } from 'pages/consentAndRights/deploymentPlans-v2/upsert/utils'
import { FormOptionActionSheet } from 'components/form/FormOptionActionSheet'
import { Box } from '@mui/material'

type Props = {
  policyDocumentType: PolicyDocumentType
  policyDocuments: PolicyDocumentDTO[]
  editMode: boolean
}

export const DeploymentPlanDocumentAssignment: React.FC<Props> = ({
  policyDocumentType,
  policyDocuments,
  editMode,
}) => {
  const isTermsOfService = policyDocumentType === PolicyDocumentType.TERMS_OF_SERVICE
  const isPrivacyPolicy = policyDocumentType === PolicyDocumentType.PRIVACY_POLICY

  const items = useMemo(() => {
    const filteredPolicyDocs = policyDocuments.filter(
      policyDocument => policyDocument.policyDocumentType === policyDocumentType,
    )
    return [
      ...(isPrivacyPolicy && editMode && filteredPolicyDocs.length === 0
        ? [{ code: DeploymentPlanFormSpecialValues.NO_DOCUMENT, name: DeploymentPlanFormSpecialValues.NO_DOCUMENT }]
        : []),
      ...(isTermsOfService
        ? [{ code: DeploymentPlanFormSpecialValues.NO_DOCUMENT, name: DeploymentPlanFormSpecialValues.NO_DOCUMENT }]
        : []),
      ...filteredPolicyDocs,
    ]
  }, [policyDocumentType, policyDocuments, isTermsOfService, isPrivacyPolicy, editMode])

  const name = isTermsOfService ? 'termsOfServiceCode' : 'privacyPolicyCode'

  return (
    <FormOptionActionSheet
      formPropertyName={name}
      valueKey="code"
      items={items}
      placeholder="Select Document"
      buttonProps={{
        size: 'small',
        color: 'tertiary',
        sx: { maxWidth: '200px' },
      }}
      renderItem={item => (
        <Box
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '130px',
          }}
        >
          {item.name}
        </Box>
      )}
    />
  )
}
