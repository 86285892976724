import { useCallback, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { Button, TextInput, theme } from '@ketch-com/deck'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'

import { FormError } from 'components/form/FormError'
import { useExternalAssessmentResponse } from '../context/ExternalAssessmentResponseContext'
import { AssessmentBlockCompletionStatusDTO } from '@ketch-com/figurehead'

import { FileInfoDTO } from 'interfaces/files/fileInfo'
import { AssessmentExternalQuestionInput } from './AssessmentExternalQuestionInput'
import { ExternalCompletedQuestionActions } from './ExternalCompletedQuestionActions'
import { AttachmentsList } from 'components/attachments/AttachmentsList'
import { AttachmentsUpload } from 'components/AttachmentsUpload/AttachmentsUpload'
import { MAX_FILE_UPLOAD_SIZE_4_MB } from 'utils/constants/maxFileUploadSize'
import { acceptableFileTypes } from 'pages/policyCenter/assessments/edit/constants'

export interface AssesssmentQuestionProps {
  showQuestionDetails?: boolean
  isFirst: boolean
  index: number
}

// TODO: uncomment attachments related code when endpoints are ready

export const AssesssmentQuestion: React.FC<AssesssmentQuestionProps> = ({ showQuestionDetails = false, isFirst }) => {
  const {
    assessment,
    attachmentsCount,
    block,
    blockAttachments,
    choices,
    completeAnswer,
    isTextQuestion,
    isCompleted,
    isCompletedButStillWorking,
    isRejected,
    questionNumber,
    save,
    setAttachmentsCount,
    setIsValid,
    setHasTouchedInput,
    refetchAssessments,
    value,
  } = useExternalAssessmentResponse()

  const requireAdditionalDetails = !!block.question?.attributes?.requireAdditionalDetails
  const isRequired = !!block.question?.attributes?.isRequired
  const showAdditionalDetails = !!block.question?.attributes?.requireAdditionalDetails
  const allowAttachments = !!block.question?.attributes?.allowAttachments
  const requireAttachments = !!block.question?.attributes?.requireAttachments

  const [additionalDetails, setAdditionalDetails] = useState(block.answer?.attributes?.text || '')

  const [isAdditionalDetailsValid, setIsAdditionalDetailsValid] = useState(true)
  const [isAttachmentsValid, setIsAttachmentsValid] = useState(true)

  const complete = useCallback(async () => {
    let failedAnyValidations = false
    if (block.question?.attributes?.isRequired && (isTextQuestion ? value.length === 0 : choices.length === 0)) {
      setIsValid(false)
      failedAnyValidations = true
    }

    if (block.question?.attributes?.requireAttachments && attachmentsCount < 1) {
      setIsAttachmentsValid(false)
      failedAnyValidations = true
    }

    if (block.question?.attributes?.requireAdditionalDetails && additionalDetails?.trim()?.length < 1) {
      setIsAdditionalDetailsValid(false)
      failedAnyValidations = true
    }
    if (failedAnyValidations) return

    setIsAttachmentsValid(true)
    setIsValid(true)
    setIsAdditionalDetailsValid(true)

    await completeAnswer({
      params: {
        assessmentId: assessment.id || '',
        blockId: block.id || '',
        completionStatus: AssessmentBlockCompletionStatusDTO.CompleteAssessmentBlockCompletionStatus,
      },
    })
    refetchAssessments()
  }, [
    block.question?.attributes?.isRequired,
    block.question?.attributes?.requireAttachments,
    block.question?.attributes?.requireAdditionalDetails,
    block.id,
    isTextQuestion,
    value.length,
    choices.length,
    attachmentsCount,
    additionalDetails,
    setIsValid,
    completeAnswer,
    assessment.id,
    refetchAssessments,
  ])

  const changeAdditionalDetails = useCallback(
    (text: string) => {
      setHasTouchedInput(true)
      setIsAdditionalDetailsValid(true)
      setAdditionalDetails(text)
      save({ choiceIDs: choices, text })
    },
    [choices, save, setHasTouchedInput],
  )

  const changeAttachments = useCallback(
    (uploads: FileInfoDTO[]) => {
      setIsAttachmentsValid(true)
      setAttachmentsCount(attachmentsCount + uploads?.length || 1)
    },
    [attachmentsCount, setAttachmentsCount],
  )

  const removeAttachments = useCallback(() => {
    setIsAttachmentsValid(true)
    setAttachmentsCount(attachmentsCount - 1)
  }, [attachmentsCount, setAttachmentsCount])

  const renderAdditionalDetails = () => {
    let questionDetails
    if (showQuestionDetails) {
      questionDetails = (
        <Typography>
          &nbsp;
          {requireAdditionalDetails ? 'Required' : 'Optional'}
        </Typography>
      )
    } else if (isCompleted) {
      questionDetails = (
        <Box>
          <Typography>{block.answer?.attributes?.text || <EmptyValueRenderer />}</Typography>
        </Box>
      )
    } else
      questionDetails = (
        <Box>
          <TextInput
            value={additionalDetails}
            onChange={evt => changeAdditionalDetails(evt.target.value)}
            type="text"
            fullWidth
            error={!isAdditionalDetailsValid}
          />
          {!isAdditionalDetailsValid ? <FormError msg="Required" /> : null}
        </Box>
      )
    return (
      <Box mt={3}>
        <Typography variant="label">
          {isCompleted
            ? 'Additional Details'
            : `Provide Additional Details${requireAdditionalDetails ? ' (Required)' : ''}`}
        </Typography>
        {questionDetails}
      </Box>
    )
  }

  return (
    <>
      <Box
        my={3}
        px={2}
        mt={!isFirst ? 3 : 0}
        sx={{
          borderLeft: isCompletedButStillWorking
            ? `2px solid ${theme.palette.Success.PrimaryDark}`
            : isRejected
            ? `2px solid ${theme.palette.Warning.Primary}`
            : '',
        }}
      >
        <Box alignItems="center" display="flex" justifyContent="space-between" mb={1.5}>
          <Box display="flex" alignItems="center" gap={0.5}>
            <Typography variant="label" color={theme.palette.Text.Secondary}>
              Question {questionNumber}
            </Typography>
          </Box>
          {isCompleted ? (
            <ExternalCompletedQuestionActions />
          ) : (
            <Box display="flex" alignItems="center">
              <Button color="secondary" onClick={complete}>
                Complete
              </Button>
            </Box>
          )}
        </Box>
        <Typography component="div" variant="h3" overflow="hidden">
          {block.question?.attributes?.question || ''}
        </Typography>
        <Typography component="div" variant="body" mt={1.5} mb={2} overflow="hidden">
          {block.question?.attributes?.description || ''}
        </Typography>
        <Box>
          <Typography variant="label">Answer</Typography>
          {showQuestionDetails ? (
            <Typography variant="body">
              &nbsp;
              {isRequired ? 'Required' : 'Optional'}
            </Typography>
          ) : (
            <Box>
              <AssessmentExternalQuestionInput />
            </Box>
          )}
          {showAdditionalDetails && renderAdditionalDetails()}
          {/* disabled until attachments endpoint is updated */}
          {allowAttachments && isCompleted && (
            <Box mt={3}>
              <Typography variant="label">Attachments</Typography>
              {showQuestionDetails ? (
                <Typography>
                  &nbsp;
                  {requireAttachments ? 'Required' : 'Optional'}
                </Typography>
              ) : (
                <AttachmentsList attachments={blockAttachments} />
              )}
            </Box>
          )}
          {showQuestionDetails && choices?.length > 0 && (
            <Box mt={3}>
              <Typography variant="label">Answer Options</Typography>
              <Typography>{choices.join(', ')}</Typography>
            </Box>
          )}
          {/* disabled until attachments endpoint is completed */}
          {allowAttachments && !isCompleted && !isRejected && (
            <Box mt={2.5} gap={1} sx={{ cursor: 'pointer' }}>
              <Box
                display="inline-flex"
                alignItems="center"
                py={1}
                pl={!isAttachmentsValid ? 1 : 0}
                sx={{
                  border: !isAttachmentsValid ? `1px solid ${theme.palette.Warning.Primary}` : undefined,
                  borderRadius: '5px',
                }}
              >
                <AttachmentsUpload
                  onUploadComplete={changeAttachments}
                  onDelete={removeAttachments}
                  attachments={blockAttachments}
                  canEdit={true}
                  uploadContext={{
                    version: 'assessments_answers',
                    folder: `${block.id}`,
                    bucket: '',
                    isExternal: true,
                  }}
                  accept={acceptableFileTypes}
                  maxSize={MAX_FILE_UPLOAD_SIZE_4_MB}
                />
              </Box>
              {!isAttachmentsValid ? (
                <Box>
                  <FormError msg="Required" />
                </Box>
              ) : null}
            </Box>
          )}
        </Box>
      </Box>
      {/* TODO: Unsure if Needed, Requires Review, delete endpoint not migrated */}
      {/* {isDeleteOpen && (
        <RemoveQuestionModal
          assessmentId={assessment.id || ''}
          blockId={block.id || ''}
          questionName={block.question?.attributes?.name || ''}
          onSubmit={() => {
            setIsDeleteOpen(false)
          }}
          onCancel={() => setIsDeleteOpen(false)}
        />
      )} */}
    </>
  )
}
