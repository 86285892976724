import { Formik } from 'formik'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { RoutesManager } from 'utils/routing/routesManager'
import {
  AssetSummaryDTO,
  GetAssetHierarchiesResponseBodyDTO,
  GetScenarioResponseBodyDTO,
  PutAssetRequestBodyDTO,
} from '@ketch-com/figurehead'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { RelatedPathsAndAssets } from 'pages/assetManager/dsrConfig/interfaces'
import { getAssetType } from 'pages/assetManager/utils'
import { Button, theme } from '@ketch-com/deck'
import { ContentBoundaries } from 'components/ui-layouts/contentBoundaries/ContentBoundaries'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'
import {
  getDsrIdentityLookupFormValidation,
  getDsrIdentityLookupInitialValues,
} from 'pages/assetManager/dsrIdentityLookup/utils'
import { useUpdateAssetV2 } from 'api/assets/mutations/useUpdateAssetV2'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { DsrConfigParams } from 'utils/routing/routesV2/systems'
import { AssetInfoNav } from 'pages/dataSystems/AssetMigration/AssetInfoNav'
import { LookUpTab } from 'pages/dataSystems/AssetMigration/ScenarioDetails/CustomSQLTypes'
import { PrimaryDataset } from 'pages/dataSystems/AssetMigration/ScenarioDetails/PrimaryDataset'
import { RelatedDatasets } from 'pages/dataSystems/AssetMigration/ScenarioDetails/RelatedDatasets'

type Props = {
  assetsHierarchies: GetAssetHierarchiesResponseBodyDTO[]
  relatedPathsAndAssets: RelatedPathsAndAssets
  identitySpaces: IdentitySpaceDTO[]
  scenarioData: GetScenarioResponseBodyDTO
  assetsSummary: AssetSummaryDTO[]
}

export const DSRIdentityLookup: React.FC<Props> = ({
  assetsHierarchies,
  scenarioData,
  relatedPathsAndAssets,
  identitySpaces,
  assetsSummary,
}) => {
  const { scenarioId, id: primaryDatasetId, formMode } = useParams<DsrConfigParams>()
  const navigate = useNavigate()
  const providerCode = assetsSummary?.[0]?.asset?.connection?.providerCode || ''
  const datasetType = getAssetType(providerCode, 2)
  const assetHierarchies = assetsHierarchies[0] // assetsHierarchies[0] is primary dataset
  const assetHierarchiesLength = assetHierarchies?.hierarchySummaries?.length || 0

  // assetsSummary[0] is primary asset summary
  const { name: databaseName = '', id: databaseId = '' } =
    assetsSummary[0]?.asset?.resource?.hierarchies?.CANONICAL_RESOURCE_TYPE_DATABASE || {}
  const { name: datasetName = '', id: datasetId = '' } = assetsSummary[0]?.asset?.resource || {}
  const technology = assetsSummary?.[0]?.asset?.connection?.technology || ''

  const dbUrl = RoutesManager.systems.dsrInvocation.scenarioDetails.getURL({
    id: databaseId!,
    resourceTypeCode: assetsSummary[0]?.asset?.resource?.resourceType?.code || '',
    scenarioId: scenarioId!,
    tab: LookUpTab.NO_DIRECT,
  })

  const configureDsrForIdentityUrl = RoutesManager.systems.dsrInvocation.dsrConfig.getURL({
    scenarioId: scenarioId!,
    id: datasetId,
    formMode: formMode!,
  })

  const { mutate: allocateIdentity } = useUpdateAssetV2({
    onError: () => {
      showToast({ content: 'Failed to allocate Identifier', type: 'error' })
    },
    onSuccess: () => {
      navigate(configureDsrForIdentityUrl)
    },
  })

  return (
    <>
      <Box width={1280} margin="0 auto" mb={2.5} pb={2.5} borderBottom={`1px solid ${theme.palette.iron.main}`}>
        <AssetInfoNav
          database={{ name: databaseName, id: databaseId, url: dbUrl }}
          technology={technology}
          dataset={{
            name: datasetName,
            id: datasetId,
            assetsSummary: assetsSummary,
            attributeCount: assetHierarchiesLength,
          }}
        />
      </Box>
      <ContentBoundaries>
        <Box
          sx={{
            backgroundColor: theme.palette.Common.Container,
            borderRadius: ({ spacing }) => spacing(1.5),
            padding: ({ spacing }) => spacing(4),
          }}
        >
          <Typography variant="h3">User Lookup</Typography>
          <Box
            sx={{
              backgroundColor: theme.palette.bleachWhite.main,
              borderRadius: ({ palette, spacing }) => spacing(1.5),
              padding: ({ palette, spacing }) => spacing(1, 3),
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: ({ palette, spacing }) => spacing(2.5),
              margin: ({ palette, spacing }) => spacing(4, 0),
            }}
          >
            <InfoIcon />
            <Box display="flex" flexDirection="column" gap={0.5}>
              <Typography fontSize={14} fontWeight={600} color={theme.palette.chileanFire.main}>
                Can't Lookup an Identity.
              </Typography>
              <Typography fontSize={14} fontWeight={400} color={theme.palette.chileanFire.main}>
                There's no way to identify the user in this {datasetType.toLowerCase()} because no identity is assigned.
              </Typography>
            </Box>
          </Box>
          <Formik
            initialValues={getDsrIdentityLookupInitialValues({ datasetId: primaryDatasetId! })}
            validationSchema={getDsrIdentityLookupFormValidation()}
            onSubmit={values => {
              const { primaryAttributeId, primaryAttributeIdentitySpaceCode } = values
              const matchedIdentitySpace = identitySpaces?.find(
                identitySpace => primaryAttributeIdentitySpaceCode === identitySpace.code,
              )
              const formData = {
                assetSummary: {
                  asset: { identitySpace: { code: matchedIdentitySpace?.code, name: matchedIdentitySpace?.name } },
                },
              } as PutAssetRequestBodyDTO

              const attributeResourceTypeCode =
                assetsHierarchies?.[0]?.hierarchySummaries?.filter(
                  ({ asset }) => asset?.resource?.id === primaryAttributeId,
                )?.[0]?.asset?.resource?.resourceType?.code || ''
              allocateIdentity({
                params: {
                  assetCode: primaryAttributeId || '',
                  resourceTypeCode: attributeResourceTypeCode,
                  formData,
                },
              })
            }}
          >
            {({ isSubmitting, submitForm, values }) => {
              const { isPrimaryDataset, relatedDatasetHasIdentitySpace, datasetId } = values
              return (
                <>
                  <PrimaryDataset
                    assetsHierarchies={assetsHierarchies}
                    identitySpaces={identitySpaces}
                    assetsSummary={assetsSummary}
                  />

                  <RelatedDatasets
                    relatedPathsAndAssets={relatedPathsAndAssets}
                    assetsSummary={assetsSummary}
                    assetsHierarchies={assetsHierarchies}
                    identitySpaces={identitySpaces}
                  />

                  <Box display="flex" gap={2}>
                    <Link to={dbUrl}>
                      <Button color="secondary" size="large" pending={isSubmitting}>
                        Cancel
                      </Button>
                    </Link>
                    <Button
                      size="large"
                      pending={isSubmitting}
                      onClick={() => {
                        if (!isPrimaryDataset) {
                          if (relatedDatasetHasIdentitySpace) navigate(configureDsrForIdentityUrl)
                          if (!relatedDatasetHasIdentitySpace)
                            navigate(
                              RoutesManager.systems.dsrInvocation.configureIdentity.getURL({
                                scenarioId: scenarioId!,
                                id: datasetId,
                                formMode: formMode!,
                              }),
                            )
                        } else {
                          submitForm()
                        }
                      }}
                    >
                      Continue
                    </Button>
                  </Box>
                </>
              )
            }}
          </Formik>
        </Box>
      </ContentBoundaries>
    </>
  )
}
