import { AssessmentAttributesSectionsItemDTO } from '@ketch-com/figurehead'
import { Box, Typography } from '@mui/material'
import { isSectionComplete } from '../constants'
import { Icon, ListItem, theme } from '@ketch-com/deck'
import { AssessmentSection } from '@ketch-com/windlass/dist/assessments/externalUser'

export const AssessmentEditPagerSection: React.FC<{
  index: number
  openSection: () => void
  section: AssessmentAttributesSectionsItemDTO | AssessmentSection
}> = ({ index, openSection, section }) => {
  const completed = isSectionComplete(section)

  return (
    <ListItem
      title={section.name || `Section ${index + 1}`}
      sx={{
        '& .MuiListItemText-root': {
          '& .MuiTypography-root': {
            color: completed ? 'greenHaze.main' : 'darkDusk.main',
          },
        },
      }}
      startIcon={
        <Box
          className="pager-index"
          borderRadius={100}
          bgcolor={completed ? 'greenHaze.main' : 'darkDusk.main'}
          color="white.main"
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={24}
          width={24}
          mr={1}
        >
          <Typography component="div" variant="label">
            {` ${index + 1} `}
          </Typography>
        </Box>
      }
      endElement={
        <Box display="flex" alignItems="center" gap={1.5} justifyContent="space-between">
          {completed && <Icon name="OCheck" iconColor={theme.palette.greenHaze.main} />}
          <Icon name="OArrowCRight" iconColor={theme.palette.fadedDarkGrey.main} />
        </Box>
      }
      onClick={openSection}
    />
  )
}
