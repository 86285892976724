import Box from '@mui/material/Box'
import React, { useContext, useState } from 'react'
import { RightsQueueViewContext } from '../../../context/RightsQueueViewContext'
import { Chip, Icon, theme, Banner, Button, IconDictionary } from '@ketch-com/deck'
import pluralize from 'pluralize'
import { DsrMetadataChip } from '../../DsrMetadataChip'
import { Typography } from '@mui/material'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { RightInvocationTypeDTO } from '@ketch-com/figurehead'
import { RightDuplicatesModal } from 'pages/orchestration/rightsQueue/components/RightDuplicatesModal'
import { isRelease } from 'utils/releases'
import { Release } from 'utils/releases/RELEASES'

type Props = {
  mode?: 'overview' | 'workflow' | 'appeal'
}

export const MetadataCardList: React.FC<Props> = ({ mode = 'overview' }) => {
  const shouldDisplayRightDuplicates = isRelease(Release.RightDuplicates)
  const [shouldDisplayRightDuplicatesModal, setShouldDisplayRightDuplicatesModal] = useState(false)
  const { rightInvocation, isAppealed, original, appeal, workflowExecution, appealWorkflowExecution } =
    useContext(RightsQueueViewContext)

  // Overview tab should show appeal details
  // Workflow tab should show main workflow
  // Appeals table tab should show appeal details
  const shouldUseAppealWorkflow = isAppealed && mode !== 'workflow'

  const invokedAt = shouldUseAppealWorkflow ? appeal.invokedAt : original.invokedAt
  const completedAt = shouldUseAppealWorkflow ? appealWorkflowExecution?.completedAt : workflowExecution?.completedAt
  const dueAt = shouldUseAppealWorkflow ? appeal.dueAt : original.dueAt
  const daysRemaining = shouldUseAppealWorkflow ? appeal.daysRemaining : original.daysRemaining
  const fulfillmentDays = shouldUseAppealWorkflow
    ? appeal.daysAllowedToFulfillAppeal
    : original.daysAllowedToFulfillRequest
  const workflowDefinitionName = shouldUseAppealWorkflow
    ? rightInvocation?.appealWorkflowExecution?.workflowDefinitionName
    : rightInvocation?.workflowExecution?.workflowDefinitionName

  return (
    <Box>
      {shouldDisplayRightDuplicates &&
      rightInvocation?.type === RightInvocationTypeDTO.RightTypeDuplicateRightInvocationType ? (
        <Box mb={4}>
          <Banner
            severity="info"
            icon={<Icon name={IconDictionary.FCopy} />}
            title="Duplicate Request"
            children="This request has been invoked more than once using the same email address within the past 365 days."
            onClick={e => {
              e.stopPropagation()
            }}
            action={
              <Button variant="link" onClick={() => setShouldDisplayRightDuplicatesModal(true)}>
                View
              </Button>
            }
          />
        </Box>
      ) : null}
      {shouldDisplayRightDuplicatesModal && (
        <RightDuplicatesModal
          rightInvocation={rightInvocation}
          onClose={() => setShouldDisplayRightDuplicatesModal(false)}
        />
      )}
      <Box display="flex" alignItems="stretch" gap={2} mb={2} justifyContent="space-between" maxWidth="100%">
        <DsrMetadataChip
          title="Invoke Date"
          subtitle={
            <Box display="flex" alignItems="center">
              <Typography variant="label">
                <FormattedDateRenderer date={invokedAt} showTimezone />
              </Typography>
            </Box>
          }
          icon={<Icon height={28} width={28} iconColor={theme.palette.Text.Secondary} name="OInbox" />}
        />
        <DsrMetadataChip
          title="Due Date"
          subtitle={
            <Box display="flex" flexDirection="column" alignItems="flex-start" gap={1}>
              <Typography variant="label">
                <FormattedDateRenderer date={dueAt} showTimezone />{' '}
              </Typography>
              {rightInvocation?.isExtended && <Chip label="Extended" size="small" />}
            </Box>
          }
          icon={<Icon height={28} width={28} iconColor={theme.palette.Text.Secondary} name="OCalendar" />}
        />
        {mode === 'overview' ? (
          <DsrMetadataChip
            title="Jurisdiction"
            subtitle={
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  wordBreak: 'break-all',
                }}
              >
                <Typography variant="label">{rightInvocation?.policyScopeName}</Typography>
              </Box>
            }
            icon={<Icon height={28} width={28} iconColor={theme.palette.Text.Secondary} name="OGlobe" />}
          />
        ) : (
          <DsrMetadataChip
            title="Data Subject"
            subtitle={
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  wordBreak: 'break-all',
                }}
              >
                <Typography variant="label">
                  {`${rightInvocation?.user?.first} ${rightInvocation?.user?.last}`}
                </Typography>
              </Box>
            }
            icon={<Icon height={28} width={28} iconColor={theme.palette.Text.Secondary} name="OGlobe" />}
          />
        )}
        {mode === 'overview' ? (
          <DsrMetadataChip
            title="Fulfillment Time"
            subtitle={
              <Box display="flex" alignItems="center">
                <Typography variant="label">
                  {fulfillmentDays} {pluralize('Day', fulfillmentDays)}
                </Typography>
              </Box>
            }
            icon={<Icon height={28} width={28} iconColor={theme.palette.Text.Secondary} name="OClock" />}
          />
        ) : (
          <DsrMetadataChip
            title="Request"
            subtitle={
              <Box display="flex" flexDirection="column" gap={0.25}>
                <Typography variant="label">{rightInvocation?.rightName || 'Right Invocation'}</Typography>
                <Typography variant="footnote" mb={1}>
                  {rightInvocation?.id}
                </Typography>
              </Box>
            }
            icon={<Icon height={28} width={28} iconColor={theme.palette.Text.Secondary} name="OWorkflow" />}
          />
        )}
        {mode === 'overview' || !rightInvocation?.requestStatusText ? (
          <DsrMetadataChip
            withDivider={false}
            title="Workflow Used"
            subtitle={
              <Box display="flex" alignItems="center" maxWidth={250}>
                <Typography variant="label" sx={{ overflow: 'auto', wordWrap: 'break-word' }}>
                  {workflowDefinitionName || <EmptyValueRenderer />}
                </Typography>
              </Box>
            }
            icon={<Icon height={28} width={28} iconColor={theme.palette.Text.Secondary} name="OWorkflow" />}
          />
        ) : (
          <DsrMetadataChip
            withDivider={false}
            title={completedAt ? 'Completed' : 'Days Remaining'}
            subtitle={
              <Box display="flex" flexDirection="column" gap={0.25}>
                <Typography variant="label">
                  {completedAt ? (
                    <FormattedDateRenderer date={completedAt || daysRemaining} showTimezone />
                  ) : daysRemaining >= 0 ? (
                    <>
                      {daysRemaining} {pluralize('day', daysRemaining)}
                    </>
                  ) : (
                    <>
                      Overdue by {-daysRemaining} {pluralize('day', daysRemaining)}
                    </>
                  )}
                </Typography>
              </Box>
            }
            icon={<Icon height={28} width={28} iconColor={theme.palette.Text.Secondary} name="OClock" />}
          />
        )}
      </Box>
    </Box>
  )
}
