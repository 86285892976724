import * as Yup from 'yup'

import { DataRoleType } from 'interfaces/events'
import { DataSubjectTypeDTO, FormFieldTypeDTO } from '@ketch-com/figurehead'

export const getRightInvocationValidationSchema = (dataSubjectTypes: DataSubjectTypeDTO[]) =>
  Yup.object().shape({
    identities: Yup.array()
      .of(
        Yup.object().shape({
          code: Yup.string().required('Required'),
          value: Yup.string().required('Required'),
        }),
      )
      .required('Required'),
    policyScopeCode: Yup.string().required('Required'),
    rightCode: Yup.string().required('Required'),
    email: Yup.string().email().required('Required'),
    first: Yup.string().required('Required'),
    last: Yup.string().required('Required'),
    dataRole: Yup.number().required('Required'),
    dataSubjectTypeCode: Yup.string().test('Is required', 'Required', (value, ctx) =>
      ctx?.parent?.dataRole === DataRoleType.DATA_ROLE_CONTROLLER ? !!value : true,
    ),
    dataSubjectTypeRelationshipDetails: Yup.string().test('Is required', 'Required', (value, ctx) => {
      const dataSubjectType = dataSubjectTypes.find(dst => dst.code === ctx?.parent?.dataSubjectTypeCode)
      return dataSubjectType && dataSubjectType.requiresUserInput ? !!value : true
    }),
    formData: Yup.array().of(
      Yup.object().shape({
        /* for all field types except checkbox and file */
        value: Yup.string().test('Is required', 'Required', (value, ctx) => {
          const { type } = ctx?.parent

          if (type === 'checkbox' || type === FormFieldTypeDTO.FileFormFieldType) {
            return true
          } else {
            return !!value
          }
        }),
        /* only for checkbox field type */
        values: Yup.array().test('Is required', 'At least one selection required', (value, ctx) => {
          const { type } = ctx?.parent

          if (type === 'checkbox') {
            return (value as string[])?.length > 0
          } else {
            return true
          }
        }),
        /* only for file field type */
        file: Yup.mixed().test({
          name: 'file',
          test: (_, { createError, parent }) => {
            const { type, file, path } = parent
            if (type === FormFieldTypeDTO.FileFormFieldType) {
              const selectedFile: File | null = file
              if (!selectedFile)
                return createError({
                  path,
                  message: 'Required',
                })
              if (selectedFile.size > 4e6)
                return createError({
                  path,
                  message: 'Maximum file size: 4 MB',
                })
              return true
            } else {
              return true
            }
          },
        }),
      }),
    ),
  })
