import React, { HTMLAttributes, useCallback, useMemo, useRef } from 'react'
import { ActionSheetItem, DropListButton, ListItemText } from '@ketch-com/deck'
import { Box, Popper, Typography, styled, autocompleteClasses, inputBaseClasses, textFieldClasses } from '@mui/material'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { ExperienceType } from 'interfaces/experiences-v2/experienceType'
import { noExperienceOption } from 'pages/consentAndRights/deploymentPlans-v2/upsert/utils'

type Props = {
  experiences: ExperienceV2DTO[]
  label?: string
  inFormRow?: boolean
  experienceId?: string
  onChange?: (newAssigneeId?: string) => void
  disabled?: boolean
  size?: 'small' | 'medium'
  width?: string
  required?: boolean
}

interface CustomPopperProps {
  width?: string
}

const CustomPopper = styled(Popper)<CustomPopperProps>(({ theme, width }) => ({
  boxShadow: '0px 10px 20px 0px rgba(0, 0, 0, 0.20), 0px 1px 5px 0px rgba(0, 0, 0, 0.05)',
  borderRadius: 5,
  backgroundColor: theme.palette.white.main,
  width: '100%',

  '& .MuiTypography-root': {
    overflowWrap: 'anywhere',
  },

  [`& .${autocompleteClasses.root}`]: {
    [`& .${textFieldClasses.root}`]: {
      padding: '8px 8px 0 8px',
      [`& .${inputBaseClasses.root}`]: {
        backgroundColor: theme.palette.white.main,
      },
    },
  },

  '& .MuiAutocomplete-noOptions': {
    padding: '8px 16px',
    color: theme.palette.text.secondary,
  },
}))

// Option rows can be either headers or experiences
export type ListItem = {
  id: string
  name: string
  type?: ExperienceType
  header?: boolean // Indicate that it is an unclickable header row
}

/**
 * This component is a custom version of our normal FormDropListButton that uses an option
 * list with two separate sections for modal vs. preference experiences.
 */
export const DeploymentExperienceSelector: React.FC<Props> = ({
  experiences,
  label,
  experienceId,
  width,
  onChange = () => {},
  disabled = false,
  size = 'small',
  required = true,
}) => {
  const ref = useRef()

  const options = useMemo(() => {
    const consentAndDisclosureOptions: ListItem[] = [{ id: 'c&d-header', name: 'Modal Experiences', header: true }]
    const preferenceOptions: ListItem[] = [{ id: 'preferences-header', name: 'Preference Experiences', header: true }]

    for (const experience of experiences) {
      if (!experience.id || !experience.name || !experience.type) {
        continue
      }

      const item = { id: experience.id, name: experience.name, type: experience.type as ExperienceType }

      if (experience.type === ExperienceType.Consent) {
        consentAndDisclosureOptions.push(item)
      } else if (experience.type === ExperienceType.Preference) {
        preferenceOptions.push(item)
      }
    }

    return [
      noExperienceOption,
      ...(consentAndDisclosureOptions.length > 1 ? consentAndDisclosureOptions : []),
      ...(preferenceOptions.length > 1 ? preferenceOptions : []),
    ]
  }, [experiences])

  const selectedValue = useMemo(
    () => options.find(experience => experience.id === experienceId),
    [options, experienceId],
  )

  const renderOption = useCallback(
    (props: HTMLAttributes<HTMLLIElement>, opt: unknown, _: any, __?: boolean) => {
      const option = opt as ListItem

      const isSelected = option.id === selectedValue?.id

      if (option.id === noExperienceOption.id) return null

      // Allow for non-clickable section headers in the list
      if (option.header) {
        return (
          <ActionSheetItem key={option.id} subSectionTitle>
            {option.name}
          </ActionSheetItem>
        )
      }

      return (
        <ActionSheetItem {...props} key={option.id} selected={isSelected}>
          <ListItemText selected={isSelected}>
            <Typography variant={isSelected ? 'label' : 'body'}>{option.name}</Typography>
          </ListItemText>
        </ActionSheetItem>
      )
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [label],
  )

  const labelText: React.ReactNode = !required ? (
    <>
      {label} <Typography color="Text.Secondary">(Optional)</Typography>
    </>
  ) : (
    <>{label}</>
  )

  const customFilter = (options: ListItem[], { inputValue }: { inputValue: string }) => {
    const filteredConsentOptions: ListItem[] = [{ id: 'c&d-header', name: 'Modal Experiences', header: true }]
    const filteredPreferenceOptions: ListItem[] = [
      { id: 'preferences-header', name: 'Preference Experiences', header: true },
    ]

    for (const option of options) {
      if (!option.type || option.header) {
        continue
      }

      if (option.name.toLowerCase().includes(inputValue.toLowerCase())) {
        if (option.type === ExperienceType.Consent) {
          filteredConsentOptions.push(option)
        } else if (option.type === ExperienceType.Preference) {
          filteredPreferenceOptions.push(option)
        }
      }
    }

    const hasConsentOptions = filteredConsentOptions.length > 1
    const hasPreferenceOptions = filteredPreferenceOptions.length > 1

    if (!hasConsentOptions && !hasPreferenceOptions && inputValue) {
      return [] // This will trigger the "No options" message
    }

    return [
      noExperienceOption,
      ...(hasConsentOptions ? filteredConsentOptions : []),
      ...(hasPreferenceOptions ? filteredPreferenceOptions : []),
    ]
  }

  return (
    <Box ref={ref}>
      <DropListButton
        disabled={disabled}
        size={size}
        onChange={(_, value: ListItem | null, reason) => {
          if (reason === 'clear') {
            onChange(noExperienceOption.id)
          } else {
            value && onChange(value.id)
          }
        }}
        fullWidth
        label={labelText}
        filterOptions={customFilter}
        options={options}
        placeholder={`Select ${label || 'Experience'}`}
        getOptionLabel={(opt: ListItem) => opt.name || opt.id || ''}
        renderOption={renderOption}
        width={width}
        PopperComponent={CustomPopper}
        value={selectedValue || (null as any)}
        componentsProps={{
          popper: {
            placement: 'bottom-start',
            sx: { width },
          },
        }}
        noOptionsText="No experiences found"
      />
    </Box>
  )
}
