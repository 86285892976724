import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { groupBy, mapValues } from 'lodash'
import { ObjectLiteral } from 'interfaces'
import { GridColDef } from '@mui/x-data-grid'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { ExperienceDefaultDisplay } from 'interfaces/experiences-v2/experienceDefaultDisplay'
import { DeploymentPlanDTO, PolicyScopeExperienceDTO } from 'interfaces/deploymentPlans-v2/deploymentPlan'
import {
  ExperienceType,
  LegacyExperienceType,
  LegacyExperienceTypeMapping,
} from 'interfaces/experiences-v2/experienceType'
import { EXPERIENCE_DEFAULT_DISPLAY_NAMES } from 'interfaces/experiences-v2/experienceDefaultDisplay'
import { RoutesManager } from 'utils/routing/routesManager'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { isRelease } from 'utils/releases'
import { Release } from 'utils/releases/RELEASES'
import { DeploymentPlanFormSpecialValues } from 'pages/consentAndRights/deploymentPlans-v2/upsert/utils'
import { usePolicyScopes } from 'api/policyScopes/queries/usePolicyScopes'
import { useExperiences } from 'api/experiences-v2/queries/useExperiences'
import { Button, Chip, ContentGroup, DataGrid, Icon, theme } from '@ketch-com/deck'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'
import { SectionTitle } from 'components/ui-kit/typography/compositions/SectionTitle'
import { FormGroup } from 'components/ui-layouts/formLayout/FormGroup'
import { Banner } from '@ketch-com/deck'
import { Box, AlertColor, Typography, Tooltip, Link } from '@mui/material'
import { useThemes } from 'api/themes-v3/queries/useThemes'
import { PolicyScopeDTO } from 'interfaces/policyScopes-v2/policyScope'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'

type Props = {
  deploymentPlan: DeploymentPlanDTO
}

export const DeploymentPlanOverview: React.FC<Props> = ({ deploymentPlan }) => {
  const navigate = useNavigate()
  const { isEntitled } = useIsEntitled()

  const { data: policyScopes, isLoading: isPolicyScopesLoading } = usePolicyScopes()
  const { data: experiences, isLoading: isExperiencesLoading } = useExperiences()
  const { data: themes, isLoading: isThemesLoading } = useThemes()

  const planTheme = useMemo(() => themes.find(({ id }) => id === deploymentPlan.themeID), [themes, deploymentPlan])

  const isReady = !isPolicyScopesLoading && !isExperiencesLoading && !isThemesLoading
  const isEntitledToContentAndDisclosure = isEntitled(ENTITLEMENTS.EXP_SERVER_CONSENT_AND_DISCLOSURE)

  const existingMappings = useMemo(
    () =>
      mapValues(groupBy(deploymentPlan?.policyScopeExperiences, 'policyScopeCode'), policyScopeExperiences =>
        policyScopeExperiences.reduce<ObjectLiteral<PolicyScopeExperienceDTO>>(
          (acc, policyScopeExperience) => ({
            ...acc,
            [policyScopeExperience.experienceType]: policyScopeExperience,
          }),
          {},
        ),
      ),
    [deploymentPlan],
  )

  const experiencesMap = useMemo(
    () =>
      experiences.reduce<ObjectLiteral<ExperienceV2DTO>>(
        (acc, experience) => ({
          ...acc,
          [experience.id!]: experience,
        }),
        {},
      ),
    [experiences],
  )

  const ExperienceRenderer = useCallback(
    function ExperienceRenderer({
      policyScopeCode,
      experienceType,
    }: {
      policyScopeCode: string
      experienceType: ExperienceType
    }) {
      const existingMapping = existingMappings[policyScopeCode]?.[LegacyExperienceTypeMapping[experienceType]]

      if (existingMapping) {
        const experience = experiencesMap[existingMapping?.experienceID as string]

        return experience ? (
          <Box display="flex" alignItems="center" justifyContent="center" gap={0.5}>
            <Typography>{experience.name}</Typography>
            <Link href={RoutesManager.deployment.experiencesV2.view.root.getURL({ id: experience.id })} height="14px">
              <Icon name="OInfo" width={14} height={14} />
            </Link>
          </Box>
        ) : (
          <Typography variant="label">{DeploymentPlanFormSpecialValues.NO_EXPERIENCE}</Typography>
        )
      }

      return <EmptyValueRenderer />
    },
    [existingMappings, experiencesMap],
  )

  const columns = React.useMemo(
    (): GridColDef<PolicyScopeDTO>[] => [
      {
        align: 'left',
        field: 'jurisdiction',
        flex: 1,
        headerAlign: 'left',
        headerName: 'Jurisdiction',
        sortable: false,
        width: 300,
        maxWidth: 300,
        renderCell: ({ row: { name, code } }) => (
          <Box padding="8px 0">
            <NameAndCodeCellRenderer name={name} code={code} />
          </Box>
        ),
      },
      ...(isEntitledToContentAndDisclosure
        ? [
            {
              align: 'left',
              field: 'autoInitiatedExperience',
              flex: 1,
              width: 300,
              maxWidth: 300,
              headerAlign: 'left',
              headerName: 'Auto Initiated Experience',
              sortable: false,
              renderCell: ({ row: { code } }) => (
                <ExperienceRenderer policyScopeCode={code} experienceType={ExperienceType.Consent} />
              ),
              renderHeader: () => (
                <Box display="flex" justifyContent="center" alignItems="center" gap={0.5}>
                  <Typography variant="smallLabel">Auto Initiated</Typography>
                  <Tooltip
                    title={
                      <Typography>
                        An <Typography variant="label">Auto Initiated</Typography> experience will be displayed
                        automatically based on Ketch’s consent resolution logic as described{' '}
                        <Typography
                          variant="bodyLink"
                          color={theme.palette.Action.Primary}
                          component="a"
                          href="https://docs.ketch.com/ketch/docs/experience-rendering-logic"
                          target="_blank"
                        >
                          here
                        </Typography>
                        .
                      </Typography>
                    }
                    placement="top"
                  >
                    <Box height={20} width={20}>
                      <Icon name="FInfo" iconColor={theme.palette.Text.Secondary} width={20} height={20} />
                    </Box>
                  </Tooltip>
                </Box>
              ),
            } as GridColDef<PolicyScopeDTO>,
          ]
        : []),
      ...(isEntitledToContentAndDisclosure
        ? [
            {
              align: 'left',
              field: 'autoInitiatedDefaultDisplay',
              flex: 1,
              width: 300,
              maxWidth: 300,
              headerAlign: 'left',
              headerName: 'Auto Initiated Default Display',
              sortable: false,
              renderCell: ({ row: { code } }) => {
                const existingMapping = existingMappings[code]?.[LegacyExperienceType.CONSENT]

                return !!existingMapping?.experienceID ? (
                  EXPERIENCE_DEFAULT_DISPLAY_NAMES[existingMapping?.experienceDefault || ExperienceDefaultDisplay.MODAL]
                ) : (
                  <EmptyValueRenderer />
                )
              },
            } as GridColDef<PolicyScopeDTO>,
          ]
        : []),
      {
        align: 'left',
        field: 'userInitiatedExperience',
        flex: 1,
        width: 300,
        maxWidth: 300,
        headerAlign: 'left',
        headerName: 'User Initiated Experience',
        sortable: false,
        renderCell: ({ row: { code } }) => (
          <ExperienceRenderer policyScopeCode={code} experienceType={ExperienceType.Preference} />
        ),
        renderHeader: () => (
          <Box display="flex" justifyContent="center" alignItems="center" gap={0.5}>
            <Typography variant="smallLabel">User Initiated</Typography>
            <Tooltip
              title={
                <Typography>
                  A <Typography variant="label">User Initiated</Typography> experience is displayed when a user takes
                  action to trigger it, such as clicking a footer link. To learn more about User-Initiated experiences,
                  see{' '}
                  <Typography
                    variant="bodyLink"
                    color={theme.palette.Action.Primary}
                    component="a"
                    href="https://docs.ketch.com/ketch/docs/preference-management-center-configuration"
                    target="_blank"
                  >
                    here
                  </Typography>
                  .
                </Typography>
              }
              placement="top"
            >
              <Box height={20} width={20}>
                <Icon name="FInfo" iconColor={theme.palette.Text.Secondary} width={20} height={20} />
              </Box>
            </Tooltip>
          </Box>
        ),
      },
      {
        align: 'right',
        field: 'viewButton',
        flex: 1,
        width: 120,
        maxWidth: 120,
        headerAlign: 'left',
        headerName: '',
        sortable: false,
        renderCell: ({ row: { code } }) => (
          <Button
            color="tertiary"
            onClick={() => {
              navigate(RoutesManager.policyCenter.policyScopes.view.root.getURL({ code }))
            }}
          >
            View
          </Button>
        ),
      },
    ],
    [ExperienceRenderer, existingMappings, isEntitledToContentAndDisclosure, navigate],
  )

  if (!isReady) {
    return <Spinner padding={50} />
  }

  return (
    <>
      {!isRelease(Release.PropertyThemeDocuments) && (
        <FormGroup>
          <ContentGroup title="Basic Details" titleVariant="h3" variant="inner-page-header" />
          {planTheme?.name ? (
            <>
              <Typography variant="label" component="p" sx={{ mt: 3, mb: 1 }}>
                Theme
              </Typography>
              <Chip
                clickable
                label={planTheme?.name}
                deleteIcon={<Icon name="OArrowCRight" />}
                onClick={() => navigate(RoutesManager.deployment.themesV3.view.root.getURL({ id: planTheme?.id }))}
                onDelete={() => navigate(RoutesManager.deployment.themesV3.view.root.getURL({ id: planTheme?.id }))}
              />
            </>
          ) : (
            <EmptyValueRenderer />
          )}
        </FormGroup>
      )}

      <FormGroup>
        <SectionTitle>Experience Delivery</SectionTitle>
        {deploymentPlan?.isOrchestrationOnly ? (
          <Box mb={2}>
            <Banner isCompact severity={'info' as AlertColor} variant={'filled'} title="Quiet mode is enabled">
              These configurations are not applicable. Quiet mode deployments will not serve experiences or generate
              consent.
            </Banner>
          </Box>
        ) : null}
        <DataGrid
          rows={policyScopes}
          columns={columns}
          autosizeOnMount
          disableChildrenSorting
          disableColumnMenu
          disableColumnPinning
          disableColumnReorder
          disableColumnResize
          disableRowHoverStates
          disableRowSelectionOnClick
          getRowHeight={() => 'auto'}
          getRowId={row => row.code}
          hideFooter
          sx={{
            padding: 'unset',
          }}
        />
      </FormGroup>
    </>
  )
}
