import { API } from '../../../utils/api/api'
import { FetchProcessingActivitiesSummaryResponseBodyDTO } from '@ketch-com/figurehead'
import { formatRequestString } from '../../../utils/helpers/api/queryString'

interface FetchProcessingActivitiesSummaryParams {
  organizationCode: string
  isV2FeatureFlagEnabled?: boolean
}

export const fetchProcessingActivitiesSummary = ({
  isV2FeatureFlagEnabled,
}: FetchProcessingActivitiesSummaryParams) => {
  const entityUrl = isV2FeatureFlagEnabled
    ? '/api/risk-review/processing-activities/summary'
    : '/api/processing-activities/summary'
  return API.get<FetchProcessingActivitiesSummaryResponseBodyDTO>(
    formatRequestString({
      entityUrl,
    }),
  )
}
