import { theme } from '@ketch-com/deck'

export const dataSystemProgressIndicatorStyles = {
  bgcolor: 'transparent',
  fgcolor: theme.palette.Highlight.PrimaryDark,
  highlightColor: theme.palette.Highlight.Disabled,
}

export const SYSTEM_PROTOCOL_MAXIMUM =
  'You have reached the maximum number of connected systems/protocols allowed under your current plan. To upgrade your account, please contact us at support@ketch.com.'

export const resourceSupportPreviewMapping: Record<string, string> = {
  'azuresqldb.table': 'azuresqldb.table',
  'snowflake.table': 'snowflake.table',
  'redshift.table': 'redshift.table',
  'oracle.table': 'oracle.table',
  'postgresql.table': 'postgresql.table',
  'cockroachDB.table': 'cockroachDB.table',
  'mariadb.table': 'mariadb.table',
  'mssqlserver.table': 'mssqlserver.table',
  'bigquery.table': 'bigquery.table',
}
