import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'
import { useFeatureFlag } from 'utils/hooks'
import { createUsePaginatedQuery, CustomPaginatedQueryConfig } from '../../common/paginatedQuery'
import { ApiQueryKeys } from '../../common/queryKeys'
import { fetchProcessingActivityVersions } from '../fetchers/fetchProcessingActivityVersions'

const useCustomPaginatedQuery = createUsePaginatedQuery({
  queryKey: ApiQueryKeys.processingActivityVersions,
  queryFn: fetchProcessingActivityVersions,
  select: res => res?.data || {},
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery>

export const useProcessingActivityVersions = (config?: QueryConfig) => {
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isV2FeatureFlagEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.ASSESSMENT,
    FEATURE_FLAGS_VALUES.ASSESSMENTS_VERSION,
  )
  return useCustomPaginatedQuery({
    ...config,
    params: {
      ...config?.params,
      isV2FeatureFlagEnabled,
    },
  })
}
