import {
  ClassificationRefreshScheduleDay,
  ClassificationRefreshScheduleFrequency,
} from '@ketch-com/windlass/dist/appliance/databases'

export const canonicalResourceTypeMap: Record<string, string> = {
  CANONICAL_RESOURCE_TYPE_UNSPECIFIED: 'Unspecified',
  CANONICAL_RESOURCE_TYPE_DATABASE: 'Database',
  CANONICAL_RESOURCE_TYPE_DATASET: 'Table',
  CANONICAL_RESOURCE_TYPE_ATTRIBUTE: 'Column',
  CANONICAL_RESOURCE_TYPE_OBJECT: 'Object',
  CANONICAL_RESOURCE_TYPE_DRIVE: 'Database',
  CANONICAL_RESOURCE_TYPE_FOLDER: 'Folder',
  CANONICAL_RESOURCE_TYPE_FILE: 'File',
  CANONICAL_RESOURCE_TYPE_SCHEMA: 'Schema',
}

export const columnFiltersDefaults = ['dataOwner', 'classificationSchedule']

export const columnFiltersByResourceType: Record<string, string[]> = {
  database: [],
  drive: [],
  schema: columnFiltersDefaults,
  dataset: columnFiltersDefaults,
  object: columnFiltersDefaults,
  folder: columnFiltersDefaults,
  file: columnFiltersDefaults,
  table: columnFiltersDefaults,
  column: columnFiltersDefaults,
  attribute: columnFiltersDefaults,
}

export const filterDisplayNameMap: Record<string, string> = {
  dataSensitivityCodes: 'Data Sensitivity',
  dataCategoryCodes: 'Data Categories',
  dataTypeCodes: 'Data Types',
}

export const personalDataTypeOptions = [
  { name: 'Personal', value: 'personal' },
  { name: 'Sensitive', value: 'sensitive' },
  { name: 'Non-Personal', value: 'non-personal' },
]

export const classificationFilterKeyToTypeMap: Record<string, string> = {
  dataCategoryCodes: 'dataCategories',
  dataTypeCodes: 'dataTypes',
  dataSensitivityCodes: 'dataSensitivities',
}

export const EXECUTION_DURATION_OPTIONS = [
  '1h',
  '2h',
  '3h',
  '4h',
  '5h',
  '6h',
  '7h',
  '8h',
  '9h',
  '10h',
  '11h',
  '12h',
  '13h',
  '14h',
  '15h',
  '16h',
  '17h',
  '18h',
  '19h',
  '20h',
  '21h',
  '22h',
  '23h',
  '24h',
]

export const frequencyMapping: Record<string, ClassificationRefreshScheduleFrequency | undefined> = {
  '0': ClassificationRefreshScheduleFrequency.Disabled,
  '1': ClassificationRefreshScheduleFrequency.Daily,
  '2': ClassificationRefreshScheduleFrequency.Weekly,
  '3': ClassificationRefreshScheduleFrequency.Monthly,
}

export const CLASSIFICATION_SCHEDULE_FREQUENCY_OPTIONS = [
  { id: '1', name: 'Every Day' },
  { id: '2', name: 'Every Week' },
  { id: '3', name: 'Every Month' },
]

export const dayMapping: Record<string, ClassificationRefreshScheduleDay | undefined> = {
  '1': ClassificationRefreshScheduleDay.Monday,
  '2': ClassificationRefreshScheduleDay.Tuesday,
  '3': ClassificationRefreshScheduleDay.Wednesday,
  '4': ClassificationRefreshScheduleDay.Thursday,
  '5': ClassificationRefreshScheduleDay.Friday,
  '6': ClassificationRefreshScheduleDay.Saturday,
  '7': ClassificationRefreshScheduleDay.Sunday,
}
