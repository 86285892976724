import { accounts } from 'utils/routing/routesV2/accounts'
import { assetManager } from 'utils/routing/routesV2/assetManager'
import { assetManagerDemo } from 'utils/routing/routesV2/assetManagerDemo'
import { assets } from 'utils/routing/routesV2/assets'
import { auth } from 'utils/routing/routesV2/auth'
import { admin } from 'utils/routing/routesV2/admin'
import { callback } from 'utils/routing/routesV2/callback'
import { deployment } from 'utils/routing/routesV2/deployment'
import { developers } from 'utils/routing/routesV2/developers'
import { insights } from 'utils/routing/routesV2/insights'
import { notifications } from 'utils/routing/routesV2/notifications'
import { orchestration } from 'utils/routing/routesV2/orchestration'
import { permissions } from 'utils/routing/routesV2/permissions'
import { policyCenter } from 'utils/routing/routesV2/policyCenter'
import { portabilityDemo } from 'utils/routing/routesV2/portabilityDemo'
import { product } from 'utils/routing/routesV2/product'
import { registration } from 'utils/routing/routesV2/registration'
import { reporting } from 'utils/routing/routesV2/reporting'
import { settings } from 'utils/routing/routesV2/settings'
import { skus } from 'utils/routing/routesV2/skus'
import { systems } from 'utils/routing/routesV2/systems'
import { updates } from './routesV2/updates'
import { home } from './routesV2/home'
import { developmentUtils } from './routesV2/developmentUtils'
import { onboarding } from './routesV2/onboarding'
import { externalPrivacyPortal } from './routesV2/externalPrivacyPortal'

export const RoutesManager = {
  accounts,
  admin,
  assetManager,
  assetManagerDemo,
  assets, // for ketch static assets
  auth,
  callback,
  deployment,
  developers,
  developmentUtils,
  home,
  insights,
  notifications,
  orchestration,
  permissions,
  policyCenter,
  portabilityDemo,
  product,
  registration,
  reporting,
  settings,
  systems,
  skus,
  updates,
  onboarding,
  externalPrivacyPortal,
}
