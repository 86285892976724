import { AssessmentAttributesSectionsItemDTO } from '@ketch-com/figurehead'
import { Box } from '@mui/material'
import { AssessmentEditPagerSection } from './AssessmentEditPagerSection'
import { AssessmentSection } from '@ketch-com/windlass/dist/assessments/externalUser'

type Props = {
  sections: AssessmentAttributesSectionsItemDTO[] | AssessmentSection[]
  setOpenSections: (s: string[]) => void
}
export const AssessmentEditPager: React.FC<Props> = ({ sections, setOpenSections }) => {
  return (
    <Box bgcolor="white.main" borderRadius="11px" width={296} mt={4} py={1} alignSelf="flex-start" px={1}>
      {sections.map((section, index) => (
        <AssessmentEditPagerSection
          index={index}
          key={index}
          openSection={() => setOpenSections([section.name || ''])}
          section={section}
        />
      ))}
    </Box>
  )
}
