import { useMemo } from 'react'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { CreateLayout } from 'components/ui-layouts/createLayout/CreateLayout'
import { CreateLayoutStepContent } from 'components/ui-layouts/createLayout/CreateLayoutStepContent'
import { CreateLayoutActions } from 'components/ui-layouts/createLayout/CreateLayoutActions'
import { Group } from 'components/ui-layouts/group/Group'
import { Button } from '@ketch-com/deck'
import {
  CreatePatchDSRConfigsRequestBodyDTO,
  AssetSummaryDTO,
  GetScenarioResponseBodyDTO,
  GetDSRConfigsResponseBodyDTO,
  ListDsrAttributeActionsSummaryBodyDTO,
} from '@ketch-com/figurehead'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { RoutesManager } from 'utils/routing/routesManager'
import { useParams } from 'react-router-dom'
import {
  getDsrConfigFormInitialValues,
  getDsrConfigFormValidationSchema,
  mapApplicationValuesToPayload,
} from 'pages/assetManager/dsrConfig/utils'
import {
  UserLookup,
  RelatedDataSets,
  DatasetConfigurationContainer,
} from 'pages/assetManager/dsrConfig/upsert/components'
import { DsrConfigureFormStep, RelatedPathsAndAssets } from 'pages/assetManager/dsrConfig/interfaces'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { theme } from '@ketch-com/deck'
import { useDsrConfigInitiator } from 'pages/assetManager/hooks'
import { AssetInfoNav } from 'pages/dataSystems/AssetMigration/AssetInfoNav'
import { LookUpTab } from 'pages/dataSystems/AssetMigration/ScenarioDetails/CustomSQLTypes'
import { DsrConfigParams } from 'utils/routing/routesV2/systems'

type Props = {
  isReady: boolean
  onSubmit: (obj: {
    createData: CreatePatchDSRConfigsRequestBodyDTO
    patchData: CreatePatchDSRConfigsRequestBodyDTO
  }) => void
  assetsHierarchies: ListDsrAttributeActionsSummaryBodyDTO[]
  assetsSummary: AssetSummaryDTO[]
  relatedPathsAndAssets: RelatedPathsAndAssets
  identitySpaces: IdentitySpaceDTO[]
  scenarioData: GetScenarioResponseBodyDTO
  assetsDsrConfig: GetDSRConfigsResponseBodyDTO[]
}

export const DSRConfigCreate: React.FC<Props> = ({
  isReady,
  assetsHierarchies,
  assetsSummary,
  relatedPathsAndAssets,
  identitySpaces,
  scenarioData,
  assetsDsrConfig,
  onSubmit,
}) => {
  const { initiatorIsDatabase } = useDsrConfigInitiator()
  const { scenarioId, id: primaryDatasetId } = useParams<DsrConfigParams>()
  const navigate = useNavigate()
  const assetHierarchies = assetsHierarchies[0] // assetsHierarchies[0] is primary dataset
  const assetHierarchiesLength = assetHierarchies?.hierarchySummaries?.length || 0

  const assetAttributesSummary: AssetSummaryDTO[] = useMemo(
    () =>
      assetHierarchies?.hierarchySummaries?.filter(hierarchySummary => hierarchySummary.asset?.identitySpace?.code) ||
      [],
    [assetHierarchies?.hierarchySummaries],
  )

  assetAttributesSummary?.forEach?.(attribute => {
    const matchedIdentitySpace = identitySpaces?.find(
      identitySpace => attribute.asset?.identitySpace?.code === identitySpace.code,
    )
    const { asset = {} } = attribute
    const { identitySpace: assetIdentitySpace = {} } = asset
    if (matchedIdentitySpace) {
      assetIdentitySpace.name = matchedIdentitySpace.name
    }
  })

  // assetsSummary[0] is primary asset summary
  const { name: databaseName = '', id: databaseId = '' } =
    assetsSummary[0]?.asset?.resource?.hierarchies?.CANONICAL_RESOURCE_TYPE_DATABASE || {}
  const systemId = assetsSummary[0]?.asset?.connection?.installedDataSystemID as string
  const { name: datasetName = '', id: datasetId = '' } = assetsSummary[0]?.asset?.resource || {}
  const resourceTypeCode = assetsSummary[0]?.asset?.resource?.resourceType?.code as string

  const dbUrl = RoutesManager.systems.dsrInvocation.scenarioDetails.getURL({
    id: databaseId,
    resourceTypeCode,
    scenarioId: scenarioId!,
    tab: LookUpTab.DIRECT,
  })

  const breadcrumbs = [
    { title: 'Data Map', link: RoutesManager.systems.root.getURL() },
    {
      title: databaseName,
      link: RoutesManager.systems.id.overview.root.getURL({ id: systemId }),
    },
    {
      title: 'Rights Orchestration',
      link: RoutesManager.systems.id.rightsOrchestration.root.getURL({ assetId: databaseId, id: systemId }),
    },
    { title: `Configure: ${scenarioData?.scenario?.name}` },
  ]
  const connectionCode = assetsSummary?.[0]?.asset?.connection?.connectionCode // for now connectionCode is same for all assets
  const technology = assetsSummary?.[0]?.asset?.connection?.technology || ''

  const formInitialValue = useMemo(
    () =>
      getDsrConfigFormInitialValues({
        assetAttributesSummary,
        relatedPathsAndAssets,
        assetsDsrConfig,
        assetsHierarchies,
        isEditMode: false,
      }),
    [assetAttributesSummary, relatedPathsAndAssets, assetsDsrConfig, assetsHierarchies],
  )

  return (
    <>
      {isReady ? (
        <Box width={1280} margin="0 auto" mb={2.5} pb={2.5} borderBottom={`1px solid ${theme.palette.iron.main}`}>
          <AssetInfoNav
            database={{ name: databaseName, id: databaseId, url: dbUrl }}
            technology={technology}
            dataset={{
              name: datasetName,
              id: datasetId,
              assetsSummary: assetsSummary,
              attributeCount: assetHierarchiesLength,
            }}
          />
        </Box>
      ) : null}

      {formInitialValue ? (
        <CreateLayout
          title=""
          isReady={isReady}
          initialValues={formInitialValue}
          validationSchema={getDsrConfigFormValidationSchema({
            primaryDatasetId: primaryDatasetId!,
            assetsHierarchies,
          })}
          onSubmit={values => {
            onSubmit(
              mapApplicationValuesToPayload({
                values,
                scenarioId: scenarioId!,
                relatedPathsAndAssets,
                primaryDatasetId: primaryDatasetId!,
                connectionCode,
                assetsDsrConfig,
                canonicalRightCode: scenarioData?.scenario?.canonicalRight?.code || '',
              }),
            )
          }}
          steps={() => [
            DsrConfigureFormStep.USER_LOOKUP,
            DsrConfigureFormStep.RELATED_TABLES,
            DsrConfigureFormStep.CONFIGURATION,
          ]}
          breadcrumbs={breadcrumbs}
          getIsActiveStepValid={(step, props) => {
            return true
          }}
        >
          {({ isSubmitting, submitForm, errors }, { isLastStep, setNextStep }) => (
            <>
              <CreateLayoutStepContent step={DsrConfigureFormStep.USER_LOOKUP}>
                <UserLookup assetAttributesSummary={assetAttributesSummary} />
              </CreateLayoutStepContent>

              <CreateLayoutStepContent step={DsrConfigureFormStep.RELATED_TABLES}>
                <RelatedDataSets relatedPathsAndAssets={relatedPathsAndAssets} assetsSummary={assetsSummary} />
              </CreateLayoutStepContent>

              <CreateLayoutStepContent step={DsrConfigureFormStep.CONFIGURATION}>
                <DatasetConfigurationContainer
                  assetsHierarchies={assetsHierarchies}
                  assetsSummary={assetsSummary}
                  identitySpaces={identitySpaces}
                />
              </CreateLayoutStepContent>

              <CreateLayoutActions>
                <Group>
                  <Button
                    color="secondary"
                    size="large"
                    pending={isSubmitting}
                    onClick={() => {
                      if (initiatorIsDatabase) {
                        navigate(dbUrl)
                      } else {
                        navigate(
                          RoutesManager.systems.dsrInvocation.assetScenarios.getURL({
                            id: databaseId,
                            resourceType: resourceTypeCode,
                          }),
                        )
                      }
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    size="large"
                    pending={isSubmitting}
                    onClick={() => {
                      if (isLastStep) {
                        submitForm()
                      } else {
                        setNextStep()
                      }
                    }}
                  >
                    {isLastStep ? 'Save & Finish' : 'Next'}
                  </Button>
                </Group>
              </CreateLayoutActions>
            </>
          )}
        </CreateLayout>
      ) : (
        <Spinner />
      )}
    </>
  )
}
