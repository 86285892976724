import { Button, TextInput, theme } from '@ketch-com/deck'
import { Box, Link, Typography } from '@mui/material'
import { useSendCollaborationAuth } from 'api/assessments/mutations/useSendCollaborationAuth'
import { ReactComponent as HeaderKetchLogo } from 'assets/icons/ketch_logo_with_text.svg'
import { useCallback, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'

export const ExternalAuthLogin: React.FC = () => {
  const [linkSent, setLinkSent] = useState(false)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [inputValue, setInputValue] = useState('')
  const email = searchParams.get('email')
  const [inputError, setInputError] = useState<string | null>(null)
  const { executeRecaptcha } = useGoogleReCaptcha()

  const { mutateAsync: sendCollaborationAuthEmail } = useSendCollaborationAuth()

  const handleSendMagicLink = useCallback(async () => {
    if (!executeRecaptcha) {
      return
    }
    let recaptchaToken = null
    try {
      recaptchaToken = await executeRecaptcha('sendMagicLink')
    } catch (error) {
      recaptchaToken = ''
    }
    sendCollaborationAuthEmail({
      params: {
        email: email || inputValue || undefined,
        recaptchaToken: recaptchaToken,
        redirectUrl: `${window.location.origin}/collaboration-workspace/callback`,
      },
    })
    setLinkSent(true)
  }, [email, executeRecaptcha, inputValue, sendCollaborationAuthEmail])

  const isValidEmail = useCallback((email: string): boolean => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }, [])

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      setInputValue(value)

      if (value && !isValidEmail(value)) {
        setInputError('Invalid email format')
      } else {
        setInputError(null)
      }
    },
    [isValidEmail],
  )

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      sx={{ backgroundColor: theme.palette.Common.Container }}
      alignItems="center"
      paddingTop={8}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={8}
        minWidth="384px"
        width="40vw"
        borderBottom={`solid 1px ${theme.palette.Common.Divider}`}
        paddingBottom={3}
      >
        <HeaderKetchLogo
          id="ketch-logo-button"
          onClick={() => navigate(RoutesManager.externalPrivacyPortal.login.getURL())}
          style={{ position: 'relative', top: '0.5px', cursor: 'pointer' }}
        />
        {!linkSent ? (
          <Box display="flex" flexDirection="column" gap={3}>
            <Typography variant="h1">Sign In To Privacy Hub</Typography>
            {email ? (
              <Typography variant="label">Send To: {<Typography variant="body">{email}</Typography>}</Typography>
            ) : (
              <TextInput
                type="email"
                label="Email"
                fullWidth
                value={inputValue}
                error={!!inputError}
                onChange={handleInputChange}
                placeholder="Enter email"
              />
            )}
            <Button
              size="large"
              onClick={handleSendMagicLink}
              disabled={(!inputValue.length && !email) || !!inputError}
            >
              Send Me a Magic Link
            </Button>
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" gap={3} alignItems="flex-start">
            <Typography variant="h1">Magic Link Sent</Typography>
            <Typography variant="body" sx={{ fontSize: 16 }}>
              Please Check your email and click the verification link we sent you to confirm your identity.
            </Typography>
            <Typography variant="label">Didn't receive the email?</Typography>
            <Button
              fullWidth={false}
              sx={{ flexGrow: 0 }}
              variant="link"
              color="secondary"
              onClick={handleSendMagicLink}
            >
              Send Again
            </Button>
          </Box>
        )}
      </Box>
      <Box display="flex" flexDirection="column" paddingTop={3} gap={2} minWidth="384px" width="40vw">
        <Typography variant="smallBody">Copyright &copy; 2024 Ketch Kloud, Inc. All rights reserved</Typography>
        <Link href="mailto:support@ketch.com" underline="none" mb="2px">
          <Typography variant="smallBody" color={theme.palette.Action.Primary}>
            Contact Support
          </Typography>
        </Link>
      </Box>
    </Box>
  )
}
