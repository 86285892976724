import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { GetProcessingActivityResponseBodyDTO } from '@ketch-com/figurehead'

type Params = APIRequestParams<{
  activityId: string
  isV2FeatureFlagEnabled?: boolean
}>

export const fetchProcessingActivity = ({ activityId, includeMetadata, isV2FeatureFlagEnabled }: Params) => {
  const entityUrl = isV2FeatureFlagEnabled
    ? `/api/risk-review/processing-activities/${activityId}`
    : `/api/processing-activities/${activityId}`
  return API.get<GetProcessingActivityResponseBodyDTO>(
    formatRequestString({
      entityUrl,
      params: {
        filters: {
          includeMetadata,
        },
      },
    }),
  )
}
