import { AvatarSize, Avatar, Chip } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'

export interface AssessmentNameAndSubjectProps {
  assessmentName: string
  dataSubjectCode: string
  dataSubjectIcon?: string
  maxWidth?: string
}

export const AssessmentNameAndSubject: React.FC<AssessmentNameAndSubjectProps> = ({
  assessmentName,
  dataSubjectCode,
  dataSubjectIcon,
  maxWidth,
}) => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" gap={0.5}>
      <Box
        maxWidth={maxWidth || undefined}
        sx={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        <Typography variant="label">{assessmentName}</Typography>
      </Box>
      <Box display="flex" alignItems="center" gap={0.5}>
        <Typography variant="footnote">evaluation of</Typography>
        {dataSubjectIcon ? (
          <Box display="flex" gap={0.5}>
            <Avatar src={dataSubjectIcon} size={AvatarSize.small} variant="circular" />{' '}
            <Typography> {dataSubjectCode}</Typography>
          </Box>
        ) : (
          <Chip size="small" label={dataSubjectCode} />
        )}
      </Box>
    </Box>
  )
}
