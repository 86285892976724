import { Typography } from '@mui/material'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { useExternalAssessmentResponse } from '../context/ExternalAssessmentResponseContext'
import { AssessmentExternalLongAnswerInput } from './assessmentInputs/AssessmentExternalLongAnswerInput'
import { AssessmentExternalShortAnswerInput } from './assessmentInputs/AssessmentExternalShortAnswerInput'
import { AssessmentExternalSingleChoiceInput } from './assessmentInputs/AssessmentExternalSingleChoiceInput'
import { QUESTION_TYPES } from 'pages/policyCenter/assessments/questions/constants'
import { AssessmentExternalMultiChoiceInput } from './assessmentInputs/AssessmentExternalMultiChoiceInput'

export const AssessmentExternalQuestionInput: React.FC<{}> = () => {
  const { block, isCompleted, isRejected } = useExternalAssessmentResponse()

  const questionType = block.question?.attributes?.type

  if (isCompleted || isRejected) {
    const completedText =
      questionType === QUESTION_TYPES.LONG_ANSWER || questionType === QUESTION_TYPES.SHORT_ANSWER
        ? block.answer?.attributes?.text
        : block.answer?.attributes?.choiceIDs
            ?.map(choiceID => block.question?.attributes?.choices?.find(choice => choice.id === choiceID)?.displayText)
            ?.join(', ')
    return (
      <Typography variant="body" sx={{ whiteSpace: 'pre-line' }}>
        {completedText || <EmptyValueRenderer />}
      </Typography>
    )
  }

  switch (questionType) {
    case QUESTION_TYPES.LONG_ANSWER:
      return <AssessmentExternalLongAnswerInput />
    case QUESTION_TYPES.MULTIPLE_CHOICE:
      return <AssessmentExternalMultiChoiceInput />
    case QUESTION_TYPES.SINGLE_CHOICE:
      return <AssessmentExternalSingleChoiceInput />
    case QUESTION_TYPES.SHORT_ANSWER:
    default:
      return <AssessmentExternalShortAnswerInput />
  }
}
