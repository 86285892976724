import React, { useMemo } from 'react'
import { get } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { NameAndCodeCellRenderer } from 'components/renderers/NameAndCodeCellRenderer'
import { Button, DataGrid, TableCell, ContentGroup } from '@ketch-com/deck'

// utils
import { RoutesManager } from 'utils/routing/routesManager'

// types
import { LegalBaseDTO } from 'interfaces/legalBases/legalBase'
import { PurposeDTO } from '@ketch-com/figurehead'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import Typography from '@mui/material/Typography'
type Props = {
  isReady: boolean
  legalBases: LegalBaseDTO[]
  purposesByScope: PurposeDTO[]
  policyScopeCode: string
}

export const PurposesTable: React.FC<Props> = ({ isReady, legalBases, purposesByScope, policyScopeCode }) => {
  const navigate = useNavigate()

  const rows = useMemo(
    () => purposesByScope.toSorted((a, b) => (a.name || '').localeCompare(b.name || '')),
    [purposesByScope],
  )

  const gridColumns = [
    {
      field: 'Purposes',
      headerName: 'Purposes',
      renderCell: ({ row: { name, code } }: GridRenderCellParams<PurposeDTO>) => {
        return (
          <TableCell>
            <NameAndCodeCellRenderer name={name} code={code} />
          </TableCell>
        )
      },
      sortable: false,
      width: 300,
    },
    {
      field: 'Description',
      headerName: 'Description',
      renderCell: ({ row: { description } }: GridRenderCellParams<PurposeDTO>) => {
        return (
          <TableCell>
            <Typography>{description || 'None'}</Typography>
          </TableCell>
        )
      },
      sortable: false,
      flex: 1,
    },
    {
      field: 'Legal Basis',
      headerName: 'Legal Basis',
      renderCell: ({ row }: GridRenderCellParams<PurposeDTO>) => {
        const purposeLegalBasesAssignment = get(row, 'legalBases')
        const currentScopeLegalBasisCode = get(purposeLegalBasesAssignment, policyScopeCode)
        const legalBasesItem = legalBases.find(({ code }) => code === currentScopeLegalBasisCode)
        return (
          <TableCell>
            <Typography>{legalBasesItem?.name || 'None'}</Typography>
          </TableCell>
        )
      },
      sortable: false,
      width: 300,
    },
    {
      field: 'action',
      headerName: '',
      renderCell: ({ row: { code } }: GridRenderCellParams<PurposeDTO>) => {
        return (
          <TableCell>
            <Button
              color="tertiary"
              onClick={() => navigate(RoutesManager.policyCenter.purposes.view.root.getURL({ code }))}
            >
              View
            </Button>
          </TableCell>
        )
      },
      sortable: false,
      width: 100,
    },
  ]

  return (
    <ContentGroup
      titleVariant="h3"
      title="Individual Purposes"
      subTitle="These purposes will be rendered individually without any relationship to a group"
    >
      {isReady && (
        <DataGrid
          loading={!isReady}
          rowCount={rows.length}
          rows={rows}
          columns={gridColumns}
          autosizeOnMount
          disableColumnMenu
          disableColumnPinning
          disableColumnReorder
          disableChildrenSorting
          disableColumnResize
          disableRowHoverStates
          hideFooter
          disableRowSelectionOnClick
          getRowId={({ id }) => id}
          sx={{
            padding: 0,
            '& .MuiDataGrid-columnHeader[role="columnheader"]:hover': {
              backgroundImage: 'unset',
            },
          }}
          getRowHeight={() => 'auto'}
        />
      )}
    </ContentGroup>
  )
}
