import { Box, BoxProps } from '@mui/material'
import { PortalFooter } from 'pages/externalPrivacyPortal/PortalFooter'
import { PortalHeader } from 'pages/externalPrivacyPortal/PortalHeader'

interface PortalPageProps extends BoxProps {
  withoutAuth?: boolean
  children: React.ReactNode
}

export const PortalPage: React.FC<PortalPageProps> = ({ withoutAuth, children, ...rest }) => {
  return (
    <Box {...rest} width="100%" minWidth="1244px" minHeight="100vh" display="flex" flexDirection="column">
      <PortalHeader withoutAuth={withoutAuth} alignSelf="flex-start" />
      <Box flexGrow={1} display="flex" flexDirection="column">
        {children}
      </Box>
      <PortalFooter alignSelf="flex-end" />
    </Box>
  )
}
