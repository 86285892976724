import { SidebarFormPositionRadioGroup } from '../SidebarFormPositionRadioGroup'
import { SidebarFormGroup } from '../../../../../../experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'
import { themeUpsertFormKeys } from '../../../../utils/useThemeValidationSchema'
import Box from '@mui/material/Box'
import {
  BuilderTextInputMode,
  FormTextInput,
} from '../../../../../../experiences-v2/upsert/components/upsertExperienceSidebar/components/FormTextInput'
import { FormSectionSwitch } from '../../../../../../experiences-v2/upsert/components/upsertExperienceSidebar/components/FormSectionSwitch'
import { getIn, useFormikContext } from 'formik'
import { ThemeConfig } from '@ketch-sdk/ketch-types'
import { FormColorInput } from '../FormColorInput'

export const ModalThemeContainerForm: React.FC = () => {
  const { values } = useFormikContext<ThemeConfig>()
  return (
    <>
      <SidebarFormGroup sx={{ pt: 0, gap: '16px' }}>
        <SidebarFormPositionRadioGroup variant={'modal'} />
      </SidebarFormGroup>
      <SidebarFormGroup hasBorderTop hasBorderBottom>
        <FormColorInput
          name={themeUpsertFormKeys.modalContainerBackgroundColor}
          label={'Background Color'}
          fullWidth
          required
        />
        <Box display={'flex'} flexDirection={'column'}>
          <FormTextInput
            name={themeUpsertFormKeys.modalContainerCornerRadius}
            label={'Corner Radius (px)'}
            inputWidth="92px"
            mode={BuilderTextInputMode.CornerRadius}
          />
        </Box>
      </SidebarFormGroup>
      <SidebarFormGroup>
        <FormSectionSwitch
          title={'Backdrop'}
          name={themeUpsertFormKeys.modalContainerBackdropVisible}
          tooltip={
            'The backdrop is a color overlay under the modal that covers the entire website content to maximize modal visibility. Please note that the user will not be able to interact with the underlying content until the modal is dismissed.'
          }
        />
        {getIn(values, themeUpsertFormKeys.modalContainerBackdropVisible) && (
          <>
            <Box px={'18px'}>
              <Box display={'flex'} gap={'12px'}>
                <Box width={232}>
                  <FormColorInput
                    name={themeUpsertFormKeys.modalContainerBackdropBackgroundColor}
                    label={'Background Color'}
                    required
                  />
                </Box>
                <Box width={92} mt={'-2px'}>
                  <FormTextInput
                    name={themeUpsertFormKeys.modalContainerBackdropBackgroundOpacity}
                    label={'Opacity (%)'}
                    inputWidth="92px"
                    mode={BuilderTextInputMode.Opacity}
                    min={0}
                    max={100}
                    sx={{ width: '92px' }}
                  />
                </Box>
              </Box>
              {/* TODO:JB - Descoped */}
              {/* <Box display={'flex'} flexDirection={'column'}>
                <FormControlLabel
                  control={<FormCheckbox name={themeUpsertFormKeys.modalContainerBackdropDisableInteractions} />}
                  label={<Typography variant={'label'}>Disable Underlying Content Interactions</Typography>}
                />
                <Typography variant={'smallBody'} color={theme.palette.Text.Secondary}>
                  When enabled, your website content will remain non-clickable until the modal is dismissed.
                </Typography>
              </Box> */}
            </Box>
          </>
        )}
      </SidebarFormGroup>
    </>
  )
}
