import { ApiQueryKeys } from 'api/common/queryKeys'
import { HandleUploadParams } from './AttachmentUpload.types'

export const UPLOADED_ERRORS_TIMEOUT = 4000

/**
 * Handles the upload of files.
 *
 * @param params - The upload parameters.
 */
export const handleUpload = async ({ files, queryClient, onUploadComplete }: HandleUploadParams) => {
  if (onUploadComplete) onUploadComplete(files)
  await queryClient.refetchQueries(ApiQueryKeys.filesList) // Refetch file list queries after upload.
}
