import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from 'api/common/mutation'
import { putExternalAnswer } from '../fetchers/putExternalAnswer'

const useCustomMutation = createUseMutation({
  mutationFn: putExternalAnswer,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const usePutExternalAnswer = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
