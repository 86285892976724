import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchExternalAssessment } from '../fetchers/fetchExternalAssessment'
import { ApiQueryKeys } from 'api/common/queryKeys'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.assessmentExternal,
  queryFn: fetchExternalAssessment,
  select: res => res?.data || null,
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useExternalAssessment = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
}
