import { DsrConfigSelectedAssetProvider } from 'pages/dataSystems/AssetMigration/ScenarioDetails/context/DsrConfigSelectedAssetContext'
import RightsOrchestrationTab from './RightsOrchestrationTab'

export const RightsOrchestrationTabContainer = () => {
  return (
    <DsrConfigSelectedAssetProvider>
      <RightsOrchestrationTab />
    </DsrConfigSelectedAssetProvider>
  )
}
