import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchPasswordlessUsers } from '../fetchers/fetchPasswordlessUsers'
import { ApiQueryKeys } from 'api/common/queryKeys'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.fetchPasswordlessUsers,
  queryFn: fetchPasswordlessUsers,
  select: res => res?.data || null,
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useFetchPasswordlessUsers = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
}
