import { TabSegmented, TabSegmentedGroup } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import { SearchTextInput } from 'components/searchTextInput/SearchTextInput'
import { StatusList } from '../constants'

export interface AssessmentsListFiltersProps {
  handleSearchFilterChange: (nextValue: string) => void
  handleSearchInputChange: (nextValue: string) => void
  searchQuery: string
  searchStatusFilter: string
}

export const AssessmentsListFilters: React.FC<AssessmentsListFiltersProps> = ({
  handleSearchFilterChange,
  handleSearchInputChange,
  searchQuery,
  searchStatusFilter,
}) => {
  return (
    <Box marginTop={6} marginBottom={4} display="flex" width="100%" justifyContent="space-between" alignItems="center">
      <SearchTextInput
        onChange={nextValue => handleSearchInputChange(nextValue)}
        value={searchQuery}
        showClearButton
        minWidth="176px"
        sx={{
          width: '176px',
          '& .MuiInputBase-input': { maxWidth: '112px', minWidth: '112px' },
        }}
      />
      <Box>
        <TabSegmentedGroup value={searchStatusFilter} exclusive disabled={false} onChange={() => {}}>
          {StatusList.map(value => (
            <TabSegmented
              key={value.code}
              value={value.value}
              size="small"
              disabled={false}
              onClick={() => {
                handleSearchFilterChange(value.value)
              }}
            >
              <Typography variant="label">{value.code}</Typography>
            </TabSegmented>
          ))}
        </TabSegmentedGroup>
      </Box>
    </Box>
  )
}
