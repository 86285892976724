import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from 'api/common/mutation'
import { sendCollaborationAuth } from '../fetchers/sendCollaborationAuth'

const useCustomMutation = createUseMutation({
  mutationFn: sendCollaborationAuth,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useSendCollaborationAuth = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
