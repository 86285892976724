import React, { useContext } from 'react'
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd'
import { useFormikContext } from 'formik'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import {
  FormFieldTypeDTO,
  FormFieldVariantDTO,
  FormTemplateDTO,
  PreviewConfigurationExperienceFormFieldDTO,
} from '@ketch-com/figurehead'
import { FormTemplateUpsertContext } from 'pages/consentAndRights/forms/templates/upsert/context/FormTemplateUpsertContextProvider'

import { getPreviewConfigurationExperienceFormFieldDTOfromFormFieldDTO } from '../utils'
import { ActionSheetItem, Button, Checkbox, DropListButton, Icon, ListItemText, theme } from '@ketch-com/deck'
import { InputTypeBadge } from 'pages/consentAndRights/forms/templates/upsert/components/TemplateSectionConfigSidePanel/components/FormTemplateSection/components/FormFields/components/InputTypeBadge'

const disabledInputNames = ['firstName', 'lastName', 'email']

type Props = {
  remove: <T>(index: number) => T | undefined
  provided: DraggableProvided
  snapshot: DraggableStateSnapshot
  option: PreviewConfigurationExperienceFormFieldDTO
  formFieldId?: string
  formFieldIndex: number
}

export const DraggableFormField: React.FC<Props> = ({
  formFieldId,
  remove,
  provided,
  snapshot,
  option,
  formFieldIndex,
}) => {
  const { setFieldValue, setFieldTouched, values } = useFormikContext<FormTemplateDTO>()
  const isAlwaysRequiredField = disabledInputNames.includes(option?.name || '')
  const { activeSectionIndex, selectedFieldCodeForAddition, formFields } = useContext(FormTemplateUpsertContext)

  const selectedFormField = formFields.find(cv => cv.id === option?.id)

  const formFieldsInAllSections = values?.sections?.reduce?.(
    (acc, formTemplateSection) => [...acc, ...(formTemplateSection?.formFields || [])],
    [] as PreviewConfigurationExperienceFormFieldDTO[],
  )

  const droplistFormFieldItems = formFields
    .filter(field => !formFieldsInAllSections?.find?.(previewFormField => previewFormField.id === field.id))
    .filter(field => field.code !== selectedFieldCodeForAddition)

  const isOptionDisabledInput = disabledInputNames.includes(option?.name || '')

  const inputTypeBadgeTitle =
    option?.type === FormFieldTypeDTO.FileFormFieldType
      ? 'File'
      : option?.type === FormFieldTypeDTO.CheckboxFormFieldType
      ? 'Checkbox'
      : option?.type === FormFieldTypeDTO.TextFormFieldType &&
        option?.variant === FormFieldVariantDTO.InputFormFieldVariant
      ? 'Text'
      : option?.type === FormFieldTypeDTO.TextFormFieldType &&
        option?.variant === FormFieldVariantDTO.TextareaFormFieldVariant
      ? 'Text Area'
      : 'Dropdown'

  return (
    <Box
      ref={provided.innerRef}
      key={formFieldId}
      display="flex"
      flexDirection="column"
      sx={{
        backgroundColor: 'superLightGrey.main',
        borderRadius: 2.75,
        p: 1.5,
        mb: 2,
        outline: ({ palette }) => (snapshot.isDragging ? `3px dashed ${palette.fadedGrey.main}` : undefined),
      }}
      style={{
        ...provided.draggableProps.style,
      }}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      {/* Row 1: Drag'n'Drop Indicator Icon, Option Value Input, Delete Item Button */}

      <Box display="flex" mb={2} alignItems="center">
        <Box display="flex" alignItems="center" flex={1}>
          {/* Left */}

          <Box display="flex" mr={2} pt={0.5}>
            <Icon name="ODragHandle" iconColor={theme.palette.fadedDarkGrey.main} />
          </Box>

          {/* Center */}

          <Box display="flex" gap={1} flex={1} alignItems="center">
            {isOptionDisabledInput && (
              <Typography variant="label" color="initial">
                {option.label}
              </Typography>
            )}

            {!isOptionDisabledInput && (
              <DropListButton
                sx={{
                  width: '300px',
                }}
                disableClearable={selectedFormField !== null}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={option => option?.name || option?.title || ''}
                options={droplistFormFieldItems}
                onChange={(_, value) =>
                  value &&
                  setFieldValue(`sections.${activeSectionIndex}.formFields[${formFieldIndex}]`, {
                    ...getPreviewConfigurationExperienceFormFieldDTOfromFormFieldDTO(value),
                    required: !!option.required,
                  })
                }
                renderOption={(props, option, { selected }) => {
                  return (
                    <ActionSheetItem selected={selected} {...props}>
                      <ListItemText selected={selected}>{option.name}</ListItemText>
                    </ActionSheetItem>
                  )
                }}
                value={selectedFormField}
                placeholder="Select Field"
                size={'small'}
              />
            )}
          </Box>

          {/* Right */}

          <Box ml={1} display="flex" alignItems="center">
            <Button
              variant="iconCustomRounded"
              color="tertiary"
              disabled={isAlwaysRequiredField}
              onClick={() => {
                if (isAlwaysRequiredField) return
                remove(formFieldIndex)
              }}
            >
              <Icon name="FBin" />
            </Button>
          </Box>
        </Box>
      </Box>

      {/* Row 2: Required Checkbox */}

      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" padding="0px 3px" gap={'1px'}>
          <Checkbox
            disabled={isAlwaysRequiredField}
            name="Required"
            title="Required"
            checked={!!values?.sections?.[activeSectionIndex as number]?.formFields?.[formFieldIndex]?.required}
            onChange={(e, checked) => {
              const setFieldValueTarget = `sections.[${activeSectionIndex}].formFields[${formFieldIndex}].required`
              setFieldValue(setFieldValueTarget, checked)
              setFieldTouched(setFieldValueTarget, true)
            }}
          />
          <Typography
            variant={
              !!values?.sections?.[activeSectionIndex as number]?.formFields?.[formFieldIndex]?.required
                ? 'label'
                : 'body'
            }
          >
            Required
          </Typography>
        </Box>

        <InputTypeBadge title={inputTypeBadgeTitle} />
      </Box>
    </Box>
  )
}
