import { Box } from '@mui/material'
import { ErrorMessage, useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FormSection } from 'components/ui-layouts/formLayout/FormSection'
import { GetAssetHierarchiesResponseBodyDTO, AssetSummaryDTO } from '@ketch-com/figurehead'
import {
  AttributeConfiguration,
  DatasetCollection,
} from 'pages/assetManager/dsrConfig/upsert/components/DatasetConfiguration/components'
import { DsrConfigFormData } from 'pages/assetManager/dsrConfig/interfaces'
import { Text } from 'components/ui-kit/typography/Text'
import pluralize from 'pluralize'
import { getAssetType } from 'pages/assetManager/utils'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { DsrConfigParams } from 'utils/routing/routesV2/systems'

type Props = {
  assetsHierarchies: GetAssetHierarchiesResponseBodyDTO[]
  assetsSummary: AssetSummaryDTO[]
  identitySpaces: IdentitySpaceDTO[]
}

export const DatasetConfigurationContainer: React.FC<Props> = ({
  assetsHierarchies,
  assetsSummary,
  identitySpaces,
}) => {
  const providerCode = assetsSummary?.[0]?.asset?.connection?.providerCode || ''
  const datasetType = getAssetType(providerCode, 2)
  const attributeType = getAssetType(providerCode, 3)
  const { id: primaryDatasetId } = useParams<DsrConfigParams>()
  const { values } = useFormikContext<DsrConfigFormData>()
  const { relatedDatasets } = values
  const [includedDatasetIds, setIncludedDatasetIds] = useState([primaryDatasetId])

  useEffect(() => {
    const includedRelatedDatasetIds: string[] = [primaryDatasetId!]
    for (const [datasetId, datasetValue] of Object.entries(relatedDatasets)) {
      if (datasetValue.included) includedRelatedDatasetIds.push(datasetId)
    }
    setIncludedDatasetIds(includedRelatedDatasetIds)
  }, [setIncludedDatasetIds, relatedDatasets, primaryDatasetId])
  return (
    <FormSection
      title={`Configure ${datasetType} ${pluralize.plural(attributeType)}`}
      subTitle={`Browse and select actions to be applied to ${pluralize.plural(attributeType)} in this ${datasetType}.`}
    >
      <Box mb={2}>
        <ErrorMessage name="actions">
          {msg => {
            switch (typeof msg) {
              case 'string':
                return (
                  <Text fontWeight={400} fontSize={12} color="chileanFire">
                    {msg}
                  </Text>
                )
              case 'object':
                // updateValue in tables has errored
                return (
                  <Text fontWeight={400} fontSize={12} color="chileanFire">
                    {`Please check update value in the included ${pluralize(
                      datasetType.toLowerCase(),
                      includedDatasetIds?.length || 0,
                    )}.`}
                  </Text>
                )

              default:
                return null
            }
          }}
        </ErrorMessage>
      </Box>
      {includedDatasetIds.length > 1 ? (
        <DatasetCollection
          includedDatasetIds={includedDatasetIds}
          assetsHierarchies={assetsHierarchies}
          assetsSummary={assetsSummary}
          identitySpaces={identitySpaces}
        />
      ) : (
        <AttributeConfiguration
          assetHierarchies={assetsHierarchies[0]} // assetsHierarchies[0] is primary dataset
          identitySpaces={identitySpaces}
        />
      )}
    </FormSection>
  )
}
