import React from 'react'
import { Button, PopUp, DataGrid, TableCell, Icon, theme, EmptyState, IconDictionary } from '@ketch-com/deck'
import { useRightInvocationsPaginatedV2 } from 'api/rightInvocationsV2/queries/useRightInvocationsPaginatedV2'
import { RightInvocationTypeDTO, RightInvocationDTO } from '@ketch-com/figurehead'
import { MaybeNull } from 'interfaces/common'
import { Box, Tooltip, Typography } from '@mui/material'
import { RightListStatusCellRendererV2 } from 'pages/orchestration/rightsQueue/listV2/components/RightListStatusCellRendererV2'
import { getTooltipContent } from 'utils/messages/getTooltipContent'
import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { joinNonEmpty } from 'utils/helpers/joinNonEmpty'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import {
  FetchRightInvocationsFilterKeys,
  FetchRightInvocationsOrderByKeys,
} from 'interfaces/rightInvocationsV2/FetchRightInvocationsFilterKeys'
import { DataRendererWithTooltip } from 'pages/orchestration/rightsQueue/listV2/components/RightsQueueListTable/hooks/useRightsQueueListTableDataGridColumns'
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'

export type Props = {
  rightInvocation: MaybeNull<RightInvocationDTO>
  onClose: () => void
}

export const RightDuplicatesModal: React.FC<Props> = ({ onClose, rightInvocation }) => {
  const navigate = useNavigate()
  const { data: duplicateRightInvocations, isLoading: isLoadingDuplicateRightInvocations } =
    useRightInvocationsPaginatedV2({
      itemsPerPage: 500,
      params: {
        type: RightInvocationTypeDTO.RightTypeDuplicateRightInvocationType,
        query: rightInvocation?.user?.email,
      },
    })

  const rightInvocationsColumns: GridColDef<RightInvocationDTO>[] = [
    {
      align: 'left',
      field: FetchRightInvocationsFilterKeys.statuses,
      headerAlign: 'left',
      headerName: 'Request Status',
      sortable: true,
      minWidth: 160,
      renderCell: ({ row: duplicateRightInvocation }: GridRenderCellParams<RightInvocationDTO>) => (
        <TableCell>
          <Box display="flex" alignItems="center">
            <RightListStatusCellRendererV2 rightInvocation={duplicateRightInvocation} />
            {duplicateRightInvocation.hasIssues && (
              <Tooltip title={getTooltipContent(ApplicationEntity.RIGHTS_QUEUE)}>
                <Box>
                  <Icon iconColor={theme.palette.chileanFire.main} name="FImportant" fontSize="small" />
                </Box>
              </Tooltip>
            )}
          </Box>
        </TableCell>
      ),
    },
    {
      align: 'left',
      field: 'subjectName',
      flex: 1,
      minWidth: 140,
      headerAlign: 'left',
      headerName: 'Subject Name',
      sortable: false,
      renderCell: ({ row: duplicateRightInvocation }: GridRenderCellParams<RightInvocationDTO>) => {
        const { user } = duplicateRightInvocation
        const subjectName = joinNonEmpty([user?.first, user?.last])
        return <TableCell>{user ? <Typography>{subjectName}</Typography> : <EmptyValueRenderer />}</TableCell>
      },
    },
    {
      align: 'left',
      field: 'requestCode',
      headerAlign: 'left',
      flex: 1,
      headerName: 'Request',
      minWidth: 140,
      sortable: false,
      renderCell: ({ row: duplicateRightInvocation }: GridRenderCellParams<RightInvocationDTO>) => {
        return <DataRendererWithTooltip rightInvocation={duplicateRightInvocation} />
      },
    },
    {
      align: 'left',
      field: 'workflow',
      headerAlign: 'left',
      flex: 1,
      minWidth: 140,
      headerName: 'Workflow',
      sortable: false,
      renderCell: ({ row: duplicateRightInvocation }: GridRenderCellParams<RightInvocationDTO>) => {
        const isAppealed = !!duplicateRightInvocation?.appeal?.isAppealed
        const workflowExecution = isAppealed
          ? duplicateRightInvocation?.appealWorkflowExecution
          : duplicateRightInvocation?.workflowExecution
        let workflowDefinitionName = workflowExecution?.workflowDefinitionName
        if (duplicateRightInvocation?.rightCode && duplicateRightInvocation.rightCode.startsWith('OT Workflow')) {
          workflowDefinitionName = duplicateRightInvocation?.rightCode
        }

        return workflowDefinitionName ? (
          <TableCell>
            <Typography title={workflowDefinitionName} variant="body">
              {workflowDefinitionName}
            </Typography>
          </TableCell>
        ) : (
          <TableCell>
            <EmptyValueRenderer />
          </TableCell>
        )
      },
    },
    {
      align: 'left',
      field: FetchRightInvocationsOrderByKeys.invoked_at,
      headerAlign: 'left',
      flex: 1,
      minWidth: 120,
      headerName: 'Invoked Date',
      sortable: false,
      renderCell: ({ row: duplicateRightInvocation }: GridRenderCellParams<RightInvocationDTO>) => {
        return (
          <TableCell>
            <FormattedDateRenderer date={duplicateRightInvocation?.invokedAt} format="MMM DD YYYY" />
          </TableCell>
        )
      },
    },
    {
      align: 'right',
      field: 'id',
      headerAlign: 'left',
      flex: 1,
      minWidth: 100,
      headerName: '',
      sortable: false,
      renderCell: ({ row: duplicateRightInvocation }: GridRenderCellParams<RightInvocationDTO>) => {
        return (
          <TableCell>
            {duplicateRightInvocation.id !== rightInvocation?.id ? (
              <Button
                color="tertiary"
                size="small"
                onClick={() => {
                  navigate(
                    RoutesManager.orchestration.rightsQueue.view.root.getURL({
                      id: duplicateRightInvocation.id,
                    }),
                  )
                }}
              >
                View
              </Button>
            ) : null}
          </TableCell>
        )
      },
    },
  ]

  return (
    <PopUp
      isLoading={isLoadingDuplicateRightInvocations}
      title="Duplicate Requests"
      subTitle="Similar requests from the same email that were submitted within a period of less than 365 days"
      variant="modal"
      popUpWidth="870px"
      onClose={onClose}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" onClick={onClose}>
            Close
          </Button>
        </>
      }
    >
      {!isLoadingDuplicateRightInvocations && Boolean(!duplicateRightInvocations.length) ? (
        <EmptyState
          size="small"
          colorTheme={theme.palette.Text.Secondary}
          iconName={IconDictionary.FCopy}
          title="Unable to find duplicate requests"
          subTitle="There are no similar requests from the same email that were submitted within a period of less than 365 days."
        />
      ) : null}

      {!isLoadingDuplicateRightInvocations && Boolean(duplicateRightInvocations.length) ? (
        <Box height={225}>
          <DataGrid
            getRowHeight={() => 'auto'}
            getRowId={row => row?.id}
            rows={duplicateRightInvocations}
            columns={rightInvocationsColumns}
            checkboxSelection={false}
            hideFooter={false}
            hideFooterPagination={false}
            hideFooterRowCount
            disableColumnMenu
            disableColumnPinning
            disableColumnReorder
            disableColumnResize
            disableChildrenSorting
            disableRowSelectionOnClick
            disableRowHoverStates
            loading={isLoadingDuplicateRightInvocations}
            columnMenuProps={
              {
                slots: {
                  columnMenuFilterItem: null,
                  columnMenuAggregationItem: null,
                  columnMenuGroupingItem: null,
                },
              } as any
            }
          />
        </Box>
      ) : null}
    </PopUp>
  )
}
