import { ResidencyWithCountryDetails } from 'pages/dataSystems/DataSystem/components/DataResidencyWithEdit'
import { DataMapRegionDTO } from '@ketch-com/figurehead'
import {
  ActionSheetItem,
  ActionSheetWrapper,
  Button,
  Chip,
  DropListButton,
  Icon,
  ListItemText,
  TextInput,
  theme,
} from '@ketch-com/deck'
import { Card as MuiCard, Grid, Typography, Box } from '@mui/material'
import { Logger } from 'utils/Logger'
import {
  RetentionPeriodType,
  RetentionPeriodTypeOptions,
  securityMeasureOptions,
  SecurityMeasures,
} from 'pages/dataSystems/DataSystem/types'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { useMemo, useState } from 'react'

interface ResidencyCardProps {
  values: ResidencyWithCountryDetails
}

export const ResidencyCard = ({ values }: ResidencyCardProps) => {
  return (
    <MuiCard
      sx={{
        padding: '16px',
        width: '240px',
        boxShadow: 'none',
        borderRadius: '12px',
        border: `1px solid ${theme.palette.Common.Divider}`,
      }}
    >
      <Grid container>
        <Grid item xs={12} paddingBottom={2}>
          <Typography variant="h4">{values.countryDetails.name}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="smallLabel" color="textSecondary">
            Security Measures
          </Typography>
        </Grid>
        {Array.isArray(values?.security) && values.security.length > 0 ? (
          values.security.map(security => (
            <Grid item xs={12} key={security}>
              <Typography variant="body">{securityMeasureOptions[security as SecurityMeasures].title}</Typography>
            </Grid>
          ))
        ) : (
          <EmptyValueRenderer />
        )}
        <Grid item xs={12} paddingTop={2}>
          <Typography variant="smallLabel" color="textSecondary">
            Physical Retention
          </Typography>
        </Grid>
        {values.retentionPeriod && values.retentionPeriodType ? (
          <Grid item xs={12} paddingBottom={1}>
            <Typography variant="body">
              {`${values.retentionPeriod} ${
                RetentionPeriodTypeOptions[parseInt(values.retentionPeriodType) as RetentionPeriodType].title
              }`}
            </Typography>
          </Grid>
        ) : (
          <EmptyValueRenderer paddingBottom={1} />
        )}
      </Grid>
    </MuiCard>
  )
}

type EditResidencyProps = {
  deleteResidency: () => void
  regions: DataMapRegionDTO[]
  residencyDetails: ResidencyWithCountryDetails
  setResidencyDetails: (values: ResidencyWithCountryDetails) => void
}

export const EditResidency = ({
  deleteResidency,
  regions,
  residencyDetails,
  setResidencyDetails,
}: EditResidencyProps) => {
  const [retentionPeriod, setRetentionPeriod] = useState<string | undefined>(
    residencyDetails.retentionPeriodType || '0',
  )
  const [rentionPeriodMenuAnchor, setRetentionPeriodMenuAnchor] = useState<HTMLElement | null>(null)

  const handleOpenRentionPeriodMenu = (event: React.MouseEvent<HTMLElement>) => {
    setRetentionPeriodMenuAnchor(event.currentTarget)
  }

  const handleCloseRetentionPeriodMenu = () => {
    setRetentionPeriodMenuAnchor(null)
  }

  const retentionPeriodName = useMemo(
    () =>
      retentionPeriod
        ? RetentionPeriodTypeOptions[parseInt(retentionPeriod) as keyof typeof RetentionPeriodTypeOptions].title
        : undefined,
    [retentionPeriod],
  )

  function onChange(field: string, newValue: DataMapRegionDTO | number | string | null) {
    if (!newValue) {
      Logger.error('[DataMap System Edit] Error selecting country')
      return
    }
    if (field === 'country' && newValue !== null) {
      setResidencyDetails({
        ...residencyDetails,
        countryDetails: newValue as DataMapRegionDTO,
        country: (newValue as DataMapRegionDTO).code,
      })
    } else if (field === 'security') {
      const doesExist = residencyDetails.security?.findIndex(i => i === parseInt(newValue as string))
      residencyDetails.security = residencyDetails.security || []
      if (doesExist >= 0) {
        setResidencyDetails({
          ...residencyDetails,
          security: [
            ...residencyDetails.security.slice(0, doesExist),
            ...residencyDetails.security.slice(doesExist + 1),
          ],
        })
      } else {
        setResidencyDetails({ ...residencyDetails, security: [...residencyDetails.security, +newValue] })
      }
    } else if (field === 'retentionPeriod') {
      setResidencyDetails({ ...residencyDetails, retentionPeriod: +newValue })
    } else if (field === 'retentionPeriodType') {
      setResidencyDetails({ ...residencyDetails, retentionPeriodType: newValue as string })
    }
  }

  return (
    <MuiCard
      sx={{
        padding: '16px',
        width: '340px',
        boxShadow: 'none',
        borderRadius: '12px',
        border: `1px solid ${theme.palette.Common.Divider}`,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end" width="100%">
            <Button variant="icon" color="ghost" onClick={deleteResidency}>
              <Icon name="OCross" />
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <DropListButton
            disableClearable
            options={regions}
            isOptionEqualToValue={(option, value) => option.code === value.code}
            renderOption={(props, option, { selected }) => (
              <ActionSheetItem key={option.code} selected={selected} {...props}>
                <ListItemText selected={selected}>{option.name}</ListItemText>
              </ActionSheetItem>
            )}
            renderInput={params => (
              <TextInput
                {...params}
                InputProps={{
                  ...params.InputProps,
                  placeholder: 'Select Country',
                }}
                inputProps={{
                  ...params.inputProps,
                  style: { color: theme.palette.Text.Secondary },
                }}
              />
            )}
            getOptionLabel={option => option.name || ''}
            label="Residency"
            placeholder="Select Country"
            size="small"
            width="100%"
            value={residencyDetails.countryDetails}
            onChange={(_, v) => onChange('country', v)}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography component="div" marginBottom={1} variant="smallLabel">
            Security Measures
          </Typography>
          {Object.keys(securityMeasureOptions).map(key => {
            return (
              <Chip
                key={key}
                clickable
                selectable
                isSelected={residencyDetails.security.some(i => i === +key)}
                onClick={() => onChange('security', key)}
                label={securityMeasureOptions[key as unknown as SecurityMeasures].title}
                sx={{ margin: '5px' }}
              />
            )
          })}
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Typography component="div" marginBottom={1} variant="smallLabel">
                Physical Retention
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextInput
                placeholder="Number"
                type="number"
                sx={{
                  maxWidth: '95px',
                  width: '95px',
                  height: '32px',
                  maxHeight: '32px',
                  minHeight: '32px',
                  '& .MuiInputBase-input': {
                    minWidth: '75px',
                    maxWidth: '75px',
                    width: '75px',
                  },
                }}
                onChange={e => onChange('retentionPeriod', e.currentTarget.value)}
                value={residencyDetails.retentionPeriod || ''}
                inputProps={{ min: '0' }}
              />
            </Grid>
            <Grid item xs={4}>
              <Button
                size="small"
                color="tertiary"
                endIcon={<Icon name="OArrowCDown" />}
                onClick={handleOpenRentionPeriodMenu}
              >
                {retentionPeriodName}
              </Button>
              <ActionSheetWrapper
                anchorEl={rentionPeriodMenuAnchor}
                open={!!rentionPeriodMenuAnchor}
                onClose={handleCloseRetentionPeriodMenu}
              >
                {Object.values(RetentionPeriodTypeOptions).map(item => (
                  <ActionSheetItem
                    key={item.value}
                    onClick={() => {
                      setRetentionPeriod(item.value)
                      onChange('retentionPeriodType', item.value)
                      handleCloseRetentionPeriodMenu()
                    }}
                  >
                    {item.title}
                  </ActionSheetItem>
                ))}
              </ActionSheetWrapper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MuiCard>
  )
}
