import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { AssessmentDocumentDTO } from '@ketch-com/figurehead'
import { AssessmentDocument } from '@ketch-com/windlass/dist/assessments/externalUser'

type Params = APIRequestParams<{
  data: AssessmentDocument
  isV2FeatureFlagEnabled?: boolean
}>

export type CombinedAssessmentCreationResponse = (AssessmentDocumentDTO | AssessmentDocument) &
  Partial<AssessmentDocumentDTO & AssessmentDocument>

export const createAssessment = ({ data, isV2FeatureFlagEnabled }: Params) => {
  const entityUrl = isV2FeatureFlagEnabled ? `/api/risk-review/resources/assessments` : `/api/resources/assessments`
  return API.post<CombinedAssessmentCreationResponse>(
    formatRequestString({
      entityUrl,
    }),
    data,
  )
}
