import { Box, Typography, styled } from '@mui/material'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'
import React, { useContext } from 'react'
import { BuilderContext } from '../../../Builder'
import { getIn } from 'formik'
import { Icon } from '@ketch-com/deck'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { BUILDER_CONSTANTS, BuilderImageVariant } from '../../../utils/enums'
import { clamp } from 'lodash'
import { BannerModalLogoPosition } from '@ketch-sdk/ketch-types'
import { ImageContainer } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/components/preference/components/ImageContainer'

const HeaderBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '8px',
  padding: '24px',
  minHeight: '87px',
})

const CloseButtonBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 30,
  height: 30,
})

export const ModalHeader: React.FC = () => {
  const { themeConfig, experienceConfig, isTheme } = useContext(BuilderContext)

  // Theme
  const headerBackgroundColor = getIn(themeConfig, themeUpsertFormKeys.modalHeaderBackgroundColor)
  const headerTextColor = getIn(themeConfig, themeUpsertFormKeys.modalHeaderTitleColor)
  const closeButtonBackgroundColor = getIn(themeConfig, themeUpsertFormKeys.modalHeaderReturnButtonFillColor)
  const closeButtonIconColor = getIn(themeConfig, themeUpsertFormKeys.modalHeaderReturnButtonIconColor)
  const closeButtonBorderRadius =
    clamp(
      getIn(themeConfig, themeUpsertFormKeys.modalHeaderReturnButtonCornerRadius),
      BUILDER_CONSTANTS.MIN_BORER_RADIUS,
      BUILDER_CONSTANTS.MAX_BORDER_RADIUS,
    ) + 'px'
  const borderRadius =
    clamp(
      getIn(themeConfig, themeUpsertFormKeys.modalContainerCornerRadius),
      BUILDER_CONSTANTS.MIN_BORER_RADIUS,
      BUILDER_CONSTANTS.MAX_BORDER_RADIUS,
    ) + 'px'
  const logoPosition = getIn(themeConfig, themeUpsertFormKeys.modalHeaderLogoPosition) as BannerModalLogoPosition
  const logoUrl = getIn(themeConfig, themeUpsertFormKeys.modalHeaderLogoUrl)
  const isVertical = logoPosition === BannerModalLogoPosition.Top
  const showLogo = isTheme || logoUrl

  // Experience
  const headerText = getIn(experienceConfig, experienceUpsertFormKeys.modalHeaderTitle)
  const showCloseButton = getIn(experienceConfig, experienceUpsertFormKeys.modalHeaderCloseButtonVisible)

  return (
    <HeaderBox
      sx={{
        background: headerBackgroundColor,
        borderTopLeftRadius: borderRadius,
        borderTopRightRadius: borderRadius,
      }}
    >
      <Box
        display="flex"
        flexDirection={isVertical ? 'column' : 'row'}
        gap={isVertical ? '4px' : '8px'}
        alignItems={isVertical ? 'flex-start' : 'center'}
      >
        {showLogo && <ImageContainer variant={BuilderImageVariant.BannerModal} imageUrl={logoUrl} fitContent />}
        <Typography
          fontSize={26}
          fontWeight={700}
          lineHeight={'39px'}
          color={headerTextColor}
          sx={{ wordWrap: 'break-word', maxWidth: '600px' }}
        >
          {headerText}
        </Typography>
      </Box>
      {showCloseButton && (
        <CloseButtonBox
          sx={{
            backgroundColor: closeButtonBackgroundColor,
            borderRadius: closeButtonBorderRadius,
          }}
        >
          <Icon name={'OCross'} iconColor={closeButtonIconColor} sx={{ borderRadius: closeButtonBorderRadius }} />
        </CloseButtonBox>
      )}
    </HeaderBox>
  )
}
