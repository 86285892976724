import React, { useCallback, useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { getFullNameUserShortDTO } from 'utils/helpers/getFullNameUserShortDTO'
import { useUser } from 'api/users/queries/useUser'
import { Button, Icon, theme } from '@ketch-com/deck'
import { useIsPermitted } from 'utils/hooks'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useExternalAssessmentResponse } from '../context/ExternalAssessmentResponseContext'
import { useFetchPasswordlessUsers } from 'api/assessments/queries/useFetchPasswordlessUsers'

export const ExternalCompletedQuestionActions: React.FC<{}> = () => {
  const { block, isCompleted, isCompletedButStillWorking, uncomplete, refetchAssessments } =
    useExternalAssessmentResponse()
  const handleUncomplete = useCallback(async () => {
    await uncomplete()
    refetchAssessments()
  }, [uncomplete, refetchAssessments])

  const { isPermitted } = useIsPermitted()
  const isPermittedToRespond = isPermitted([PERMISSIONS.ASSESSMENT_RESPOND])

  const { data: completedBy } = useUser({
    params: { userId: block.completedBy || '' },
    enabled: !!block.completedBy,
  })

  const { data: passwordlessUsers } = useFetchPasswordlessUsers({})
  const completedByName = useMemo(() => {
    if (block.completedBy?.startsWith('email')) {
      if (passwordlessUsers?.users) {
        const user = passwordlessUsers.users.find(user => user.authId === block.completedBy)
        if (user) {
          return user.email
        }
      }
    } else {
      if (completedBy) {
        return getFullNameUserShortDTO(completedBy)
      }
    }
    return 'Unknown User'
  }, [block.completedBy, completedBy, passwordlessUsers?.users])

  const helperText = isCompleted ? (
    <Typography variant="smallBody" color="darkDuskFaded.main" component="div" mt={0.25}>
      Completed by&nbsp;
      <Typography variant="smallLabel">{completedByName}</Typography>
    </Typography>
  ) : null

  return (
    <Box display="flex" gap={2} alignItems="center">
      {helperText}
      {isCompleted ? (
        <Box display="inline-flex" alignItems="center" gap={0.75}>
          <Icon name="FCheckRound" iconColor={theme.palette.greenHaze.main} />
          <Typography color="greenHaze.main" variant="label">
            Completed
          </Typography>
          {isPermittedToRespond && isCompletedButStillWorking ? (
            <Button sx={{ ml: 1 }} color="tertiary" onClick={handleUncomplete}>
              Edit
            </Button>
          ) : null}
        </Box>
      ) : null}
    </Box>
  )
}
