export enum ExperienceType {
  CONSENT = 1,
  PREFERENCE = 2,
}

export const EXPERIENCE_TYPE_NAMES = {
  [ExperienceType.CONSENT]: 'Banner & Modal',
  [ExperienceType.PREFERENCE]: 'Preference Management',
}

export const EXPERIENCE_TYPE_OPTIONS = [
  {
    id: ExperienceType.CONSENT,
    name: EXPERIENCE_TYPE_NAMES[ExperienceType.CONSENT],
  },
  {
    id: ExperienceType.PREFERENCE,
    name: EXPERIENCE_TYPE_NAMES[ExperienceType.PREFERENCE],
  },
]
