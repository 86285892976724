import { groupBy, find } from 'lodash'
import { MaybeNull } from 'interfaces/common'
import { DeploymentPlanDTO } from 'interfaces/deploymentPlans-v2/deploymentPlan'
import { PolicyScopeDTO } from 'interfaces/policyScopes-v2/policyScope'
import { ExperienceType } from 'interfaces/experiences-v2/experienceType'
import {
  DeploymentPlanFormValues,
  DeploymentPlanFormSpecialValues,
} from 'pages/consentAndRights/deploymentPlans-v2/upsert/utils'

export enum DeploymentPlanFormStep {
  BASIC_DETAILS = 'Basic Details',
  EXPERIENCES = 'Experiences',
  DOCUMENTS = 'Documents',
}

const getPolicyScopeExperiencesReducer = ({
  deploymentPlan,
  duplicate,
}: {
  deploymentPlan: MaybeNull<DeploymentPlanDTO>
  duplicate: boolean
}) => {
  const existing = groupBy(deploymentPlan?.policyScopeExperiences, 'policyScopeCode')

  return (acc: {}, { code }: PolicyScopeDTO): DeploymentPlanFormValues['policyScopeExperiences'] => {
    if (duplicate) {
      const consent = find(existing[code], ({ experienceType }) => experienceType === ExperienceType.Consent)
      const preference = find(existing[code], ({ experienceType }) => experienceType === ExperienceType.Preference)

      return {
        ...acc,
        [code]: {
          [ExperienceType.Consent]: {
            experienceID: consent?.experienceID || DeploymentPlanFormSpecialValues.NO_EXPERIENCE,
            experienceDefault: consent ? consent.experienceDefault : '',
          },
          [ExperienceType.Preference]: {
            experienceID: preference?.experienceID || DeploymentPlanFormSpecialValues.NO_EXPERIENCE,
          },
        },
      }
    } else {
      return {
        ...acc,
        [code]: {
          [ExperienceType.Consent]: {
            experienceID: DeploymentPlanFormSpecialValues.NO_EXPERIENCE,
            experienceDefault: '',
          },
          [ExperienceType.Preference]: {
            experienceID: DeploymentPlanFormSpecialValues.NO_EXPERIENCE,
          },
        },
      }
    }
  }
}

export const getInitialValues = ({
  duplicate,
  policyScopes,
  deploymentPlan,
}: {
  duplicate: boolean
  policyScopes: PolicyScopeDTO[]
  deploymentPlan: MaybeNull<DeploymentPlanDTO>
}): DeploymentPlanFormValues => {
  const policyScopeExperiences = (policyScopes || []).reduce(
    getPolicyScopeExperiencesReducer({ duplicate, deploymentPlan }),
    {},
  )

  if (duplicate) {
    return {
      code: '',
      name: '',
      isOrchestrationOnly: false,
      privacyPolicyCode: deploymentPlan?.privacyPolicyCode || DeploymentPlanFormSpecialValues.NO_DOCUMENT,
      termsOfServiceCode: deploymentPlan?.privacyPolicyCode || DeploymentPlanFormSpecialValues.NO_DOCUMENT,
      themeID: deploymentPlan?.themeID || '',
      policyScopeExperiences,
    }
  }

  return {
    code: '',
    name: '',
    isOrchestrationOnly: false,
    privacyPolicyCode: '',
    termsOfServiceCode: '',
    themeID: '',
    policyScopeExperiences,
  }
}
