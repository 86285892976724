import { PutProcessingActivityDataSystemConsentStatusRequestBodyDTO } from '@ketch-com/figurehead'
import { API } from '../../../utils/api/api'
import { formatRequestString } from '../../../utils/helpers/api/queryString'

type Params = {
  formData: PutProcessingActivityDataSystemConsentStatusRequestBodyDTO
  activityId: string
  dataSystemId: string
  isV2FeatureFlagEnabled?: boolean
}

type Response = {}

export const updateProcessingActivityDataSystemConsentStatus = ({
  formData,
  activityId,
  dataSystemId,
  isV2FeatureFlagEnabled,
}: Params) => {
  const entityUrl = isV2FeatureFlagEnabled
    ? `/api/risk-review/processing-activities/${activityId}/data-systems/${dataSystemId}/consent-status`
    : `/api/processing-activities/${activityId}/data-systems/${dataSystemId}/consent-status`
  return API.put<Response>(
    formatRequestString({
      entityUrl,
    }),
    formData,
  )
}
