import React, { useCallback } from 'react'
import { Box, Typography } from '@mui/material'
import { AssessmentHighLevelDetails } from 'pages/externalPrivacyPortal/types'
import { AssessmentNameAndSubject } from 'pages/externalPrivacyPortal/common/component/AssessmentNameAndSubject'
import { Button, Chip, Icon, Spinner, Status, StatusVariant, theme } from '@ketch-com/deck'
import { ProgressIndicator } from 'pages/policyCenter/assessments/view/components/ProgressIndicator'
import {
  assessmentStatusTypeToCodeMap,
  statusStateToCodeMap,
  statusStyleByStatusCode,
} from 'pages/externalPrivacyPortal/overview/constants'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'

export interface AssessmentDetailsHeaderProps extends Omit<AssessmentHighLevelDetails, 'assessmentId'> {
  autoSaving: boolean
}

export const AssessmentDetailsHeader: React.FC<AssessmentDetailsHeaderProps> = ({
  assessmentName,
  autoSaving,
  dataSubjectIcon,
  dataSubjectCode,
  status,
  questionCount,
}) => {
  const percent = Math.round((questionCount.complete / questionCount.total) * 100)
  const statusStyleKey = assessmentStatusTypeToCodeMap[status]
  const navigate = useNavigate()
  const handleBackClick = useCallback(() => {
    navigate(RoutesManager.externalPrivacyPortal.overview.root.getURL())
  }, [navigate])
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      height="64px"
      px={3}
      py={1}
      bgcolor={theme.palette.Common.Container}
      borderBottom={`1px solid ${theme.palette.Common.Divider}`}
    >
      <Box display="flex" alignItems="center" gap={1}>
        <Button size="large" variant="icon" color="tertiary" onClick={handleBackClick}>
          <Icon name="OArrowCLeft" />
        </Button>
        <AssessmentNameAndSubject
          assessmentName={assessmentName}
          dataSubjectCode={dataSubjectCode}
          dataSubjectIcon={dataSubjectIcon}
          maxWidth="100%"
        />
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" width="30%">
        <Box display="flex" alignItems="center" gap={2}>
          <Status variant={StatusVariant.small} status={statusStateToCodeMap[statusStyleKey]} label={statusStyleKey} />
          {status !== 'Approved' ? (
            <ProgressIndicator style={statusStyleByStatusCode[statusStyleKey]} percent={percent} width={24} />
          ) : (
            <Icon name="FCheckRound" />
          )}
          <Box display="flex" flexDirection="column" marginRight={5}>
            <Typography>{percent}%</Typography>
            <Typography variant="footnote">{`${questionCount.complete}/${questionCount.total} Questions`}</Typography>
          </Box>
        </Box>
        <Box display="flex" gap={1.5} alignItems="center">
          <Chip
            size="small"
            label={<Typography variant="footnote">{autoSaving ? 'Saving...' : 'Auto-Saved'}</Typography>}
            icon={
              autoSaving ? <Spinner size="16px" /> : <Icon name="OCheck" iconColor={theme.palette.Text.Secondary} />
            }
          />
        </Box>
      </Box>
    </Box>
  )
}
