import { APIListRequestParams } from '../../common/utils'
import { API } from '../../../utils/api/api'
import { FetchProcessingActivityVersionsResponseBodyDTO } from '@ketch-com/figurehead'
import { formatRequestString } from '../../../utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  activityId?: string
  isV2FeatureFlagEnabled?: boolean
}>

export const fetchProcessingActivityVersions = ({
  activityId,
  query,
  includeMetadata,
  limit,
  start,
  isV2FeatureFlagEnabled,
}: Params) => {
  const entityUrl = isV2FeatureFlagEnabled
    ? `/api/risk-review/processing-activities/${activityId}/versions`
    : `/api/processing-activities/${activityId}/versions`
  return API.get<FetchProcessingActivityVersionsResponseBodyDTO>(
    formatRequestString({
      entityUrl,
      params: {
        filters: {
          start,
          limit,
          includeMetadata,
          ...(query && {
            query,
          }),
        },
      },
    }),
  )
}
