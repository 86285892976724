import { API } from '../../../utils/api/api'
import { formatRequestString } from '../../../utils/helpers/api/queryString'

type Params = {
  activityId: string
  isV2FeatureFlagEnabled?: boolean
}

export const deleteProcessingActivityAssessment = ({ activityId, isV2FeatureFlagEnabled }: Params) => {
  const entityUrl = isV2FeatureFlagEnabled
    ? `/api/risk-review/processing-activities/${activityId}/assessments`
    : `/api/processing-activities/${activityId}/assessments`
  return API.delete<{}>(
    formatRequestString({
      entityUrl,
    }),
  )
}
