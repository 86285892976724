import { useCallback, useState } from 'react'

export function useWidthRef(defaultWidth?: number): [number, (node: HTMLDivElement) => void] {
  const [width, setWidth] = useState(defaultWidth || 0)
  const widthRef = useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width)
    }
  }, [])

  return [width, widthRef]
}
