import { StatusState, theme } from '@ketch-com/deck'
import { AssessmentStatus } from '@ketch-com/windlass/dist/assessments/externalUser'
import { ProgressIndicatorStyle } from 'pages/policyCenter/assessments/view/components/ProgressIndicator'

interface AssessmentStatusFilter {
  code: string
  value: AssessmentStatus | string
}
export const StatusList: AssessmentStatusFilter[] = [
  { code: 'All', value: 'All' },
  { code: 'Complete', value: 'Complete' },
  { code: 'In Progress', value: AssessmentStatus.in_progress },
  { code: 'Review', value: AssessmentStatus.in_review },
  { code: 'Approved', value: AssessmentStatus.approved },
]

export const assessmentStatusTypeToCodeMap: Record<string, string> = {
  [AssessmentStatus.in_progress]: 'In Progress',
  [AssessmentStatus.in_review]: 'Review',
  [AssessmentStatus.approved]: 'Approved',
}

export const statusStateToCodeMap: Record<string, StatusState> = {
  Complete: StatusState['success'],
  'In Progress': StatusState['inProgress'],
  Review: StatusState['warning'],
  Approved: StatusState['finished'],
}

export const statusStyleByStatusCode: Record<string, ProgressIndicatorStyle> = {
  Complete: {
    bgcolor: 'transparent',
    fgcolor: theme.palette.Success.Primary,
    highlightColor: theme.palette.Highlight.Disabled,
  },
  'In Progress': {
    bgcolor: 'transparent',
    fgcolor: theme.palette.Action.PrimaryDark,
    highlightColor: theme.palette.Highlight.Disabled,
  },
  Review: {
    bgcolor: 'transparent',
    fgcolor: theme.palette.Warning.Primary,
    highlightColor: theme.palette.Highlight.Disabled,
  },
  Approved: {
    bgcolor: 'transparent',
    fgcolor: theme.palette.Warning.Primary,
    highlightColor: theme.palette.Highlight.Disabled,
  },
}
