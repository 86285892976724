import { useCallback, useMemo } from 'react'
import * as Yup from 'yup'
import { useCheckIsPolicyScopeCodeAvailable } from 'api/policyScopes/mutations/useCheckIsPolicyScopeCodeAvailable'
import { validationRegExp } from 'utils/constants/forms/validationRegExp'
import { useCodeValidationSchema } from 'utils/helpers/validators/useCodeValidationSchema'
import { PolicyScopeFormValues } from '../interfaces'
import { validationMessages } from 'utils/constants/forms/validationMessages'
import { FormikErrors, FormikProps } from 'formik'
import { PolicyScopeFormStep } from 'pages/policyCenter/policyScopes/upsert/utils/constants'

const stepFieldsMap: Record<PolicyScopeFormStep, (keyof PolicyScopeFormValues)[]> = {
  [PolicyScopeFormStep.DETAILS]: ['name', 'code', 'description'],
  [PolicyScopeFormStep.REGULATIONS]: ['regionCodes', 'regulationCodes'],
  [PolicyScopeFormStep.RIGHTS]: ['rightsFulfillment', 'timeToInvokeAppealInDays', 'timeToFulfillAppealInDays'],
  [PolicyScopeFormStep.PURPOSES]: ['legalBases', 'requiresDisplay'],
}

export const useValidationSchema = ({ isEditMode }: { isEditMode?: boolean } = {}) => {
  const { mutateAsync: handleCheckIsPolicyScopeCodeAvailable } = useCheckIsPolicyScopeCodeAvailable()
  const codeValidationSchema = useCodeValidationSchema({
    handler: value =>
      handleCheckIsPolicyScopeCodeAvailable({
        params: {
          code: value,
        },
      }),
  })

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        ...(!isEditMode && {
          code: codeValidationSchema,
        }),
        name: Yup.string().max(75, 'Name must not exceed 75 characters').required('Required'),
        regulationCodes: Yup.array().min(1, 'Please select at least one regulation').required('Required'),
        regionCodes: Yup.array().required('Required'),
        rightsFulfillment: Yup.array().of(
          Yup.object().shape({
            fulfillment: Yup.string().when('checked', {
              is: true,
              then: Yup.string()
                .matches(validationRegExp.NUMERIC_INTEGER_STRING, validationMessages.POSITIVE_INTEGER)
                .required('Required'),
            }),
          }),
        ),
        timeToInvokeAppealInDays: Yup.string()
          .matches(validationRegExp.NON_ZERO_NUMERIC_INTEGER_STRING, validationMessages.POSITIVE_INTEGER)
          .test('timeToInvokeAppealInDays', 'Required', function (value, context) {
            const rightsFulfillment = context?.parent?.rightsFulfillment || []
            const isRequired = (rightsFulfillment as PolicyScopeFormValues['rightsFulfillment']).some(
              ({ allowAppeal }) => allowAppeal,
            )
            const isValid = isRequired ? !!value : true

            return isValid
          }),
        timeToFulfillAppealInDays: Yup.string()
          .matches(validationRegExp.NON_ZERO_NUMERIC_INTEGER_STRING, validationMessages.POSITIVE_INTEGER)
          .test('timeToFulfillAppealInDays', 'Required', (value, context) => {
            const rightsFulfillment = context?.parent?.rightsFulfillment || []
            const isRequired = (rightsFulfillment as PolicyScopeFormValues['rightsFulfillment']).some(
              ({ allowAppeal }) => allowAppeal,
            )
            const isValid = isRequired ? !!value : true

            return isValid
          }),
        // Ensure a legal basis is selected for each purpose
        legalBases: Yup.lazy(obj => {
          const shape: Record<string, any> = {}

          if (obj) {
            Object.keys(obj).forEach(purposeId => {
              shape[purposeId] = Yup.string().required('Please select a legal basis')
            })
          }

          return Yup.object().shape(shape)
        }),
      }),
    [isEditMode, codeValidationSchema],
  )

  const isStepValid = useCallback(
    (
      formStep: PolicyScopeFormStep,
      errors: FormikErrors<PolicyScopeFormValues>,
      formikProps: FormikProps<PolicyScopeFormValues>,
    ) => {
      const { setFieldTouched } = formikProps
      const stepFields = stepFieldsMap[formStep] || []
      let stepValid = true

      stepFields.forEach(stepField => {
        if (!!errors[stepField]) {
          // Set fields to touched
          if (stepField === 'rightsFulfillment' && Array.isArray(errors.rightsFulfillment)) {
            errors.rightsFulfillment.forEach((rightsFulfillment, idx) => {
              Object.keys(rightsFulfillment).forEach(errorKey => {
                setFieldTouched(`rightsFulfillment[${idx}].${errorKey}`, true)
              })
            })
          } else if (stepField === 'legalBases' && errors.legalBases) {
            Object.keys(errors.legalBases).forEach(purposeCode => {
              setFieldTouched(`legalBases.${purposeCode}`, true)
            })
          } else {
            setFieldTouched(stepField, true)
          }
          stepValid = false
        }
      })

      return stepValid
    },
    [],
  )

  return { validationSchema, isStepValid }
}
