import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { PasswordLessUsers } from '@ketch-com/harbormaster/dist/authentication/user'

export const fetchPasswordlessUsers = () => {
  const entityUrl = '/api/passwordless/users'
  return API.get<PasswordLessUsers>(
    formatRequestString({
      entityUrl,
    }),
  )
}
