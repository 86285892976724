import React, { useContext } from 'react'
import { Box, Typography, Tooltip } from '@mui/material'
import { FormTemplateSectionDTO } from '@ketch-com/figurehead'
import { FormFieldPreviewGrid } from 'pages/consentAndRights/forms/templates/upsert/components/TemplateSections/components/DraggableFormTemplateSectionItem/components'
import { SectionDescription } from '../../components'
import { FormTemplateUpsertContext } from 'pages/consentAndRights/forms/templates/upsert/context/FormTemplateUpsertContextProvider'
import { Icon, Chip, IconDictionary } from '@ketch-com/deck'

type Props = {
  formTemplateSection: FormTemplateSectionDTO
}

export const FormTemplateSection: React.FC<Props> = ({ formTemplateSection }) => {
  const { dataSubjectTypes } = useContext(FormTemplateUpsertContext)
  const isDescriptionEmpty =
    !formTemplateSection?.description?.length || formTemplateSection.description === '<p><br></p>'

  const selectedDataSubjectTypes = formTemplateSection?.conditions?.[0]?.values || []

  const sectionDataSubjectTypesDetails = dataSubjectTypes?.filter(dataSubjectType =>
    selectedDataSubjectTypes.includes(dataSubjectType.code || ''),
  )

  return (
    <Box>
      {selectedDataSubjectTypes?.length ? (
        <Box display="flex" justifyContent="right">
          <Tooltip
            placement="bottom"
            title={
              <Box display="flex" flexDirection="column" alignItems="flex-start" gap={1}>
                This section is only visible to
                <Box display="flex" flexDirection="column" gap={1.5}>
                  {sectionDataSubjectTypesDetails?.map(subjectType => {
                    return (
                      <Box key={subjectType.code} display="flex" flexDirection="column">
                        <Typography variant="smallLabel">{subjectType.name}</Typography>
                        <Typography variant="smallBody">{subjectType.code}</Typography>
                      </Box>
                    )
                  })}
                </Box>
              </Box>
            }
          >
            <Box>
              <Chip
                icon={<Icon name={IconDictionary.OEyeShow} width={20} height={20} />}
                label={<Typography variant="label">{selectedDataSubjectTypes.length}</Typography>}
                size="small"
              />
            </Box>
          </Tooltip>
        </Box>
      ) : null}
      <Box display="flex" mb={4} overflow="hidden">
        <Box display="flex" alignItems="center" flex={1}>
          <Box display="flex" flex={1} width="100%" flexDirection="column">
            {/* Section Title */}
            {formTemplateSection?.title && (
              <Typography variant="h4" color="darkDusk.main" mb={!isDescriptionEmpty ? 0.5 : 2.5}>
                {formTemplateSection?.title}
              </Typography>
            )}
            {/* Section Description */}

            {!isDescriptionEmpty && <SectionDescription description={formTemplateSection?.description} />}
            {/* Section Form Fields */}
            <FormFieldPreviewGrid formFields={formTemplateSection?.formFields} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
