import React, { useContext, useState } from 'react'
import Box from '@mui/material/Box'

import { Formik } from 'formik'
import { FormikHelpers } from 'formik'
import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context'
import { useUpdateWorkflowExecutionStep } from 'api/workflowExecutions/mutations/useUpdateWorkflowExecutionStep'
import { showToast } from 'components/modals/AlertComponent'
import { useDownloadFile } from 'api/files/mutations/useDownloadFile'
import { FileInfoDTO } from '@ketch-com/figurehead'
import { convertFileInfoDTOArrayToFileInfoDTOLocalArray } from 'utils/api/serializeFileInfoDTO'
import { MAX_FILE_UPLOAD_SIZE_4_MB } from 'utils/constants/maxFileUploadSize'
import { Chip, FileExtensionIcon, Icon, IconDictionary, InfoRow } from '@ketch-com/deck'
import { FormDropZoneWithUpload } from 'components/form/FormDropZone/FormDropZoneWithUpload'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'

type MyFormValues = {
  resolutionAttachments: FileInfoDTO[]
}

export const Attachments: React.FC = () => {
  const [isEditMode, setIsEditMode] = useState(false)
  const { workflowStep, stepDetails, workflowExecutionId, rightInvocation, refetchStepDetails, canEdit } = useContext(
    RightInvocationWorkflowStepDetailViewContext,
  )

  const resolutionAttachments = stepDetails?.manualInterventionData?.resolutionAttachments || []

  const initialValues: MyFormValues = {
    resolutionAttachments: convertFileInfoDTOArrayToFileInfoDTOLocalArray(resolutionAttachments),
  }

  const { mutateAsync: updateWorkflowExecutionStep } = useUpdateWorkflowExecutionStep({
    onSuccess: async () => await refetchStepDetails(),
  })

  async function handleOnSubmit(values: MyFormValues, formikHelpers: FormikHelpers<MyFormValues>): Promise<any> {
    try {
      updateWorkflowExecutionStep({
        params: {
          workflowExecutionId,
          stepId: workflowStep?.ID,
          formData: {
            manualInterventionData: {
              resolutionAttachments: values.resolutionAttachments || '',
            },
          },
        },
      })
    } catch (error) {
      console.error(error)
    }
  }

  const { mutateAsync: handleDownloadFile } = useDownloadFile()

  const handleDownloadOnClick = async (attachment: FileInfoDTO) => {
    try {
      const { data } = await handleDownloadFile({
        params: {
          fileUrl: attachment?.downloadUrl || '',
        },
      })
      let a: HTMLAnchorElement | null = document.createElement('a')
      a.href = URL.createObjectURL(data)
      a.download = attachment?.name || ''
      a.click()
      a = null
    } catch (error) {
      showToast({
        content: `Unable to download file ${attachment.name}`,
        type: 'error',
      })
    }
  }

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={handleOnSubmit} validateOnMount>
      {({ values, setFieldValue, submitForm, isSubmitting }) => {
        return (
          <>
            <InfoRow
              title="Attachments"
              isEditable={canEdit}
              isEditing={isEditMode}
              isLoading={isSubmitting}
              onEditChange={() => {
                setIsEditMode(true)
              }}
              onAcceptChange={async () => {
                setIsEditMode(false)
                setFieldValue(
                  'resolutionAttachments',
                  convertFileInfoDTOArrayToFileInfoDTOLocalArray(resolutionAttachments),
                )
              }}
              onCancelChange={() => {
                setIsEditMode(false)
                setFieldValue(
                  'resolutionAttachments',
                  convertFileInfoDTOArrayToFileInfoDTOLocalArray(resolutionAttachments),
                )
              }}
              isEmpty={!resolutionAttachments.length}
            >
              {isEditMode ? (
                <Box display="flex" alignItems="center">
                  <FormDropZoneWithUpload
                    multiple
                    disableDownload
                    title="Upload Files"
                    required={false}
                    name="resolutionAttachments"
                    disabled={isSubmitting}
                    maxSize={MAX_FILE_UPLOAD_SIZE_4_MB}
                    onDelete={async file => refetchStepDetails()}
                    onUploadComplete={async files => refetchStepDetails()}
                    uploadContext={{
                      version: 'resolution_attachments',
                      folder: `${rightInvocation?.id}_${workflowStep?.ID}`,
                      bucket: '',
                    }}
                  />
                </Box>
              ) : !!resolutionAttachments.length ? (
                <Box display="flex" gap={2} flexWrap="wrap" maxWidth={490}>
                  {resolutionAttachments.map((file, index) => {
                    return (
                      <Chip
                        label={file?.name}
                        key={index}
                        size="small"
                        icon={<FileExtensionIcon iconVariant="filled" extension={file?.contentType} />}
                        onDelete={() => {
                          handleDownloadOnClick(file)
                        }}
                        deleteIcon={<Icon name={IconDictionary.ODownload} />}
                        onClick={() => {
                          handleDownloadOnClick(file)
                        }}
                      />
                    )
                  })}
                </Box>
              ) : (
                <EmptyValueRenderer />
              )}
            </InfoRow>
          </>
        )
      }}
    </Formik>
  )
}
