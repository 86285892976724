import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { IDParams } from './utils'
import { FormMode } from 'interfaces/formModes/formMode'

export type ScenarioDetailsParams = {
  id: string
  resourceTypeCode: string
  scenarioId: string
  tab: string
}
export type DsrConfigParams = {
  id: string
  formMode: FormMode
  scenarioId: string
}

export const SearchParams = {
  rightsOrchestrationAssetId: 'assetId',
}

// TODO:JB - Rename to dataMap once released to prod
export const systems = {
  root: {
    pattern: '/data-map',
    getURL: () => `/data-map`,
    permissions: [PERMISSIONS.DATA_SYSTEMS_READ],
  },

  // Systems home
  systemsHome: {
    root: {
      pattern: '/systems-home',
      getURL: () => '/data-map/systems-home',
      permissions: [PERMISSIONS.DATA_SYSTEMS_READ],
    },
  },

  // Data system
  id: {
    root: {
      pattern: '/systems-home/systems/:id',
      getURL: ({ id }: IDParams) => `/data-map/systems-home/systems/${id}`,
      permissions: [PERMISSIONS.DATA_SYSTEMS_READ],
    },
    overview: {
      root: {
        pattern: '/overview',
        getURL: ({ id }: IDParams) => `/data-map/systems-home/systems/${id}/overview`,
        permissions: [PERMISSIONS.DATA_SYSTEMS_READ],
      },
    },
    connections: {
      root: {
        pattern: '/connections',
        getURL: ({ id }: IDParams) => `/data-map/systems-home/systems/${id}/connections`,
        permissions: [PERMISSIONS.DATA_SYSTEMS_READ],
      },
    },
    assets: {
      root: {
        pattern: '/assets',
        getURL: ({ id }: IDParams) => `/data-map/systems-home/systems/${id}/assets`,
        permissions: [PERMISSIONS.DATA_SYSTEMS_READ],
      },
    },
    details: {
      root: {
        pattern: '/advanced',
        getURL: ({ id }: IDParams) => `/data-map/systems-home/systems/${id}/details`,
      },
    },
    rightsOrchestration: {
      root: {
        pattern: '/rights-orchestration',
        getURL: ({ id, assetId }: { id: string; assetId?: string }) =>
          `/data-map/systems-home/systems/${id}/rights-orchestration${
            assetId ? `?${SearchParams.rightsOrchestrationAssetId}=${assetId}` : ''
          }`,
        permissions: [PERMISSIONS.DATA_SYSTEMS_READ],
      },
    },
    riskManagement: {
      root: {
        pattern: '/risk-management',
        getURL: ({ id }: IDParams) => `/data-map/systems-home/systems/${id}/risk-management`,
        permissions: [PERMISSIONS.DATA_SYSTEMS_READ],
      },
    },
    disclosures: {
      root: {
        pattern: '/disclosures',
        getURL: ({ id }: IDParams) => `/data-map/systems-home/systems/${id}/disclosures`,
        permissions: [PERMISSIONS.DATA_SYSTEMS_READ],
      },
    },
  },

  // New
  systems: {
    root: {
      pattern: '/systems',
      getURL: () => '/data-map/systems-home/systems',
      permissions: [PERMISSIONS.DATA_SYSTEMS_READ],
    },
    settings: {
      root: {
        pattern: '/systems-home/systems/settings',
        getURL: () => '/data-map/systems-home/systems/settings',
        permissions: [PERMISSIONS.DATA_SYSTEMS_WRITE],
      },
    },
    id: {
      root: {
        pattern: '/systems-home/systems/:id',
        getURL: ({ id }: IDParams) => `/data-map/systems-home/systems/${id}`,
        permissions: [PERMISSIONS.DATA_SYSTEMS_READ],
      },
      edit: {
        root: {
          pattern: '/:id/edit',
          getURL: ({ id }: IDParams) => `/data-map/systems-home/systems/${id}/edit`,
        },
      },
      overview: {
        root: {
          pattern: '/systems-home/systems/:id/overview',
          getURL: ({ id }: IDParams) => `/data-map/systems-home/systems/${id}/overview`,
        },
      },
      connections: {
        root: {
          pattern: '/connections',
          getURL: ({ id }: IDParams) => `/data-map/systems-home/systems/${id}/connections`,
        },
      },
      details: {
        root: {
          pattern: '/advanced',
          getURL: ({ id }: IDParams) => `/data-map/systems-home/systems/${id}/details`,
        },
      },
    },
  },

  connections: {
    root: {
      pattern: '/connections',
      getURL: () => `/data-map/systems-home/connections`,
      permissions: [PERMISSIONS.DATA_SYSTEMS_READ],
    },
    create: {
      pattern: '/systems-home/connections/create',
      getURL: () => `/data-map/systems-home/connections/create`,
      permissions: [PERMISSIONS.DATA_SYSTEMS_READ],
    },
    upsert: {
      root: {
        pattern: '/systems-home/connections/:id/upsert/instance/:instanceId',
        getURL: ({ id, instanceId }: { id?: string; instanceId?: string }) =>
          `/data-map/systems-home/connections/${id}/upsert/instance/${instanceId}`,
        permissions: [PERMISSIONS.DATA_SYSTEMS_READ],
      },
      edit: {
        pattern: '/systems-home/connections/:id/upsert/edit/instance/:instanceId',
        getURL: ({ id, instanceId }: { id?: string; instanceId?: string }) =>
          `/data-map/systems-home/connections/${id}/upsert/edit/instance/${instanceId}`,
        permissions: [PERMISSIONS.DATA_SYSTEMS_READ],
      },
    },
  },

  create: {
    root: {
      pattern: '/systems-home/add-system',
      getURL: () => '/data-map/systems-home/add-system',
      permissions: [PERMISSIONS.DATA_SYSTEMS_READ],
    },
  },

  classification: {
    root: {
      pattern: '/data-map/classification',
      getURL: () => '/data-map/classification',
      permissions: [PERMISSIONS.LABEL_READ],
    },
    dataTypes: {
      pattern: `/data-map/classification/data-types`,
      getURL: () => `/data-map/classification/data-types`,
      permissions: [PERMISSIONS.LABEL_READ],
    },
    dataCategories: {
      pattern: `/data-map/classification/data-categories`,
      getURL: () => `/data-map/classification/data-categories`,
      permissions: [PERMISSIONS.LABEL_READ],
    },
  },

  dsrInvocation: {
    assetScenarios: {
      // TODO - this is list of all table (cols?) assets. How is it used? -> probably need to build it out for non-db dsr
      pattern: `/systems-home/dsr-invocation/asset/:id/resource-type/:resource-type`,
      getURL: ({ id, resourceType }: { id: string; resourceType: string }) =>
        `/data-map/systems-home/dsr-invocation/asset/${id}/resource-type/${resourceType}`,
      permissions: [PERMISSIONS.DATA_SYSTEMS_WRITE],
    },
    dsrConfig: {
      pattern: `/systems-home/dsr-invocation/asset/:id/scenario/:scenarioId/dsr-config/:formMode`,
      getURL: ({ id, formMode, scenarioId }: DsrConfigParams) =>
        `/data-map/systems-home/dsr-invocation/asset/${id}/scenario/${scenarioId}/dsr-config/${formMode}`,
      permissions: [PERMISSIONS.DATA_SYSTEMS_WRITE],
    },
    scenarioDetails: {
      pattern: '/systems-home/dsr-invocation/resourceType/:resourceTypeCode/asset/:id/scenario/:scenarioId/:tab',
      getURL: ({ id, resourceTypeCode, scenarioId, tab }: ScenarioDetailsParams) =>
        `/data-map/systems-home/dsr-invocation/resourceType/${resourceTypeCode}/asset/${id}/scenario/${scenarioId}/${tab}`,
      permissions: [PERMISSIONS.DATA_SYSTEMS_READ],
    },
    configureIdentity: {
      pattern: `/systems-home/dsr-invocation/asset/:id/scenario/:scenarioId/identity-lookup/:formMode`,
      getURL: ({ id, formMode, scenarioId }: DsrConfigParams) =>
        `/data-map/systems-home/dsr-invocation/asset/${id}/scenario/${scenarioId}/identity-lookup/${formMode}`,
      permissions: [PERMISSIONS.DATA_SYSTEMS_WRITE],
    },
  },
}
