import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'
import { useFeatureFlag } from 'utils/hooks'
import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from '../../common/mutation'
import { updateProcessingActivityDataSystemConsentStatus } from '../fetchers/updateProcessingActivityDataSystemConsentStatus'

const useCustomMutation = createUseMutation({
  mutationFn: updateProcessingActivityDataSystemConsentStatus,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useUpdateProcessingActivityDataSystemConsentStatus = (config?: Config) => {
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isV2FeatureFlagEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.ASSESSMENT,
    FEATURE_FLAGS_VALUES.ASSESSMENTS_VERSION,
  )
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {
    isV2FeatureFlagEnabled,
  })
}
