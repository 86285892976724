import React, { useContext } from 'react'
import { BuilderContext } from '../../../Builder'
import { Box, Typography, styled } from '@mui/material'
import { Icon } from '@ketch-com/deck'
import { getIn } from 'formik'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'
import { ItemStyle, ListLayout } from '@ketch-sdk/ketch-types'
import { BuilderSwitch, SwitchVariant } from '../../BuilderSwitch'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { clamp } from 'lodash'
import { BUILDER_CONSTANTS } from '../../../utils/enums'
import { SectionEmptyState } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/components/SectionEmptyState'

const PurposeListBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  marginTop: '16px',
})

const PurposeItemBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
})

const PurposeTitleBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: '61px',
  padding: '12px 8px',
})

const PurposeLeftBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
})

const PurposeRightBox = styled(Box)({
  display: 'flex',
})

const PurposeTextBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
})

const PurposeContentBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '8px 24px 22px 40px',
})

export const ModalPurposeList: React.FC = () => {
  const { themeConfig, experienceConfig, isTheme } = useContext(BuilderContext)

  // Theme
  const listItemLayout = getIn(themeConfig, themeUpsertFormKeys.modalPurposeListLayout)
  const listItemStyle = getIn(themeConfig, themeUpsertFormKeys.modalPurposeListStyle)
  const listItemBackgroundColor = getIn(themeConfig, themeUpsertFormKeys.modalPurposeListItemFillColor)
  const listItemOutlineColor = getIn(themeConfig, themeUpsertFormKeys.modalPurposeListItemOutlineColor)
  const listItemContentColor = getIn(themeConfig, themeUpsertFormKeys.modalPurposeListItemContentColor)
  const listItemIconColor = getIn(themeConfig, themeUpsertFormKeys.modalPurposeListItemArrowIconColor)
  const listItemLinkColor = getIn(themeConfig, themeUpsertFormKeys.modalPurposeListItemLinkColor)
  const listItemCornerRadius =
    clamp(
      getIn(themeConfig, themeUpsertFormKeys.modalPurposeListItemCornerRadius),
      BUILDER_CONSTANTS.MIN_BORER_RADIUS,
      BUILDER_CONSTANTS.MAX_BORDER_RADIUS,
    ) + 'px'
  const switchButtonOnBackgroundColor = getIn(
    themeConfig,
    themeUpsertFormKeys.modalPurposeListSwitchButtonsOnBackgroundColor,
  )
  const switchButtonOnTextColor = getIn(themeConfig, themeUpsertFormKeys.modalPurposeListSwitchButtonsOnTextColor)
  const switchButtonOffBackgroundColor = getIn(
    themeConfig,
    themeUpsertFormKeys.modalPurposeListSwitchButtonsOffBackgroundColor,
  )
  const switchButtonOffTextColor = getIn(themeConfig, themeUpsertFormKeys.modalPurposeListSwitchButtonsOffTextColor)

  // For list layout === expandable (default), the outlined style corresponds to an underline (bottom border online), vs for
  // list layout === cards, the outlined style corresponds to a full border.
  const isOutlined = listItemLayout === ListLayout.Cards && listItemStyle === ItemStyle.Outlined
  const isUnderlined = listItemLayout === ListLayout.Expandable && listItemStyle === ItemStyle.Outlined
  const hasBorderRadius = listItemStyle === ItemStyle.Filled

  // Experience
  const showSwitchButtonLabels = getIn(
    experienceConfig,
    experienceUpsertFormKeys.modalPurposeListSwitchButtonLabelsVisible,
  )
  const showLegalBases = getIn(experienceConfig, experienceUpsertFormKeys.modalPurposeListLegalBasisVisible)
  const switchButtonOnText = getIn(experienceConfig, experienceUpsertFormKeys.modalPurposeListSwitchButtonLabelsOnText)
  const switchButtonOffText = getIn(
    experienceConfig,
    experienceUpsertFormKeys.modalPurposeListSwitchButtonLabelsOffText,
  )
  const showVendorsLink = getIn(experienceConfig, experienceUpsertFormKeys.modalPurposeListVendorsVisible)
  const vendorsLinkText = getIn(experienceConfig, experienceUpsertFormKeys.modalPurposeListVendorsLinkText)

  const sampleListData = [
    {
      purposeName: 'Analytics',
      switchVariant: SwitchVariant.alwaysOn,
      legalBasisText: 'Legal Basis: Consent - Opt Out',
      open: true,
    },
    {
      purposeName: 'Essential Services',
      switchVariant: SwitchVariant.on,
      legalBasisText: 'Legal Basis: Consent - Opt Out',
      open: false,
    },
    {
      purposeName: 'Personalization',
      switchVariant: SwitchVariant.off,
      legalBasisText: 'Legal Basis: Consent - Opt In',
      open: false,
    },
  ]

  return (
    <PurposeListBox>
      {sampleListData.map(({ purposeName, switchVariant, legalBasisText, open }) => (
        <PurposeItemBox
          sx={{
            background: listItemStyle === ItemStyle.Filled ? listItemBackgroundColor : '',
            border: isOutlined ? `1.5px solid ${listItemOutlineColor}` : '',
            borderBottom: isUnderlined ? `1.5px solid ${listItemOutlineColor}` : '',
            borderRadius: hasBorderRadius ? listItemCornerRadius : '',
          }}
        >
          <PurposeTitleBox>
            <PurposeLeftBox>
              <Icon name={open ? 'OArrowCDown' : 'OArrowCRight'} iconColor={listItemIconColor} />
              <PurposeTextBox>
                <Typography variant="h4" color={listItemContentColor}>
                  {purposeName}
                </Typography>
                <Typography variant="smallBody" color={listItemContentColor} sx={{ opacity: '0.65' }}>
                  {showLegalBases ? legalBasisText : ''}
                </Typography>
              </PurposeTextBox>
            </PurposeLeftBox>
            <PurposeRightBox>
              <BuilderSwitch
                variant={switchVariant}
                onBackgroundColor={switchButtonOnBackgroundColor}
                onTextColor={switchButtonOnTextColor}
                offBackgroundColor={switchButtonOffBackgroundColor}
                offTextColor={switchButtonOffTextColor}
                onText={switchButtonOnText}
                offText={switchButtonOffText}
                showText={showSwitchButtonLabels}
              />
            </PurposeRightBox>
          </PurposeTitleBox>
          {open && (
            <PurposeContentBox>
              <Typography fontSize={14} fontWeight={400} color={listItemContentColor}>
                Data may be utilized in various ways to derive additional meaning and purpose. This can include the use
                of data in aggregate views or tactical detailed analysis, the use of data in algorithms and models, and
                the use of data for reporting and dashboards.
              </Typography>
              <Box display="flex" flexDirection="column">
                <Typography fontSize={14} fontWeight={600} color={listItemContentColor}>
                  Legal Basis
                </Typography>
                <Typography fontSize={14} fontWeight={400} color={listItemContentColor}>
                  Data subject has received adequate disclosure describing the processing for one or more specific
                  purposes, and can subsequently opt-out.
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" gap="8px">
                <Box display={'flex'} alignItems="center">
                  <Typography fontSize={14} fontWeight={600} color={listItemLinkColor}>
                    Cookies
                  </Typography>
                  <Icon name={'OArrowRight'} iconColor={listItemLinkColor} />
                </Box>
                {showVendorsLink ? (
                  <Box display={'flex'} alignItems="center">
                    <Typography fontSize={14} fontWeight={600} color={listItemLinkColor}>
                      {vendorsLinkText}
                    </Typography>
                    <Icon name={'OArrowRight'} iconColor={listItemLinkColor} />
                  </Box>
                ) : (
                  <SectionEmptyState section="Vendors Link" hidden={isTheme} compact width="fit-content" />
                )}
              </Box>
            </PurposeContentBox>
          )}
        </PurposeItemBox>
      ))}
    </PurposeListBox>
  )
}
