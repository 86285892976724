import { Box, Typography } from '@mui/material'
import { useOrganizationLanguages } from 'api/languages/queries/useOrganizationLanguages'
import TextAreaInfoRow from 'components/InfoRow/TextAreaInfoRow'
import { useDataSystemsContext } from 'pages/dataSystems/DataSystem/context/DataSystemContext'

export const DisclosureTranslations: React.FC = () => {
  const { updatedDataSystem, handleDisclosuresUpdate } = useDataSystemsContext()

  // Disclosure languages
  const { data: languages, isLoading: isLanguagesLoading } = useOrganizationLanguages()
  const hasLanguages = !isLanguagesLoading && !!languages.length

  return hasLanguages ? (
    <Box display="flex" flexDirection="column" gap={4} mt={2}>
      <Typography variant="h4">Translations</Typography>
      {languages.map(({ language: { code, englishName } }) => (
        <TextAreaInfoRow
          key={`${code}-disclosure-description`}
          title={englishName}
          value={updatedDataSystem?.disclosures?.[code]?.description}
          setValue={translatedDescription => handleDisclosuresUpdate(code, translatedDescription)}
        />
      ))}
    </Box>
  ) : null
}
