import { PreUploadResponse } from '@ketch-com/windlass/dist/assessments/externalUser'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

interface PrepareFileUploadResponse extends PreUploadResponse {
  csrf_token?: string
}

interface PrepareFileUpdateParams {
  isExternal?: boolean
}

export const prepareFileUpload = ({ isExternal }: PrepareFileUpdateParams) => {
  const entityUrl = isExternal
    ? '/api/external-risk-review/resources/files/prepare-upload'
    : '/api/v2/files/prepare-upload'
  return API.get<PrepareFileUploadResponse>(
    formatRequestString({
      entityUrl: entityUrl,
    }),
    { withCredentials: true },
  )
}
