import { Box, Typography } from '@mui/material'
import { MainAsessmentsList } from './components/MainAssessmentsList'
import { AssessmentsListFilters } from './components/AssessmentsListFilters'
import { useMemo, useState } from 'react'
import { CircularProgress, CircularProgressSize, EmptyState, theme } from '@ketch-com/deck'
import { PortalPage } from '../common/component/PortalPage'
import { useAssessmentsListExternal } from 'api/assessments/queries/useAssessmentsListExternal'
import {
  AssessmentAttributeEvaluationSubject,
  AssessmentStatus,
} from '@ketch-com/windlass/dist/assessments/externalUser'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { showToast } from 'components/modals/AlertComponent'
import { OrganizationAssessmentsListProps } from './components/OrganizationAssessmentsList'

export const PrivacyPortalOverview: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState('')
  const [searchStatusFilter, setSearchStatusFilter] = useState<AssessmentStatus | string>('All')
  const navigate = useNavigate()

  const unfiltered = searchStatusFilter === 'All'
  const { data: assessmentsList, isLoading } = useAssessmentsListExternal({
    params: {
      name: searchQuery.length ? searchQuery : undefined,
      status: unfiltered
        ? undefined
        : searchStatusFilter === 'Complete'
        ? AssessmentStatus.in_progress
        : (searchStatusFilter as AssessmentStatus),
    },
    onError: error => {
      if ((error as AxiosError).status === 403) {
        navigate(RoutesManager.externalPrivacyPortal.login.getURL())
      } else {
        showToast({
          content: 'An error occurred while fetching assessments',
          type: 'error',
        })
      }
    },
  })

  const filteredList: OrganizationAssessmentsListProps[] = useMemo(() => {
    if (!assessmentsList?.tenants || assessmentsList?.meta?.count === 0) return []
    return Object.entries(assessmentsList.tenants).reduce((list, [organizationCode, assessmentsList]) => {
      if (!assessmentsList?.length) return list
      const assessmentsByOrg = {
        organizationCode,
        assessmentsList: assessmentsList.reduce((acc, assessment) => {
          if (
            (searchStatusFilter !== 'Complete' && searchStatusFilter !== AssessmentStatus.in_progress) ||
            // handling filtering between Completed and In Progress on the front end for now
            (searchStatusFilter === AssessmentStatus.in_progress &&
              assessment.attributes?.totalQuestions !== assessment.attributes?.completedQuestions) ||
            (searchStatusFilter === 'Complete' &&
              assessment.attributes?.totalQuestions === assessment.attributes?.completedQuestions)
          ) {
            acc.push({
              name: assessment.attributes?.name || '',
              status: assessment.attributes?.status!,
              dataSubjectCode:
                assessment.attributes?.evaluationSubject === AssessmentAttributeEvaluationSubject.system
                  ? assessment.attributes?.systemName
                  : assessment.attributes?.evaluationSubject ===
                    AssessmentAttributeEvaluationSubject.processing_activity
                  ? assessment.attributes?.processingActivityName
                  : 'Unknown',
              dataSubjectIcon: assessment.attributes?.systemLogoUrl,
              questionCount: {
                complete: assessment.attributes?.completedQuestions || 0,
                total: assessment.attributes?.totalQuestions!,
              },
              assessmentId: assessment.id,
            })
          }
          return acc
        }, [] as any[]),
      }
      if (assessmentsByOrg.assessmentsList.length) {
        list.push(assessmentsByOrg)
      }
      return list
    }, [] as OrganizationAssessmentsListProps[])
  }, [assessmentsList?.meta?.count, assessmentsList?.tenants, searchStatusFilter])

  return (
    <PortalPage>
      <Box width="100%" display="flex" justifyContent="center" paddingY={4} marginBottom={4}>
        <Box width="80vw" minWidth="750px">
          <Typography variant="h1" marginBottom={1}>
            Welcome!
          </Typography>
          <Typography variant="h3" color={theme.palette.Text.Secondary}>
            Below are all privacy assessments assigned to you.
          </Typography>
          <AssessmentsListFilters
            handleSearchFilterChange={setSearchStatusFilter}
            handleSearchInputChange={setSearchQuery}
            searchQuery={searchQuery}
            searchStatusFilter={searchStatusFilter}
          />
          {filteredList.length ? (
            <MainAsessmentsList assessmentsByOrgList={filteredList} />
          ) : isLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" width="100%" padding={4}>
              <CircularProgress size={CircularProgressSize.xLarge} />
            </Box>
          ) : (
            <EmptyState
              title="No results found"
              subTitle={
                unfiltered
                  ? "Sorry, we couldn't find any assessments assigned to you.  Please double check with your assessment provider and try again."
                  : "Sorry, we couldn't find any results that match your filter criteria. Please adjust your filters and try again."
              }
              iconName="OFilter"
            />
          )}
        </Box>
      </Box>
    </PortalPage>
  )
}
