import { Box, Typography } from '@mui/material'
import { useFiltersV3 } from 'utils/hooks'
import { RightsQueueMegaFilterButtonPanelIds } from '../constants'
import { SwitchToggle } from '@ketch-com/deck'
import { RightInvocationTypeDTO } from '@ketch-com/figurehead'

const panelId = RightsQueueMegaFilterButtonPanelIds.duplicates

export const DuplicatesFilter = ({ handlePaginationReset }: { handlePaginationReset: () => void }) => {
  const { replaceParams, getPanelParamValuesArrayByPanelId, removeSingleSearchParam } = useFiltersV3()

  const panelParamValuesArray = getPanelParamValuesArrayByPanelId(panelId)

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" gap={1} pl={2}>
      <Typography variant="body">Show Duplicate Requests Only</Typography>
      <SwitchToggle
        checked={Boolean(panelParamValuesArray.length)}
        onChange={(_, checked) => {
          if (checked) {
            replaceParams({ [panelId]: RightInvocationTypeDTO.RightTypeDuplicateRightInvocationType, page: '0' })
          } else {
            removeSingleSearchParam(panelId)
          }
          handlePaginationReset()
        }}
      />
    </Box>
  )
}
