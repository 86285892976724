import { FormFieldDTO, FormFieldTypeDTO, FormFieldVariantDTO } from '@ketch-com/figurehead'

export const getHumanReadableFieldType = (formField?: FormFieldDTO) => {
  if (!formField) return ''

  const isTextArea =
    formField.type === FormFieldTypeDTO.TextFormFieldType &&
    formField.variant === FormFieldVariantDTO.TextareaFormFieldVariant
  const isInput =
    formField.type === FormFieldTypeDTO.TextFormFieldType &&
    formField.variant === FormFieldVariantDTO.InputFormFieldVariant

  if (isTextArea || isInput) return 'Text Field'

  if (formField.type === FormFieldTypeDTO.DropdownFormFieldType) return 'Dropdown'

  if (formField.type === FormFieldTypeDTO.CheckboxFormFieldType) return 'Checkbox'

  if (formField.type === FormFieldTypeDTO.FileFormFieldType) return 'File'

  return 'Input'
}
