import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MaybeNull } from 'interfaces/common'
import { DeploymentPlanDTO } from 'interfaces/deploymentPlans-v2/deploymentPlan'
import { PolicyScopeDTO } from 'interfaces/policyScopes-v2/policyScope'
import { PolicyDocumentDTO } from 'interfaces/policyDocuments/policyDocument'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { useValidationSchema, DeploymentPlanFormValues } from 'pages/consentAndRights/deploymentPlans-v2/upsert/utils'
import { getInitialValues, DeploymentPlanFormStep } from 'pages/consentAndRights/deploymentPlans-v2/upsert/create/utils'
import { RoutesManager } from 'utils/routing/routesManager'
import { ContentGroup, Spinner, UpsertLayout, UpsertLayoutMode } from '@ketch-com/deck'
import { DeploymentPlanBasicDetails } from 'pages/consentAndRights/deploymentPlans-v2/upsert/components/DeploymentPlanBasicDetails'
import { DeploymentPlanExperiences } from 'pages/consentAndRights/deploymentPlans-v2/upsert/components/deploymentPlanExperiences/DeploymentPlanExperiences'
import { DeploymentPlanDocuments } from 'pages/consentAndRights/deploymentPlans-v2/upsert/components/deploymentPlanDocuments/DeploymentPlanDocuments'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { ThemeV3DTO } from 'interfaces/themes-v3/theme'
import { isRelease } from 'utils/releases'
import { Release } from 'utils/releases/RELEASES'
import { FormikProvider } from 'formik'
import { useFormik } from 'formik'
import { Box } from '@mui/material'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

type Props = {
  isReady: boolean
  duplicate: boolean
  deploymentPlan: MaybeNull<DeploymentPlanDTO>
  policyScopes: PolicyScopeDTO[]
  experiences: ExperienceV2DTO[]
  policyDocuments: PolicyDocumentDTO[]
  themes: ThemeV3DTO[]
  onSubmit: (values: DeploymentPlanFormValues) => Promise<void>
}

const breadcrumbs = [
  { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
  { title: 'Deployment Plans', link: RoutesManager.deployment.deploymentPlansV2.root.getURL() },
  { title: 'Create New Plan' },
]

export const DeploymentPlanCreate: React.FC<Props> = ({
  isReady,
  duplicate,
  deploymentPlan,
  policyScopes,
  experiences,
  policyDocuments,
  themes,
  onSubmit,
}) => {
  const [currentFormStep, setCurrentFormStep] = useState(0)
  const navigate = useNavigate()
  const { isEntitled } = useIsEntitled()

  const isEntitledToContentAndDisclosure = isEntitled(ENTITLEMENTS.EXP_SERVER_CONSENT_AND_DISCLOSURE)

  const initialValues = useMemo(
    () => getInitialValues({ duplicate, deploymentPlan, policyScopes }),
    [duplicate, deploymentPlan, policyScopes],
  )

  const validationSchema = useValidationSchema({ isEntitledToContentAndDisclosure })

  const formikProps = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
  })

  const { isSubmitting, submitForm } = formikProps

  const formSteps: DeploymentPlanFormStep[] = [
    DeploymentPlanFormStep.BASIC_DETAILS,
    DeploymentPlanFormStep.EXPERIENCES,
    DeploymentPlanFormStep.DOCUMENTS,
  ]

  const formStepComponents = useMemo(() => {
    return [
      <DeploymentPlanBasicDetails themes={themes.filter(theme => !!theme.summary)} />,
      <DeploymentPlanExperiences
        policyScopes={policyScopes}
        experiences={experiences}
        upsertMode={UpsertLayoutMode.create}
      />,
      ...(isRelease(Release.PropertyThemeDocuments)
        ? []
        : [<DeploymentPlanDocuments policyDocuments={policyDocuments} editMode={false} />]),
    ]
  }, [policyScopes, experiences, policyDocuments, themes])

  const isLastStep = formSteps.length - 1 === currentFormStep

  const onAccept = useCallback(async () => {
    if (isLastStep) return submitForm()
    setCurrentFormStep(prev => prev + 1)
  }, [isLastStep, submitForm])

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <FormikProvider value={formikProps}>
        <UpsertLayout
          showStepper
          formSteps={formSteps}
          currentFormStep={currentFormStep}
          mode={UpsertLayoutMode.create}
          renderFormTitle={() => <ContentGroup title="Create Deployment Plan" titleVariant="h2" isDivider />}
          acceptButtonProps={{ pending: isSubmitting, disabled: isSubmitting || !isReady }}
          cancelButtonProps={{ pending: isSubmitting }}
          acceptActionButtonText={isLastStep ? 'Done' : 'Next'}
          onAccept={onAccept}
          onCancel={() => {
            navigate(RoutesManager.deployment.applications.root.getURL())
          }}
        >
          {isReady ? (
            <Box pt={2} component="form" autoComplete="off">
              {formStepComponents.filter((_, i) => currentFormStep >= i)}
            </Box>
          ) : (
            <Box
              sx={{
                margin: '100px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spinner size="32px" thickness={2.5} />
            </Box>
          )}
        </UpsertLayout>
      </FormikProvider>
    </>
  )
}
