import { FormFieldTypeDTO } from '@ketch-com/figurehead'
import { FormFieldDTOWithValue } from 'interfaces/rightInvocations/RightInvocationFormValues'

export const getFormInputType = (
  field: FormFieldDTOWithValue,
): 'dropdown' | 'textarea' | 'text' | 'checkbox' | 'file' => {
  if (field.type === FormFieldTypeDTO.DropdownFormFieldType) return 'dropdown'
  if (field.type === FormFieldTypeDTO.TextFormFieldType && field.variant === 'textarea') return 'textarea'
  if (field.type === FormFieldTypeDTO.TextFormFieldType && field.variant !== 'textarea') return 'text'
  if (field.type === FormFieldTypeDTO.CheckboxFormFieldType) return 'checkbox'
  if (field.type === FormFieldTypeDTO.FileFormFieldType) return 'file'
  return 'text'
}
