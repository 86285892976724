import { MaybeNull } from '../../../../../interfaces'
import { PolicyScopeTemplateDTO } from '../../../../../interfaces/policyScopes/policyScope'
import { PolicyScopeFormValues } from '../interfaces'
import { optOutRights } from '../constants'
import { useContext } from 'react'
import { PolicyScopeUpsertContext } from 'pages/policyCenter/policyScopes/upsert/context/PolicyScopeUpsertContext'

export const useInitialValues = ({
  policyScopeTemplate,
}: {
  policyScopeTemplate?: MaybeNull<PolicyScopeTemplateDTO>
}): PolicyScopeFormValues => {
  const { purposes } = useContext(PolicyScopeUpsertContext)

  // Populate legalBases and requiresDisplay objects
  const { legalBases, requiresDisplay } = purposes.reduce(
    (acc, purpose) => {
      acc.legalBases[purpose.ID] = ''
      acc.requiresDisplay[purpose.ID] = false
      return acc
    },
    {
      legalBases: {} as Record<string, string>,
      requiresDisplay: {} as Record<string, boolean>,
    },
  )

  return {
    name: policyScopeTemplate?.name || '',
    code: '',
    description: policyScopeTemplate?.description || '',
    regionCodes: policyScopeTemplate?.regionCodes || [],
    regulationCodes: policyScopeTemplate?.regulationCodes || [],
    rightsFulfillment:
      policyScopeTemplate?.rightsFulfillment?.map(right => ({
        ...right,
        checked: !optOutRights.includes(right.rightCode),
      })) || [],
    legalBases,
    requiresDisplay,
  }
}

export const getInitialValues = ({
  policyScopeTemplate,
}: {
  policyScopeTemplate?: MaybeNull<PolicyScopeTemplateDTO>
}): PolicyScopeFormValues => {
  return {
    name: policyScopeTemplate?.name || '',
    code: '',
    description: policyScopeTemplate?.description || '',
    regionCodes: policyScopeTemplate?.regionCodes || [],
    regulationCodes: policyScopeTemplate?.regulationCodes || [],
    rightsFulfillment:
      policyScopeTemplate?.rightsFulfillment?.map(right => ({
        ...right,
        checked: !optOutRights.includes(right.rightCode),
      })) || [],
    legalBases: {},
    requiresDisplay: {},
  }
}
