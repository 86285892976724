import React from 'react'
import { styled } from '@mui/material/styles'
import { SxProps, Theme, Tooltip, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { Icon, SwitchToggle, theme } from '@ketch-com/deck'

const PREFIX = 'IdentityVerificationForm'

const classes = {
  verificationSwitchContainer: `${PREFIX}-verificationSwitchContainer`,
}

const StyledBox = styled(Box)(({ theme: { palette, spacing } }) => ({
  [`& .${classes.verificationSwitchContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(2, 2.25),
  },
}))

interface IIdentityVerificationFormProps {
  requisiteForm?: React.ReactNode
  title: string
  value: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  sx?: SxProps<Theme>
  disabled?: boolean
  tooltipContent?: React.ReactNode
  isEntitled?: boolean
  entitledTooltipContent?: React.ReactNode
}

export const VerificationOption: React.FC<IIdentityVerificationFormProps> = ({
  value,
  onChange,
  title,
  sx,
  requisiteForm,
  disabled,
  tooltipContent,
  isEntitled,
  entitledTooltipContent,
}) => {
  return (
    <StyledBox display="flex" flexDirection="column">
      <Box
        className={classes.verificationSwitchContainer}
        sx={{
          backgroundColor: ({ palette }) => (value ? palette.tara.main : 'transparent'),
          borderRadius: ({ spacing }) => spacing(1.375),
          ...sx,
        }}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h4" color={value ? 'greenHaze.main' : 'darkDusk.main'}>
            {title}
          </Typography>
          {!isEntitled && (
            <Tooltip title={entitledTooltipContent}>
              <span style={{ marginTop: '4px', paddingLeft: '4px' }}>
                <Icon name="FLock" width={16} height={16} iconColor={theme.palette.Black.o48} />
              </span>
            </Tooltip>
          )}
        </Box>
        <Tooltip title={tooltipContent}>
          <SwitchToggle disabled={!isEntitled || disabled} checked={value} onChange={e => onChange(e)} size="medium" />
        </Tooltip>
      </Box>

      {!!value ? requisiteForm : null}
    </StyledBox>
  )
}
