export const TCF_PURPOSE_CODE_PREFIX = 'tcf.purpose'
export const TCF_SPECIAL_PURPOSE_CODE_PREFIX = 'tcf.specialPurpose'
export const TCF_FEATURE_CODE_PREFIX = 'tcf.feature'
export const TCF_SPECIAL_FEATURE_CODE_PREFIX = 'tcf.specialFeature'
export const TCF_PURPOSE_1_CODE = 'tcf.purpose_1'

export const GDPR_REGULATION_CODE = 'gdpreu'

export const CONSENT_OPTIN_LEGAL_BASIS_CODE = 'consent_optin'
export const LEGIT_INTEREST_OBJECTABLE_LEGAL_BASIS_CODE = 'legitimateinterest_objectable'
export const LEGIT_INTEREST_LEGAL_BASIS_CODE = 'legitimateinterest'
