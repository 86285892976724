import { SidebarFormGroup } from '../../../../../../experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'
import { FormColorInput } from '../FormColorInput'
import { themeUpsertFormKeys } from '../../../../utils/useThemeValidationSchema'
import {
  BuilderTextInputMode,
  FormTextInput,
} from '../../../../../../experiences-v2/upsert/components/upsertExperienceSidebar/components/FormTextInput'
import { themeFileAllowedTypes, themeFileUploadContext } from 'pages/consentAndRights/themes-v3/upsert/utils/utils'
import { FormFileUpload } from 'components/ui-kit/form/fileUpload/FormFileUpload'
import { SidebarFormRadioGroup } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormRadioGroup'
import { BannerModalLogoPosition } from '@ketch-sdk/ketch-types'
import { BannerModalLogoPositionLabels } from 'pages/consentAndRights/themes-v3/upsert/utils/labels'

export const ModalThemeHeaderForm: React.FC = () => {
  return (
    <>
      <SidebarFormGroup hasBorderBottom sx={{ pt: 0 }}>
        <FormColorInput
          label={'Background Color'}
          required
          name={themeUpsertFormKeys.modalHeaderBackgroundColor}
          fullWidth
        />
        <FormColorInput label={'Title Color'} required name={themeUpsertFormKeys.modalHeaderTitleColor} fullWidth />
      </SidebarFormGroup>
      <SidebarFormGroup title={'"X" Button'}>
        <FormColorInput
          name={themeUpsertFormKeys.modalHeaderReturnButtonFillColor}
          label={'"X" Button Fill Color'}
          fullWidth
          required
        />
        <FormColorInput
          name={themeUpsertFormKeys.modalHeaderReturnButtonIconColor}
          label={'"X" Icon Color'}
          fullWidth
          required
        />
        <FormTextInput
          name={themeUpsertFormKeys.modalHeaderReturnButtonCornerRadius}
          label={'"X" Button Corner Radius (px)'}
          inputWidth="92px"
          mode={BuilderTextInputMode.CornerRadius}
        />
      </SidebarFormGroup>
      <SidebarFormGroup sx={{ mt: '8px' }} hasBorderTop>
        <FormFileUpload
          name={themeUpsertFormKeys.modalHeaderLogoUrl}
          label={'Header Logo'}
          belowButtonLabel={'Container size: 140x40'}
          uploadContext={themeFileUploadContext}
          accept={themeFileAllowedTypes}
        />
        <SidebarFormRadioGroup
          name={themeUpsertFormKeys.modalHeaderLogoPosition}
          title={'Logo Position'}
          options={Object.values(BannerModalLogoPosition).map(position => ({
            label: BannerModalLogoPositionLabels[position],
            value: position,
          }))}
          row
        />
      </SidebarFormGroup>
    </>
  )
}
