import React from 'react'

import { PolicyDocumentDTO, PolicyDocumentType, POLICY_DOCUMENT_NAMES } from 'interfaces/policyDocuments/policyDocument'
import { DeploymentPlanDocumentAssignment } from 'pages/consentAndRights/deploymentPlans-v2/upsert/components/deploymentPlanDocuments/DeploymentPlanDocumentAssignment'
import { DataGrid, FormRow } from '@ketch-com/deck'
import { GridColDef } from '@mui/x-data-grid'
import { Typography } from '@mui/material'

type Item = {
  policyDocumentType: PolicyDocumentType
  name: string
}

const items: Item[] = [
  {
    policyDocumentType: PolicyDocumentType.PRIVACY_POLICY,
    name: POLICY_DOCUMENT_NAMES[PolicyDocumentType.PRIVACY_POLICY],
  },
  {
    policyDocumentType: PolicyDocumentType.TERMS_OF_SERVICE,
    name: POLICY_DOCUMENT_NAMES[PolicyDocumentType.TERMS_OF_SERVICE],
  },
]

type Props = {
  policyDocuments: PolicyDocumentDTO[]
  editMode: boolean
}

export const DeploymentPlanDocuments: React.FC<Props> = ({ policyDocuments, editMode }) => {
  const columns = React.useMemo(
    (): GridColDef<Item>[] => [
      {
        align: 'left',
        field: 'name',
        flex: 1,
        headerAlign: 'left',
        headerName: 'Property',
        sortable: false,
        width: 200,
        maxWidth: 200,
        renderCell: ({ row: { policyDocumentType } }) => (
          <Typography sx={{ padding: '16px 0' }}>{POLICY_DOCUMENT_NAMES[policyDocumentType]}</Typography>
        ),
      },
      {
        align: 'left',
        field: 'platform',
        flex: 1,
        width: 200,
        maxWidth: 200,
        headerAlign: 'left',
        headerName: 'Type',
        sortable: false,
        renderCell: ({ row: { policyDocumentType } }) => (
          <DeploymentPlanDocumentAssignment
            policyDocumentType={policyDocumentType}
            policyDocuments={policyDocuments}
            editMode={editMode}
          />
        ),
      },
    ],
    [policyDocuments, editMode],
  )

  return (
    <FormRow title="Documents" hasTopBorder>
      <DataGrid
        rows={items}
        columns={columns}
        autosizeOnMount
        disableChildrenSorting
        disableColumnMenu
        disableColumnPinning
        disableColumnReorder
        disableColumnResize
        disableRowHoverStates
        disableRowSelectionOnClick
        getRowHeight={() => 'auto'}
        getRowId={row => row.policyDocumentType}
        hideFooter
        sx={{
          padding: 'unset',
          width: '400px',
        }}
      />
    </FormRow>
  )
}
