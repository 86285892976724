import { AssessmentBlockCompletionStatusDTO, AssessmentBlockReviewStatusDTO } from '@ketch-com/figurehead'
import { AssessmentDocument } from '@ketch-com/windlass/dist/assessments/externalUser'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

interface FetchExternalAssessmentParams {
  id?: string
  completionStatus?: AssessmentBlockCompletionStatusDTO
  reviewStatus?: AssessmentBlockReviewStatusDTO
}

export const fetchExternalAssessment = ({ id, completionStatus, reviewStatus }: FetchExternalAssessmentParams) => {
  const entityUrl = `/api/external-risk-review/resources/assessments/${id}`
  return API.get<AssessmentDocument>(
    formatRequestString({
      entityUrl,
      params: {
        filters: {
          ...(completionStatus && { 'blocks[completionStatus]': completionStatus }),
          ...(reviewStatus && { 'blocks[reviewStatus]': reviewStatus }),
        },
      },
    }),
  )
}
