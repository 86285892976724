import { themeUpsertFormKeys } from '../../../../utils/useThemeValidationSchema'
import { FormColorInput } from '../FormColorInput'
import {
  BuilderTextInputMode,
  FormTextInput,
} from '../../../../../../experiences-v2/upsert/components/upsertExperienceSidebar/components/FormTextInput'
import { SidebarFormGroup } from '../../../../../../experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'
import { themeFileAllowedTypes } from 'pages/consentAndRights/themes-v3/upsert/utils/utils'
import { FormFileUpload } from 'components/ui-kit/form/fileUpload/FormFileUpload'
import { themeFileUploadContext } from 'pages/consentAndRights/themes-v3/upsert/utils/utils'
import { SidebarFormRadioGroup } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormRadioGroup'
import { BannerModalLogoPosition } from '@ketch-sdk/ketch-types'
import { BannerModalLogoPositionLabels } from 'pages/consentAndRights/themes-v3/upsert/utils/labels'

export const BannerThemeHeaderForm: React.FC = () => {
  return (
    <>
      <SidebarFormGroup hasBorderBottom sx={{ pt: 0 }}>
        <FormColorInput label={'Title Color'} required name={themeUpsertFormKeys.bannerHeaderTitleColor} fullWidth />
      </SidebarFormGroup>
      <SidebarFormGroup title={'"X" Button'}>
        <FormColorInput
          name={themeUpsertFormKeys.bannerHeaderReturnButtonFillColor}
          label={'"X" Button Fill Color'}
          fullWidth
          required
        />
        <FormColorInput
          name={themeUpsertFormKeys.bannerHeaderReturnButtonIconColor}
          label={'"X" Icon Color'}
          fullWidth
          required
        />
        <FormTextInput
          name={themeUpsertFormKeys.bannerHeaderReturnButtonCornerRadius}
          label={'"X" Button Corner Radius (px)'}
          inputWidth="92px"
          mode={BuilderTextInputMode.CornerRadius}
        />
        <SidebarFormGroup sx={{ mt: '8px' }} hasBorderTop>
          <FormFileUpload
            name={themeUpsertFormKeys.bannerHeaderLogoUrl}
            label={'Header Logo'}
            belowButtonLabel={'Container size: 140x40'}
            uploadContext={themeFileUploadContext}
            accept={themeFileAllowedTypes}
          />
          <SidebarFormRadioGroup
            name={themeUpsertFormKeys.bannerHeaderLogoPosition}
            title={'Logo Position'}
            options={Object.values(BannerModalLogoPosition).map(position => ({
              label: BannerModalLogoPositionLabels[position],
              value: position,
            }))}
            row
          />
        </SidebarFormGroup>
      </SidebarFormGroup>
    </>
  )
}
