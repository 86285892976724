import { RightsFulfillment } from 'interfaces/rightsFulfillments/rightsFulfillment'
import { RegulationDTO } from 'interfaces/regulations/regulation'
import { RightDTO } from 'interfaces/rights/right'
import { PolicyScopeDTO } from 'interfaces/policyScopes/policyScope'
import { PolicyScopeFormValues } from '../../pages/policyCenter/policyScopes/upsert/interfaces'
import { fetchCustomRightName } from 'api/customRightNames/fetchers/fetchCustomRightName'

export const getPossibleRightsFulfillment = ({
  regulationCodes,
  regulations,
  values,
}: {
  regulationCodes?: string[]
  regulations: RegulationDTO[]
  values?: PolicyScopeFormValues
}) => {
  return (regulationCodes || []).reduce<RightsFulfillment[]>((acc, regulationCode) => {
    const regulation = regulations.find(({ code }) => code === regulationCode)

    const res = (regulation?.rightsFulfillment || []).reduce<RightsFulfillment[]>(
      (regulationAcc, regulationFulfillment) => {
        const found = acc.find(fulfillment => fulfillment.rightCode === regulationFulfillment.rightCode)
        // find the value in the current form values so that we can preserve the checked state if found
        const regulationFulfillmentInValues = values?.rightsFulfillment?.find(
          ({ rightCode }) => rightCode === regulationFulfillment.rightCode,
        )

        if (found) {
          found.checked = true

          // Select the biggest value
          if (regulationFulfillment.fulfillment > found.fulfillment) {
            found.fulfillment = regulationFulfillment.fulfillment
          }

          return regulationAcc
        }

        return [
          ...regulationAcc,
          {
            ...regulationFulfillment,
            allowAppeal: regulationFulfillment?.allowAppeal,
            checked: !!regulationFulfillmentInValues
              ? // if found in values preserve the checked state
                !!regulationFulfillmentInValues?.checked
              : // otherwise check it if the regulation is not the default one, true if not default
                regulationCode !== 'default',
          },
        ]
      },
      [],
    )

    return [...acc, ...res]
  }, [])
}

export const getAllRightsSelectedSortedByStatus = ({
  regulationCodes,
  regulations,
  rights,
  rightsCodes,
}: {
  regulationCodes: string[]
  rightsCodes: string[]
  regulations: RegulationDTO[]
  rights: RightDTO[]
}) => {
  const allRightsFulfillment = getPossibleRightsFulfillment({
    regulationCodes,
    regulations,
  })

  const allRightsSelectedSortedByStatus: RightDTO[] = []

  allRightsFulfillment?.forEach?.(rightFulfillment => {
    const foundRight = rights.find(right => right.code === rightFulfillment.rightCode)

    if (foundRight) {
      allRightsSelectedSortedByStatus.push(foundRight)
    }
  })

  allRightsSelectedSortedByStatus.sort(right => {
    const hasRight = rightsCodes.indexOf(right!.code) !== -1

    return hasRight ? -1 : 1
  })

  return allRightsSelectedSortedByStatus
}

export const matchAndAddFulfillmentDataToRight = async (
  rightsArray: RightDTO[],
  fulfillmentArray: PolicyScopeDTO['rightsFulfillment'],
) => {
  // Fetch all customRightName data concurrently
  const fetchPromises = rightsArray.map(async right => {
    // Go through each right
    // Cycle through each fulfillment and find the corresponding data
    const matchingFulfillment = fulfillmentArray?.find(fulfillment => fulfillment.rightCode === right.code)

    if (matchingFulfillment?.customRightNameID) {
      // If you get a match, fetch its data.
      const { data: customRightData } = await fetchCustomRightName({
        id: matchingFulfillment.customRightNameID,
      })

      return {
        ...right,
        ...(matchingFulfillment || {}),
        customRightData: customRightData.customRightName ? { ...customRightData.customRightName } : { name: '' },
      }
    }

    return {
      ...right,
      ...(matchingFulfillment || {}),
      customRightData: { name: '' },
    }
  })

  const mergedResults = await Promise.all(fetchPromises)

  return mergedResults
}
