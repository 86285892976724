import { Link } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import { ReactComponent as ScenarioDetailsIcon } from 'assets/icons/square_button_next.svg'
import { RoutesManager } from 'utils/routing/routesManager'
import { LookUpTab } from 'pages/assetManager/scenario/details/constants'
import { ScenarioDTO } from '@ketch-com/figurehead'
import React from 'react'
import { useDsrConfigSelectedAssetContext } from 'pages/dataSystems/AssetMigration/ScenarioDetails/context/DsrConfigSelectedAssetContext'

interface ScenarioNavButtonProps {
  scenario: ScenarioDTO
}

export const ScenarioNavButton: React.FC<ScenarioNavButtonProps> = ({ scenario }) => {
  const { selectedAsset } = useDsrConfigSelectedAssetContext()
  const assetId = selectedAsset?.resource?.id as string
  const url = RoutesManager.systems.dsrInvocation.scenarioDetails.getURL({
    id: assetId,
    scenarioId: scenario.id!,
    tab: LookUpTab.DIRECT,
    resourceTypeCode: selectedAsset?.resource?.resourceType!,
  })
  return (
    <Link to={url}>
      <IconButton sx={{ padding: 0 }} color="secondary" aria-label="Configure Dataset" disableFocusRipple disableRipple>
        <ScenarioDetailsIcon />
      </IconButton>
    </Link>
  )
}
