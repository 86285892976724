import {
  BannerButtonAction,
  FormFieldStyle,
  ItemStyle,
  PreferenceCenterPage,
  QuickLinkAction,
  CanonicalRightCode,
  RightsFormMode,
  SubscriptionListStyle,
  SwitchButtonDisplay,
  UnsubscribeFromAllPosition,
  ProgressiveExperienceButtonAction,
  ProgressiveExperienceConsentLayout,
  ProgressiveExperiencePosition,
  ProgressiveExperienceType,
} from '@ketch-sdk/ketch-types'
import { ConsentExperienceType } from 'interfaces/experiences-v2/consentExperienceType'
import { ExperienceType } from 'interfaces/experiences-v2/experienceType'

export const PreferencePageLabels = {
  [PreferenceCenterPage.Welcome]: 'Welcome',
  [PreferenceCenterPage.PrivacyPolicy]: 'Privacy Policy',
  [PreferenceCenterPage.Purpose]: 'Purposes',
  [PreferenceCenterPage.Subscriptions]: 'Subscriptions',
  [PreferenceCenterPage.RequestsHome]: 'Requests',
  [PreferenceCenterPage.RequestsForm]: 'Request Form',
  [PreferenceCenterPage.RequestsSubmitted]: 'Summary',
}

export const ExperienceTypeLabels = {
  [ExperienceType.Consent]: 'Banner & Modal',
  [ExperienceType.Preference]: 'Preference Management',
  [ExperienceType.Progressive]: 'Progressive Consent',
}

export const ConsentExperienceTypeLabels = {
  [ConsentExperienceType.Banner]: 'Banner',
  [ConsentExperienceType.Modal]: 'Modal',
}

export const BannerButtonActionLabels = {
  [BannerButtonAction.SaveCurrentState]: 'Save Current State',
  [BannerButtonAction.AcceptAll]: 'Accept All (Full Consent)',
  [BannerButtonAction.OpenModal]: 'Open Modal',
  [BannerButtonAction.OpenPreferences]: 'Open Preferences',
  [BannerButtonAction.RejectAll]: 'Reject All (No Consent)',
  [BannerButtonAction.OpenURL]: 'Open URL',
}

export const UnsubscribeFromAllPositionLabels = {
  [UnsubscribeFromAllPosition.Top]: 'Top',
  [UnsubscribeFromAllPosition.Bottom]: 'Bottom',
}

export const ItemStyleLabels = {
  [ItemStyle.Outlined]: 'Outlined',
  [ItemStyle.Filled]: 'Filled',
}

export const ItemStyleLabelsWhenLayoutExpandable = {
  [ItemStyle.Outlined]: 'Underlined',
  [ItemStyle.Filled]: 'Filled',
}

export const RightsFormModeLabels = {
  [RightsFormMode.Single]: 'Single Form',
  [RightsFormMode.Custom]: 'Custom Forms',
}

export const CanonicalRightCodeLabels = {
  [CanonicalRightCode.Get]: 'Provide Data',
  [CanonicalRightCode.Delete]: 'Delete Data',
  [CanonicalRightCode.Restrict]: 'Restrict Processing',
  [CanonicalRightCode.Update]: 'Update Data',
}

export const FormFieldStyleLabels = {
  [FormFieldStyle.Box]: 'Box',
  [FormFieldStyle.Underline]: 'Underline',
}

export const SubscriptionListStyleLabels = {
  [SubscriptionListStyle.Underlined]: 'Underlined',
  [SubscriptionListStyle.Filled]: 'Filled',
}

export const SwitchButtonDisplayLabels = {
  [SwitchButtonDisplay.Always]: 'Always',
  [SwitchButtonDisplay.WhenLegalBasisDiffers]: 'Only when it differs from the Legal Basis default.',
}

export const QuickLinkActionLabels = {
  [QuickLinkAction.OpenUrl]: 'Open a URL',
  [QuickLinkAction.OpenPage]: 'Open a Page in the Preference Center',
}

export const ProgressiveExperienceTypeLabels = {
  [ProgressiveExperienceType.Consent]: 'Consent',
  [ProgressiveExperienceType.Subscription]: 'Subscription',
}

export const ProgressiveExperienceConsentLayoutLabels = {
  [ProgressiveExperienceConsentLayout.ActionButtons]: 'Action Buttons',
  [ProgressiveExperienceConsentLayout.ToggleSwitch]: 'Toggle Switch',
}

export const ProgressiveExperiencePositionLabels = {
  [ProgressiveExperiencePosition.FollowPointer]: 'Follow Pointer',
  [ProgressiveExperiencePosition.Embed]: 'Embed',
}

export const ProgressiveExperienceButtonActionLabels = {
  [ProgressiveExperienceButtonAction.Accept]: 'Accept',
  [ProgressiveExperienceButtonAction.Reject]: 'Reject',
  [ProgressiveExperienceButtonAction.Dismiss]: 'Dismiss',
}
