import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

interface FetchExternalTokenParams {
  code: string
  state: string
}

export const fetchExternalToken = ({ code, state }: FetchExternalTokenParams) => {
  const entityUrl = '/api/auth/collaboration-workspace/token'
  return API.get(
    formatRequestString({
      entityUrl,
      params: {
        filters: {
          code,
          state,
        },
      },
    }),
  )
}
