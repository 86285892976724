import { APIRequestParams } from '../../common/utils'
import { API } from '../../../utils/api/api'
import { GetProcessingActivityVersionResponseBodyDTO } from '@ketch-com/figurehead'
import { formatRequestString } from '../../../utils/helpers/api/queryString'

type Params = APIRequestParams<{
  activityId?: string
  versionId?: string
  isV2FeatureFlagEnabled?: boolean
}>

export const fetchProcessingActivityVersion = ({
  activityId,
  versionId,
  includeMetadata,
  isV2FeatureFlagEnabled,
}: Params) => {
  const entityUrl = isV2FeatureFlagEnabled
    ? `/api/risk-review/processing-activities/${activityId}/versions/${versionId}`
    : `/api/processing-activities/${activityId}/versions/${versionId}`
  return API.get<GetProcessingActivityVersionResponseBodyDTO>(
    formatRequestString({
      entityUrl,
      params: {
        filters: {
          includeMetadata,
        },
      },
    }),
  )
}
