import { Box, Typography, styled } from '@mui/material'
import { BuilderImageVariant, LanyardColors } from '../../../utils/enums'

type Props = {
  /** Which type of icon is this empty state for */
  variant: BuilderImageVariant
  /** If this icon is selected, only applies to nav panel icons */
  selected?: boolean
}

const variantFontSize = {
  [BuilderImageVariant.Header]: '14px',
  [BuilderImageVariant.HeroBanner]: '14px',
  [BuilderImageVariant.Navigation]: '10px',
  [BuilderImageVariant.SummaryBanner]: '14px',
  [BuilderImageVariant.BannerModal]: '14px',
}

const variantText = {
  [BuilderImageVariant.Header]: 'Logotype',
  [BuilderImageVariant.HeroBanner]: 'Hero Banner',
  [BuilderImageVariant.Navigation]: 'Icon',
  [BuilderImageVariant.SummaryBanner]: 'Summary Banner',
  [BuilderImageVariant.BannerModal]: 'Logotype',
}

const HeaderImageEmptyStateBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '64px',
  maxHeight: '64px',
  alignItems: 'center',
  justifyContent: 'center',
  background: LanyardColors.GreyBackground,
}))

const HeroBannerEmptyStateBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '320px',
  alignItems: 'center',
  justifyContent: 'center',
  background: LanyardColors.GreyBackground,
}))

const NavigationIconEmptyStateBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: LanyardColors.GreyBackground,
  width: 24,
  height: 24,
  padding: '2px 1.5px',
}))

const SummaryBannerEmptyStateBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '320px',
  alignItems: 'center',
  justifyContent: 'center',
  background: LanyardColors.GreyBackground,
}))

const BannerModalImageEmptyStateBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '40px',
  width: '140px',
  alignItems: 'center',
  justifyContent: 'center',
  background: LanyardColors.GreyBackground,
}))

export const ImageEmptyState: React.FC<Props> = ({ variant, selected = false }) => {
  switch (variant) {
    case BuilderImageVariant.Header:
      return (
        <HeaderImageEmptyStateBox>
          <Typography fontSize={variantFontSize[variant]} fontWeight={600} color={LanyardColors.Black48}>
            {variantText[variant]}
          </Typography>
        </HeaderImageEmptyStateBox>
      )
    case BuilderImageVariant.HeroBanner:
      return (
        <HeroBannerEmptyStateBox>
          <Typography fontSize={variantFontSize[variant]} fontWeight={600} color={LanyardColors.Black48}>
            {variantText[variant]}
          </Typography>
        </HeroBannerEmptyStateBox>
      )
    case BuilderImageVariant.Navigation:
      return (
        <NavigationIconEmptyStateBox
          sx={{ background: selected ? LanyardColors.White24 : LanyardColors.GreyBackground }}
        >
          <Typography
            fontSize={variantFontSize[variant]}
            fontWeight={600}
            color={selected ? LanyardColors.White64 : LanyardColors.Black48}
          >
            {variantText[variant]}
          </Typography>
        </NavigationIconEmptyStateBox>
      )
    case BuilderImageVariant.SummaryBanner:
      return (
        <SummaryBannerEmptyStateBox>
          <Typography fontSize={variantFontSize[variant]} fontWeight={600} color={LanyardColors.Black48}>
            {variantText[variant]}
          </Typography>
        </SummaryBannerEmptyStateBox>
      )
    case BuilderImageVariant.BannerModal:
      return (
        <BannerModalImageEmptyStateBox>
          <Typography fontSize={variantFontSize[variant]} fontWeight={600} color={LanyardColors.Black48}>
            {variantText[variant]}
          </Typography>
        </BannerModalImageEmptyStateBox>
      )
  }
}
