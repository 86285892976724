import React from 'react'
import { useFormikContext } from 'formik'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { FormFieldFormDTO } from 'pages/consentAndRights/forms/fields/upsert/interfaces'
import Grid from '@mui/material/Grid'
import { DataGrid, Icon, TableCell } from '@ketch-com/deck'
import { FormInput } from 'components/form/FormInput'
import { TranslationDTO } from 'pages/settings/customRightName/components/LanguageCellRenderer'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { FormInlineEdit } from 'components/form/FormInlineEdit'

type DataGridTranslations = TranslationDTO & {
  rowIndex: number
}

export const FileInputConfig: React.FC = () => {
  const [isTranslationsExpanded, setIsTranslationsExpanded] = React.useState<boolean>(false)
  const { values, setFieldTouched, errors } = useFormikContext<FormFieldFormDTO>()

  const dataGridRows: DataGridTranslations[] =
    values?.translations.map((row, index) => ({ ...row, rowIndex: index })) || []

  return (
    <Box display="flex" flexDirection="column" p={2.25}>
      <Box
        p={2}
        mb={2}
        sx={{
          backgroundColor: theme => theme.palette.white.main,
          borderRadius: 2.75,
        }}
      >
        <Grid container spacing={3}>
          {/* Title Input */}

          <Grid item xs={6}>
            <FormInput
              required
              fullWidth
              formPropertyName="title"
              label="Display Name"
              placeholder="Add field label"
              shouldUpdateDebounced
            />
          </Grid>

          <Grid item xs={6} />

          {/* UI Hint Input */}

          <Grid item xs={6}>
            <FormInput
              fullWidth
              formPropertyName="uiHint"
              label="Hint Text"
              placeholder="Add hint text"
              onChange={e => {
                if (errors.uiHint?.includes('characters')) setFieldTouched('uiHint', true)
              }}
              shouldUpdateDebounced
              alwaysShowError
            />
          </Grid>

          <Grid item xs={6} />
        </Grid>

        {/* Translations Section Header */}

        <Box
          sx={{
            borderTop: theme =>
              `1px solid ${isTranslationsExpanded ? theme.palette.darkDusk.main : theme.palette.iron.main}`,
          }}
          display="flex"
          alignItems="center"
          onClick={() => setIsTranslationsExpanded(e => !e)}
          mt={2}
          mb={isTranslationsExpanded ? 2 : 0}
          pt={1.5}
        >
          <Icon name={isTranslationsExpanded ? 'OArrowCDown' : 'OArrowCRight'} />

          <Typography variant="label">Translations</Typography>
        </Box>

        {/* Dropdown Item Translations Inputs Table */}

        {isTranslationsExpanded && (
          <DataGrid
            autosizeOnMount
            autosizeOptions={{
              includeHeaders: true,
              includeOutliers: false,
              expand: true,
            }}
            getRowHeight={() => 'auto'}
            columns={[
              {
                align: 'left',
                field: 'language',
                flex: 1,
                headerAlign: 'left',
                headerName: 'Language',
                sortable: false,
                renderCell: ({ row }: GridRenderCellParams<DataGridTranslations>) => {
                  return <TableCell title={row.title} />
                },
              },
              {
                align: 'left',
                field: 'fieldTitleTranslation',
                flex: 1,
                headerAlign: 'left',
                headerName: 'Display Name Translation',
                sortable: false,
                renderCell: ({ row: { rowIndex } }: GridRenderCellParams<DataGridTranslations>) => {
                  return (
                    <TableCell>
                      <FormInlineEdit
                        sx={{
                          input: {
                            maxWidth: '250px !important',
                          },
                          '& .InlineEdit-content': {
                            '&.InlineEdit-medium': {
                              maxWidth: '250px',
                              height: 'auto',
                              minHeight: '32px',
                            },
                          },
                        }}
                        formPropertyName={`translations[${rowIndex}].fieldTitleTranslation`}
                        placeholder="Add Translation"
                        shouldUpdateDebounced
                        // necessary so it doesn't interfere with the data grid accesibility keyboard actions
                        onKeyDown={e => e.stopPropagation()}
                      />
                    </TableCell>
                  )
                },
              },
              {
                align: 'left',
                field: 'uiHintTranslation',
                flex: 1,
                headerAlign: 'left',
                headerName: 'Hint Text Translation',
                sortable: false,
                renderCell: ({ row: { rowIndex } }: GridRenderCellParams<DataGridTranslations>) => {
                  return (
                    <TableCell>
                      <FormInlineEdit
                        sx={{
                          input: {
                            maxWidth: '250px !important',
                          },
                          '& .InlineEdit-content': {
                            '&.InlineEdit-medium': {
                              maxWidth: '250px',
                              height: 'auto',
                              minHeight: '32px',
                            },
                          },
                        }}
                        formPropertyName={`translations[${rowIndex}].uiHintTranslation`}
                        placeholder="Add Translation"
                        shouldUpdateDebounced
                        // necessary so it doesn't interfere with the data grid accesibility keyboard actions
                        onKeyDown={e => e.stopPropagation()}
                      />
                    </TableCell>
                  )
                },
              },
            ]}
            disableChildrenSorting
            disableColumnMenu
            disableColumnPinning
            disableColumnResize
            disableColumnReorder
            disableRowSelectionOnClick
            disableRowHoverStates
            getRowId={row => row.code}
            rows={dataGridRows}
            hideFooter
          />
        )}
      </Box>
    </Box>
  )
}
