import { API } from 'utils/api/api'
import {
  AssessmentListTenantDocumentMap,
  ListExternalAssessmentsParams,
} from '@ketch-com/windlass/dist/assessments/externalUser'
import { formatRequestString } from 'utils/helpers/api/queryString'

export interface GetAssessmentsListExternalParams {
  limit?: ListExternalAssessmentsParams['page[limit]']
  name?: ListExternalAssessmentsParams['filter[name]']
  start?: ListExternalAssessmentsParams['page[start]']
  status?: ListExternalAssessmentsParams['filter[status]']
}

export const getAssessmentsListExternal = ({
  limit = 30,
  name,
  start = 0,
  status,
}: GetAssessmentsListExternalParams) => {
  const entityUrl = `/api/external-risk-review/resources/assessments`
  return API.get<AssessmentListTenantDocumentMap>(
    formatRequestString({
      entityUrl,
      params: {
        filters: {
          'page[start]': start,
          'page[limit]': limit,
          ...(name && { 'filter[name]': name }),
          ...(status && { 'filter[status]': status }),
        },
      },
    }),
  )
}
