import React from 'react'
import { useFormikContext } from 'formik'
import { PolicyScopeDTO } from 'interfaces/policyScopes-v2/policyScope'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { DeploymentPlanDTO } from 'interfaces/deploymentPlans-v2/deploymentPlan'
import { Banner, DataGrid, FormRow, UpsertLayoutMode } from '@ketch-com/deck'
import { Box, AlertColor } from '@mui/material'
import { useDeploymentPlanExperiencesColumns } from 'pages/consentAndRights/deploymentPlans-v2/upsert/components/deploymentPlanExperiences/hooks/useDeploymentPlanExperiencesColumns'

type Props = {
  policyScopes: PolicyScopeDTO[]
  experiences: ExperienceV2DTO[]
  upsertMode: UpsertLayoutMode
}

export const DeploymentPlanExperiences: React.FC<Props> = ({ policyScopes, experiences, upsertMode }) => {
  const { values } = useFormikContext()
  const columns = useDeploymentPlanExperiencesColumns(experiences, upsertMode)

  return (
    <FormRow title="Experiences" hasTopBorder>
      {(values as DeploymentPlanDTO)?.isOrchestrationOnly ? (
        <Box mb={2}>
          <Banner isCompact severity={'info' as AlertColor} variant={'filled'} title="Quiet mode is enabled">
            These configurations are not applicable. Quiet mode deployments will not serve experiences or generate
            consent.
          </Banner>
        </Box>
      ) : null}
      <DataGrid
        rows={policyScopes}
        columns={columns}
        loading={!policyScopes.length}
        autosizeOnMount
        disableChildrenSorting
        disableColumnMenu
        disableColumnPinning
        disableColumnReorder
        disableColumnResize
        disableRowHoverStates
        disableRowSelectionOnClick
        getRowHeight={() => 'auto'}
        getRowId={row => row.code}
        hideFooter
        sx={{
          padding: 'unset',
        }}
      />
    </FormRow>
  )
}
