// HOW TO USE:
// use .test in your yup validation.
// if the response is false, call logError('[fieldName]', context.createError({ message: 'Required' }).message);
// Example:
// value: Yup.string().required().test('log-value', 'Required', (value, context) => {
//     if (!value) logError('headers.value', context.createError({ message: 'Required' }).message);
//     return !!value;
//   }),

export const logError = (fieldName: string, errorMessage: string) => {
  console.log(`Validation Error - ${fieldName}: ${errorMessage}`)
}
