import { PutProcessingActivityStatusRequestBodyDTO } from '@ketch-com/figurehead'
import { API } from '../../../utils/api/api'
import { formatRequestString } from '../../../utils/helpers/api/queryString'

type Params = {
  formData: PutProcessingActivityStatusRequestBodyDTO
  activityId: string
  isV2FeatureFlagEnabled?: boolean
}

type Response = {}

export const updateProcessingActivityStatus = ({ formData, activityId, isV2FeatureFlagEnabled }: Params) => {
  const entityUrl = isV2FeatureFlagEnabled
    ? `/api/risk-review/processing-activities/${activityId}/status`
    : `/api/processing-activities/${activityId}/status`
  return API.put<Response>(
    formatRequestString({
      entityUrl,
    }),
    formData,
  )
}
