import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios'
import { getAuth0 } from 'components/providers/AuthProviderHelper'

const posthogPublicKey = (window as any)?.figureheadConfig?.REACT_APP_POSTHOG_PUBLIC_KEY
const checksumai = (window as any)?.checksumai

type CancelableRequest<R> = Promise<R> & { cancel: () => void }

const getIsUnauthenticatedRoute = (url: string): boolean => {
  if (url.includes('/auth/config')) return true
  if (url.includes('/provision/organization/') && url.includes('/available')) return true
  if (url.includes('/provision/new-signup')) return true
  if (url.includes('/provision/plans')) return true
  return false
}

const externalPrivacyPortalRoutes = (url: string): boolean => {
  if (url.includes('/external-risk-review')) return true
  return false
}

export class APIClient {
  client: AxiosInstance

  constructor(baseURL?: string) {
    const client = axios.create({
      baseURL,
      responseType: 'json',
    })

    client.interceptors.request.use(async config => {
      if (getIsUnauthenticatedRoute(config.url ?? '')) {
        return config
      }

      // External privacy portal routes use the magic link token exclusively
      if (externalPrivacyPortalRoutes(config.url ?? '')) {
        const token = localStorage.getItem('collaborationToken')
        if (token && config && config.headers) {
          config.headers['Authorization'] = `Bearer ${token}`
        } else if (!token && config && config.headers) {
          // strip token on logout
          config.headers['Authorization'] = ''
        }
        // Optional debugging
        // console.log('Making authenticated portal request:', config)

        return config
      } else {
        // Retrieve token from Auth0

        let token = null

        try {
          token = await getAuth0().getAccessTokenSilently()
        } catch (error) {}

        if (token && config && config.headers) {
          config.headers['Authorization'] = `Bearer ${token}`
        }
        return config
      }
    })

    client.interceptors.response.use(
      response => {
        if (!!posthogPublicKey) {
          checksumai?.capture?.('network_request', {
            url: response?.config?.url,
            request_method: response?.config?.method,
            request_payload: response?.config?.data,
            response_status: response?.status,
            response: response?.data,
          })
        }
        return response
      },
      error => {
        if (error.response) {
          if (error.response.status === 401) {
            // TODO - handle 401 from BE once data migration permissions ironed out
            // getAuth0().logout()
          }

          // Pass BE error to  original caller to handle
          return Promise.reject(error)
        }

        return Promise.reject()
      },
    )

    this.client = client
  }

  getCancelableAttributes() {
    const source = axios.CancelToken.source()

    return {
      cancelToken: source.token,
      cancel: () => {
        source.cancel()
      },
    }
  }

  get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig) {
    const { cancelToken, cancel } = this.getCancelableAttributes()

    const request = this.client.get(url, {
      ...config,
      cancelToken,
    }) as CancelableRequest<R>

    request.cancel = cancel

    return request
  }

  post<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig) {
    const { cancelToken, cancel } = this.getCancelableAttributes()

    const request = this.client.post(url, data, {
      ...config,
      cancelToken,
    }) as CancelableRequest<R>

    request.cancel = cancel

    return request
  }

  put<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig) {
    const { cancelToken, cancel } = this.getCancelableAttributes()

    const request = this.client.put(url, data, {
      ...config,
      cancelToken,
    }) as CancelableRequest<R>

    request.cancel = cancel

    return request
  }

  patch<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig) {
    const { cancelToken, cancel } = this.getCancelableAttributes()

    const request = this.client.patch(url, data, {
      ...config,
      cancelToken,
    }) as CancelableRequest<R>

    request.cancel = cancel

    return request
  }

  delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig) {
    const { cancelToken, cancel } = this.getCancelableAttributes()

    const request = this.client.delete(url, {
      ...config,
      cancelToken,
    }) as CancelableRequest<R>

    request.cancel = cancel

    return request
  }
}

export const API = new APIClient((window as any)?.figureheadConfig?.REACT_APP_BASE_URL)
