export const OPERATOR_OPTIONS = [
  { id: '==', name: 'Equals' },
  { id: '!=', name: 'Not Equals' },
  { id: '>', name: 'Greater Than' },
  { id: '<', name: 'Less Than' },
  { id: '<=', name: 'Less Than or Equal to' },
  { id: '>=', name: 'Greater Than or Equal to' },
  { id: 'contains', name: 'Contains' },
  { id: 'not_contains', name: "Doesn't Contain" },
]

export const APP_RESPONSE_VARIABLE_VALUE_OPTIONS = [
  { id: '"data_found"', name: 'Data Found' },
  { id: '"data_not_found"', name: 'Data Not Found' },
]
