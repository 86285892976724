import {
  AssessmentAttributeEvaluationSubject,
  AssessmentAttributeInitialAssigneeType,
  AssessmentDocument,
} from '@ketch-com/windlass/dist/assessments/externalUser'
import { useTeams } from 'api/teams/queries/useTeams'
import { Team } from 'interfaces/services/harbormaster/teams_gen.schemas'
import { fromAssignee, toAssignee } from 'utils/helpers/teamStringParser'
import { logError } from 'utils/helpers/validators/validationLogging'
import * as Yup from 'yup'

export type AssessmentCreateFormValues = {
  name: string
  template: string
  assignee: string
  initialAssigneeType: AssessmentAttributeInitialAssigneeType
  evaluationSubject?: AssessmentAttributeEvaluationSubject
  evaluationSubjectId?: string
}

export const getInitialAssessmentCreateFormValues = (): Omit<AssessmentCreateFormValues, 'initialAssigneeType'> => {
  return {
    name: '',
    template: '',
    assignee: '',
  }
}

export const useMapAssessmentCreateFormValuesToPayload = () => {
  const { data: teams } = useTeams({ params: { includeAssignee: true } })
  const teamsList: Team[] = teams?.teams || []

  return ({
    name,
    template,
    assignee,
    initialAssigneeType,
    evaluationSubject,
    evaluationSubjectId,
  }: AssessmentCreateFormValues): AssessmentDocument => {
    const [team, teamAssignee] = fromAssignee(assignee)

    const builtAssignee =
      team && !teamAssignee
        ? toAssignee(team, teamsList.find(team => team.id === assignee)?.assigneeId || '')
        : undefined

    return {
      data: {
        type: 'Assessment',
        relationships: {
          template: {
            data: { attributes: { publishedID: template } },
          },
        },
        attributes: {
          name,
          initialAssignee: builtAssignee ? builtAssignee : assignee && assignee.length > 0 ? assignee : undefined,
          initialAssigneeType,
          evaluationSubjectId: evaluationSubjectId,
          evaluationSubject: evaluationSubject,
        },
      },
    }
  }
}

export const emailValidationRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export const getCreateAssessmentValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required('Required'),
    template: Yup.string().required('Required'),
    assignee: Yup.string().test('is-valid', 'Invalid assignee', (value, context) => {
      if (!value) return true
      const isValid = emailValidationRegex.test(value) || /^(team\||auth0\|)/.test(value)
      if (!isValid)
        logError(
          'assignee',
          context.createError({ message: 'Invalid format: must be a valid email or start with team| or auth0|' })
            .message,
        )
      return isValid
    }),
  })
