import { MaybeNull } from 'interfaces'
import * as Yup from 'yup'
import { getMaxCharsErrorMessage } from 'utils/constants/forms/validationMessages'
import { IGetDecisionGatewayInitialValues } from './getDecisionGatewayInitialValues'

const MAX_NAME_LENGTH = 45
// const MAX_OPERAND_LENGTH = 256

interface IValidationParams {
  usedCodes?: Array<string>
}

const requiredIfAutomaticSelection = function (this: any, value?: MaybeNull<string | number>) {
  const ctx = this as Yup.TestContext & { from: [any, { value: IGetDecisionGatewayInitialValues }] }
  const { value: formValues } = ctx.from[1]

  return formValues.pathSelector === 'manual' || !!value
}

export const getDecisionGatewayValidationSchema = (params: IValidationParams) =>
  Yup.object().shape({
    code: Yup.string()
      .notOneOf(params?.usedCodes || [], 'This code is already in use')
      .required('This field is required'),
    description: Yup.string().required('Required'),
    descriptionLabel: Yup.string().required('Required'),
    name: Yup.string().required('Required'),
    assignee: Yup.string().required('Required'),
    pathSelector: Yup.string().required('Required'),
    timeToCompleteDays: Yup.number()
      .integer()
      .moreThan(-1, 'Number of days must be a whole number less than 365, or 0 to not require a due date.')
      .lessThan(365, 'Number of days must be a whole number less than 365, or 0 to not require a due date.'),
    transitions: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required('Required').max(MAX_NAME_LENGTH, getMaxCharsErrorMessage(MAX_NAME_LENGTH)),
          variableCategory: Yup.string(),
          description: Yup.string().required('Required'),
          variable: Yup.string().test('', 'Required', requiredIfAutomaticSelection),
          operator: Yup.string().test('', 'Required', requiredIfAutomaticSelection),
          operand: Yup.string().test('', 'Required', requiredIfAutomaticSelection),
          //TODO:JA This validation breaks checkbox selection validation. Fix for only when variableCategory = Form Field & Input Type = text
          // .max(MAX_OPERAND_LENGTH, getMaxCharsErrorMessage(MAX_OPERAND_LENGTH)),

          // "outcomeVariableStepID" is labeled "Activity" in the UI
          outcomeVariableStepID: Yup.string().when('variableCategory', {
            is: '2',
            then: Yup.string().required('Required'),
            otherwise: Yup.string(),
          }),
        }),
      )
      .test(
        'unique-app-response-operands',
        'A Decision Gateway cannot have multiple App Response paths for the same Activity and Variable Value',
        function (transitions) {
          if (!transitions) return true

          // Group transitions by outcomeVariableStepID
          const transitionsByActivity = transitions
            .filter(t => t.variable === 'app_response')
            .reduce(
              (acc, transition) => {
                const key = transition.outcomeVariableStepID || 'null'
                if (!acc[key]) acc[key] = []
                acc[key].push(transition.operand || '')
                return acc
              },
              {} as Record<string, string[]>,
            )

          // Check each activity group for duplicates
          return Object.values(transitionsByActivity).every(operands => {
            const uniqueOperands = new Set(operands)
            return uniqueOperands.size === operands.length
          })
        },
      ),
  })
