import { AssessmentDTO, AssessmentStatusDTO } from '@ketch-com/figurehead'
import Box from '@mui/material/Box'
import { AvatarSize, Banner, Chip, Icon, theme } from '@ketch-com/deck'
import Typography from '@mui/material/Typography'
import { formatDateTimeFromUnix } from 'utils/helpers/dateTime'
import { ProcessingActivityContext } from '../context/ProcessingActivityContext'
import React, { useContext } from 'react'
import { RoutesManager } from 'utils/routing/routesManager'
import { AssessmentStatusIcon } from '../../../assessments/components/AssessmentStatusIcon'
import { useUser } from 'api/users/queries/useUser'
import { isUndefined } from 'lodash'
import { AvatarGroup } from 'components/avatarGroup/AvatarGroup'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'

type Props = {
  assessment: AssessmentDTO
}

export const AssessmentDetails: React.FC<Props> = ({ assessment }) => {
  const { navigate } = useContext(ProcessingActivityContext)
  const { data: approver } = useUser({ params: { userId: assessment.meta?.updatedBy || '' } })

  const { completedQuestions, totalQuestions, status } = assessment?.attributes || {}

  const percentComplete = Math.round(((completedQuestions || 0) / (totalQuestions || 1)) * 100)

  const statusTitle =
    status === AssessmentStatusDTO.InReviewAssessmentStatus
      ? 'In Review'
      : status === AssessmentStatusDTO.ApprovedAssessmentStatus
      ? 'Approved'
      : status === AssessmentStatusDTO.ExpiredAssessmentStatus
      ? 'Revision Required'
      : `${percentComplete || 0}%`

  const statusSubtitle =
    status === AssessmentStatusDTO.ApprovedAssessmentStatus ? (
      <>
        {!isUndefined(approver?.firstName) || !isUndefined(approver?.lastName) ? (
          <>
            by{' '}
            <Typography variant="label">
              {approver?.firstName} {approver?.lastName}
            </Typography>
          </>
        ) : (
          <Typography variant="label">Unknown User</Typography>
        )}
      </>
    ) : status === AssessmentStatusDTO.ExpiredAssessmentStatus ? (
      ''
    ) : (
      `${completedQuestions || 0}/${totalQuestions || 0} Questions Complete`
    )

  const finishedInProgress =
    assessment?.attributes?.status === AssessmentStatusDTO.InProgressAssessmentStatus &&
    assessment?.attributes?.completedQuestions === assessment?.attributes?.totalQuestions

  const handleTemplateClick = () => {
    navigate(RoutesManager.policyCenter.assessments.edit.getURL({ code: assessment.id || '' }))
  }

  return (
    <Box display="flex" flexDirection="column" gap={3} maxWidth="100%">
      <Box display="flex" gap={2}>
        {/*<AssessmentProgressDetails assessment={assessment} />*/}
        <AssessmentStatusIcon assessment={assessment} width={42} height={42} />
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Typography variant="h4">{statusTitle}</Typography>
          <Typography variant="body" color={theme.palette.Text.Secondary}>
            {statusSubtitle}
          </Typography>
        </Box>
      </Box>

      {/* Notice for an assessment which is completed but not yet in review */}
      {finishedInProgress && (
        <Banner variant="standard" severity="info" isInfoBox>
          The assessment is complete but requires review before this Processing Activity can be approved.
        </Banner>
      )}

      {/* Notice for an assessment which has expired */}
      {status === AssessmentStatusDTO.ExpiredAssessmentStatus && (
        <Banner variant="standard" severity="info" isInfoBox>
          The assessment was conducted some time ago and might now need to be revised to ensure it's up to date.
        </Banner>
      )}

      {/* Assessment Name */}
      <Box display="flex" flexDirection="column" gap={0.5} mt={1.5} maxWidth="100%">
        <Typography variant="smallLabel" color={theme.palette.Text.Secondary}>
          Selected Assessment
        </Typography>
        <Chip
          label={
            <Box
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '100%',
              }}
            >
              {assessment.attributes?.name}
            </Box>
          }
          clickable
          deleteIcon={<Icon name="OArrowCRight" />}
          onDelete={handleTemplateClick}
          onClick={handleTemplateClick}
          sx={{ width: 'fit-content', maxWidth: '100%' }}
        />
      </Box>

      {/* Assessment Assignees */}
      <Box display="flex" flexDirection="column" gap={0.5}>
        <Typography variant="smallLabel" color={theme.palette.Text.Secondary}>
          Assignees
        </Typography>
        {assessment.attributes?.assigneeIds ? (
          <AvatarGroup userIds={assessment.attributes?.assigneeIds} maxCount={10} size={AvatarSize.medium} />
        ) : (
          <EmptyValueRenderer color="darkDuskFaded" />
        )}
      </Box>

      <Typography mt="auto" variant="footnote">
        Last Updated {formatDateTimeFromUnix(assessment?.meta?.updatedAt)}
      </Typography>
    </Box>
  )
}
