import { ActionSheetItem, ActionSheetWrapper, Button, Icon } from '@ketch-com/deck'
import { BulkEditModalType } from '../types'
import { useMemo, useState } from 'react'

interface BulkEditOptionMenuProps {
  triggerModal: (modalType: BulkEditModalType) => void
  currentCanonicalResourceLevel: string
}

const columnMenuItems = [{ value: BulkEditModalType.dataTypes, label: 'Data Types' }]
const databaseMenuItems = [{ value: BulkEditModalType.dataOwner, label: 'Data Owner' }]

export const BulkEditOptionMenu: React.FC<BulkEditOptionMenuProps> = ({
  triggerModal,
  currentCanonicalResourceLevel,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const menuItems = useMemo(
    () =>
      currentCanonicalResourceLevel === 'Database'
        ? databaseMenuItems
        : currentCanonicalResourceLevel === 'Column'
        ? columnMenuItems
        : [],
    [currentCanonicalResourceLevel],
  )

  return (
    <>
      <Button color="tertiary" onClick={handleButtonClick} endIcon={<Icon name="OArrowCDown" />}>
        More
      </Button>
      <ActionSheetWrapper anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
        {menuItems.map(item => (
          <ActionSheetItem
            key={item.value}
            onClick={() => {
              triggerModal(item.value)
            }}
          >
            {item.label}
          </ActionSheetItem>
        ))}
      </ActionSheetWrapper>
    </>
  )
}
