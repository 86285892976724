import { useContext } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context/RightInvocationWorkflowStepDetailViewContext'
import { SectionDescription } from 'pages/consentAndRights/forms/templates/components'
import { SelectedPath, ResolutionNotes, Attachments, Assignee } from './components'
import { logObjectDetails } from 'utils/helpers/logObjectDetails'
import { useAuthenticator } from 'api/authenticators/queries/useAuthenticator'
import { Chip, ContentGroup, InfoRow } from '@ketch-com/deck'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { IdentityVerificationContext } from '../../context/IdentityVerificationContext'

export const IdvDetails: React.FC = () => {
  const { stepDetails, workflowStep, isWorkflowExecutionStepFailed } = useContext(
    RightInvocationWorkflowStepDetailViewContext,
  )
  const IDME_MATCHING_ERROR =
    'The first and last name submitted with the DSR do not match the first and last name verified for the identity.'

  const { sanitizedStepError } = useContext(IdentityVerificationContext)

  let stepError
  if (isWorkflowExecutionStepFailed || sanitizedStepError === IDME_MATCHING_ERROR) {
    stepError = sanitizedStepError
  }

  const { data: authenticator, isLoading: isLoadingAuthenticator } = useAuthenticator({
    enabled: !!stepDetails?.executionData?.idv?.authenticatorId,
    params: {
      authenticatorId: stepDetails?.executionData?.idv?.authenticatorId || '',
    },
  })

  logObjectDetails(
    {
      stepDetails,
      workflowStep,
      results: stepDetails?.results,
      authenticator,
    },
    'IdvDetails.tsx',
    'white',
    false, // false ensures will log only in debug mode (i.e. ?debug=log)
  )

  return (
    <Box display="flex" flexDirection="column" gap={6} mb={6}>
      <Box display="flex" flexDirection="column" gap={4}>
        <ContentGroup variant="inner-page-header" titleVariant="h3" title="Error" />

        <InfoRow title="Error Details">
          {stepError ? <Typography>{stepError}</Typography> : <EmptyValueRenderer />}
        </InfoRow>
      </Box>
      <Box display="flex" flexDirection="column" gap={4}>
        <ContentGroup variant="inner-page-header" titleVariant="h3" title="Details" />
        {/* Assignee */}
        <Assignee />
      </Box>
      <Box display="flex" flexDirection="column" gap={4}>
        <ContentGroup variant="inner-page-header" titleVariant="h3" title="Email Notification" />
        <InfoRow title="Subject">
          {stepDetails?.executionData?.email?.subject ? (
            stepDetails?.executionData?.email?.subject
          ) : (
            <EmptyValueRenderer />
          )}
        </InfoRow>
        <InfoRow title="Body">
          {stepDetails?.executionData?.email?.body ? (
            <SectionDescription description={stepDetails?.executionData?.email?.body} />
          ) : (
            <EmptyValueRenderer />
          )}
        </InfoRow>
      </Box>
      <Box display="flex" flexDirection="column" gap={4}>
        <ContentGroup variant="inner-page-header" titleVariant="h3" title="Verification Method" />
        <InfoRow title="Method" isLoading={isLoadingAuthenticator}>
          {stepDetails?.executionData?.idv?.isStandard ? (
            'Standard'
          ) : !authenticator?.name ? (
            'ID.ME'
          ) : (
            <Box>
              <Chip
                label={
                  <Typography variant="label">
                    Authenticator: <Typography>{authenticator?.name || ''}</Typography>
                  </Typography>
                }
              />
            </Box>
          )}
        </InfoRow>
      </Box>
      {isWorkflowExecutionStepFailed && (
        <Box display="flex" flexDirection="column" gap={4}>
          <ContentGroup
            titleVariant="h3"
            variant="inner-page-header"
            title="Manual Intervention"
            subTitle="Specify Manual Intervention details."
          />

          {/* Selected Path Input, Verified or Not Verified */}

          <SelectedPath />

          <ResolutionNotes />

          <Attachments />
        </Box>
      )}
    </Box>
  )
}
