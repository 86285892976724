import { AssessmentBlockCompletionStatusDTO, AssessmentBlockCompletionStatusObjectDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

export interface UpdateExternalBlockCompletionStatusParams {
  includeMetadata?: boolean
  assessmentId: string
  blockId: string
  completionStatus: AssessmentBlockCompletionStatusDTO
}

export const updateExternalBlockCompletionStatus = ({
  assessmentId,
  blockId,
  completionStatus,
}: UpdateExternalBlockCompletionStatusParams) => {
  const entityUrl = `/api/external-risk-review/resources/assessments/${assessmentId}/blocks/${blockId}/completion-status`
  return API.put<AssessmentBlockCompletionStatusObjectDTO>(
    formatRequestString({
      entityUrl,
    }),
    { completionStatus },
  )
}
