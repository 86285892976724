import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { MaybeNull } from 'interfaces/common'
import { DeploymentPlanDTO } from 'interfaces/deploymentPlans-v2/deploymentPlan'
import { PolicyScopeDTO } from 'interfaces/policyScopes-v2/policyScope'
import { PolicyDocumentDTO } from 'interfaces/policyDocuments/policyDocument'
import { RoutesManager } from 'utils/routing/routesManager'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { useValidationSchema, DeploymentPlanFormValues } from 'pages/consentAndRights/deploymentPlans-v2/upsert/utils'
import { getInitialValues } from 'pages/consentAndRights/deploymentPlans-v2/upsert/edit/utils'
import { ContentGroup, Spinner, UpsertLayout, UpsertLayoutMode } from '@ketch-com/deck'
import { DeploymentPlanBasicDetails } from 'pages/consentAndRights/deploymentPlans-v2/upsert/components/DeploymentPlanBasicDetails'
import { DeploymentPlanExperiences } from 'pages/consentAndRights/deploymentPlans-v2/upsert/components/deploymentPlanExperiences/DeploymentPlanExperiences'
import { DeploymentPlanDocuments } from 'pages/consentAndRights/deploymentPlans-v2/upsert/components/deploymentPlanDocuments/DeploymentPlanDocuments'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { ThemeV3DTO } from 'interfaces/themes-v3/theme'
import { isRelease } from 'utils/releases'
import { Release } from 'utils/releases/RELEASES'
import { FormikProvider, useFormik } from 'formik'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { Box } from '@mui/material'

type Props = {
  isReady: boolean
  deploymentPlan: MaybeNull<DeploymentPlanDTO>
  policyScopes: PolicyScopeDTO[]
  experiences: ExperienceV2DTO[]
  policyDocuments: PolicyDocumentDTO[]
  themes: ThemeV3DTO[]
  onSubmit: (values: DeploymentPlanFormValues) => Promise<void>
}

export const DeploymentPlanEdit: React.FC<Props> = ({
  isReady,
  deploymentPlan,
  policyScopes,
  experiences,
  policyDocuments,
  themes,
  onSubmit,
}) => {
  const navigate = useNavigate()
  const { isEntitled } = useIsEntitled()

  const name = deploymentPlan?.name
  const code = deploymentPlan?.code
  const isEntitledToContentAndDisclosure = isEntitled(ENTITLEMENTS.EXP_SERVER_CONSENT_AND_DISCLOSURE)

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
    { title: 'Deployment Plans', link: RoutesManager.deployment.deploymentPlansV2.root.getURL() },
    {
      title: name || code,
      link: RoutesManager.deployment.deploymentPlansV2.view.root.getURL({ code }),
    },
    { title: 'Edit Plan' },
  ]

  const initialValues = useMemo(
    () => getInitialValues({ deploymentPlan, policyScopes }),
    [deploymentPlan, policyScopes],
  )

  const validationSchema = useValidationSchema({ isEditMode: true, isEntitledToContentAndDisclosure })

  const formikProps = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  })
  const { submitForm, isSubmitting } = formikProps

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <FormikProvider value={formikProps}>
        <UpsertLayout
          mode={UpsertLayoutMode.edit}
          renderFormTitle={() => <ContentGroup title="Edit Deployment Plan" titleVariant="h2" isDivider />}
          onAccept={submitForm}
          onCancel={() => navigate(RoutesManager.deployment.deploymentPlansV2.view.root.getURL({ code }))}
          acceptButtonProps={{ pending: isSubmitting, disabled: isSubmitting || !isReady }}
          cancelButtonProps={{ pending: isSubmitting }}
        >
          {isReady ? (
            <Box>
              {/* Filters themes with the summary field as a way to only show V2 themes */}
              <DeploymentPlanBasicDetails isEditMode themes={themes.filter(theme => !!theme.summary)} />
              <DeploymentPlanExperiences
                policyScopes={policyScopes}
                experiences={experiences}
                upsertMode={UpsertLayoutMode.edit}
              />
              {!isRelease(Release.PropertyThemeDocuments) && (
                <DeploymentPlanDocuments policyDocuments={policyDocuments} editMode={true} />
              )}
            </Box>
          ) : (
            <Box
              sx={{
                margin: '100px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spinner size="32px" thickness={2.5} />
            </Box>
          )}
        </UpsertLayout>
      </FormikProvider>
    </>
  )
}
