import { intersection } from 'lodash'

import { LegalBaseDTO } from 'interfaces/legalBases/legalBase'
import { PolicyScopeDTO } from 'interfaces/policyScopes/policyScope'
import {
  CONSENT_OPTIN_LEGAL_BASIS_CODE,
  GDPR_REGULATION_CODE,
  LEGIT_INTEREST_LEGAL_BASIS_CODE,
  LEGIT_INTEREST_OBJECTABLE_LEGAL_BASIS_CODE,
  TCF_FEATURE_CODE_PREFIX,
  TCF_PURPOSE_1_CODE,
  TCF_PURPOSE_CODE_PREFIX,
  TCF_SPECIAL_FEATURE_CODE_PREFIX,
  TCF_SPECIAL_PURPOSE_CODE_PREFIX,
} from 'utils/constants/tcf'
import { Box, styled } from '@mui/material'

export const getValidLegalBasesForPolicyScope = ({
  regulationCodes,
  legalBases,
  purposeCode,
}: {
  regulationCodes: string[]
  legalBases: LegalBaseDTO[]
  purposeCode?: string
}) => {
  if (
    regulationCodes?.includes(GDPR_REGULATION_CODE) &&
    (purposeCode === TCF_PURPOSE_1_CODE || purposeCode?.startsWith(TCF_SPECIAL_FEATURE_CODE_PREFIX))
  ) {
    return legalBases.filter(legalBasis => legalBasis.code === CONSENT_OPTIN_LEGAL_BASIS_CODE)
  }

  if (regulationCodes?.includes(GDPR_REGULATION_CODE) && purposeCode?.startsWith(TCF_PURPOSE_CODE_PREFIX)) {
    return legalBases.filter(
      legalBasis =>
        legalBasis.code === CONSENT_OPTIN_LEGAL_BASIS_CODE ||
        legalBasis.code === LEGIT_INTEREST_OBJECTABLE_LEGAL_BASIS_CODE,
    )
  }

  if (
    regulationCodes?.includes(GDPR_REGULATION_CODE) &&
    (purposeCode?.startsWith(TCF_SPECIAL_PURPOSE_CODE_PREFIX) || purposeCode?.startsWith(TCF_FEATURE_CODE_PREFIX))
  ) {
    return legalBases.filter(legalBasis => legalBasis.code === LEGIT_INTEREST_LEGAL_BASIS_CODE)
  }

  return legalBases.filter(legalBase => intersection(regulationCodes, legalBase.regulationCodes).length)
}

export const getValidPolicyScopesForPurpose = ({
  policyScopes,
  legalBases,
  purposeCode,
}: {
  policyScopes: PolicyScopeDTO[]
  legalBases: LegalBaseDTO[]
  purposeCode?: string
}) =>
  policyScopes.filter(
    policyScope =>
      !!getValidLegalBasesForPolicyScope({
        regulationCodes: policyScope.regulationCodes || [],
        legalBases,
        purposeCode,
      })?.length,
  )

export const tableClasses = {
  tableContainer: 'PurposeTableContainer',
}

export const StyledBox = styled(Box)(({ theme: { spacing } }) => ({
  [`&.${tableClasses.tableContainer}`]: {
    width: `calc(100% + ${spacing(4)})`,
    marginLeft: spacing(-2),
  },
}))
