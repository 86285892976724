import React from 'react'
import moment from 'moment'
import { Link, useNavigate } from 'react-router-dom'
import { Typography, Box } from '@mui/material'
import { Avatar, AvatarSize, Button, theme } from '@ketch-com/deck'
import { TaskDTO } from '@ketch-com/figurehead'
import {
  getAssignedByHeaderByTaskType,
  getDisplayIdentifierHeaderByTaskType,
  getRedirectUrlByTaskType,
  isTagSyncedTaskType,
  getConnectionNameFromTagSyncedTaskTitle,
} from '../utils/getDisplayDetails'
import { ActivityTimerChip } from 'pages/orchestration/rightsQueue/components/ActivityTimerChip'
import { UserAvatar } from 'components/avatar/UserAvatar'
import { TeamChip } from 'components/chip/TeamChip'

type Props = {
  isLast?: boolean
  task: TaskDTO
}

export const TaskCardTodo: React.FC<Props> = ({ task, isLast }) => {
  const navigate = useNavigate()
  const isAutomated = task?.isAutomatedAssignedBy
  const imageURL = task?.assignedByMember?.imageURL
  const imageURLIncludesGravatar = imageURL?.includes?.('gravatar') // if true, then UI will not render because it doesn't match our design system
  const dueAt = task?.metadata?.dueAt
  const team = task?.principalTeam

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
        paddingBottom: 3,
        borderBottom: !isLast ? `1px solid` : 'none',
        borderColor: 'iron.main',
      }}
    >
      {/* Row 1 */}

      <Box display="flex" alignItems="center" justifyContent="space-between" mb={4}>
        {/* Left */}

        <Box display="flex" alignItems="center" gap={2}>
          {isAutomated ? (
            <Avatar
              icon="FAutomation"
              size={AvatarSize.large}
              variant="circular"
              backgroundColor={theme.palette.iron.main}
              textColor={theme.palette.Text.Secondary}
            />
          ) : imageURL && !imageURLIncludesGravatar ? (
            <Avatar
              variant="circular"
              alt={[task?.assignedByMember?.firstName, task?.assignedByMember?.lastName].filter(Boolean).join(' ')}
              src={task?.assignedByMember?.imageURL}
              size={AvatarSize.large}
            />
          ) : (
            <UserAvatar user={task?.assignedByMember} size={AvatarSize.large} />
          )}
          <Typography variant="h4">{task.title}</Typography>
          {dueAt ? <ActivityTimerChip dueAt={dueAt} includeTimerString /> : null}
        </Box>

        {/* Right */}

        <Box display="flex" alignItems="center">
          <Typography variant="body" mr={2}>
            {moment((task?.metadata?.createdAt || 0) * 1000).fromNow()}
          </Typography>

          <Link
            to={task?.viewUrl || '#'}
            style={{
              textDecoration: 'none',
            }}
          >
            <Button color="primary">View</Button>
          </Link>
        </Box>
      </Box>

      {/* Row 2 */}

      <Box display="flex" gap={2} justifyContent="space-between">
        {/* Left */}

        <Box display="flex" flex={2} gap={3}>
          {/* Col - 1 */}

          <Box flex={1} display="flex" flexDirection="column">
            <Typography variant="smallLabel" color="darkDuskFaded.main">
              {getAssignedByHeaderByTaskType(task?.taskType)}
            </Typography>
            <Typography variant="body">
              {task?.isAutomatedAssignedBy
                ? 'Automated'
                : `${[task?.assignedByMember?.firstName, task?.assignedByMember?.lastName].filter(Boolean).join(' ')}`}
            </Typography>
            {!!team ? (
              <Box mt={2} display="flex" flexDirection="column" gap={0.5}>
                <Typography component="div" variant="smallLabel" color="darkDuskFaded.main">
                  Assigned Via Team
                </Typography>
                <Box>
                  <TeamChip team={team} />
                </Box>
              </Box>
            ) : null}
          </Box>

          {/* Col - 2 */}

          <Box flex={2} display="flex" flexDirection="column">
            <Typography variant="smallLabel" color="darkDuskFaded.main">
              {task.requestId ? getDisplayIdentifierHeaderByTaskType(task?.taskType) : ''}
            </Typography>

            <Typography
              variant="body"
              color="sphere.main"
              sx={{
                cursor: 'pointer',
              }}
              onClick={() => navigate(getRedirectUrlByTaskType(task?.taskType, task?.requestId, task?.viewUrl))}
            >
              {isTagSyncedTaskType(task?.taskType)
                ? getConnectionNameFromTagSyncedTaskTitle(task?.title)
                : task.requestId}
            </Typography>
          </Box>
        </Box>

        {/* Right */}
        <Box flex={1} display="flex" flexDirection="column">
          <Typography variant="smallLabel" color="darkDuskFaded.main">
            Details
          </Typography>
          <Typography variant="body">{task.details}</Typography>
        </Box>
      </Box>
    </Box>
  )
}
