import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { PutProcessingActivityResponseBodyDTO, PutProcessingActivityRequestBodyDTO } from '@ketch-com/figurehead'

type Params = {
  id: string
  formData: PutProcessingActivityRequestBodyDTO
  isV2FeatureFlagEnabled?: boolean
}

export const editProcessingActivity = ({ id, formData, isV2FeatureFlagEnabled }: Params) => {
  const entityUrl = isV2FeatureFlagEnabled
    ? `/api/risk-review/processing-activities/${id}`
    : `/api/processing-activities/${id}`
  return API.put<PutProcessingActivityResponseBodyDTO>(
    formatRequestString({
      entityUrl,
    }),
    formData,
  )
}
