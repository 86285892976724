import { Box } from '@mui/material'
import { Error } from 'components/ui-kit/form/common/error/Error'
import { TextInput } from '@ketch-com/deck'
import { useExternalAssessmentResponse } from '../../context/ExternalAssessmentResponseContext'

export const AssessmentExternalShortAnswerInput: React.FC<{}> = () => {
  const { value, isValid, changeValue } = useExternalAssessmentResponse()

  return (
    <Box>
      <TextInput
        fullWidth
        value={value}
        onChange={evt => changeValue(evt.target.value)}
        name="answer"
        error={!isValid}
      />
      {!isValid ? <Error>Required</Error> : null}
    </Box>
  )
}
