import { PieChart, Pie, Cell } from 'recharts'

export interface ProgressIndicatorStyle {
  bgcolor: string
  fgcolor: string
  highlightColor: string
}
export interface ProgressIndicatorProps {
  percent?: number
  width?: number
  style: ProgressIndicatorStyle
}

export const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({ style, percent = 0, width = 28 }) => {
  const data = [
    { name: 'Other', percent: 100 - percent, color: style.highlightColor },
    { name: 'Total', percent, color: style.fgcolor },
  ]

  return (
    <PieChart
      width={width}
      height={width}
      data={data}
      margin={{
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
      }}
    >
      <Pie
        data={data}
        cx={width / 2}
        cy={width / 2}
        innerRadius={width * 0.3}
        outerRadius={width * 0.4}
        cornerRadius={25}
        fill="#00000000"
        stroke="#00000000"
        paddingAngle={0}
        dataKey="percent"
        startAngle={90}
        endAngle={470}
      >
        {data.map((entry, index) => (
          <Cell style={{ outline: 'none' }} key={`cell-${index}`} fill={entry.color} />
        ))}
      </Pie>
    </PieChart>
  )
}
