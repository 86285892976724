import React from 'react'
import { Box, Typography } from '@mui/material'
import { Chip, Status, StatusState, StatusVariant } from '@ketch-com/deck'
import { DeploymentPlanDTO } from 'interfaces/deploymentPlans-v2/deploymentPlan'
import { SENSITIVE_DEPLOYMENT_PLANS_STATUSES } from 'interfaces/deploymentPlans-v2/deploymentPlanStatus'
import { Badge } from 'components/ui-kit/badge/Badge'

type Props = {
  isLatest: boolean
  deploymentPlan: DeploymentPlanDTO
}

export const DeploymentPlanViewDetails: React.FC<Props> = ({ isLatest, deploymentPlan }) => {
  const { code, status, version, isOrchestrationOnly } = deploymentPlan

  const isDeployed = SENSITIVE_DEPLOYMENT_PLANS_STATUSES.includes(status)
  const statusLabel = isDeployed ? 'Deployed' : 'Undeployed'
  const statusState = isDeployed ? StatusState.success : StatusState.draft

  return (
    <Box display="flex" gap={1}>
      <Status label={statusLabel} status={statusState} variant={StatusVariant.outlined} icon="Dot" />

      {isOrchestrationOnly ? (
        <Chip
          clickable
          size="small"
          label={<Typography variant="smallLabel">Quiet Mode</Typography>}
          sx={{ cursor: 'auto' }}
        />
      ) : null}

      {!isLatest && <Badge variant="outline">v{version}</Badge>}

      <Chip
        size="small"
        label={
          <Typography variant="smallLabel">
            Code: <Typography variant="smallBody">{code}</Typography>
          </Typography>
        }
      />
    </Box>
  )
}
