import { useMemo } from 'react'
import * as Yup from 'yup'

import { useCodeValidationSchema } from 'utils/helpers/validators/useCodeValidationSchema'
import { useCheckIsFormFieldCodeAvailable } from 'api/formFields/mutations/useCheckIsFormFieldCodeAvailable'
import { getMaxCharsErrorMessage, validationMessages } from 'utils/constants/forms/validationMessages'
import { MAX_CODE_LENGTH, MAX_HINT_LENGTH, MAX_TITLE_LENGTH } from '../../constants/validationCharLengths'

const { REQUIRED } = validationMessages

export const useBasicDetailsValidation = ({ isEditMode = false }: { isEditMode?: boolean } = {}) => {
  const { mutateAsync: handleCheckIsPermissionCodeAvailable } = useCheckIsFormFieldCodeAvailable()

  const codeValidationSchema = useCodeValidationSchema({
    handler: value =>
      handleCheckIsPermissionCodeAvailable({
        params: {
          code: value,
        },
      }),
  })

  return useMemo(
    () =>
      Yup.object().shape({
        ...(isEditMode ? {} : { code: codeValidationSchema }),
        name: Yup.string().required(REQUIRED).max(MAX_TITLE_LENGTH, getMaxCharsErrorMessage(MAX_TITLE_LENGTH)),

        title: Yup.string()
          .when('inputType', {
            is: 'text',
            then: Yup.string().required(REQUIRED).max(MAX_TITLE_LENGTH, getMaxCharsErrorMessage(MAX_TITLE_LENGTH)),
          })
          .when('inputType', {
            is: 'dropdown',
            then: Yup.string().required(REQUIRED).max(MAX_TITLE_LENGTH, getMaxCharsErrorMessage(MAX_TITLE_LENGTH)),
          })
          .when('inputType', {
            is: 'checkbox',
            then: Yup.string(),
          })
          .when('inputType', {
            is: 'file',
            then: Yup.string().required(REQUIRED).max(MAX_TITLE_LENGTH, getMaxCharsErrorMessage(MAX_TITLE_LENGTH)),
          }),

        uiHint: Yup.string().max(MAX_HINT_LENGTH, getMaxCharsErrorMessage(MAX_HINT_LENGTH)),
        translations: Yup.array()
          .of(
            Yup.object().shape({
              code: Yup.string().required(REQUIRED).max(MAX_CODE_LENGTH, getMaxCharsErrorMessage(MAX_CODE_LENGTH)),
              title: Yup.string().required(REQUIRED).max(MAX_TITLE_LENGTH, getMaxCharsErrorMessage(MAX_TITLE_LENGTH)),
              fieldTitleTranslation: Yup.string().max(MAX_TITLE_LENGTH, getMaxCharsErrorMessage(MAX_TITLE_LENGTH)),
              uiHintTranslation: Yup.string().max(MAX_HINT_LENGTH, getMaxCharsErrorMessage(MAX_HINT_LENGTH)),
            }),
          )
          .required(REQUIRED),
      }),
    [isEditMode, codeValidationSchema],
  )
}
