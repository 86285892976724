import React from 'react'
import { FileInfoDTO } from 'interfaces/files/fileInfo'
import { Box } from '@mui/material'
import { DownloadLinkChip } from '../chip/DownloadLinkChip'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'

type Props = {
  attachments: FileInfoDTO[]
}

export const AttachmentsList: React.FC<Props> = ({ attachments }) => {
  return (
    <Box>
      {!!attachments.length ? (
        attachments.map(attachment => (
          <Box display="flex" alignItems="center" key={attachment?.name} py={0.875}>
            <DownloadLinkChip
              fileName={attachment?.name}
              contentType={attachment?.content_type}
              downloadUrl={attachment?.download_url}
            />
          </Box>
        ))
      ) : (
        <EmptyValueRenderer />
      )}
    </Box>
  )
}
