import React from 'react'

import { DeploymentPlanDTO } from 'interfaces/deploymentPlans-v2/deploymentPlan'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { GridColDef } from '@mui/x-data-grid-premium'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { Button, Chip, ContentGroup, DataGrid, Status, StatusState, StatusVariant, TableCell } from '@ketch-com/deck'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { FirstNameLastNameRenderer } from 'components/renderers/FirstNameLastNameRenderer'
import { Box } from '@mui/material'

type Props = {
  deploymentPlan: DeploymentPlanDTO
  versions: DeploymentPlanDTO[]
}

export const DeploymentPlanVersions: React.FC<Props> = ({ deploymentPlan, versions }) => {
  const { queries, setQueryParam, removeQueryParam } = useQueryParams<{ version?: string }>()
  const currentVersion = queries.version ? +queries.version : deploymentPlan.version

  const columns = React.useMemo(
    (): GridColDef<DeploymentPlanDTO>[] => [
      {
        align: 'left',
        field: 'status',
        flex: 1,
        headerAlign: 'left',
        headerName: 'Status',
        sortable: false,
        width: 110,
        maxWidth: 110,
        renderCell: ({ row: { latest } }) => (
          <TableCell>
            {!!latest && (
              <Status icon="Dot" label="Latest" status={StatusState.success} variant={StatusVariant.ghost} />
            )}
          </TableCell>
        ),
      },
      {
        align: 'left',
        field: 'version',
        flex: 1,
        headerAlign: 'left',
        headerName: 'Version',
        sortable: false,
        width: 200,
        maxWidth: 200,
        renderCell: ({ row: { version } }) => (
          <TableCell>
            <Chip size="small" label={`v${version}`} />
          </TableCell>
        ),
      },
      {
        align: 'left',
        field: 'deploymentPlan',
        flex: 1,
        width: 450,
        maxWidth: 450,
        headerAlign: 'left',
        headerName: 'Deployment Plan',
        sortable: false,
        renderCell: ({ row: { name, code } }) => <NameAndCodeCellRenderer name={name} code={code} />,
      },
      {
        align: 'left',
        field: 'created',
        width: 250,
        maxWidth: 250,
        headerAlign: 'left',
        headerName: 'Created',
        sortable: false,
        renderCell: ({ row: { version } }) => <FormattedDateRenderer date={version} />,
      },
      {
        align: 'left',
        field: 'createdBy',
        width: 200,
        maxWidth: 200,
        headerAlign: 'left',
        headerName: 'Created By',
        sortable: false,
        renderCell: ({ row: { audit } }) => (
          <Box padding="16px 0">
            <FirstNameLastNameRenderer firstName={audit?.updatedBy?.firstName} lastName={audit?.updatedBy?.lastName} />
          </Box>
        ),
      },
      {
        align: 'right',
        field: 'viewButton',
        headerAlign: 'right',
        headerName: '',
        sortable: false,
        renderCell: ({ row: { version, latest } }) => (
          <Button
            disabled={!version || version === currentVersion}
            color="tertiary"
            onClick={() => {
              if (latest) {
                if (queries.version) {
                  removeQueryParam('version')
                }
              } else {
                if (version) {
                  setQueryParam('version', version)
                }
              }
            }}
          >
            View
          </Button>
        ),
      },
    ],
    [currentVersion, queries.version, setQueryParam, removeQueryParam],
  )

  return (
    <>
      <ContentGroup title="Version History" titleVariant="h3" variant="inner-page-header" />

      {versions.length ? (
        <DataGrid
          rows={versions}
          columns={columns}
          autosizeOnMount
          disableChildrenSorting
          disableColumnMenu
          disableColumnPinning
          disableColumnReorder
          disableColumnResize
          disableRowHoverStates
          disableRowSelectionOnClick
          getRowHeight={() => 'auto'}
          getRowId={row => row.version}
          hideFooter
          sx={{
            padding: 'unset',
            mt: '24px',
          }}
        />
      ) : (
        <EmptyState
          bgVariant="grey"
          variant="page"
          title="No versions exist for the selected deployment plan"
          description="As you make changes to this deployment plan, you can view that history here"
        />
      )}
    </>
  )
}
