import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from 'api/common/mutation'
import { createAssessment } from '../fetchers/createAssessment'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'
import { useFeatureFlag } from 'utils/hooks'

const useCustomMutation = createUseMutation({
  mutationFn: createAssessment,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useCreateAssessment = (config?: Config) => {
  const mutation = useCustomMutation(config)
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isV2FeatureFlagEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.ASSESSMENT,
    FEATURE_FLAGS_VALUES.ASSESSMENTS_VERSION,
  )

  return useBindMutationStaticParams(mutation, {
    isV2FeatureFlagEnabled,
  })
}
