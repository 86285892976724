import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import { InfoRow } from '@ketch-com/deck'
import { FormFieldTypeDTO } from '@ketch-com/figurehead'
import React, { useContext } from 'react'
import { RightsQueueViewContext } from '../../../context/RightsQueueViewContext'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { ViewFile } from 'components/cloudinary/ViewFile'
import type { CloudinaryResource } from 'interfaces/cloudinary/cloudinary'

export const FormData: React.FC = () => {
  const { rightInvocation } = useContext(RightsQueueViewContext)

  return (
    <>
      {rightInvocation?.user?.formData?.length &&
        rightInvocation?.user?.formData?.map((field, idx) => {
          if (!field.value) return null

          let fieldElements = (
            <Box
              key={field?.name}
              display="flex"
              alignItems="center"
              gap={1}
              sx={{
                wordBreak: 'break-word',
              }}
            >
              {field.value ? (
                <Typography
                  variant="body"
                  sx={{
                    wordBreak: 'break-word',
                  }}
                >
                  {field?.value}
                </Typography>
              ) : (
                <EmptyValueRenderer />
              )}
            </Box>
          )
          switch (field?.type) {
            case FormFieldTypeDTO.CheckboxFormFieldType:
              fieldElements = (
                <Box
                  component="ul"
                  pl={!!field?.values?.length ? 2.5 : 0}
                  my={0}
                  sx={{
                    listStyle: 'initial',
                  }}
                >
                  {!!field?.values?.length ? (
                    field?.values?.map?.(val => {
                      return (
                        <Box
                          component="li"
                          key={val}
                          sx={{
                            wordBreak: 'break-word',
                          }}
                        >
                          <Typography
                            variant="body"
                            sx={{
                              wordBreak: 'break-word',
                            }}
                          >
                            {field?.options?.find(option => option.value === val)?.label}
                          </Typography>
                        </Box>
                      )
                    })
                  ) : (
                    <EmptyValueRenderer />
                  )}
                </Box>
              )
              break

            case FormFieldTypeDTO.FileFormFieldType:
              const cloudinaryResource: CloudinaryResource = JSON.parse(field?.value) || {}
              const hasCloudinaryUploadData = cloudinaryResource?.secure_url && cloudinaryResource?.public_id
              if (hasCloudinaryUploadData) {
                fieldElements = (
                  <Box
                    key={field?.name}
                    display="flex"
                    alignItems="center"
                    gap={1}
                    sx={{
                      wordBreak: 'break-word',
                    }}
                  >
                    <ViewFile cloudinaryResource={cloudinaryResource} />
                  </Box>
                )
              } else fieldElements = <></>

              break
          }

          return (
            <InfoRow key={`${field?.name}_${field?.value}_${idx}`} title={field?.name || ''}>
              {fieldElements}
            </InfoRow>
          )
        })}
    </>
  )
}
