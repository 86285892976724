import { isRelease } from 'utils/releases'
import { Release } from 'utils/releases/RELEASES'
import {
  BannerExperienceBuilderSection,
  BannerThemeBuilderSection,
  ModalExperienceBuilderSection,
  ModalThemeBuilderSection,
  PreferenceExperienceBuilderSection,
  PreferenceThemeBuilderSection,
  ProgressiveExperienceBuilderSection,
} from './enums'

export const ExperienceBuilderSectionLabels: { [section: string]: string } = {
  [BannerExperienceBuilderSection.Header]: 'Header',
  [BannerExperienceBuilderSection.Footer]: 'Footer',
  [BannerExperienceBuilderSection.Description]: 'Description',
  [BannerExperienceBuilderSection.ActionButtons]: 'Action Buttons',
  [ModalExperienceBuilderSection.Header]: 'Header',
  [ModalExperienceBuilderSection.Description]: 'Description',
  [ModalExperienceBuilderSection.DescriptionTitle]: 'Description Title',
  [ModalExperienceBuilderSection.ListHeader]: 'List Header',
  [ModalExperienceBuilderSection.PurposeList]: 'Purposes List',
  [ModalExperienceBuilderSection.Footer]: 'Footer',
  [PreferenceExperienceBuilderSection.WelcomeTabHeader]: 'Header',
  [PreferenceExperienceBuilderSection.WelcomeTabNavigation]: 'Navigation',
  [PreferenceExperienceBuilderSection.WelcomeTabFooter]: 'Footer',
  [PreferenceExperienceBuilderSection.WelcomeTabWelcomeMessage]: 'Welcome Text',
  [PreferenceExperienceBuilderSection.WelcomeTabQuickLinks]: 'Quick Links',
  [PreferenceExperienceBuilderSection.WelcomeTabQuickLinksLinkTwo]: 'Quick Links',
  [PreferenceExperienceBuilderSection.WelcomeTabQuickLinksLinkThree]: 'Quick Links',
  [PreferenceExperienceBuilderSection.WelcomeTabQuickLinksLink2Visible]: 'Link 2',
  [PreferenceExperienceBuilderSection.WelcomeTabQuickLinksLink3Visible]: 'Link 3',
  [PreferenceExperienceBuilderSection.WelcomeTabAbout]: 'About Text',
  [PreferenceExperienceBuilderSection.WelcomeTabExitButton]: 'Exit Button',
  [PreferenceExperienceBuilderSection.WelcomeTabGpcBadge]: 'Footer',
  [PreferenceExperienceBuilderSection.WelcomeTabAboutDescription]: 'About Text Description',
  [PreferenceExperienceBuilderSection.PurposesTabHeaderTitle]: 'Page Title',
  [PreferenceExperienceBuilderSection.PurposesTabHeaderDescription]: 'Description',
  [PreferenceExperienceBuilderSection.PurposesTabListHeader]: 'List Header',
  [PreferenceExperienceBuilderSection.PurposesTabList]: 'Purposes List',
  [PreferenceExperienceBuilderSection.PurposesTabActionButton]: 'Action Button',
  [PreferenceExperienceBuilderSection.SubscriptionsTabHeaderTitle]: 'Page Title',
  [PreferenceExperienceBuilderSection.SubscriptionsTabHeaderDescription]: 'Description',
  [PreferenceExperienceBuilderSection.SubscriptionsTabUnsubscribeAll]: 'Unsubscribe From All',
  [PreferenceExperienceBuilderSection.SubscriptionsTabList]: 'Subscriptions List',
  [PreferenceExperienceBuilderSection.SubscriptionsTabActionButton]: 'Action Button',
  [PreferenceExperienceBuilderSection.RequestsHomeHeaderTitle]: 'Page Title',
  [PreferenceExperienceBuilderSection.RequestsHomeHeaderDescription]: 'Description',
  [PreferenceExperienceBuilderSection.RequestsHomeDsrLink]: 'DSR Portal Link',
  [PreferenceExperienceBuilderSection.RequestsHomeRightsListTitle]: 'Request Types (Rights)',
  [PreferenceExperienceBuilderSection.RequestsHomeRightsListRequestTypes]: 'Request Types (Rights)',
  [PreferenceExperienceBuilderSection.RequestsFormContent]: isRelease(Release.FormsWithinExperienceServer)
    ? 'Forms'
    : 'Form',
  [PreferenceExperienceBuilderSection.RequestsFormActionButton]: 'Action Button',
  [PreferenceExperienceBuilderSection.RequestsSubmittedHeader]: 'Page Header',
  [PreferenceExperienceBuilderSection.RequestsSubmittedSubtitle]: 'Sub-Title',
  [PreferenceExperienceBuilderSection.RequestsSubmittedDescription]: 'Description',
  [PreferenceExperienceBuilderSection.RequestsSubmittedActionButton]: 'Action Button',
  [ProgressiveExperienceBuilderSection.Container]: 'Layout',
  [ProgressiveExperienceBuilderSection.Header]: 'Header',
  [ProgressiveExperienceBuilderSection.Description]: 'Description',
  [ProgressiveExperienceBuilderSection.Subscriptions]: 'Subscription Details',
  [ProgressiveExperienceBuilderSection.ActionButtons]: 'Action Buttons',
  [ProgressiveExperienceBuilderSection.SwitchButton]: 'Switch Button',
}

export const ThemeBuilderSectionLabels: { [section: string]: string } = {
  [BannerThemeBuilderSection.Container]: 'Container',
  [BannerThemeBuilderSection.Header]: 'Header',
  [BannerThemeBuilderSection.Description]: 'Description',
  [BannerThemeBuilderSection.ActionButtons]: 'Action Buttons',
  [BannerThemeBuilderSection.ActionButtonsSecondary]: 'Action Buttons',
  [BannerThemeBuilderSection.ActionButtonsTertiary]: 'Action Buttons',
  [BannerThemeBuilderSection.Footer]: 'Footer',
  [ModalThemeBuilderSection.Container]: 'Container',
  [ModalThemeBuilderSection.Header]: 'Header',
  [ModalThemeBuilderSection.Description]: 'Description',
  [ModalThemeBuilderSection.ListHeader]: 'Bulk Actions & List Title',
  [ModalThemeBuilderSection.ListHeaderAcceptAll]: 'Bulk Actions & List Title',
  [ModalThemeBuilderSection.ListHeaderRejectAll]: 'Bulk Actions & List Title',
  [ModalThemeBuilderSection.PurposeList]: 'Purposes List',
  [ModalThemeBuilderSection.Footer]: 'Footer',
  [PreferenceThemeBuilderSection.WelcomeTabContainer]: 'Container',
  [PreferenceThemeBuilderSection.WelcomeTabHeader]: 'Header',
  [PreferenceThemeBuilderSection.WelcomeTabNavigation]: 'Navigation',
  [PreferenceThemeBuilderSection.WelcomeTabExitButton]: 'Exit Button',
  [PreferenceThemeBuilderSection.WelcomeTabHeroBanner]: 'Hero Banner',
  [PreferenceThemeBuilderSection.WelcomeTabWelcomeMessage]: 'Welcome Text',
  [PreferenceThemeBuilderSection.WelcomeTabQuickLinks]: 'Quick Links',
  [PreferenceThemeBuilderSection.WelcomeTabAbout]: 'About Text',
  [PreferenceThemeBuilderSection.WelcomeTabKetchBadge]: 'Footer',
  [PreferenceThemeBuilderSection.PurposesTabHeader]: 'Page Title & Description',
  [PreferenceThemeBuilderSection.PurposesTabListHeader]: 'Bulk Actions & List Title',
  [PreferenceThemeBuilderSection.PurposesTabListHeaderAcceptAll]: 'Bulk Actions & List Title',
  [PreferenceThemeBuilderSection.PurposesTabListHeaderRejectAll]: 'Bulk Actions & List Title',
  [PreferenceThemeBuilderSection.PurposesTabList]: 'Purposes List',
  [PreferenceThemeBuilderSection.PurposesTabFooter]: 'Footer',
  [PreferenceThemeBuilderSection.SubscriptionsTabHeader]: 'Page Title & Description',
  [PreferenceThemeBuilderSection.SubscriptionsTabUnsubscribeAll]: 'Unsubscribe From All',
  [PreferenceThemeBuilderSection.SubscriptionsTabList]: 'Subscriptions List',
  [PreferenceThemeBuilderSection.SubscriptionsTabFooter]: 'Footer',
  [PreferenceThemeBuilderSection.RequestsHomeHeader]: 'Page Title & Description',
  [PreferenceThemeBuilderSection.RequestsHomeDsrLink]: 'DSR Portal Link',
  [PreferenceThemeBuilderSection.RequestsHomeRightsList]: 'Request Types (Rights)',
  [PreferenceThemeBuilderSection.RequestsFormHeader]: 'Form Header',
  [PreferenceThemeBuilderSection.RequestsFormContent]: 'Form',
  [PreferenceThemeBuilderSection.RequestsFormContentFields]: 'Form',
  [PreferenceThemeBuilderSection.RequestsFormActionButton]: 'Action Button',
  [PreferenceThemeBuilderSection.RequestsSubmittedHeader]: 'Page Header',
  [PreferenceThemeBuilderSection.RequestsSubmittedBanner]: 'Summary Banner',
  [PreferenceThemeBuilderSection.RequestsSubmittedText]: 'Summary Text',
  [PreferenceThemeBuilderSection.RequestsSubmittedFooter]: 'Footer',
}
