import { Button, Icon, theme } from '@ketch-com/deck'
import { Box, styled } from '@mui/material'
import { AppNavigationContext } from 'components/appLayout/appNavigation/context/AppNavigationContext'
import { SidebarMode } from 'components/appLayout/appNavigation/types'
import { useContext } from 'react'
import { REFER_A_FRIEND_LINK } from 'utils/constants/referAFriend'
import { PlanAndBillingContext } from 'components/planAndBilling/common/context/PlanAndBillingContext'

const ReferAFriendBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  minHeight: 56,
  marginRight: '6px',
})

const ReferAFriendButton = styled(Button)({
  height: '40px',
  minHeight: '40px',
  borderRadius: 100,
  backgroundColor: `${theme.palette.Black.o100} !important`,
  boxShadow: '0px 3px 10px 1px rgba(0, 0, 0, 0.31)',
  ':hover': {
    boxShadow: '0px 3px 10px 1px rgba(0, 0, 0, 0.31)',
  },
  transition: 'width 0.3s ease',
  '& span': {
    margin: '0px !important',
  },
  '& .DeckBtnWrapChild': {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',

    // These 3 styles make the text not jump as wraps/unwraps when the sidebar width changes
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    transition: 'width 0.3s ease',
  },
})

/**
 * Refer a friend sidebar button
 */
export const SidebarReferAFriend: React.FC = () => {
  const { isOrgOnFreePlan } = useContext(PlanAndBillingContext)
  const { sidebarMode } = useContext(AppNavigationContext)
  const isSidebarExpanded = sidebarMode === SidebarMode.Open || sidebarMode === SidebarMode.Overlay
  const icon = <Icon name="OGift" iconColor="white" width={24} height={24} />

  const handleButtonClick = () => {
    window.open(REFER_A_FRIEND_LINK, '_blank', 'noreferrer')
  }

  if (isOrgOnFreePlan) return null

  return (
    <ReferAFriendBox
      sx={{
        padding: isSidebarExpanded ? '0px 16px' : undefined,
      }}
    >
      <ReferAFriendButton
        fullWidth
        variant={isSidebarExpanded ? undefined : 'iconCustomRounded'}
        startIcon={isSidebarExpanded ? icon : undefined}
        onClick={handleButtonClick}
        sx={{
          width: isSidebarExpanded ? undefined : '40px',
          padding: isSidebarExpanded ? '8px 12px' : undefined,
        }}
      >
        {isSidebarExpanded ? <>Refer, Get $1000</> : icon}
      </ReferAFriendButton>
    </ReferAFriendBox>
  )
}
