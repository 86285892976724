import Box from '@mui/material/Box'
import { Filter, MegaFilterType } from 'components/MegaFilter/interfaces'
import { useContext, useState } from 'react'
import { ProcessingActivitiesContext } from '../context/ProcessingActivitiesContext'
import { useFilters } from 'utils/hooks/useFiltersV2'
import { useDebounce } from 'react-use'

import { SearchTextInput } from 'components/searchTextInput/SearchTextInput'

import { MegaFilter } from 'components/MegaFilter/MegaFilter'

export const ProcessingActivitiesFilters: React.FC = () => {
  const { filters, isFiltersLoading, isFiltersFetching, processingActivities, isProcessingActivitiesRefetching } =
    useContext(ProcessingActivitiesContext)

  const { replaceParams, removeSingleSearchParam, getParamValueByKey } = useFilters()
  const search = getParamValueByKey({ key: 'search' })
  const [searchFilter, setSearchFilter] = useState(search.length ? search[0] : '')

  const internalLoading = (isFiltersLoading && !isFiltersFetching) || isProcessingActivitiesRefetching
  // 250ms debounce for search filter
  useDebounce(
    () => {
      if (searchFilter) {
        replaceParams({ search: searchFilter })
      } else {
        removeSingleSearchParam('search')
      }
    },
    250,
    [searchFilter],
  )

  const megaFilters: Filter[] = [
    {
      key: 'status',
      label: 'Status',
      type: MegaFilterType.ChipList,
      items: !!filters.statuses
        ? filters.statuses.map(status => ({
            id: status || '',
            label: status.charAt(0).toUpperCase() + status.slice(1) || '', // Capitalize first letter
          }))
        : [],
      includeAll: true,
    },
    {
      key: 'type',
      label: 'Type',
      type: MegaFilterType.ChipList,
      items: !!filters.types
        ? filters.types.map(type => ({
            id: type || '',
            label: type.charAt(0).toUpperCase() + type.slice(1) || '', // Capitalize first letter
          }))
        : [],
      includeAll: true,
    },
    {
      key: 'dataSystem',
      label: 'Data System',
      type: MegaFilterType.CheckList,
      items: !!filters.dataSystems
        ? filters.dataSystems.map(system => ({
            id: system.id || '',
            label: system.dataSystem?.name || '',
          }))
        : [],
      includeAll: true,
    },
    {
      key: 'purpose',
      label: 'Purpose',
      type: MegaFilterType.CheckList,
      items: !!filters.purposes
        ? filters.purposes.map(purpose => ({
            id: purpose.code || '',
            label: purpose.name || '',
          }))
        : [],
      includeAll: true,
    },
  ]

  return (
    <Box display="flex" alignItems="center" gap={2} mt={6}>
      <SearchTextInput
        value={searchFilter}
        size="small"
        placeholder="Search"
        onChange={newValue => setSearchFilter(newValue)}
        showClearButton
      />
      <MegaFilter
        totalItemsCount={processingActivities.length}
        filters={megaFilters}
        isLoading={internalLoading}
        buttonProps={{ size: 'medium' }}
      />
    </Box>
  )
}
