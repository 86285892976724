import { createUseQuery } from 'api/common/query'
import { ApiQueryKeys } from '../../common/queryKeys'
import { useAuth, useFeatureFlag } from '../../../utils/hooks'
import { fetchProcessingActivitiesSummary } from '../fetchers/fetchProcessingActivitiesSummary'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.processingActivitiesSummary,
  queryFn: fetchProcessingActivitiesSummary,
  select: res => res?.data || {},
})

export const useProcessingActivitiesSummary = () => {
  const { userData } = useAuth()
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isV2FeatureFlagEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.ASSESSMENT,
    FEATURE_FLAGS_VALUES.ASSESSMENTS_VERSION,
  )

  return useCustomQuery({
    params: {
      organizationCode: userData.organizationCode,
      isV2FeatureFlagEnabled,
    },
  })
}
