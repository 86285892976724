import { useDataMapRegions } from 'api/dataMap/queries/useDataMapRegions'
import { useUpdateInstalledDataSystem } from 'api/dataSystems/mutations/useUpdateInstalledDataSystem'
import { useInstalledDataSystem } from 'api/dataSystems/queries/useInstalledDataSystem'
import { usePolicyDocuments } from 'api/policyDocuments/queries/usePolicyDocuments'
import { useTeams } from 'api/teams/queries/useTeams'
import { useUsers } from 'api/users/queries/useUsers'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getFullNameUserShortDTO } from 'utils/helpers/getFullNameUserShortDTO'
import { DocumentOptionType } from '../types'
import {
  DataSensitivityDTO,
  FindCategoriesV3ResponseBodyDTO,
  FindDataTypeV3ResponseBodyDTO,
  GetAssetsDataSystemsResponseBodyDTO,
  InstalledDataSystemV2DTO,
  PutInstalledDataSystemV2ResponseBodyDTO,
} from '@ketch-com/figurehead'
import { createCustomContext } from 'utils/hooks/createCustomContext'
import { MetaData } from 'interfaces/common'
import { UserStatus } from 'interfaces/users/userStatus'
import { showToast } from 'components/modals/AlertComponent'
import { AxiosResponse } from 'axios'
import { camelCaseToTitleWords } from 'utils/text/stringUtils'
import { dataClassificationsRolloverProps } from '../assetsTab/components/extractedComponentTypes'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { useIsEntitled } from 'utils/hooks'
import { useDataMapFeatureFlags } from '../hooks/useDataSystemFeatureFlags'
import { useDataCategories } from 'api/dataSystemsClassifications/queries/useDataCategories'
import { useFetchDataTypes } from 'api/assets/queries/useFetchDataTypes'
import { useFetchDataSensitivities } from 'api/assets/queries/useFetchDataSensitivities'
import { RadioOptions } from 'components/RadioGroup/RadioGroup'
import { fetchAssetsDataSystems } from 'api/assets/fetchers/fetchAssetsDataSystems'
export interface AllUserOption {
  code: string
  name: string
  value: string
  id: string
  ID: string
  firstName: string
  lastName: string
  email: string
  metadata: MetaData
  status: UserStatus
}

interface AllTeamOtion {
  id: string
  name: string
  value: string
}

interface DataClassificationFilterOption {
  name: string
  id: string
  isSelected: boolean
}

export interface DataSystemsContextValue {
  regionResponse: ReturnType<typeof useDataMapRegions>
  userOwners: Array<{ id: string; name: string; value: string }>
  teamsUsing: Array<{ id: string; name: string; value: string }>
  systemUsers: Array<{ id: string; name: string; value: string }>
  docsUsed: Array<{ name: string; value: string }>
  updatedDataSystem: InstalledDataSystemV2DTO | null | undefined
  appDescriptor: InstalledDataSystemV2DTO | null | undefined
  isLoading: boolean
  isError: boolean
  allUserOptions: AllUserOption[]
  allTeamOptions: AllTeamOtion[] | undefined
  allDocuments: DocumentOptionType[]
  handleSystemFieldUpdates: (updateSubset: Partial<InstalledDataSystemV2DTO>) => void
  handleSystemCustomFieldValueUpdates: (id: string, label: string, values?: string[] | string) => void
  dataClassificationsRolloverProps?: dataClassificationsRolloverProps
  setdataClassificationsRolloverProps: (props?: dataClassificationsRolloverProps) => void
  refetchAllUsers: (options?: any) => Promise<any>
  isEntitledToDemo: boolean
  isEntitledToDataMap: boolean
  isSamplesPreviewEnabled: boolean
  showAssetsTab: boolean
  showClassificationSchedule: boolean
  showRightsOrchestrationTab: boolean
  allDataCategories: FindCategoriesV3ResponseBodyDTO | undefined
  dataCategoryFilterOptions: DataClassificationFilterOption[] | undefined
  allDataTypes: FindDataTypeV3ResponseBodyDTO | undefined | null
  dataTypesFilterOptions: DataClassificationFilterOption[] | undefined
  dataSensitivityOptions: RadioOptions
  allDataSensitivities: DataSensitivityDTO[] | null | undefined
  assetsDataSystems: GetAssetsDataSystemsResponseBodyDTO | undefined
  handleDisclosuresUpdate: (code: string, disclosureDescription?: string) => void
  isLoadingAssetsDataSystems: boolean
}

const { Provider, useCustomContext: useDataSystemsContext } = createCustomContext<DataSystemsContextValue>({
  displayName: 'DataSystemsContext',
})

export const DataSystemsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { id } = useParams<{ id: string }>()

  const regionResponse = useDataMapRegions({})
  const { data: allUsers = [], refetch: refetchAllUsers } = useUsers({ params: { active: true } })
  const { data: teams = [] } = useTeams({})
  const { data: documentsList = [] } = usePolicyDocuments({})
  const { mutateAsync: handleUpdateInstalledDataSystem } = useUpdateInstalledDataSystem({})

  const { isEntitled } = useIsEntitled()
  const isEntitledToDemo = useMemo(() => isEntitled(ENTITLEMENTS.DEMO), [isEntitled])

  const isEntitledToDataMap = useMemo(() => isEntitled(ENTITLEMENTS.DATA_MAPPING), [isEntitled])

  //Data System Feature Flags
  const { isSamplesPreviewEnabled, showAssetsTab, showClassificationSchedule, showRightsOrchestrationTab } =
    useDataMapFeatureFlags()

  const {
    data: appDescriptor,
    isLoading,
    isError,
    refetch,
  } = useInstalledDataSystem({
    params: {
      id: id!,
    },
  })

  const [updatedDataSystem, setUpdatedDataSystem] = useState<InstalledDataSystemV2DTO | undefined | null>(appDescriptor)
  const [dataClassificationsRolloverProps, setdataClassificationsRolloverProps] = useState<
    dataClassificationsRolloverProps | undefined
  >()

  useEffect(() => {
    if (appDescriptor) {
      setUpdatedDataSystem(appDescriptor)
    }
  }, [appDescriptor])

  const userOwnerIds = updatedDataSystem?.ownerUserIds || []
  const systemUserIds = updatedDataSystem?.systemUserIds || []
  const teamOwnerIds = useMemo(() => {
    if (updatedDataSystem?.ownerTeamIds) return updatedDataSystem?.ownerTeamIds
    return []
  }, [updatedDataSystem?.ownerTeamIds])

  const allUserOptions = allUsers.map(user => ({
    ...user,
    code: user.ID,
    name: getFullNameUserShortDTO(user),
    value: user.ID,
    id: user.ID,
  }))

  const userOwners = allUserOptions.filter(u => userOwnerIds.includes(u.ID))

  const allTeamOptions = useMemo(() => {
    if (!Array.isArray(teams))
      return teams.teams?.map(team => ({ id: team.id || '', name: team.name || '', value: team.id || '' }))
    return []
  }, [teams])

  const teamsUsing = useMemo(() => {
    if (allTeamOptions) return allTeamOptions!.filter(t => teamOwnerIds.includes(t.id || ''))
    return []
  }, [allTeamOptions, teamOwnerIds])

  const systemUsers = allUserOptions.filter(u => systemUserIds.includes(u.ID))

  const allDocuments: DocumentOptionType[] = useMemo(() => {
    if (documentsList) return documentsList?.map(document => ({ ...document, value: document.code }))
    return []
  }, [documentsList])

  const docsUsed = useMemo(() => {
    return (
      updatedDataSystem?.documents?.map(document => {
        const defaultVal = document.code || ''
        const docObj = allDocuments.find(doc => defaultVal === doc.code)

        if (!docObj) {
          return { name: defaultVal, value: defaultVal }
        } else {
          return docObj
        }
      }) || []
    )
  }, [allDocuments, updatedDataSystem?.documents])

  const handleSystemFieldUpdates = useCallback(
    (updateSubset: Partial<InstalledDataSystemV2DTO>) => {
      const updatedObject = { ...updatedDataSystem, ...updateSubset }
      setUpdatedDataSystem(updatedObject)
      const updatingKeys = Object.keys(updateSubset).map(camelCaseToTitleWords).join('; ')

      handleUpdateInstalledDataSystem({
        params: {
          id: updatedObject.id!,
          formData: { installedDataSystem: updatedObject },
        },
      })
        .then((r: AxiosResponse<PutInstalledDataSystemV2ResponseBodyDTO>) => {
          showToast({ content: `Updated ${updatingKeys}`, type: 'success' })
          setUpdatedDataSystem(r.data.installedDataSystem!)
          refetch()
        })
        .catch(e => {
          showToast({ content: `Failed to save updates to ${updatingKeys} `, type: 'error' })
        })
    },
    [handleUpdateInstalledDataSystem, refetch, updatedDataSystem],
  )

  const handleSystemCustomFieldValueUpdates = useCallback(
    (id: string, label: string, values?: string[] | string) => {
      const updatedObject = { ...updatedDataSystem }
      if (!updatedObject.customAttributeValues) {
        throw new Error('Could not find customAttributeValues')
      }
      const attributeIndex = updatedObject.customAttributeValues?.findIndex(
        customAttribute => customAttribute.configuration?.id === id,
      )
      if (attributeIndex === undefined) {
        throw new Error(`could not find attribute with ID: ${id}`)
      }
      updatedObject.customAttributeValues[attributeIndex].values = Array.isArray(values)
        ? values
        : values
        ? [values]
        : []
      setUpdatedDataSystem(updatedObject)

      handleUpdateInstalledDataSystem({
        params: {
          id: updatedObject.id!,
          formData: { installedDataSystem: updatedObject },
        },
      })
        .then((r: AxiosResponse<PutInstalledDataSystemV2ResponseBodyDTO>) => {
          showToast({ content: `Updated values of ${label}`, type: 'success' })
          setUpdatedDataSystem(r.data.installedDataSystem!)
          refetch()
        })
        .catch(e => {
          showToast({ content: `Failed to save updates to ${label} `, type: 'error' })
        })
    },
    [handleUpdateInstalledDataSystem, refetch, updatedDataSystem],
  )

  const { data: allDataCategories } = useDataCategories({ params: { limit: 1000 } })

  const dataCategoryFilterOptions: DataClassificationFilterOption[] | undefined = useMemo(() => {
    if (allDataCategories?.categories) {
      return allDataCategories?.categories.map(dataCategory => ({
        name: dataCategory.name || dataCategory.code!,
        id: dataCategory.code!,
        isSelected: false,
      }))
    }
    return undefined
  }, [allDataCategories?.categories])

  const { data: allDataTypes } = useFetchDataTypes({ params: { limit: 1000 } })

  const dataTypesFilterOptions: DataClassificationFilterOption[] | undefined = useMemo(() => {
    if (allDataTypes?.dataTypes) {
      return allDataTypes?.dataTypes.map(dataType => ({
        name: dataType.name || dataType.code!,
        id: dataType.code!,
        isSelected: false,
      }))
    }
    return undefined
  }, [allDataTypes?.dataTypes])

  const { data: dataSensitivitiesResp } = useFetchDataSensitivities({ params: { limit: 10 } })
  const allDataSensitivities = useMemo(
    () => (dataSensitivitiesResp ? dataSensitivitiesResp.dataSensitivities : dataSensitivitiesResp),
    [dataSensitivitiesResp],
  )
  const dataSensitivityOptions: RadioOptions = useMemo(
    () =>
      allDataSensitivities
        ? allDataSensitivities.map(sensitivity => ({
            title: sensitivity.name || sensitivity.code,
            value: sensitivity.id,
            disabled: false,
          }))
        : [],
    [allDataSensitivities],
  )

  const [assetsDataSystems, setAssetDataSystems] = useState<GetAssetsDataSystemsResponseBodyDTO>()
  const dataSystemCode = appDescriptor?.dataSystem?.code

  // Handle disclosure update for a specific language
  const handleDisclosuresUpdate = useCallback(
    (code: string, disclosureDescription?: string) => {
      const updatedDisclosures = updatedDataSystem?.disclosures || {}
      updatedDisclosures[code] = { description: disclosureDescription || '' }

      // Remove key from disclosures if new value is empty
      if (!disclosureDescription) {
        delete updatedDisclosures[code]
      }

      handleSystemFieldUpdates({
        disclosures: updatedDisclosures,
      })
    },
    [handleSystemFieldUpdates, updatedDataSystem?.disclosures],
  )

  useEffect(() => {
    if (showAssetsTab && !!dataSystemCode && !assetsDataSystems) {
      const fetchData = async () => {
        const data = await fetchAssetsDataSystems({
          isV2FeatureFlagEnabled: false,
          dataSystemCode,
        })
        setAssetDataSystems(data.data)
      }
      fetchData()
    }
  }, [assetsDataSystems, dataSystemCode, showAssetsTab])

  const isLoadingAssetsDataSystems = useMemo(() => assetsDataSystems === undefined, [assetsDataSystems])

  const value = useMemo(
    () => ({
      regionResponse,
      allDataCategories,
      dataCategoryFilterOptions,
      allDataTypes,
      dataTypesFilterOptions,
      allUserOptions,
      allTeamOptions,
      allDocuments,
      userOwners,
      teamsUsing,
      systemUsers,
      docsUsed,
      updatedDataSystem,
      appDescriptor,
      isLoading,
      isError,
      handleSystemFieldUpdates,
      handleSystemCustomFieldValueUpdates,
      dataClassificationsRolloverProps,
      setdataClassificationsRolloverProps,
      refetchAllUsers,
      isEntitledToDemo,
      isEntitledToDataMap,
      isSamplesPreviewEnabled,
      showAssetsTab,
      showClassificationSchedule,
      showRightsOrchestrationTab,
      dataSensitivityOptions,
      allDataSensitivities,
      assetsDataSystems,
      handleDisclosuresUpdate,
      isLoadingAssetsDataSystems,
    }),
    [
      regionResponse,
      allDataCategories,
      dataCategoryFilterOptions,
      allDataTypes,
      dataTypesFilterOptions,
      allUserOptions,
      allTeamOptions,
      allDocuments,
      userOwners,
      teamsUsing,
      systemUsers,
      docsUsed,
      updatedDataSystem,
      appDescriptor,
      isLoading,
      isError,
      handleSystemFieldUpdates,
      handleSystemCustomFieldValueUpdates,
      dataClassificationsRolloverProps,
      refetchAllUsers,
      isEntitledToDemo,
      isEntitledToDataMap,
      isSamplesPreviewEnabled,
      showAssetsTab,
      showClassificationSchedule,
      showRightsOrchestrationTab,
      dataSensitivityOptions,
      allDataSensitivities,
      assetsDataSystems,
      handleDisclosuresUpdate,
      isLoadingAssetsDataSystems,
    ],
  )

  return <Provider value={value}>{children}</Provider>
}

export { useDataSystemsContext }
