import { Link, useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import { TabsRoutesConfig } from 'components/ui-kit/tabs/TabsRoutes'
import { RoutesManager } from 'utils/routing/routesManager'
import { ViewLayout } from 'components/ui-layouts/viewLayout/ViewLayout'
import { ViewLayoutContent } from 'components/ui-layouts/viewLayout/ViewLayoutContent'
import { AssetSummaryDTO, GetDSRConfigCountResponseBodyDTO } from '@ketch-com/figurehead'
import { Button } from '@ketch-com/deck'
import { ConfigurationProgress } from 'pages/assetManager/scenario/components'
import { getAssetType } from 'pages/assetManager/utils'
import pluralize from 'pluralize'
import React, { useState } from 'react'
import { ImportConfigurationModal } from 'pages/assetManager/scenario/details/components/ImportConfigurationModal'
import Typography from '@mui/material/Typography'
import { AssetInfoNav } from 'pages/dataSystems/AssetMigration/AssetInfoNav'
import { ScenarioDetailsParams } from 'utils/routing/routesV2/systems'
import { LookUpTypes } from 'pages/dataSystems/AssetMigration/ScenarioDetails/LookUpTypes'
import { LookUpTab } from 'pages/dataSystems/AssetMigration/ScenarioDetails/CustomSQLTypes'

type Props = {
  assetSummary: AssetSummaryDTO
  dsrConfigCount: GetDSRConfigCountResponseBodyDTO
}

export const ScenarioDetails: React.FC<Props> = ({ assetSummary, dsrConfigCount }) => {
  const { scenarioId, id: assetId } = useParams<ScenarioDetailsParams>()
  const [showModal, setShowModal] = useState(false)

  const providerCode = assetSummary?.asset?.connection?.providerCode || ''
  const datasetType = getAssetType(providerCode, 2)
  const technology = assetSummary.asset?.connection?.technology || ''
  const resourceTypeCode = assetSummary.asset?.resource?.resourceType?.code as string
  const systemId = assetSummary.asset?.connection?.installedDataSystemID as string
  const { name: databaseName = '', id: databaseId = '' } = assetSummary.asset?.resource || {}
  const { personalAssets = 0, dsrScenario = {}, dsrConfigurationCount = {} } = dsrConfigCount
  const { totalActions = 0 } = dsrConfigurationCount[scenarioId!] || {}
  const currentScenario = dsrScenario[scenarioId!] || {}
  const dbUrl = RoutesManager.systems.dsrInvocation.scenarioDetails.getURL({
    scenarioId: scenarioId!,
    id: assetId!,
    resourceTypeCode,
    tab: LookUpTab.DIRECT,
  })
  const databaseTabs: TabsRoutesConfig[] = [
    {
      title: 'Direct Lookup',
      shouldShowCount: true,
      link: { pathname: dbUrl },
    },
    {
      title: 'No Direct Lookup',
      shouldShowCount: true,
      link: {
        pathname: `${RoutesManager.systems.dsrInvocation.scenarioDetails.getURL({
          scenarioId: scenarioId!,
          id: assetId!,
          resourceTypeCode,
          tab: LookUpTab.NO_DIRECT,
        })}`,
      },
    },
    {
      title: 'Custom SQL',
      shouldShowCount: true,
      link: {
        pathname: `${RoutesManager.systems.dsrInvocation.scenarioDetails.getURL({
          scenarioId: scenarioId!,
          id: assetId!,
          resourceTypeCode,
          tab: LookUpTab.CUSTOM_SQL,
        })}`,
      },
    },
  ]

  return (
    <Box padding={1}>
      <Box bgcolor="white.main" width="100%" borderRadius="11px" p={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant={'h3'}>{currentScenario.name}</Typography>
          <Box display="flex" justifyContent="right" alignItems="center">
            <ConfigurationProgress progress={Math.round((totalActions / personalAssets) * 100)} small />
            <Typography variant={'h4'} ml={1} mr={3}>
              {totalActions}/{personalAssets} {pluralize(datasetType, personalAssets)} Configured
            </Typography>
            <Link to={RoutesManager.systems.id.rightsOrchestration.root.getURL({ id: systemId, assetId })}>
              <Button color="primary">Done</Button>
            </Link>
          </Box>
        </Box>
      </Box>
      <Box width={1280} margin="0 auto" mt={2} mb={2}>
        <AssetInfoNav database={{ name: databaseName, id: databaseId, url: dbUrl }} technology={technology} />
      </Box>
      <ViewLayout>
        <Box mt={2} mb={4} mx={2.5} pt={2} display="flex" justifyContent="space-between">
          <Box>
            <Box>
              <Typography variant={'h3'} mr={1}>
                Configure {pluralize(datasetType, personalAssets)}
              </Typography>
            </Box>
            <Typography variant="label" color="grey">
              Browse and select the {datasetType} you want to configure for a "{currentScenario.name}" event.
            </Typography>
          </Box>
        </Box>
        <ViewLayoutContent tabs={databaseTabs}>
          <LookUpTypes dsrConfigCount={dsrConfigCount} />
        </ViewLayoutContent>
      </ViewLayout>
      {showModal ? <ImportConfigurationModal onCancel={() => setShowModal(false)} /> : null}
    </Box>
  )
}
