import React, { useContext } from 'react'
import { useFormikContext } from 'formik'
import { themeUpsertFormKeys } from '../../../../utils/useThemeValidationSchema'
import { Typography } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import { SidebarFormGroup } from '../../../../../../experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'
import { FormCheckbox } from 'components/form/FormCheckbox'
import { PlanAndBillingContext } from 'components/planAndBilling/common/context/PlanAndBillingContext'
import { AppNavigationContext } from 'components/appLayout/appNavigation/context/AppNavigationContext'
import { ThemeV3DTO } from 'interfaces/themes-v3/theme'
import { FormFileUpload } from 'components/ui-kit/form/fileUpload/FormFileUpload'
import { themeFileAllowedTypes, themeFileUploadContext } from 'pages/consentAndRights/themes-v3/upsert/utils/utils'

export const BannerThemeFooterForm: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<ThemeV3DTO>()
  const { isOrgOnFreePlan, isOrgOnStarterPlan } = useContext(PlanAndBillingContext)
  const { setShowNotEntitledModal } = useContext(AppNavigationContext)

  return (
    <SidebarFormGroup sx={{ p: 0, m: 0, gap: 0 }}>
      <FormControlLabel
        control={
          <FormCheckbox
            name={themeUpsertFormKeys.bannerFooterKetchBadgeVisible}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if ((isOrgOnFreePlan || isOrgOnStarterPlan) && values?.content?.banner?.footer?.ketchBadgeVisible) {
                setShowNotEntitledModal(true)
                return
              }
              setFieldValue(
                'content.banner.footer.ketchBadgeVisible',
                !values?.content?.banner?.footer?.ketchBadgeVisible,
              )
            }}
          />
        }
        label={<Typography variant={'label'}>Show “Powered by Ketch” Badge</Typography>}
      />
      <SidebarFormGroup sx={{ mt: '24px' }} hasBorderTop>
        <FormFileUpload
          name={themeUpsertFormKeys.bannerFooterLogoUrl}
          label={'Footer Logo'}
          belowButtonLabel={'Container size: 140x40'}
          uploadContext={themeFileUploadContext}
          accept={themeFileAllowedTypes}
        />
      </SidebarFormGroup>
    </SidebarFormGroup>
  )
}
