import { QuestionDTO } from '@ketch-com/figurehead'

export const QUESTION_TYPES = {
  SHORT_ANSWER: 'short_answer',
  LONG_ANSWER: 'long_answer',
  SINGLE_CHOICE: 'single_choice',
  MULTIPLE_CHOICE: 'multi_choice',
}

export const QUESTION_TYPE_OPTIONS = [
  { id: QUESTION_TYPES.SHORT_ANSWER, name: 'Short Answer' },
  { id: QUESTION_TYPES.LONG_ANSWER, name: 'Long Answer' },
  { id: QUESTION_TYPES.SINGLE_CHOICE, name: 'Single Choice' },
  { id: QUESTION_TYPES.MULTIPLE_CHOICE, name: 'Multiple Choice' },
]

export const getQuestionTypeName = (code?: string) =>
  QUESTION_TYPE_OPTIONS.find(type => type.id === code)?.name || 'Unknown'

export const getQuestionDetailsString = (question?: QuestionDTO) => {
  return `${getQuestionTypeName(question?.attributes?.type)} | ${
    question?.attributes?.allowAttachments
      ? `Attachments ${question?.attributes?.requireAttachments ? 'Required' : 'Allowed'}`
      : 'No Attachments'
  } | ${question?.attributes?.isRequired ? 'Required' : 'Optional'}`
}

export const QUESTION_TYPE_FILTER_OPTIONS = [
  { id: '', title: 'All Types' },
  ...QUESTION_TYPE_OPTIONS.map(({ id, name }) => ({ id, title: name })),
]

export const RISK_SCORE_OPTION_IDS = {
  RISK_SCORE_NONE: 'RISK_SCORE_NONE',
  RISK_SCORE_EXTREME: 'RISK_SCORE_EXTREME',
  RISK_SCORE_CRITICAL: 'RISK_SCORE_CRITICAL',
  RISK_SCORE_TOLERABLE: 'RISK_SCORE_TOLERABLE',
  RISK_SCORE_ACCEPTABLE: 'RISK_SCORE_ACCEPTABLE',
}

export const RISK_SCORE_OPTIONS = [
  {
    id: RISK_SCORE_OPTION_IDS.RISK_SCORE_NONE,
    name: 'N/A or None',
    content: 'Risk levels are not applicable for this option or do not generate any risk at all.',
    color: 'darkDusk.main',
  },
  {
    id: RISK_SCORE_OPTION_IDS.RISK_SCORE_EXTREME,
    name: 'Extreme',
    content: 'Any events with this level can lead to a significant business impact or disruption of operations.',
    color: 'red.main',
  },
  {
    id: RISK_SCORE_OPTION_IDS.RISK_SCORE_CRITICAL,
    name: 'Critical',
    content:
      'In the likelihood of an event, consequences can severely impact business operations and are generally unacceptable.',
    color: 'chileanFire.main',
  },
  {
    id: RISK_SCORE_OPTION_IDS.RISK_SCORE_TOLERABLE,
    name: 'Tolerable',
    content:
      'A tolerable risk level is intended to communicate a rising concern that may grow to a more significant threat.',
    color: 'pirateGold.main',
  },
  {
    id: RISK_SCORE_OPTION_IDS.RISK_SCORE_ACCEPTABLE,
    name: 'Acceptable',
    content:
      'In the likelihood of an event, consequences will create an insignificant impact to the organization overall.',
    color: 'storm.main',
  },
]

export const getRiskScoreName = (code?: string) =>
  RISK_SCORE_OPTIONS.find(option => option.id === code)?.name || 'Unknown'

export const isChoiceBasedQuestion = (type?: string) =>
  type === QUESTION_TYPES.MULTIPLE_CHOICE || type === QUESTION_TYPES.SINGLE_CHOICE
