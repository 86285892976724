import { DataGrid, EmptyState, FormRow } from '@ketch-com/deck'
import { useFormikContext } from 'formik'
import { policyScopePurposeColumns } from 'pages/policyCenter/policyScopes/upsert/components/PolicyScopePurposes/utils'
import { PolicyScopeUpsertContext } from 'pages/policyCenter/policyScopes/upsert/context/PolicyScopeUpsertContext'
import { PolicyScopeFormValues } from 'pages/policyCenter/policyScopes/upsert/interfaces'
import React, { useContext } from 'react'

export const PolicyScopePurposes: React.FC = () => {
  const { purposes, legalBases } = useContext(PolicyScopeUpsertContext)
  const { values } = useFormikContext<PolicyScopeFormValues>()
  const columns = policyScopePurposeColumns(legalBases)

  const hasRegulations = values.regulationCodes?.length > 0

  return (
    <FormRow title="Purposes" subTitle="The legal basis for each purpose in this Jurisdiction.">
      {hasRegulations ? (
        <DataGrid
          sx={{
            padding: 'unset',
          }}
          autosizeOnMount
          columns={columns}
          disableChildrenSorting
          disableColumnMenu
          disableColumnPinning
          disableColumnReorder
          disableColumnResize
          disableRowHoverStates
          disableRowSelectionOnClick
          getRowHeight={() => 'auto'}
          getRowId={row => row.code}
          hideFooter
          loading={!purposes.length}
          rows={purposes}
        />
      ) : (
        <EmptyState
          iconName="OProfile"
          title="Please select at least one regulation before mapping legal bases to purposes."
        />
      )}
    </FormRow>
  )
}
