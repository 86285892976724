import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchCloudinaryResourceDetails } from 'api/cloudinary/fetchers/fetchCloudinaryResourceDetails'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.cloudinaryResourceDetails,
  queryFn: fetchCloudinaryResourceDetails,
  select: res => res?.data,
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useCloudinaryResourceDetails = (config: Config) =>
  useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
