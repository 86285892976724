export enum PERMISSIONS {
  // Admin part permissions:
  ADMIN_ACCOUNT_READ = 'account:read',
  ADMIN_ACCOUNT_WRITE = 'account:write',

  AUTHENTICATION_READ = 'authentication:read',
  AUTHENTICATION_WRITE = 'authentication:write',

  ADMIN_ENTITLEMENT_READ = 'entitlement:read',
  ADMIN_ENTITLEMENT_WRITE = 'entitlement:write',
  ADMIN_FEATURE_FLAG_READ = 'featureflag:read',
  ADMIN_FEATURE_FLAG_WRITE = 'featureflag:write',
  ADMIN_KETCH = 'ketch:admin',
  ADMIN_KETCH_READ = 'ketch:read',
  ADMIN_KETCH_TEMP_BLOCK = 'ketch:temp', // TODO - temporarily block all access to ketch admin routes
  ADMIN_KETCH_WRITE = 'ketch:write',
  ADMIN_PERMISSION_READ = 'perm:read',
  ADMIN_PERMISSION_SET_READ = 'permset:read',
  ADMIN_PERMISSION_SET_WRITE = 'permset:write',
  ADMIN_PERMISSION_WRITE = 'perm:write',

  API_KEY_READ = 'apikey:read',
  API_KEY_WRITE = 'apikey:write',

  ASSESSMENT_READ = 'assessment:read',
  ASSESSMENT_WRITE = 'assessment:write',
  ASSESSMENT_APPROVE = 'assessment:approve',
  ASSESSMENT_RESPOND = 'assessment:respond',

  ASSET_READ = 'asset:read',
  ASSET_WRITE = 'asset:write',

  ASSET_POLICY_READ = 'assetpolicy:read',
  ASSET_POLICY_WRITE = 'assetpolicy:write',

  ASSET_RELATIONSHIP_READ = 'assetrelationship:read',
  ASSET_RELATIONSHIP_WRITE = 'assetrelationship:write',

  BUSINESS_FUNCTIONS_READ = 'businessfunctions:read',
  BUSINESS_FUNCTIONS_WRITE = 'businessfunctions:write',

  CONTROLLER_READ = 'controller:read',
  CONTROLLER_WRITE = 'controller:write',

  COOKIE_READ = 'cookie:read',
  COOKIE_WRITE = 'cookie:write',

  CONTEXT_VARIABLE_READ = 'contextvariable:read',
  CONTEXT_VARIABLE_WRITE = 'contextvariable:write',

  CUSTOM_NAME_RIGHTS_READ = 'custom_right_name:read',
  CUSTOM_NAME_RIGHTS_WRITE = 'custom_right_name:write',

  DATAMAP_READ = 'datamap:read',
  DATAMAP_WRITE = 'datamap:write',

  DATA_SYSTEMS_READ = 'data_systems:read',
  DATA_SYSTEMS_WRITE = 'data_systems:write',

  DEMO = 'demo',

  DEPLOY_ADMIN = 'deploy:admin',
  DEPLOY_READ = 'deploy:read',
  DEPLOY_WRITE = 'deploy:write',

  EXP_READ = 'exp:read',
  EXP_WRITE = 'exp:write',

  HOOK_READ = 'hook:read',
  HOOK_WRITE = 'hook:write',
  HOOK_ASSIGN = 'hook:assign',

  INTEGRATIONS_WRITE = 'integrations:write',

  IDME_WRITE = 'idme:write',

  IDSP_READ = 'idsp:read',
  IDSP_WRITE = 'idsp:write',

  JURISDICTION_READ = 'jurisdiction:read',
  JURISDICTION_WRITE = 'jurisdiction:write',

  LABEL_READ = 'label:read',
  LABEL_WRITE = 'label:write',

  ORG_ADMIN = 'org:admin',
  ORG_BILLING_ADMIN = 'billing:admin',

  PERMIT_INSIGHTS_READ = 'permitinsights:read',
  PERMIT_ORCH_READ = 'permorch:read',
  PERMIT_ORCH_WRITE = 'permorch:write',

  PROTOCOL_ORCH_READ = 'protocolorch:read',
  PROTOCOL_ORCH_WRITE = 'protocolorch:write',

  POLDOC_READ = 'poldoc:read',
  POLDOC_WRITE = 'poldoc:write',

  PROCESSING_ACTIVITY_READ = 'processingactivity:read',
  PROCESSING_ACTIVITY_WRITE = 'processingactivity:write',
  PROCESSING_ACTIVITY_APPROVE = 'processingactivity:approve',

  PROPERTY_READ = 'property:read',
  PROPERTY_WRITE = 'property:write',

  PRIVACY_PROTOCOL_READ = 'privacyprotocol:read',
  PRIVACY_PROTOCOL_WRITE = 'privacyprotocol:write',

  PURPOSE_READ = 'purpose:read',
  PURPOSE_WRITE = 'purpose:write',

  REDACTION_WRITE = 'redaction:write',

  RIGHT_INSIGHTS_READ = 'rightinsights:read',
  RIGHT_READ = 'right:read',
  RIGHT_WRITE = 'right:write',

  RTINVQ_READ = 'rtinvq:read',
  RTINVQ_WRITE = 'rtinvq:write',

  SUBJECTTYPE_READ = 'subjecttype:read',
  SUBJECTTYPE_WRITE = 'subjecttype:write',

  SUBJECT_CHAT_READ = 'subjectchat:read',
  SUBJECT_CHAT_WRITE = 'subjectchat:write',

  SUBSCRIPTIONS_READ = 'subscriptions:read',
  SUBSCRIPTIONS_WRITE = 'subscriptions:write',

  TEAM_READ = 'teams:read',
  TEAM_WRITE = 'teams:write',

  THEME_READ = 'theme:read',
  THEME_WRITE = 'theme:write',

  TRANSLATIONS_READ = 'translations:read',
  TRANSLATIONS_WRITE = 'translations:write',

  TRANSPONDER_READ = 'transponder:read',
  TRANSPONDER_WRITE = 'transponder:write',

  USER_ADMIN = 'user:admin',

  WEB_TAGS_READ = 'tagorch:read',
  WEB_TAGS_WRITE = 'tagorch:write',

  WEBHOOK_READ = 'webhook:read',
  WEBHOOK_WRITE = 'webhook:write',

  WORKFLOW_ASSIGN = 'workflow:assign',
  WORKFLOW_READ = 'workflow:read',
  WORKFLOW_TASK_ASSIGN = 'task:assign',
  WORKFLOW_WRITE = 'workflow:write',
  /**
   * This permission is used to test the permissions system.
   * the developer can gate a route on this permission to simulate a user
   * who doesn't have the necessary permissions.
   */
  NOBODY_HAS_THIS_PERMISSION = 'nobody:has:this:permission',
}

export enum ADMIN_PERMISSIONS {
  ADMIN_PRODUCT_READ = 'account:read',
  ADMIN_PRODUCT_WRITE = 'account:write',
  ADMIN_SKU_READ = 'sku:read',
  ADMIN_SKU_WRITE = 'sku:write',
}
