import React, { useContext, useState } from 'react'
import { Header } from '..'
import { Typography, Tooltip, Box, FormControlLabel } from '@mui/material'
import { FormTemplateUpsertContext } from 'pages/consentAndRights/forms/templates/upsert/context/FormTemplateUpsertContextProvider'
import { FormTemplateDTO, OperatorDTO, ConditionTypeDTO } from '@ketch-com/figurehead'
import { useFormikContext, FieldArray } from 'formik'
import { Tab, TabGroup, Icon, IconDictionary, theme, Chip, Radio, RadioGroup } from '@ketch-com/deck'
import { Description, Title, FormFields } from './components'

export const FormTemplateSection: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<FormTemplateDTO>()
  const [currentTab, setCurrentTab] = useState(0)
  const { activeSectionIndex, mandatoryFormFieldIds, dataSubjectTypes } = useContext(FormTemplateUpsertContext)
  const [visibility, setVisibility] = useState(
    values?.sections?.[typeof activeSectionIndex === 'number' ? activeSectionIndex : 0].conditions?.[0]?.values
      ? 'data_subject'
      : 'anyone',
  )

  if (typeof activeSectionIndex !== 'number') return null

  const isActiveSectionPersonalDetailsSection = values?.sections?.[activeSectionIndex]?.formFields?.some(formField =>
    mandatoryFormFieldIds.includes(formField?.id || ''),
  )

  return (
    <Box display="flex" flexDirection="column">
      <Header
        title={isActiveSectionPersonalDetailsSection ? 'Personal Details' : 'Section Settings'}
        isRequired={true}
        onRemove={() => {
          const sections = values?.sections?.filter((section, index) => index !== activeSectionIndex)
          setFieldValue('sections', sections)
        }}
      />

      <Typography variant="body" component={Box} mb={2.25}>
        {isActiveSectionPersonalDetailsSection ? (
          <>
            This section contains required fields and is always visible for all data subject types. The title and
            description are optional.
          </>
        ) : (
          <>
            All elements are optional, however, every section must contain at least one element. Elements left empty
            will be excluded from this section.
          </>
        )}
      </Typography>

      {!isActiveSectionPersonalDetailsSection ? (
        <Box display="flex" flexDirection="column" gap={0.5} mb={2}>
          <Box>
            <Box display="flex" alignItems="center" gap={0.5}>
              <Typography variant="label">Who can see this section?</Typography>
              <Tooltip
                placement="bottom"
                title={
                  <Box>
                    You can manage Data Subject Types in <Typography variant="smallLabel">Settings</Typography>.
                  </Box>
                }
              >
                <Box mt={0.5}>
                  <Icon name={IconDictionary.FUnknown} width={16} height={16} iconColor={theme.palette.Black.o48} />
                </Box>
              </Tooltip>
            </Box>
            <RadioGroup
              name="group-radio"
              row
              onChange={e => {
                const value = e.target.value
                if (value === 'anyone') {
                  setFieldValue(`sections.${activeSectionIndex}.conditions`, [])
                  setVisibility(value)
                } else {
                  setFieldValue(`sections.${activeSectionIndex}.conditions`, [
                    {
                      operator: OperatorDTO.OperatorInOperator,
                      type: ConditionTypeDTO.ConditionTypeDataSubjectConditionType,
                      values: [],
                    },
                  ])
                  setVisibility(value)
                }
              }}
            >
              <FormControlLabel value="anyone" control={<Radio />} label="Anyone" checked={visibility === 'anyone'} />
              <FormControlLabel
                value="data_subject"
                control={<Radio />}
                label="Selected Data Subject Types"
                checked={visibility === 'data_subject'}
              />
            </RadioGroup>
          </Box>
          {visibility === 'data_subject' ? (
            <FieldArray
              name={`sections.${activeSectionIndex}.conditions.${0}.values`}
              render={({ push, remove }) => {
                return (
                  <Box my={1}>
                    <Box display={'flex'} flexWrap={'wrap'} gap={1}>
                      {dataSubjectTypes.map(subjectType => {
                        const selectedSubjectTypes =
                          values?.sections?.[activeSectionIndex]?.conditions?.[0]?.values || []
                        const isSubjectTypeSelected = selectedSubjectTypes?.includes(subjectType.code || '')

                        return (
                          <Chip
                            key={subjectType.code}
                            clickable
                            selectable
                            isSelected={isSubjectTypeSelected}
                            deleteIcon={
                              isSubjectTypeSelected ? (
                                <Icon name={IconDictionary.OEyeShow} width={24} height={24} />
                              ) : (
                                <Icon
                                  name={IconDictionary.OEyeHide}
                                  width={24}
                                  height={24}
                                  iconColor={theme.palette.Highlight.Primary}
                                />
                              )
                            }
                            onClick={() => {
                              const isAllSubjectTypesSelected =
                                selectedSubjectTypes?.length === dataSubjectTypes?.length - 1
                              if (isSubjectTypeSelected) {
                                remove(selectedSubjectTypes.findIndex(e => e === subjectType.code))
                              } else {
                                if (isAllSubjectTypesSelected) {
                                  setFieldValue(`sections.${activeSectionIndex}.conditions`, [])
                                  setVisibility('anyone')
                                } else {
                                  push(subjectType.code)
                                }
                              }
                            }}
                            onDelete={() => {
                              const isAllSubjectTypesSelected =
                                selectedSubjectTypes?.length === dataSubjectTypes?.length - 1
                              if (isSubjectTypeSelected) {
                                remove(selectedSubjectTypes.findIndex(e => e === subjectType.code))
                              } else {
                                if (isAllSubjectTypesSelected) {
                                  setFieldValue(`sections.${activeSectionIndex}.conditions`, [])
                                  setVisibility('anyone')
                                } else {
                                  push(subjectType.code)
                                }
                              }
                            }}
                            label={subjectType.name}
                            secondaryLabel={subjectType.code}
                          />
                        )
                      })}
                    </Box>
                  </Box>
                )
              }}
            />
          ) : null}
        </Box>
      ) : null}

      <Box mb={3}>
        <TabGroup value={currentTab}>
          <Tab key={1} label="Title" onClick={() => setCurrentTab(0)} />
          <Tab key={2} label="Description" onClick={() => setCurrentTab(1)} />
          <Tab key={3} label="Fields" onClick={() => setCurrentTab(2)} />
        </TabGroup>
      </Box>
      {currentTab === 0 && <Title />}
      {currentTab === 1 && <Description />}
      {currentTab === 2 && <FormFields />}
    </Box>
  )
}
