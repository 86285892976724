import { API } from '../../../utils/api/api'
import { FetchProcessingActivityFiltersResponseBodyDTO } from '@ketch-com/figurehead'
import { formatRequestString } from '../../../utils/helpers/api/queryString'

interface FetchProcessingActivityFiltersParams {
  isV2FeatureFlagEnabled?: boolean
}

export const fetchProcessingActivityFilters = ({ isV2FeatureFlagEnabled }: FetchProcessingActivityFiltersParams) => {
  const entityUrl = isV2FeatureFlagEnabled
    ? '/api/risk-review/processing-activities/filters'
    : '/api/processing-activities/filters'
  return API.get<FetchProcessingActivityFiltersResponseBodyDTO>(
    formatRequestString({
      entityUrl,
    }),
  )
}
