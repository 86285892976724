import { PurposeDTO, PurposeFormDTO } from 'interfaces/purposes/purpose'
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { TableCell } from '@ketch-com/deck'
import { useFormikContext } from 'formik'
import { LegalBaseDTO } from 'interfaces/legalBases/legalBase'
import { FormCheckbox } from 'components/form/FormCheckbox'
import { FormOptionActionSheet } from 'components/form/FormOptionActionSheet'
import { Box } from '@mui/material'
import { getValidLegalBasesForPolicyScope } from 'pages/policyCenter/purposes/utils'
import { PolicyScopeFormValues } from 'pages/policyCenter/policyScopes/upsert/interfaces'
import { useEffect } from 'react'

type PurposeBasicDetailsFormKeysType = {
  [key: string]: keyof PurposeFormDTO
}

export const purposeSubjectFormKeys = {
  dataSubjectTypeCodes: 'dataSubjectTypeCodes',
} as PurposeBasicDetailsFormKeysType

export const purposeDisplayDetailsFormKeys = {
  displayName: 'displayName',
  displayDescription: 'displayDescription',
} as PurposeBasicDetailsFormKeysType

export const purposeBasicDetailsFormKeys = {
  name: 'name',
  code: 'code',
  description: 'description',
  dataRole: 'dataRole',
  canonicalPurposeCodes: 'canonicalPurposeCodes',
  dataUsageDuration: 'dataUsageDuration',
  dataUsageDurationUnit: 'dataUsageDurationUnit',
} as PurposeBasicDetailsFormKeysType

export const PurposesLegalBasesDropdownCell = ({
  legalBases,
  purpose,
}: {
  legalBases: LegalBaseDTO[]
  purpose: PurposeDTO
}) => {
  const { values, setFieldValue } = useFormikContext<PolicyScopeFormValues>()
  const regulationCodes = values?.regulationCodes || []
  const validLegalBases = getValidLegalBasesForPolicyScope({ legalBases, regulationCodes, purposeCode: purpose.code })

  // Reset the legal basis if it's no longer a valid option when the selected regulations change
  useEffect(() => {
    if (
      values.legalBases?.[purpose.ID] &&
      !validLegalBases.find(legalBase => legalBase.code === values.legalBases?.[purpose.ID])
    ) {
      setFieldValue(`legalBases.${purpose.ID}`, '')
      setFieldValue(`requiresDisplay.${purpose.ID}`, false)
    }
    // Not including values.legalBases dependency here as we only need to re-check this when regulations change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.regulationCodes, purpose.ID, setFieldValue, validLegalBases])

  return (
    <TableCell sx={{ padding: '8px 0' }}>
      <Box>
        <FormOptionActionSheet
          buttonProps={{
            size: 'medium',
            color: 'tertiary',
          }}
          required
          formPropertyName={`legalBases.${purpose.ID}`}
          items={validLegalBases}
          valueKey="code"
          renderItem={item => item.name}
          placeholder="Select Legal Basis"
          onItemClick={nextValue => {
            if (nextValue.requiresOptIn || nextValue.allowOptOut) {
              setFieldValue(`requiresDisplay.${purpose.ID}`, true)
            }
            // https://ketch-com.atlassian.net/browse/KD-847
            if (nextValue.code === 'not_applicable') {
              setFieldValue(`requiresDisplay.${purpose.ID}`, false)
            }
          }}
        />
      </Box>
    </TableCell>
  )
}

export const PurposesLegalBasesCheckboxCell = ({
  legalBases,
  purpose,
}: {
  legalBases: LegalBaseDTO[]
  purpose: PurposeDTO
}) => {
  const { values, setFieldValue } = useFormikContext<PolicyScopeFormValues>()
  const legalBaseCode = values?.legalBases?.[purpose?.ID] || ''
  const legalBase = legalBases.find(item => item.code === legalBaseCode)
  const isDisabled = !legalBase || legalBase?.requiresOptIn || legalBase?.allowOptOut

  return (
    <TableCell>
      <FormCheckbox
        disabled={isDisabled}
        name={`requiresDisplay.${purpose.ID}`}
        onChange={(_, checked) => setFieldValue(`requiresDisplay.${purpose.ID}`, checked)}
      />
    </TableCell>
  )
}

export const policyScopePurposeColumns = (legalBases: LegalBaseDTO[]): GridColDef<PurposeDTO>[] => [
  {
    field: 'purpose',
    headerName: 'Purpose',
    sortable: false,
    minWidth: 250,
    align: 'left',
    headerAlign: 'left',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<PurposeDTO>) => {
      return <TableCell title={row.name} subTitle={row.code} />
    },
  },
  {
    align: 'left',
    field: 'assignments',
    headerAlign: 'left',
    headerName: 'Legal Basis Assignment',
    minWidth: 250,
    flex: 1,
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<PurposeDTO>) => {
      return <PurposesLegalBasesDropdownCell legalBases={legalBases} purpose={row} />
    },
  },
  {
    align: 'left',
    field: 'requiresDisplay',
    flex: 0.5,
    headerAlign: 'left',
    headerName: 'Display',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<PurposeDTO>) => {
      return <PurposesLegalBasesCheckboxCell legalBases={legalBases} purpose={row} />
    },
  },
]
