import React from 'react'

import { Button } from '@ketch-com/deck'
import Box from '@mui/material/Box'
import { transponderDocumentationLinks } from '../../constants'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useIsPermitted } from 'utils/hooks'
import { Tooltip } from '@mui/material'

type Props = {
  handleClick: () => void
}

export const AppliancesViewActions: React.FC<Props> = ({ handleClick }) => {
  return (
    <Box display="flex">
      <Box mr={1}>
        <Button color="tertiary" onClick={handleClick}>
          Installation Guide
        </Button>
      </Box>

      <Button
        onClick={() => {
          window.open(transponderDocumentationLinks.TRANSPONDER_OVERVIEW, '_blank', 'noopener,noreferrer')
        }}
        color="secondary"
      >
        Documentation
      </Button>
    </Box>
  )
}

export const AppliancesViewActionsV2: React.FC = () => {
  const navigate = useNavigate()
  const { isPermitted } = useIsPermitted()
  const hasTransponderWritePermission = isPermitted(PERMISSIONS.TRANSPONDER_WRITE)

  return (
    <Box display="flex">
      <Tooltip title={hasTransponderWritePermission ? '' : 'Permission is required'}>
        <Box mr={1}>
          <Button
            color="primary"
            disabled={!hasTransponderWritePermission}
            onClick={() => {
              navigate(RoutesManager.developers.transponders.create.getURL())
            }}
          >
            Create Transponder
          </Button>
        </Box>
      </Tooltip>

      <Button
        onClick={() => {
          window.open(transponderDocumentationLinks.TRANSPONDER_OVERVIEW, '_blank', 'noopener,noreferrer')
        }}
        color="secondary"
      >
        Documentation
      </Button>
    </Box>
  )
}
