import React, { useEffect, useState, useRef } from 'react'
import { Banner, Button, Chip, Icon, theme } from '@ketch-com/deck'
import { Box, Collapse, List, ListItemText, Typography } from '@mui/material'
import pluralize from 'pluralize'
import { useQueryClient } from 'react-query'
import ReactDropzone, { FileRejection } from 'react-dropzone'
import { showToast } from 'components/modals/AlertComponent'
import { useUploadFile } from 'api/files/mutations/useUploadFile'
import { useDeleteFile } from 'api/files/mutations/useDeleteFile'
import { useDownloadFile } from 'api/files/mutations/useDownloadFile'
import { FileInfoDTO } from 'interfaces/files/fileInfo'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { UPLOADED_ERRORS_TIMEOUT, handleUpload } from './AttachmentsUpload.utils'
import { Props as AttachmentUploadProps, UploadFailedPayload } from './AttachmentUpload.types'

const isUploadFailedPayload = (value: any): value is UploadFailedPayload => value?.error && value?.file

export const AttachmentsUpload: React.FC<AttachmentUploadProps> = ({
  attachments = [],
  canEdit = false,
  uploadContext = {},
  onUploadComplete,
  onDelete = null,
  accept,
  maxSize,
  ...rest
}) => {
  // state
  const [isUploading, setIsUploading] = useState(false)
  const [uploadErrors, setUploadErrors] = useState<string[]>([])

  // timeout for upload
  const timeout = useRef(0)
  useEffect(() => () => window.clearTimeout(timeout.current), [])

  // api
  const queryClient = useQueryClient()
  const { mutateAsync: handleUploadFile } = useUploadFile()
  const { mutateAsync: handleDeleteFile, isLoading: isDeletingFile } = useDeleteFile({
    onSuccess: async () => await queryClient.refetchQueries(ApiQueryKeys.filesList),
  })
  const { mutateAsync: handleDownloadFile, isLoading: isDownloadingFile } = useDownloadFile()

  const handleDownloadOnClick = async (attachment: FileInfoDTO) => {
    try {
      const { data } = await handleDownloadFile({
        params: {
          fileUrl: attachment.download_url,
        },
      })
      let a: HTMLAnchorElement | null = document.createElement('a')
      a.href = URL.createObjectURL(data)
      a.download = attachment.name
      a.click()
      a = null
      showToast({ content: `Downloaded file ${attachment.name}`, type: 'success' })
    } catch (error) {
      showToast({
        content: `Unable to download file ${attachment.name}`,
        type: 'error',
      })
    }
  }

  // callbacks
  const onDropAccepted = async (acceptedFiles: File[]) => {
    // validate
    const currentFileNames = attachments?.map(file => file.name) || []
    const acceptedFileNames = acceptedFiles.map(file => file.name)
    const duplicateFileNames = acceptedFileNames.filter(name => currentFileNames.includes(name))
    if (duplicateFileNames.length) {
      showToast({
        content: `You have already uploaded a file with name "${duplicateFileNames?.[0]}"`,
        type: 'error',
      })
      return
    }

    // upload files
    setIsUploading(true)
    const responses = await Promise.all(
      acceptedFiles.map(async file => {
        try {
          const { data } = (await handleUploadFile({
            params: {
              file,
              ...uploadContext,
            },
          }))!

          return { data, file }
        } catch (error) {
          return { error, file } as UploadFailedPayload
        }
      }),
    )
    const uploads: FileInfoDTO[] = []
    const errors: string[] = []
    responses?.forEach?.(response => {
      if (isUploadFailedPayload(response) || !response.data) {
        errors.push(response.file.name)
      } else {
        uploads.push(response.data)
      }
    })
    // handle response
    if (!errors.length) {
      showToast({ content: `${responses.length === 1 ? 'File' : 'Files'} uploaded`, type: 'success' })
    } else {
      showToast({ content: `Failed to upload ${responses.length === 1 ? 'file' : 'files'}`, type: 'error' })
    }
    setUploadErrors(errors)
    timeout.current = window.setTimeout(() => {
      setUploadErrors([])
    }, UPLOADED_ERRORS_TIMEOUT)

    setIsUploading(false)
    handleUpload({ files: uploads, queryClient, onUploadComplete })
  }

  const onDropRejected = (rejectedFiles: FileRejection[]) => {
    let content = rejectedFiles?.[0]?.errors?.[0]?.message
    showToast({
      content,
      type: 'error',
    })
  }

  const hasAttachments = !!attachments.length

  const maxSizeMB = maxSize ? maxSize / 1024 / 1024 : 0
  const supportedFileTypes = Object.entries(accept || {})
    .map(([key, value]) => value.map(x => x.replace('.', '').toUpperCase()))
    .flat()
  const sizeAndFileTypeLimitsDescription = `Up to ${maxSizeMB}MB, supported file ${pluralize(
    'type',
    supportedFileTypes?.length,
  )}: ${supportedFileTypes.join(', ')}.`

  return (
    <Box display="flex" flexDirection="column">
      {/* Attachments List */}
      {hasAttachments ? (
        <>
          <Typography variant="label" pt={1}>
            Attachments
          </Typography>
          <List>
            {attachments.map(attachment => (
              <ListItemText key={attachment.ID} sx={{ py: 0.5 }}>
                <Box maxWidth={600} display="inline-flex" alignItems="center">
                  <Chip
                    deleteIcon={<Icon name="OCross" />}
                    clickable
                    label={attachment.name}
                    disabled={isDeletingFile || isUploading}
                    onClick={async () => {
                      try {
                        await handleDeleteFile({
                          params: {
                            fileUrl: attachment.download_url,
                          },
                        })
                        showToast({ content: `Removed file ${attachment.name}`, type: 'success' })
                        onDelete?.(attachment)
                      } catch (error) {
                        showToast({
                          content: `Unable to remove file ${attachment.name}`,
                          type: 'error',
                        })
                      }
                    }}
                    onDelete={async () => {
                      try {
                        await handleDeleteFile({
                          params: {
                            fileUrl: attachment.download_url,
                          },
                        })
                        showToast({ content: `Removed file ${attachment.name}`, type: 'success' })
                        onDelete?.(attachment)
                      } catch (error) {
                        showToast({
                          content: `Unable to remove file ${attachment.name}`,
                          type: 'error',
                        })
                      }
                    }}
                  />

                  <Button
                    sx={{
                      ml: 1,
                    }}
                    variant="iconCustomRounded"
                    onClick={() => {
                      handleDownloadOnClick(attachment)
                    }}
                    disabled={isDeletingFile || isUploading || isDownloadingFile}
                    color="tertiary"
                  >
                    <Icon name="ODownload" iconColor={theme.palette.sphere.main} />
                  </Button>
                </Box>
              </ListItemText>
            ))}
          </List>
        </>
      ) : null}

      {/* File Upload */}
      <ReactDropzone
        {...rest}
        disabled={false}
        accept={accept}
        onDropAccepted={onDropAccepted}
        onDropRejected={onDropRejected}
        multiple={false}
        maxSize={maxSize || 100000000}
      >
        {({ getInputProps, open }) => (
          <Box>
            <input {...getInputProps()} />
            {hasAttachments ? (
              <Button
                pending={isUploading}
                color="secondary"
                onClick={e => {
                  e.stopPropagation()
                  open()
                }}
              >
                Add More
              </Button>
            ) : (
              <>
                <Button
                  variant="link"
                  color="secondary"
                  startIcon={<Icon name="OClip" iconColor={theme.palette.sphere.main} />}
                  onClick={e => {
                    e.stopPropagation()
                    open()
                  }}
                >
                  <Typography variant="label" color="sphere.main">
                    Add Attachments
                  </Typography>
                </Button>
                <Typography component="div" variant="smallBody" color="darkDuskFaded.main">
                  {sizeAndFileTypeLimitsDescription}
                </Typography>
              </>
            )}
            <Collapse in={!!uploadErrors.length}>
              <Banner
                severity="warning"
                title={`Failed to upload ${uploadErrors.length === 1 ? 'file' : 'files'}: ${uploadErrors.join(', ')}`}
                onClick={e => {
                  e.stopPropagation()
                }}
                action={
                  <Button
                    variant="iconSubtle"
                    onClick={() => {
                      setUploadErrors([])
                    }}
                  >
                    <Icon
                      name="OCross"
                      iconColor={theme.palette.chileanFire.main}
                      onClick={() => {
                        setUploadErrors([])
                      }}
                    />
                  </Button>
                }
              />
            </Collapse>
          </Box>
        )}
      </ReactDropzone>
    </Box>
  )
}
