import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { CloudinaryResource } from 'interfaces/cloudinary/cloudinary'
type Params = {
  public_id: string
}

export const fetchCloudinaryResourceDetails = ({ public_id }: Params) =>
  API.get<CloudinaryResource>(
    formatRequestString({
      entityUrl: `/api/v2/files/info`,
      params: {
        filters: {
          public_id,
          type: 'cloudinary',
        },
      },
    }),
  )
