import React from 'react'
import { useNavigate } from 'react-router-dom'
import { GridColDef } from '@mui/x-data-grid-premium'
import { DeploymentPlanDTO } from 'interfaces/deploymentPlans-v2/deploymentPlan'
import { PolicyDocumentType, POLICY_DOCUMENT_NAMES } from 'interfaces/policyDocuments/policyDocument'
import { RoutesManager } from 'utils/routing/routesManager'
import { excludeFalsy } from 'utils/helpers/excludeFalsy'
import { Button, ContentGroup, DataGrid } from '@ketch-com/deck'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'
import { ReactComponent as EmptyStateIcon } from '../../../../../assets/icons/emptyState/EmptyStateDocuments.svg'
import { NEW_ITEM_CODE } from '../../../../../utils/constants/misc'
import { EmptyState } from '../../../../../components/ui-layouts/emptyState/EmptyState'
import { useIsEntitled } from '../../../../../utils/hooks/useIsEntitled'
import { useIsPermitted } from '../../../../../utils/hooks/useIsPermitted'
import { PERMISSIONS } from '../../../../../interfaces/permissions/permissions'
import { ENTITLEMENTS } from '../../../../../interfaces/entitlements/entitlements'
import { Typography } from '@mui/material'

type DataGridItem = {
  documentTypeName: string
  name: string
  code: string
}

type Props = {
  deploymentPlan: DeploymentPlanDTO
}

export const DeploymentPlanDocuments: React.FC<Props> = ({ deploymentPlan }) => {
  const navigate = useNavigate()
  const { privacyPolicy, termsOfService } = deploymentPlan
  const { isEntitled } = useIsEntitled()
  const { isPermitted } = useIsPermitted()

  const isPermittedToCreatePolicyDocuments = isPermitted([PERMISSIONS.POLDOC_WRITE])
  const isEntitledToCreatePolicyDocuments = isEntitled(ENTITLEMENTS.QTY_POLICY_DOCUMENT)
  const canCreatePolicyDocuments = isEntitledToCreatePolicyDocuments && isPermittedToCreatePolicyDocuments

  const items: DataGridItem[] = [
    !!privacyPolicy && {
      documentTypeName: POLICY_DOCUMENT_NAMES[PolicyDocumentType.PRIVACY_POLICY],
      name: privacyPolicy.name,
      code: privacyPolicy.code,
    },
    !!termsOfService && {
      documentTypeName: POLICY_DOCUMENT_NAMES[PolicyDocumentType.TERMS_OF_SERVICE],
      name: termsOfService.name,
      code: termsOfService.code,
    },
  ].filter(excludeFalsy)

  const columns = React.useMemo(
    (): GridColDef<DataGridItem>[] => [
      {
        align: 'left',
        field: 'documentTypeName',
        flex: 1,
        headerAlign: 'left',
        headerName: 'Document Type',
        sortable: false,
        width: 250,
        maxWidth: 250,
        renderCell: ({ row: { documentTypeName } }) => (
          <Typography variant="label" padding="16px 0">
            {documentTypeName}
          </Typography>
        ),
      },
      {
        align: 'left',
        field: 'name',
        flex: 1,
        width: 300,
        maxWidth: 300,
        headerAlign: 'left',
        headerName: 'Document',
        sortable: false,
        renderCell: ({ row: { name, code } }) => <NameAndCodeCellRenderer name={name} code={code} />,
      },
      {
        align: 'right',
        field: 'viewButton',
        flex: 1,
        headerAlign: 'right',
        headerName: '',
        sortable: false,
        renderCell: ({ row: { code } }) => (
          <Button
            color="tertiary"
            onClick={() => {
              navigate(RoutesManager.policyCenter.policyDocuments.view.root.getURL({ code }))
            }}
          >
            View
          </Button>
        ),
      },
    ],
    [navigate],
  )

  return (
    <>
      {!items.length ? (
        <EmptyState
          variant="list"
          icon={<EmptyStateIcon />}
          title="There are no policy documents for this deployment plan"
          description={
            canCreatePolicyDocuments
              ? 'Create your first one and add it to this deployment plan to populate this section!'
              : null
          }
          actions={[
            {
              title: 'Create Document',
              hidden: !canCreatePolicyDocuments,
              size: 'big',
              onClick: () => {
                navigate(RoutesManager.policyCenter.policyDocuments.upsert.root.getURL({ code: NEW_ITEM_CODE }))
              },
            },
          ]}
        />
      ) : (
        <>
          <ContentGroup title="Associated Documents" titleVariant="h3" variant="inner-page-header" />
          <DataGrid
            rows={items}
            columns={columns}
            autosizeOnMount
            disableChildrenSorting
            disableColumnMenu
            disableColumnPinning
            disableColumnReorder
            disableColumnResize
            disableRowHoverStates
            disableRowSelectionOnClick
            getRowHeight={() => 'auto'}
            getRowId={row => row.code}
            hideFooter
            sx={{
              padding: 'unset',
              mt: '24px',
            }}
          />
        </>
      )}
    </>
  )
}
