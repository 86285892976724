import { GetDataSystemConsentTemplateV2ResponseBodyDTO } from '@ketch-com/figurehead'
import { APIRequestParams } from 'api/common/utils'

import { API } from 'utils/api/api'
import { formatRequestStringV2 } from 'utils/helpers/api/formatRequestStringV2'

type Params = APIRequestParams<{
  code: string
}>

export const fetchConsentTemplate = ({ code }: Params) => {
  return API.get<GetDataSystemConsentTemplateV2ResponseBodyDTO>(
    formatRequestStringV2({
      entityUrl: `/api/v2/data-systems/${code}/consentTemplate`,
    }),
  )
}
