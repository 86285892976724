import { CloudinaryFormFields, CloudinaryUploadResponse } from 'interfaces/cloudinary/cloudinary'

export const uploadToCloudinary = async (
  fileUploaderFormData: FormData,
  cloudinaryFormFields: CloudinaryFormFields,
  key: string,
): Promise<CloudinaryUploadResponse> => {
  const cloudinaryUrl = 'https://api.cloudinary.com/v1_1/dfp6h8kj9/image/upload'
  const response = await fetch(cloudinaryUrl, {
    method: 'POST',
    body: fileUploaderFormData,
  })

  if (!response.ok) throw response

  const data: CloudinaryUploadResponse = await response.json()
  cloudinaryFormFields[key] = data
  return data
}
