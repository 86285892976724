import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from '../../common/mutation'
import { deleteProcessingActivityModelBiasFile } from '../fetchers/deleteProcessingActivityModelBiasFile'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'
import { useFeatureFlag } from 'utils/hooks'

const useCustomMutation = createUseMutation({
  mutationFn: deleteProcessingActivityModelBiasFile,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useDeleteProcessingActivityModelBiasFile = (config?: Config) => {
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isV2FeatureFlagEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.ASSESSMENT,
    FEATURE_FLAGS_VALUES.ASSESSMENTS_VERSION,
  )
  const mutation = useCustomMutation(config)
  return useBindMutationStaticParams(mutation, {
    isV2FeatureFlagEnabled,
  })
}
