import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { Label } from 'components/ui-kit/form/common/label/Label'
import { Checkbox, TextInput, theme } from '@ketch-com/deck'
import {
  FormFieldTypeDTO,
  FormFieldVariantDTO,
  PreviewConfigurationExperienceFormFieldDTO,
} from '@ketch-com/figurehead'
import { useQueryParams } from 'utils/hooks'
import { FormTemplatePreviewQueryParams } from 'pages/consentAndRights/forms/templates/preview/interfaces'
import { DropdownField } from './DropdownField'
import { Typography } from '@mui/material'

type Props = {
  formFields?: PreviewConfigurationExperienceFormFieldDTO[]
}

export const FormFieldPreviewGrid: React.FC<Props> = ({ formFields = [] }) => {
  const { queries } = useQueryParams<{
    previewMode?: FormTemplatePreviewQueryParams['previewMode']
  }>()

  return (
    <Grid container spacing={2}>
      {(formFields || []).map(field => {
        const shouldRenderTextArea =
          field.type === FormFieldTypeDTO.TextFormFieldType &&
          field.variant === FormFieldVariantDTO.TextareaFormFieldVariant

        const shouldRenderTextInput =
          field.type === FormFieldTypeDTO.TextFormFieldType &&
          field.variant === FormFieldVariantDTO.InputFormFieldVariant

        const shouldRenderDropdown = field.type === FormFieldTypeDTO.DropdownFormFieldType

        const shouldRenderCheckbox = field.type === FormFieldTypeDTO.CheckboxFormFieldType

        const shouldRenderFileUpload = field.type === FormFieldTypeDTO.FileFormFieldType

        const checkboxItems = (field?.options || []).map(({ label, value }) => ({
          id: value,
          name: label,
        }))

        const defaultXsValue = field.width === 'full' || shouldRenderCheckbox ? 12 : 6

        const xsValue = queries.previewMode === 'mobile' || shouldRenderFileUpload ? 12 : defaultXsValue

        return (
          <Grid item xs={xsValue} key={field?.id} display="flex" flexDirection="column" justifyContent="flex-end">
            {!shouldRenderCheckbox && <Label>{field?.label || field?.name}</Label>}

            {shouldRenderTextArea && (
              <>
                <TextInput
                  placeholder={field?.hint}
                  value=""
                  multiline
                  rows={4}
                  fullWidth
                  sx={{
                    backgroundColor: 'transparent !important',
                    background: 'transparent !important',
                    borderColor: `${theme.palette.doomedGrey.main} !important`,
                    borderRadius: 'unset !important',
                  }}
                />
              </>
            )}

            {shouldRenderTextInput && field.variant === 'input' && (
              <>
                <TextInput
                  placeholder={field?.hint}
                  sx={{
                    backgroundColor: 'transparent !important',
                    background: 'transparent !important',
                    borderColor: `${theme.palette.doomedGrey.main} !important`,
                    borderRadius: 'unset !important',
                  }}
                  value=""
                  fullWidth
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </>
            )}

            {shouldRenderDropdown && <DropdownField field={field} />}

            {shouldRenderCheckbox && (
              <Box display="flex" key={field.label} flexDirection="column">
                {checkboxItems.map(item => {
                  const isChecked = false
                  return (
                    <Box display="flex" gap={1} alignItems="center" ml={-0.75}>
                      <Checkbox
                        key={item.id}
                        id={item.id}
                        name={item.id}
                        title={item?.name}
                        checked={!!isChecked}
                        onChange={() => {}}
                        sx={{
                          '&:hover': {
                            backgroundImage: 'unset',
                          },
                        }}
                      />
                      <Typography>{item?.name}</Typography>
                    </Box>
                  )
                })}
              </Box>
            )}

            {shouldRenderFileUpload && (
              <Box display="flex" flexDirection="column" gap={0.5}>
                <input type="file" accept="image/jpeg,.pdf" />
                <Typography variant="smallLabel">
                  Supported file types: JPG and PDF. Maximum file size: 4 MB.
                </Typography>
              </Box>
            )}
          </Grid>
        )
      })}
    </Grid>
  )
}
