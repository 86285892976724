import { Box, Typography } from '@mui/material'
import { AssessmentsListItem, AssessmentsListItemProps } from './AssessmentsListItem'
import pluralize from 'pluralize'

export interface assessmentsList extends Omit<AssessmentsListItemProps, 'assessmentName'> {
  name: string
}

export interface OrganizationAssessmentsListProps {
  organizationCode: string
  assessmentsList: assessmentsList[]
}

export const OrganizationAsessmentsList: React.FC<OrganizationAssessmentsListProps> = ({
  assessmentsList,
  organizationCode,
}) => {
  const listItemCount = assessmentsList.length
  return (
    <Box display="flex" gap={2} flexDirection={'column'}>
      <Box>
        <Typography variant="fadedLabel">From </Typography>
        <Typography variant="label">{organizationCode}</Typography>
        <Typography component="div" variant="footnote">{`${listItemCount} ${pluralize(
          'assessment',
          listItemCount,
        )}`}</Typography>
      </Box>
      {assessmentsList.map(assessment => (
        <AssessmentsListItem
          key={assessment.name}
          assessmentName={assessment.name}
          status={assessment.status}
          dataSubjectCode={assessment.dataSubjectCode}
          dataSubjectIcon={assessment.dataSubjectIcon}
          questionCount={assessment.questionCount}
          assessmentId={assessment.assessmentId}
        />
      ))}
    </Box>
  )
}
