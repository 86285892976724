import { useDownloadFile } from 'api/files/mutations/useDownloadFile'
import { showToast } from 'components/modals/AlertComponent'
import { Chip, FileExtensionIcon, Icon } from '@ketch-com/deck'

type DownloadLinkChipProps = {
  fileName: string
  contentType: string
  downloadUrl: string
}

export const DownloadLinkChip: React.FC<DownloadLinkChipProps> = ({ contentType, downloadUrl, fileName }) => {
  const { mutateAsync: handleDownloadFile } = useDownloadFile()

  const handleDownloadOnClick = async (downloadUrl: string, fileName: string) => {
    try {
      const { data } = await handleDownloadFile({
        params: {
          fileUrl: downloadUrl,
        },
      })
      let a: HTMLAnchorElement | null = document.createElement('a')
      a.href = URL.createObjectURL(data)
      a.download = fileName
      a.click()
      a = null
      showToast({ content: `Downloaded file ${fileName}`, type: 'success' })
    } catch (error) {
      showToast({
        content: `Unable to download file ${fileName}`,
        type: 'error',
      })
    }
  }

  return (
    <Chip
      clickable
      label={fileName}
      icon={<FileExtensionIcon iconVariant="filled" extension={contentType} />}
      deleteIcon={<Icon name="ODownload" />}
      onClick={() => handleDownloadOnClick(downloadUrl, fileName)}
      onDelete={() => handleDownloadOnClick(downloadUrl, fileName)}
    />
  )
}
