import { DataGrid, Pagination, theme } from '@ketch-com/deck'
import { GridColDef, GridRowId } from '@mui/x-data-grid'
import { useGridApiRef } from '@mui/x-data-grid-premium'
import { PaginationModel } from './hooks/useAssetQueryPayload'
import { ListAssetItemDTO } from '@ketch-com/figurehead'
import { Box } from '@mui/material'
import { useEffect, useMemo } from 'react'

export interface DataSystemAssetsTableProps {
  rowCount?: number
  columns: GridColDef<ListAssetItemDTO>[]
  rows?: ListAssetItemDTO[]
  isLoading?: boolean
  isError?: boolean
  paginationModel?: PaginationModel
  width?: string
  resourceType?: string
  elementRef?: React.RefObject<HTMLDivElement>
  pageSize?: number
  maxHeight?: string
  page: number
  handlePaginationChange: (event: React.ChangeEvent<unknown>, value: number) => void
  rowSelectionModel: GridRowId[]
  handleSelectionChange: (ids: GridRowId[], resourceType: string) => void
}

export const DataSystemAssetsTable: React.FC<DataSystemAssetsTableProps> = ({
  columns,
  isLoading,
  isError,
  rowCount,
  rows,
  width,
  resourceType,
  elementRef,
  pageSize,
  maxHeight,
  handlePaginationChange,
  page,
  rowSelectionModel,
  handleSelectionChange,
}) => {
  const pageCount = useMemo(() => (rowCount ? Math.ceil(rowCount / (pageSize || 10)) : 0), [pageSize, rowCount])

  const apiRef = useGridApiRef()

  useEffect(() => {
    apiRef.current.autosizeColumns({
      includeHeaders: true,
      includeOutliers: true,
    })
  }, [columns, apiRef])

  const contentCountainerWidth = useMemo(() => {
    if (resourceType === 'column') {
      return '1580px'
    }
    if (resourceType === 'database') {
      return '1930px'
    }
    if (resourceType !== 'table') {
      return '1680px'
    }
    return '1780px'
  }, [resourceType])

  return (
    <Box display="flex" flexDirection="column" gap={2} paddingBottom={2}>
      <Box
        maxWidth={width ? width : '870px'}
        sx={{ overflowX: 'scroll' }}
        borderRadius="16px"
        border={`1px solid ${theme.palette.Common.Divider}`}
      >
        <Box display="flex" width={contentCountainerWidth}>
          <DataGrid
            sx={{
              border: 0,
              maxHeight: maxHeight,
              '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeaderCheckbox):hover': {
                backgroundImage: 'unset',
                cursor: 'auto',
                width: 'auto',
              },

              ...(isLoading && {
                '& .MuiDataGrid-virtualScroller': {
                  height: '300px',
                },
              }),
            }}
            slotProps={{
              baseCheckbox: {
                // @ts-expect-error - MUI does not support data-test-id as slot prop type but it works
                'data-test-id': 'asset-table-row-checkbox',
              },
            }}
            hideFooter
            apiRef={apiRef}
            ref={elementRef}
            getRowHeight={() => 'auto'}
            columns={columns}
            getRowId={row => row.resource?.id!}
            rows={rows || []}
            disableRowSelectionOnClick
            checkboxSelection
            onRowSelectionModelChange={ids => {
              const resourceType = rows?.[0].resource?.resourceType
              handleSelectionChange(ids, resourceType!)
            }}
            rowSelectionModel={rowSelectionModel}
            keepNonExistentRowsSelected
            disableColumnMenu
            autosizeOnMount
            autosizeOptions={{
              expand: true,
              includeHeaders: true,
              includeOutliers: true,
            }}
            loading={isLoading}
            rowCount={rowCount || 0}
            hideFooterPagination
            hideFooterRowCount
            pageSizeOptions={[10]}
          />
        </Box>
      </Box>
      {pageCount > 1 && (
        <Pagination
          onChange={handlePaginationChange}
          count={pageCount}
          shape={'rounded'}
          page={page}
          disabled={isLoading || isError}
          hideNextButton={false}
          hidePrevButton={false}
          showFirstButton={false}
          showLastButton={false}
          boundaryCount={1}
          size={'medium'}
        />
      )}
    </Box>
  )
}
