import { theme } from '@ketch-com/deck'
import { Box, BoxProps, Link, Typography } from '@mui/material'
import React from 'react'

interface PortalFooterProps extends BoxProps {}

export const PortalFooter: React.FC<PortalFooterProps> = ({ ...rest }) => {
  return (
    <Box {...rest} display="flex" padding={1} alignItems="center" gap={3} width="100%">
      <Typography variant="smallBody">Copyright &copy; 2024 Ketch Kloud, Inc. All rights reserved</Typography>
      <Link href="mailto:support@ketch.com" underline="none" mb="2px">
        <Typography variant="smallBody" color={theme.palette.Action.Primary}>
          Contact Support
        </Typography>
      </Link>
    </Box>
  )
}
