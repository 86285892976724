import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/styles'
import clsx from 'clsx'

import { useFormikContext } from 'formik'

import { Draggable } from 'react-beautiful-dnd'
import { APP_RESPONSE_VARIABLE_VALUE_OPTIONS, OPERATOR_OPTIONS } from '../../types'
import { ICanvasStep, ITaskActivityParams } from 'pages/orchestration/workflows/edit/interfaces'
import {
  WorkflowActivityCode,
  WorkflowActivityConnectorCode,
  WorkflowActivityGatewayMode,
} from 'interfaces/workflowActivities/workflowActivity'
import { IGetDecisionGatewayInitialValues } from '../utils/getDecisionGatewayInitialValues'
import { ContextVariableCategoryEnum } from 'interfaces/workflows/ContextVariableCategoryEnum'
import { getOutcomeVariablesAvailableOnUpstreamTaskSteps } from '../utils'
import { ContextVariableType } from 'pages/settings/contextVariables/constants'
import { getStepsToStartCount } from 'pages/orchestration/workflows/edit/utils/steps'
import { EditWorkflowContext } from 'pages/orchestration/workflows/edit/contexts/EditWorkflowContext'
import { FormFields } from './FormFields'
import { Button, Icon, TooltipButton, theme, Banner } from '@ketch-com/deck'
import { EditWorkflowContainerContext } from '../../../../../EditWorkflowFormContainer/context/EditWorkflowContainerContext'
import { WorkflowCanvasContext } from '../../../../../WorkflowCanvas/context/WorkflowCanvasContext'
import { isUndefined } from 'lodash'
import { getStepsInPath } from '../../../../../../utils/steps/getStepsInPath'
import { getStepType } from '../../../../../../utils/steps/getStepType'
import { CanvasStepType, CopyPasteType } from '../../../../../../utils/constants'
import { FormInlineEdit } from 'components/form/FormInlineEdit'
import { FormInput } from 'components/form/FormInput'
import { FormRadioGroup } from 'components/form/FormRadioGroup'
import { FormDroplistButton } from 'components/form/FormDroplistButton'
import { getUpstreamSystemOutcomeVariables } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/forms/gateway/DecisionGatewayForm/utils/getUpstreamSystemOutcomeVariables'
import { ContextVariableDTO } from '@ketch-com/figurehead'

const PREFIX = 'DecisionPathTile'

const classes = {
  menuIcon: `${PREFIX}-menuIcon`,
  chevronIcon: `${PREFIX}-chevronIcon`,
  chevronIconOpen: `${PREFIX}-chevronIconOpen`,
  withPointer: `${PREFIX}-withPointer`,
}

const Root = styled(Box)(({ theme: { palette } }) => ({
  [`& .${classes.chevronIcon}`]: {
    color: palette.darkDusk.main,
    transform: 'rotate(0deg)',
    transition: 'transform 0.2s ease-in-out',
  },
  [`& .${classes.chevronIconOpen}`]: {
    transform: 'rotate(90deg)',
  },
  [`& .${classes.withPointer}`]: {
    cursor: 'pointer',
  },
}))

type Props = {
  step: ICanvasStep
  index: number
  remove: (index: number) => void
  steps: Array<ICanvasStep>
  stepIndex: number
  expandable?: boolean
  copyable?: boolean
}

const getOperatorOptions = (contextVariable?: ContextVariableDTO): { id: string; name: string }[] => {
  // Only support equals for App Response
  if (contextVariable?.code === 'app_response') {
    return [OPERATOR_OPTIONS[0]]
  }

  // Otherwise get options based on context variable type
  switch (contextVariable?.type) {
    case 1: //list
      return [OPERATOR_OPTIONS[0], OPERATOR_OPTIONS[1]]
    case 2: //integer
      return OPERATOR_OPTIONS
    case 3: //boolean
      return [OPERATOR_OPTIONS[0]]
    case 4: //string
      return [OPERATOR_OPTIONS[0], OPERATOR_OPTIONS[1]]
    default:
      return [OPERATOR_OPTIONS[0], OPERATOR_OPTIONS[1]]
  }
}

export const DecisionPathTile: React.FC<Props> = ({
  index,
  remove,
  stepIndex,
  steps,
  step,
  expandable = true,
  copyable = false,
}) => {
  const { values, submitCount, errors, setFieldValue, setFieldTouched } =
    useFormikContext<IGetDecisionGatewayInitialValues>()
  const isOutcomeVariable =
    values?.transitions?.[index]?.variableCategory === ContextVariableCategoryEnum.OUTCOME_VARIABLE
  const { outcomeVariables, dataSubjectVariables } = useContext(EditWorkflowContext)
  const gatewayStepCountToStart = isOutcomeVariable ? getStepsToStartCount({ step, steps }) : 0 // TODO: getStepsToStartCount blocks JS runtime and need to be optimized
  const isDecisionBoolean = step.gateway!.mode === WorkflowActivityGatewayMode.DECISION_BOOLEAN

  const transitionErrors = errors.transitions || []
  const showTransitionError = typeof transitionErrors === 'string'

  const { handleCopyPath } = useContext(EditWorkflowContainerContext)
  const { removeActiveSteps, setHoveredCopySteps, hoveredCopyPasteType } = useContext(WorkflowCanvasContext)

  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  // Open tile on submit if there are any related errors
  useEffect(() => {
    const doesThisTileHaveErrors = (errors.transitions || [])[index]
    if (doesThisTileHaveErrors) {
      setIsExpanded(true)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitCount])

  const isAutomaticSelected = values.pathSelector !== 'manual'

  // Get the preceding API call tiles based on the step count to start
  const precedingApiCallTiles = steps.filter(step => {
    const stepCountFromStepToStart = isOutcomeVariable ? getStepsToStartCount({ step, steps }) : 0 // TODO: getStepsToStartCount blocks JS runtime and need to be optimized

    return (
      step?.workflow?.code === WorkflowActivityConnectorCode.API_CALL_V2 &&
      stepCountFromStepToStart <= gatewayStepCountToStart
    )
  })

  // Get the API call activity options based on the preceding API call tiles
  const apiCallActivityOptions = precedingApiCallTiles.map(s => ({ id: s.ID, name: s.description }))

  // Get the preceding system call tiles
  const { hasPrecedingSystemTiles, activities: precedingSystemTiles } = getUpstreamSystemOutcomeVariables({
    step,
    steps,
  })

  const systemOutcomeVariable: ContextVariableDTO = {
    name: 'App Response',
    code: 'app_response',
    activities: precedingSystemTiles,
  }

  const systemOutcomeVariableActivities = precedingSystemTiles.map(systemTile => ({
    id: systemTile.ID,
    name: systemTile.description,
  }))

  const outcomeVariableOptions = hasPrecedingSystemTiles ? [systemOutcomeVariable] : []

  // Find the selected context variable based on the current values
  const selectedContextVariable = [...outcomeVariables, ...dataSubjectVariables, systemOutcomeVariable].find(cv => {
    return cv.code === values?.transitions?.[index]?.variable
  })
  const isAppResponse = selectedContextVariable?.code === 'app_response'

  // Get the filtered outcome variables based on the type of variable selected
  const filteredOutcomeVariables = isOutcomeVariable
    ? getOutcomeVariablesAvailableOnUpstreamTaskSteps({
        steps,
        step,
        outcomeVariables,
        precedingApiCallTiles,
      })
    : [] // TODO: getOutcomeVariablesAvailableOnUpstreamTaskSteps calls getStepsToStartCount which blocks JS runtime and need to be optimized

  outcomeVariableOptions.push(...filteredOutcomeVariables)

  // Set the available context variable options based on the type of variable selected
  const contextVariableOptions = isOutcomeVariable ? outcomeVariableOptions : dataSubjectVariables

  // Get the preceding tasks based on the step count to start
  const precedingTasks = steps.filter(step => {
    const stepCountFromStepToStart = isOutcomeVariable ? getStepsToStartCount({ step, steps }) : 0 // TODO: getStepsToStartCount blocks JS runtime and need to be optimized
    return (
      (step.activityCode === WorkflowActivityCode.TASK || step?.activity?.code === WorkflowActivityCode.TASK) &&
      stepCountFromStepToStart <= gatewayStepCountToStart
    )
  })

  // Get the task activity options based on the preceding tasks and selected context variable
  const taskActivityOptions = precedingTasks
    .filter(
      step =>
        (step?.activity?.params as ITaskActivityParams)?.outcomeVariables?.length > 0 &&
        !!(step?.activity?.params as ITaskActivityParams)?.outcomeVariables?.find(
          o => o.code === selectedContextVariable?.code,
        ),
    )
    .map(s => ({ id: s.ID, name: s.description }))

  // Set the activity options combining task and API call options
  const activityOptions = isAppResponse
    ? systemOutcomeVariableActivities
    : [...taskActivityOptions, ...apiCallActivityOptions]

  const disabledCopyMessage = useRef('Copy not allowed for this workflow step')
  const disableCopy = useMemo(() => {
    // Disable copying of an invalid step
    if (isUndefined(step.valid) || !step.valid) {
      disabledCopyMessage.current = 'Please complete gateway configuration before copying'
      return true
    }

    // Handle case where path has been added but not saved yet
    if (isUndefined(step.gateway?.transitions[index])) {
      disabledCopyMessage.current = 'Please save gateway configuration before copying'
      return true
    }

    // Get all steps in this path
    const isDecisionBoolean = step.gateway?.mode === WorkflowActivityGatewayMode.DECISION_BOOLEAN
    const pathSteps = getStepsInPath({
      pathStartStepId: step.gateway?.transitions[index].ID?.toString() || '',
      steps,
      untilEnd: isDecisionBoolean,
    })

    // Handle case where there are no steps in this path
    if (!pathSteps.length) {
      disabledCopyMessage.current = 'A path containing no steps cannot be copied'
      return true
    }

    // Disable copy if any step in the path is invalid
    if (
      pathSteps.some(
        pathStep =>
          getStepType(pathStep) !== CanvasStepType.Placeholder && (isUndefined(pathStep.valid) || !pathStep.valid),
      )
    ) {
      disabledCopyMessage.current = 'A path containing an invalid step cannot be copied'
      return true
    }

    return false
  }, [index, step, steps])

  const handleCopyMouseEnter = () => {
    hoveredCopyPasteType.current = CopyPasteType.path
    setHoveredCopySteps(
      getStepsInPath({
        pathStartStepId: step.gateway?.transitions[index]?.ID?.toString() || '',
        steps,
        untilEnd: isDecisionBoolean,
      }),
    )
  }

  const handleCopyMouseLeave = () => {
    hoveredCopyPasteType.current = undefined
    setHoveredCopySteps([])
  }

  const options = [
    {
      id: ContextVariableCategoryEnum.FORM_FIELD_VARIABLE,
      name: 'Form Field',
    },
  ]

  if (dataSubjectVariables.length)
    options.push({
      id: ContextVariableCategoryEnum.DATA_SUBJECT_VARIABLE,
      name: 'Data Subject Variable',
    })

  if (outcomeVariables.length)
    options.push({
      id: ContextVariableCategoryEnum.OUTCOME_VARIABLE,
      name: 'Outcome Variable',
    })

  const variableCategory = values?.transitions?.[index]?.variableCategory

  // Update operator and operand when we switch to the App Response variable type
  useEffect(() => {
    if (isAppResponse) {
      const operandValue = values?.transitions?.[index]?.operand
      setFieldValue(`transitions.${index}.operator`, OPERATOR_OPTIONS[0].id)
      if (!APP_RESPONSE_VARIABLE_VALUE_OPTIONS.find(option => option.id === operandValue)) {
        setFieldValue(`transitions.${index}.operand`, APP_RESPONSE_VARIABLE_VALUE_OPTIONS[0].id)
      }
    }
  }, [isAppResponse, index, setFieldValue, values?.transitions])

  return (
    <Draggable key={index} draggableId={String(index)} index={index}>
      {provided => (
        <Root
          ref={provided.innerRef}
          {...provided.draggableProps}
          p={1.5}
          mb={2}
          sx={({ palette }) => ({ background: palette.bone.main })}
          borderRadius="11px"
        >
          <Box
            display="flex"
            mb={isExpanded || !isAutomaticSelected ? 1.5 : undefined}
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center" ml={-1.25}>
              <Box {...provided.dragHandleProps} display="flex" alignItems="center" pr={1}>
                <Icon
                  name="ODragHandle"
                  iconColor={theme.palette.lightGrey.main}
                  sx={{
                    ml: 0.25,
                  }}
                />
              </Box>
              {expandable && isAutomaticSelected && (
                <Box
                  className={classes.withPointer}
                  display="flex"
                  alignItems="center"
                  onClick={() => setIsExpanded(!isExpanded)}
                  pr={2}
                >
                  <Icon
                    name="OArrowCRight"
                    className={clsx(classes.chevronIcon, {
                      [classes.chevronIconOpen]: isExpanded,
                    })}
                  />
                </Box>
              )}
              <FormInlineEdit
                placeholder={`Path ${index + 1}`}
                formPropertyName={`transitions.${index}.name`}
                shouldUpdateDebounced
              />
            </Box>

            <Box display="flex" gap={1}>
              {copyable && (
                <TooltipButton
                  title={disableCopy ? disabledCopyMessage.current : undefined}
                  variant="icon"
                  color="tertiary"
                  onClick={() => {
                    handleCopyPath(step.gateway?.transitions[index]?.ID?.toString() || '', steps, isDecisionBoolean)
                    setHoveredCopySteps([])
                    hoveredCopyPasteType.current = undefined
                    removeActiveSteps()
                  }}
                  disabled={disableCopy}
                  onMouseEnter={handleCopyMouseEnter}
                  onMouseLeave={handleCopyMouseLeave}
                >
                  <Icon name={'OCopy'} iconColor={theme.palette.sphere.main} />
                </TooltipButton>
              )}
              {values.transitions.length > 2 && (
                <Button onClick={() => remove(index)} variant="icon" color="tertiary">
                  <Icon name={'FBin'} />
                </Button>
              )}
            </Box>
          </Box>

          {/* Form for manual selection */}
          <Box px={2} sx={{ display: !isAutomaticSelected ? 'block' : 'none' }}>
            <Box marginBottom={1.5}>
              <FormInput
                size="small"
                formPropertyName={`transitions.${index}.description`}
                fullWidth
                label="Description"
                required
                placeholder="Example: Look through the request and assist in its completion."
                shouldUpdateDebounced
              />
            </Box>
          </Box>

          {/* Form for automatic selection */}
          <Box px={2} sx={{ display: isExpanded && isAutomaticSelected ? 'block' : 'none' }}>
            <Box marginBottom={1.5}>
              <FormInput
                size="small"
                formPropertyName={`transitions.${index}.description`}
                fullWidth
                label="Description"
                required
                placeholder="Example: Look through the request and assist in its completion."
                shouldUpdateDebounced
              />
            </Box>
            {/* Variable Type */}
            <Box marginBottom={1.5}>
              <FormRadioGroup
                row={false}
                label="Variable Type"
                valueKey="id"
                renderLabel={({ name }, isSelected) => (
                  <Typography variant={isSelected ? 'label' : 'body'} textTransform="capitalize">
                    {name}
                  </Typography>
                )}
                formPropertyName={`transitions.${index}.variableCategory`}
                size="small"
                options={options}
                hideOptionalLabel
                onChange={(e, nextValue) => {
                  /* On change of variable type, clear other values as appropriate */

                  setFieldValue(`transitions.${index}.variable`, '') // called "Data Subject Variable" in the UI
                  setFieldTouched(`transitions.${index}.variable`, false)

                  setFieldValue(`transitions.${index}.operator`, '') // called "condition" the UI
                  setFieldTouched(`transitions.${index}.operator`, false)

                  setFieldValue(`transitions.${index}.value`, '') // called "Enter Variable Value" in the UI
                  setFieldTouched(`transitions.${index}.value`, false)

                  setFieldValue(`transitions.${index}.outcomeVariableStepID`, '') // only applies to outcome variables, but doesn't hurt to clear it
                  setFieldTouched(`transitions.${index}.outcomeVariableStepID`, false)

                  setFieldValue(`transitions.${index}.operand`, '')
                  setFieldTouched(`transitions.${index}.operand`, false)

                  setFieldValue(`transitions.${index}.formFieldID`, '') // called "condition" the UI
                  setFieldTouched(`transitions.${index}.formFieldID`, false)

                  setFieldValue(`transitions.${index}.formFieldCode`, '')
                  setFieldTouched(`transitions.${index}.formFieldCode`, false)

                  setFieldValue(`transitions.${index}.formFieldCategory`, 0)
                  setFieldTouched(`transitions.${index}.variableCategory`, false)
                }}
              />
            </Box>

            {variableCategory === ContextVariableCategoryEnum.FORM_FIELD_VARIABLE ? <FormFields index={index} /> : null}

            {(variableCategory === ContextVariableCategoryEnum.DATA_SUBJECT_VARIABLE && dataSubjectVariables.length) ||
            (variableCategory === ContextVariableCategoryEnum.OUTCOME_VARIABLE && outcomeVariableOptions.length) ? (
              <>
                {/* Variable */}
                <Box marginBottom={1.5}>
                  <FormDroplistButton
                    disableClearable
                    size="small"
                    formPropertyName={`transitions.${index}.variable`}
                    fullWidth
                    required
                    label={isOutcomeVariable ? 'Outcome Variable' : 'Data Subject Variable'}
                    placeholder="Select Outcome Variable"
                    items={contextVariableOptions}
                    valueKey="code"
                    onItemClick={({ code: id, name }) => {
                      const isSelectedContextVariableOfTypeBoolean =
                        contextVariableOptions.find(cv => cv.code === id)?.type === ContextVariableType.Boolean

                      /* Reset downstream choices when variable changes */
                      setFieldValue(`transitions.${index}.operator`, isSelectedContextVariableOfTypeBoolean ? '==' : '') // called "condition" the UI
                      setFieldTouched(`transitions.${index}.operator`, false)

                      setFieldValue(`transitions.${index}.value`, '') // called "Enter Variable Value" in the UI
                      setFieldTouched(`transitions.${index}.value`, false)

                      setFieldValue(`transitions.${index}.outcomeVariableStepID`, '') // only applies to outcome variables, but doesn't hurt to clear it
                      setFieldTouched(`transitions.${index}.outcomeVariableStepID`, false)

                      setFieldValue(`transitions.${index}.operand`, '')
                      setFieldTouched(`transitions.${index}.operand`, false)
                    }}
                  />
                </Box>

                {/* Activity (only for Outcome variables) */}
                {values?.transitions?.[index]?.variableCategory === ContextVariableCategoryEnum.OUTCOME_VARIABLE && (
                  <Box marginBottom={1.5}>
                    <FormDroplistButton
                      size="small"
                      formPropertyName={`transitions.${index}.outcomeVariableStepID`}
                      fullWidth
                      required
                      labelTooltipText="One Outcome Variable can be used in several activities. Please specify from which activity this Outcome Variable should come."
                      label="Activity"
                      placeholder="Select Activity"
                      items={activityOptions}
                      valueKey="id"
                    />
                  </Box>
                )}

                {/* Condition */}
                <Box marginBottom={1.5}>
                  <FormDroplistButton
                    size="small"
                    required
                    fullWidth
                    formPropertyName={`transitions.${index}.operator`}
                    label="Condition"
                    placeholder="Select Condition"
                    items={getOperatorOptions(selectedContextVariable)}
                    valueKey="id"
                    disabled={isAppResponse}
                  />
                </Box>

                {/* Operand (varies based on type of variable) */}
                <Box mb={1}>
                  {/* TODO KD-5975 enum for context variable types */}
                  {selectedContextVariable?.type === 1 ? (
                    <FormDroplistButton
                      size="small"
                      required
                      fullWidth
                      formPropertyName={`transitions.${index}.operand`}
                      label="Select Variable Value"
                      placeholder="Select Value"
                      items={selectedContextVariable?.values?.map(val => ({ id: val, name: val })) || []}
                      valueKey="id"
                    />
                  ) : selectedContextVariable?.type === 3 ? (
                    <FormRadioGroup
                      row
                      hideOptionalLabel
                      formPropertyName={`transitions.${index}.operand`}
                      options={[
                        { title: 'True', value: 'true' },
                        { title: 'False', value: 'false' },
                      ]}
                      renderLabel={(item, isSelected) => {
                        return (
                          <Typography variant={isSelected ? 'label' : 'body'} textTransform="capitalize">
                            {item.value}
                          </Typography>
                        )
                      }}
                    />
                  ) : isAppResponse ? (
                    <Box marginBottom={1.5}>
                      <FormRadioGroup
                        label="Variable Value"
                        valueKey="id"
                        renderLabel={({ name }, isSelected) => (
                          <Typography variant={isSelected ? 'label' : 'body'}>{name}</Typography>
                        )}
                        formPropertyName={`transitions.${index}.operand`}
                        size="small"
                        options={APP_RESPONSE_VARIABLE_VALUE_OPTIONS}
                        hideOptionalLabel
                        required
                        row
                      />
                      {showTransitionError && (
                        <Typography color={theme.palette.Warning.Primary}>{transitionErrors}</Typography>
                      )}
                    </Box>
                  ) : (
                    <FormInput
                      size="small"
                      disabled={!values?.transitions?.[index]?.variable}
                      required
                      type={selectedContextVariable?.type === 2 ? 'number' : 'text'}
                      formPropertyName={`transitions.${index}.operand`}
                      fullWidth
                      label="Enter Variable Value"
                      placeholder="Variable"
                      shouldUpdateDebounced
                    />
                  )}
                </Box>
              </>
            ) : null}

            {variableCategory === ContextVariableCategoryEnum.OUTCOME_VARIABLE && !outcomeVariableOptions.length ? (
              <Box marginBottom={2}>
                <Banner severity="error">No Outcome Variables available, please select another option.</Banner>
              </Box>
            ) : null}
          </Box>
        </Root>
      )}
    </Draggable>
  )
}
