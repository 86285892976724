import { AssessmentAttributesSectionsItemDTO, AssessmentBlockCompletionStatusDTO } from '@ketch-com/figurehead'
import { AssessmentSection } from '@ketch-com/windlass/dist/assessments/externalUser'

export const QUESTION_ASSIGNMENT_STATES = {
  ALL: 'all',
  MINE: 'mine',
  INCOMPLETE: 'incomplete',
}

export const QUESTION_FILTER_OPTIONS = [
  { id: QUESTION_ASSIGNMENT_STATES.ALL, name: 'All Questions' },
  { id: QUESTION_ASSIGNMENT_STATES.MINE, name: 'Assigned to Me' },
  { id: QUESTION_ASSIGNMENT_STATES.INCOMPLETE, name: 'Incomplete' },
]

export const isSectionComplete = (section: AssessmentAttributesSectionsItemDTO | AssessmentSection) =>
  (
    section.blocks?.filter(
      block => block.completionStatus === AssessmentBlockCompletionStatusDTO.InProgressAssessmentBlockCompletionStatus,
    ) || []
  ).length === 0

export const acceptableFileTypes = {
  'image/jpeg': ['.jpeg', '.jpg'],
  'image/png': ['.png'],
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'text/csv': ['.csv'],
}
