import { ListAssetItemDTO } from '@ketch-com/figurehead'
import { useMemo, useState } from 'react'
import { createCustomContext } from 'utils/hooks/createCustomContext'

export interface DSRConfigSelectedAssetContextProps {
  selectedAsset?: ListAssetItemDTO
  setSelectedAsset: (asset: ListAssetItemDTO | undefined) => void
}

const { Provider, useCustomContext: useDsrConfigSelectedAssetContext } =
  createCustomContext<DSRConfigSelectedAssetContextProps>({
    displayName: 'DSRConfigSelectedAssetContext',
  })

export const DsrConfigSelectedAssetProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [selectedAsset, setSelectedAsset] = useState<ListAssetItemDTO | undefined>()

  const value = useMemo(
    () => ({
      selectedAsset,
      setSelectedAsset,
    }),
    [selectedAsset],
  )

  return <Provider value={value}>{children}</Provider>
}

export { useDsrConfigSelectedAssetContext }
