import {
  AssessmentBlockCompletionStatusDTO,
  AssessmentBlockCompletionStatusObjectDTO,
  AssessmentBlockReviewStatusDTO,
  AssessmentStatusDTO,
} from '@ketch-com/figurehead'
import {
  Assessment,
  AssessmentDocument,
  AssessmentSectionBlock,
} from '@ketch-com/windlass/dist/assessments/externalUser'
import { UpdateExternalBlockCompletionStatusParams } from 'api/assessments/fetchers/updateExternalBlockCompletionStatus'
import { usePutExternalAnswer } from 'api/assessments/mutations/usePutExternalAnswer'
import { useUpdateExternalBlockCompletionStatus } from 'api/assessments/mutations/useUpdateExternalBlockCompletionStatus'
import { useFilesInfoList } from 'api/files/queries/useFilesInfoList'
import { AxiosResponse } from 'axios'
import { showToast } from 'components/modals/AlertComponent'
import { FileInfoDTO } from 'interfaces/files/fileInfo'
import { QUESTION_TYPES } from 'pages/policyCenter/assessments/questions/constants'
import { useCallback, useMemo, useState } from 'react'
import { MutateOptions, QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query'
import { useDebounce } from 'react-use'
import { createCustomContext } from 'utils/hooks/createCustomContext'

interface ExternalAssessmentResponseContextProps {
  assessment: Assessment
  attachmentsCount: number
  setAttachmentsCount: (count: number) => void
  block: AssessmentSectionBlock
  blockAttachments: FileInfoDTO[]
  choices: string[]
  isValid: boolean
  setIsValid: (valid: boolean) => void
  value: string
  setValue: (value: string) => void
  hasTouchedInput: boolean
  isTextQuestion: boolean
  isCompleted: boolean
  isCompletedButStillWorking: boolean
  isRejected: boolean
  isInReview: boolean
  questionNumber: string
  setHasTouchedInput: (touched: boolean) => void
  save: (answer: AnswerDetails) => void
  startAutoSave: (blockId: string) => void
  finishAutoSave: (blockId: string) => void
  changeValue: (newValue: string) => void
  completeAnswer: (
    customMutateAsyncParams?:
      | ({ params?: Omit<UpdateExternalBlockCompletionStatusParams, never> | undefined } & MutateOptions<
          AxiosResponse<AssessmentBlockCompletionStatusObjectDTO, any>,
          any,
          UpdateExternalBlockCompletionStatusParams,
          unknown
        >)
      | undefined,
  ) => Promise<AxiosResponse<AssessmentBlockCompletionStatusObjectDTO>>
  uncomplete: () => Promise<void>
  refetchAssessments: ExternalAssessmentResponseProviderProps['refetchAssessments']
}

interface AnswerDetails {
  text?: string
  choiceIDs?: string[]
}

export interface ExternalAssessmentResponseProviderProps {
  startAutoSave: (blockId: string) => void
  finishAutoSave: (blockId: string) => void
  questionNumber: string
  block: AssessmentSectionBlock
  assessment: Assessment
  children: React.ReactNode
  refetchAssessments: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<AxiosResponse<AssessmentDocument, any>, unknown>>
}

const { Provider, useCustomContext: useExternalAssessmentResponse } =
  createCustomContext<ExternalAssessmentResponseContextProps>({
    displayName: 'ExternAssessmentResponseContext',
  })
export const ExternalAssessmentResponseProvider: React.FC<ExternalAssessmentResponseProviderProps> = ({
  assessment,
  block,
  children,
  startAutoSave,
  finishAutoSave,
  questionNumber,
  refetchAssessments,
}) => {
  const [attachmentsCount, setAttachmentsCount] = useState(0)
  const [value, setValue] = useState(block.answer?.attributes?.text || '')
  const [isValid, setIsValid] = useState(true)
  const [hasTouchedInput, setHasTouchedInput] = useState(false)
  const [choices, setChoices] = useState(block.answer?.attributes?.choiceIDs || [])

  const { mutateAsync: completeAnswer } = useUpdateExternalBlockCompletionStatus({
    onError: error => {
      showToast({ type: 'error', content: 'Failed to complete question' })
      console.error('Failed to complete question', error)
    },
    onSuccess: (response: AxiosResponse<AssessmentBlockCompletionStatusObjectDTO>) => {
      const isCompleted =
        response.data.completionStatus === AssessmentBlockCompletionStatusDTO.CompleteAssessmentBlockCompletionStatus
      return showToast({
        type: isCompleted ? 'success' : 'warning',
        content: `Question ${isCompleted ? 'completed' : 'reset'}`,
      })
    },
  })

  const uncomplete = useCallback(async () => {
    completeAnswer({
      params: {
        assessmentId: assessment.id || '',
        blockId: block.id || '',
        completionStatus: AssessmentBlockCompletionStatusDTO.InProgressAssessmentBlockCompletionStatus,
      },
    })
  }, [assessment.id, block.id, completeAnswer])

  const { mutateAsync: saveAnswer } = usePutExternalAnswer({
    onError: () => showToast({ type: 'error', content: 'Autosave failed' }),
    onSettled: () => finishAutoSave(block.question?.id || ''),
  })

  const save: ExternalAssessmentResponseContextProps['save'] = useCallback(
    answer => {
      startAutoSave(block.question?.id || '')
      saveAnswer({ params: { assessmentId: assessment.id || '', blockId: block.id || '', answer } })
    },
    [assessment.id, block.id, block.question?.id, saveAnswer, startAutoSave],
  )

  const { data: blockAttachments } = useFilesInfoList({
    params: {
      version: 'assessments_answers',
      folder: `${block.id}`,
    },
    enabled: block.question?.attributes?.allowAttachments,
    onSuccess: (data: FileInfoDTO[] | null) => {
      setAttachmentsCount(data?.length || 0)
    },
  })

  const isTextQuestion = useMemo(
    () =>
      block.question?.attributes?.type === QUESTION_TYPES.LONG_ANSWER ||
      block.question?.attributes?.type === QUESTION_TYPES.SHORT_ANSWER,
    [block.question?.attributes?.type],
  )

  const isInReview = useMemo(
    () => assessment.attributes?.status === AssessmentStatusDTO.InReviewAssessmentStatus,
    [assessment.attributes?.status],
  )

  const changeValue = useCallback(
    (newValue: string) => {
      setHasTouchedInput(true)
      setIsValid(true)
      if (isTextQuestion) {
        setValue(newValue)
      } else {
        const choiceIDs =
          block.question?.attributes?.type === QUESTION_TYPES.MULTIPLE_CHOICE
            ? choices.includes(newValue)
              ? choices.filter(c => c !== newValue)
              : [...choices, newValue]
            : [newValue]
        setChoices(choiceIDs)
      }
    },
    [block.question?.attributes?.type, choices, isTextQuestion],
  )

  const isCompleted = useMemo(
    () => block.completionStatus === AssessmentBlockCompletionStatusDTO.CompleteAssessmentBlockCompletionStatus,
    [block.completionStatus],
  )
  const isCompletedButStillWorking = useMemo(
    () =>
      block.completionStatus === AssessmentBlockCompletionStatusDTO.CompleteAssessmentBlockCompletionStatus &&
      assessment.attributes?.status === AssessmentStatusDTO.InProgressAssessmentStatus,
    [assessment.attributes?.status, block.completionStatus],
  )
  const isRejected = useMemo(
    () =>
      block.reviewStatus === AssessmentBlockReviewStatusDTO.RejectedAssessmentBlockReviewStatus &&
      assessment.attributes?.status === AssessmentStatusDTO.InReviewAssessmentStatus,
    [assessment.attributes?.status, block.reviewStatus],
  )

  useDebounce(
    () => {
      if (!hasTouchedInput) return

      if (isTextQuestion) {
        save({ text: value })
      } else {
        save({ choiceIDs: choices })
      }
    },
    1000,
    [value, choices],
  )

  const contextValue = useMemo(
    () => ({
      assessment,
      attachmentsCount,
      setAttachmentsCount,
      block,
      blockAttachments: blockAttachments || [],
      choices,
      startAutoSave,
      finishAutoSave,
      save,
      completeAnswer,
      value,
      setValue,
      isValid,
      isCompleted,
      isCompletedButStillWorking,
      isRejected,
      isInReview,
      setIsValid,
      isTextQuestion,
      questionNumber,
      hasTouchedInput,
      setHasTouchedInput,
      changeValue,
      uncomplete,
      refetchAssessments,
    }),
    [
      assessment,
      attachmentsCount,
      block,
      blockAttachments,
      changeValue,
      choices,
      completeAnswer,
      finishAutoSave,
      hasTouchedInput,
      isCompleted,
      isCompletedButStillWorking,
      isInReview,
      isRejected,
      isTextQuestion,
      isValid,
      questionNumber,
      refetchAssessments,
      save,
      startAutoSave,
      uncomplete,
      value,
    ],
  )

  return <Provider value={contextValue}>{children}</Provider>
}

export { useExternalAssessmentResponse }
