import { FetchProcessingActivitiesResponseBodyDTO } from '@ketch-com/figurehead'
import { APIListRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestStringV2 } from 'utils/helpers/api/formatRequestStringV2'

type Params = APIListRequestParams<{
  includeDeleted?: boolean
  ordering?: string[]
  types?: string[]
  statuses?: string[]
  dataSystemIds?: string[]
  dataCategoryCodes?: string[]
  purposeIds?: string[]
  purposeCodes?: string[]
  businessFunctionCodes?: string[] // Deprecated - moving to teams
  teamIds?: string[]
  residencyCountryCodes?: string[]
  securityMeasures?: number[]
  isV2FeatureFlagEnabled?: boolean
}>

export const fetchProcessingActivities = ({ isV2FeatureFlagEnabled, ...filters }: Params) => {
  const entityUrl = isV2FeatureFlagEnabled ? '/api/risk-review/processing-activities' : '/api/processing-activities'
  return API.get<FetchProcessingActivitiesResponseBodyDTO>(
    formatRequestStringV2({
      entityUrl,
      params: { filters },
    }),
  )
}
