import { FormSectionSwitch } from '../FormSectionSwitch'
import Box from '@mui/material/Box'
import { SidebarFormGroup } from '../SidebarFormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Typography } from '@mui/material'
import { FormTextInput } from '../FormTextInput'
import { FormCheckbox } from '../FormCheckbox'
import { experienceUpsertFormKeys } from '../../../../utils/useExperienceValidationSchema'
import { SidebarFormTranslations } from '../SidebarFormTranslations'
import { getIn, useField, useFormikContext } from 'formik'
import { SwitchButtonDisplay } from '@ketch-sdk/ketch-types'
import {
  ActionSheetItem,
  Button,
  ChipsAutocomplete,
  ChipsAutocompleteOptionType,
  Icon,
  ListItemText,
  Tab,
  TabGroup,
  TabPanel,
  TabSegmented,
  TabSegmentedGroup,
  theme,
} from '@ketch-com/deck'
import { SidebarFormRadioGroup } from '../SidebarFormRadioGroup'
import { SwitchButtonDisplayLabels } from 'pages/consentAndRights/experiences-v2/upsert/utils/labels'
import { RoutesManager } from 'utils/routing/routesManager'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { SyntheticEvent, useContext, useMemo, useRef, useState } from 'react'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences-v2/upsert/context/ExperienceUpsertContext'
import { getFormNameForLocale } from 'pages/consentAndRights/experiences-v2/upsert/utils/utils'
import { defaultExperienceV2DTO } from 'pages/consentAndRights/experiences-v2/upsert/utils/defaults'
import { VendorsSegmentButtonType } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/forms/ModalExperiencePurposesListForm'
import { FormEditor } from 'components/ui-kit/form/editor/FormEditor'
import { renderDocumentLinkerModalExcludeModal } from 'components/ui-kit/form/editor/components/DocumentLinkerModal'
import { useInstalledDataSystems } from 'api/dataSystems/queries/useInstalledDataSystems'

export const PreferenceExperiencePurposesTabListForm: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<ExperienceV2DTO>()
  const { languages, isLanguagesLoading } = useContext(ExperienceUpsertContext)
  const [tab, setTab] = useState(0)
  const ref = useRef()
  const [selectedVendorsSegmentButton, setSelectedVendorsSegmentButton] = useState<VendorsSegmentButtonType>(
    VendorsSegmentButtonType.Configurations,
  )

  const { data: systems, isLoading: isSystemsLoading } = useInstalledDataSystems({
    params: {
      limit: 1000,
    },
  })
  const otherVendorOptions = useMemo(() => {
    return (
      systems?.reduce<Array<{ value: string; label: string }>>((acc, system) => {
        if (system.id && system.dataSystem?.name) {
          acc.push({
            value: system.id,
            label: system.dataSystem.name,
          })
        }
        return acc
      }, []) || []
    )
  }, [systems])

  const hasLanguages = !isLanguagesLoading && !!languages.length
  const showSwitchButtonForm = getIn(
    values,
    experienceUpsertFormKeys.preferencePurposesTabListSwitchButtonLabelsVisible,
  )
  const showCustomSwitchButtonForm = !getIn(
    values,
    experienceUpsertFormKeys.preferencePurposesTabListSwitchButtonLabelsUseDefaultText,
  )

  const showVendorsForm = getIn(values, experienceUpsertFormKeys.preferencePurposesTabListVendorsVisible)
  const showOtherVendors = getIn(values, experienceUpsertFormKeys.preferencePurposesTabListVendorsIncludeOtherVendors)
  const useDefaultVendorsLinkText = getIn(
    values,
    experienceUpsertFormKeys.preferencePurposesTabListVendorsLinkUseDefaultText,
  )
  const showOtherVendorsPicker = getIn(
    values,
    experienceUpsertFormKeys.preferencePurposesTabListVendorsIncludeOtherVendors,
  )

  const [otherVendorsField, , { setValue: setOtherVendors }] = useField(
    experienceUpsertFormKeys.preferencePurposesTabListVendorsOtherVendorIds,
  )

  const handlePurposeStacksClick = () => {
    window.open(RoutesManager.deployment.purposeStacks.root.getURL(), '_blank')
  }

  const handleProtocolsClick = () => {
    window.open(RoutesManager.orchestration.consent.privacyProtocols.root.getURL(), '_blank')
  }

  const handleSwitchButtonsDefaultChange = (event: SyntheticEvent<Element, Event>, checked: boolean) => {
    if (checked && !isLanguagesLoading) {
      // Reset both the on and off text
      ;[
        experienceUpsertFormKeys.preferencePurposesTabListSwitchButtonsOnText,
        experienceUpsertFormKeys.preferencePurposesTabListSwitchButtonsOffText,
        experienceUpsertFormKeys.preferencePurposesTabListSwitchButtonLabelsAlwaysOnText,
      ].forEach(englishFieldToReset => {
        // Reset to english default
        const defaultEnglishValue = getIn(defaultExperienceV2DTO, englishFieldToReset)
        setFieldValue(englishFieldToReset, defaultEnglishValue)

        // Reset to default for each locale
        languages.forEach(({ language: { code } }) => {
          const localeFieldToReset = getFormNameForLocale(englishFieldToReset, code)
          const defaultLocaleValue = getIn(defaultExperienceV2DTO, localeFieldToReset)
          setFieldValue(localeFieldToReset, defaultLocaleValue)
        })
      })
    }
  }

  const handleVendorLinkTextDefaultChange = (event: SyntheticEvent<Element, Event>, checked: boolean) => {
    if (checked && !isLanguagesLoading) {
      const englishFieldToReset = experienceUpsertFormKeys.preferencePurposesTabListVendorsLinkText

      // Reset to english default
      const defaultEnglishValue = getIn(defaultExperienceV2DTO, englishFieldToReset)
      setFieldValue(englishFieldToReset, defaultEnglishValue)

      // Reset to default for each locale
      languages.forEach(({ language: { code } }) => {
        const localeFieldToReset = getFormNameForLocale(englishFieldToReset, code)
        const defaultLocaleValue = getIn(defaultExperienceV2DTO, localeFieldToReset)
        setFieldValue(localeFieldToReset, defaultLocaleValue)
      })
    }
  }

  const handleSegmentChange = (_: React.MouseEvent<HTMLElement>, newVendorsSegmentType: VendorsSegmentButtonType) => {
    if (newVendorsSegmentType !== null) {
      setSelectedVendorsSegmentButton(newVendorsSegmentType)
    }
  }

  return (
    <>
      {/* Legal bases */}
      <SidebarFormGroup sx={{ paddingTop: 0 }} hasBorderBottom>
        <Box>
          <FormControlLabel
            control={<FormCheckbox name={experienceUpsertFormKeys.preferencePurposesTabListLegalBasisVisible} />}
            label={<Typography variant={'label'}>Show Legal Bases</Typography>}
          />
          <Typography component={'p'} variant={'body'} color={theme.palette.Text.Secondary}>
            The legal basis serves as your rationale for legally processing personal data. Choose whether you want this
            text label to be displayed beneath each Purpose.
          </Typography>
        </Box>
      </SidebarFormGroup>

      {/* Purpose stacks */}
      <SidebarFormGroup>
        <Box>
          <Typography component={'p'} variant={'body'} mb={1}>
            To group Purposes together, click on the button below to configure.
          </Typography>
          <Button
            onClick={handlePurposeStacksClick}
            color={'tertiary'}
            endIcon={<Icon name={'OShare'} iconColor={theme.palette.sphere.main} />}
          >
            Configure Purpose Stacks
          </Button>
        </Box>
      </SidebarFormGroup>

      {/* Tabs */}
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabGroup value={tab} onChange={(event: React.SyntheticEvent, newValue: number) => setTab(newValue)}>
          <Tab size={'medium'} label="Switch Buttons" />
          <Tab size={'medium'} label="Vendors" />
        </TabGroup>
      </Box>

      {/* Switch button labels */}
      <TabPanel value={tab} index={0} sx={{ px: 0 }}>
        <SidebarFormGroup sx={{ pt: 0 }}>
          <Box>
            <FormSectionSwitch
              name={experienceUpsertFormKeys.preferencePurposesTabListSwitchButtonLabelsVisible}
              title={'Switch Button Labels'}
            />
            {showSwitchButtonForm && (
              <Box p={'16px 18px'}>
                <SidebarFormGroup hasBorderBottom sx={{ pt: 0, pb: 3 }}>
                  <SidebarFormRadioGroup
                    name={experienceUpsertFormKeys.preferencePurposesTabListSwitchButtonLabelsDisplay}
                    title={'Display'}
                    options={Object.values(SwitchButtonDisplay).map(display => ({
                      label: SwitchButtonDisplayLabels[display],
                      value: display,
                    }))}
                  />
                </SidebarFormGroup>
                <SidebarFormGroup sx={{ pt: 3, pb: 4 }} hasBorderBottom={showCustomSwitchButtonForm && hasLanguages}>
                  <Box>
                    <FormControlLabel
                      control={
                        <FormCheckbox
                          name={experienceUpsertFormKeys.preferencePurposesTabListSwitchButtonLabelsUseDefaultText}
                          afterChange={handleSwitchButtonsDefaultChange}
                        />
                      }
                      label={<Typography variant={'label'}>Use Default Text</Typography>}
                    />
                    <Typography component={'p'} variant="body" color={theme.palette.Text.Secondary}>
                      The default text "Opted In" and "Opted Out" has translations for all available languages.
                    </Typography>
                  </Box>
                  {showCustomSwitchButtonForm && (
                    <>
                      <FormTextInput
                        label={'Switch Button ON Text'}
                        name={experienceUpsertFormKeys.preferencePurposesTabListSwitchButtonsOnText}
                        fullWidth
                        charLimit={30}
                      />
                      <FormTextInput
                        label={'Switch Button OFF Text'}
                        name={experienceUpsertFormKeys.preferencePurposesTabListSwitchButtonsOffText}
                        fullWidth
                        charLimit={30}
                      />
                      <FormTextInput
                        label={'Switch Button Always On Text'}
                        name={experienceUpsertFormKeys.preferencePurposesTabListSwitchButtonLabelsAlwaysOnText}
                        fullWidth
                        charLimit={30}
                        tooltip={
                          'This text will be displayed when the purpose legal basis affords the user no choice. Examples of such legal bases are Disclosure or Legitimate Interest - Non-Objectable.'
                        }
                      />
                    </>
                  )}
                </SidebarFormGroup>
                {showCustomSwitchButtonForm && (
                  <SidebarFormTranslations
                    title={'Switch Button Text Translations'}
                    switchButtonOnName={experienceUpsertFormKeys.preferencePurposesTabListSwitchButtonsOnText}
                    switchButtonOffName={experienceUpsertFormKeys.preferencePurposesTabListSwitchButtonsOffText}
                    switchButtonAlwaysOnName={
                      experienceUpsertFormKeys.preferencePurposesTabListSwitchButtonLabelsAlwaysOnText
                    }
                    showAlwaysOnButton
                    variant={'switchButtons'}
                    charLimit={30}
                  />
                )}
              </Box>
            )}
          </Box>
        </SidebarFormGroup>
      </TabPanel>

      {/* Vendors */}
      <TabPanel value={tab} index={1} sx={{ px: 0 }}>
        <SidebarFormGroup sx={{ paddingTop: 0 }}>
          <Box>
            <FormSectionSwitch
              name={experienceUpsertFormKeys.preferencePurposesTabListVendorsVisible}
              title={'Show Vendors'}
            />
            {showVendorsForm && (
              <Box display="flex" flexDirection="column" gap={4} p={'16px 18px'}>
                {/* Segment buttons */}
                <TabSegmentedGroup
                  value={selectedVendorsSegmentButton}
                  exclusive
                  onChange={handleSegmentChange}
                  size="small"
                  fullWidth={false}
                  sx={{ width: 'fit-content' }}
                >
                  <TabSegmented value={VendorsSegmentButtonType.Configurations} key="1">
                    <Typography
                      variant={
                        selectedVendorsSegmentButton === VendorsSegmentButtonType.Configurations
                          ? 'smallLabel'
                          : 'smallBody'
                      }
                    >
                      Configuration
                    </Typography>
                  </TabSegmented>
                  <TabSegmented value={VendorsSegmentButtonType.LinkText} key="2">
                    <Typography
                      variant={
                        selectedVendorsSegmentButton === VendorsSegmentButtonType.LinkText ? 'smallLabel' : 'smallBody'
                      }
                    >
                      Link
                    </Typography>
                  </TabSegmented>
                  <TabSegmented value={VendorsSegmentButtonType.DescriptionText} key="3">
                    <Typography
                      variant={
                        selectedVendorsSegmentButton === VendorsSegmentButtonType.DescriptionText
                          ? 'smallLabel'
                          : 'smallBody'
                      }
                    >
                      Description
                    </Typography>
                  </TabSegmented>
                </TabSegmentedGroup>

                {selectedVendorsSegmentButton === VendorsSegmentButtonType.Configurations && (
                  <Box display="flex" flexDirection="column" gap={3}>
                    <Box>
                      <Typography component={'p'} variant={'body'} mb={1}>
                        All TCF and Google vendors related to privacy protocols will be listed automatically. To manage
                        privacy protocols, click the button below.
                      </Typography>
                      <Button
                        onClick={handleProtocolsClick}
                        color={'tertiary'}
                        endIcon={<Icon name={'OShare'} iconColor={theme.palette.sphere.main} />}
                      >
                        Privacy Protocols
                      </Button>
                    </Box>

                    <Box>
                      <FormControlLabel
                        control={
                          <FormCheckbox
                            name={experienceUpsertFormKeys.preferencePurposesTabListVendorsIncludeOtherVendors}
                            afterChange={(_, checked) =>
                              setFieldValue(
                                experienceUpsertFormKeys.preferencePurposesTabListVendorsIncludeOtherVendors,
                                checked,
                              )
                            }
                          />
                        }
                        label={
                          <Typography variant={showOtherVendors ? 'label' : 'body'}>Include Other Vendors</Typography>
                        }
                      />
                      <Typography component={'p'} variant="body" color={theme.palette.Text.Secondary}>
                        Add vendors manually to the list for disclosure in this experience.
                      </Typography>
                    </Box>

                    {showOtherVendorsPicker && (
                      <ChipsAutocomplete
                        ref={ref.current}
                        fullWidth
                        renderOption={(
                          props: React.HTMLAttributes<HTMLLIElement>,
                          option: ChipsAutocompleteOptionType,
                        ) => (
                          <ActionSheetItem {...props} key={option.value}>
                            <ListItemText>{option.label}</ListItemText>
                          </ActionSheetItem>
                        )}
                        isOptionEqualToValue={(elem, value) => elem.value === value.value}
                        disableClearable
                        inputVariant="ghost"
                        placeholder="Select vendors"
                        chipSize="medium"
                        size="medium"
                        multiple
                        inRowChips
                        loading={isSystemsLoading}
                        options={otherVendorOptions}
                        // Handle adding new vendors
                        onChange={(_, newValue) => {
                          setOtherVendors(newValue.map(item => item.value))
                        }}
                        // Handle removing vendors
                        handleChipClick={deletedVendor => {
                          const result = otherVendorsField.value.filter(
                            (vendor: string) => vendor !== deletedVendor.value,
                          )
                          setOtherVendors(result)
                        }}
                        value={
                          otherVendorsField.value
                            ? otherVendorOptions.filter(
                                option =>
                                  Array.isArray(otherVendorsField.value) &&
                                  otherVendorsField.value.includes(option.value),
                              )
                            : []
                        }
                      />
                    )}
                  </Box>
                )}

                {selectedVendorsSegmentButton === VendorsSegmentButtonType.LinkText && (
                  <Box>
                    <SidebarFormGroup hasBorderBottom={!useDefaultVendorsLinkText} sx={{ pt: 0, mb: 0, gap: 3 }}>
                      <Typography>Provide text that will appear on the “Vendors” link.</Typography>

                      <Box>
                        <FormControlLabel
                          control={
                            <FormCheckbox
                              name={experienceUpsertFormKeys.preferencePurposesTabListVendorsLinkUseDefaultText}
                              afterChange={handleVendorLinkTextDefaultChange}
                            />
                          }
                          label={
                            <Typography variant={showOtherVendors ? 'label' : 'body'}>Use Default Text</Typography>
                          }
                        />
                        <Typography component={'p'} variant="body" color={theme.palette.Text.Secondary}>
                          The default text “Vendors” have translations for all available languages.
                        </Typography>
                      </Box>

                      {!useDefaultVendorsLinkText && (
                        <Box pt={1}>
                          <FormTextInput
                            label={'Vendors Link Text'}
                            name={experienceUpsertFormKeys.preferencePurposesTabListVendorsLinkText}
                            charLimit={30}
                            fullWidth
                          />
                        </Box>
                      )}
                    </SidebarFormGroup>

                    {!useDefaultVendorsLinkText && (
                      <SidebarFormTranslations
                        title={'Vendors Link Text Translations'}
                        name={experienceUpsertFormKeys.preferencePurposesTabListVendorsLinkText}
                        variant={'text'}
                        charLimit={30}
                      />
                    )}
                  </Box>
                )}

                {selectedVendorsSegmentButton === VendorsSegmentButtonType.DescriptionText && (
                  <Box>
                    <SidebarFormGroup hasBorderBottom={!useDefaultVendorsLinkText} sx={{ pt: 0, mb: 0, gap: 4 }}>
                      <Typography>Provide text that will appear on the page dedicated to vendors.</Typography>
                      <FormEditor
                        label={'Description'}
                        required={false}
                        id={experienceUpsertFormKeys.preferencePurposesTabListVendorsDescription.replaceAll('.', '_')}
                        name={experienceUpsertFormKeys.preferencePurposesTabListVendorsDescription}
                        linkChangeModal={renderDocumentLinkerModalExcludeModal}
                        charLimit={3000}
                      />
                    </SidebarFormGroup>

                    <SidebarFormTranslations
                      title={'Description Translations'}
                      name={experienceUpsertFormKeys.preferencePurposesTabListVendorsDescription}
                      variant="richText"
                      charLimit={3000}
                    />
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </SidebarFormGroup>
      </TabPanel>
    </>
  )
}
