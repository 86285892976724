import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { GetProcessingActivityChatResponseBodyDTO } from '@ketch-com/figurehead'

type Params = APIRequestParams<{
  activityId: string
  isV2FeatureFlagEnabled?: boolean
}>

export const fetchProcessingActivityChat = ({ activityId, isV2FeatureFlagEnabled }: Params) => {
  const entityUrl = isV2FeatureFlagEnabled
    ? `/api/risk-review/processing-activities/${activityId}/chat`
    : `/api/processing-activities/${activityId}/chat`
  return API.get<GetProcessingActivityChatResponseBodyDTO>(
    formatRequestString({
      entityUrl,
      params: {
        filters: {},
      },
    }),
  )
}
