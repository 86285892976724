import { useState, Dispatch, SetStateAction } from 'react'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { RoutesManager } from 'utils/routing/routesManager'
import { useParams, useNavigate } from 'react-router-dom'
import { Table } from 'components/ui-layouts/table/Table'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { InfiniteScrollLoadingIndicator } from 'components/ui-layouts/table/components/infiniteScrollLoadingIndicator'
import { ReactComponent as SchemasIcon } from 'assets/icons/schemas.svg'
import { SearchInput } from 'components/ui-kit/searchInput/SearchInput'
import { AssetSummaryDTO, LabelDTO, GetDSRConfigCountResponseBodyDTO } from '@ketch-com/figurehead'
import { Waypoint } from 'react-waypoint'
import { Badge } from 'components/ui-kit/badge/Badge'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { getLabelDTOUniqueIdentifier } from 'pages/assetManager/assets/view/components/AssetDetailViewRelatedAssets/components/DataCategoriesBulkAssignModal/utils/getLabelDTOUniqueIdentifier'
import { FormMode } from 'interfaces/formModes/formMode'
import { ButtonActions } from 'components/ui-kit/buttonActions/ButtonActions'
import { Button, Chip } from '@ketch-com/deck'
import { useDebounce } from 'react-use'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'
import { getAssetCloudImgUrl } from 'pages/assetManager/utils'
import { ReactComponent as SkippedIcon } from 'assets/icons/no.svg'
import { ReactComponent as ConfiguredIcon } from 'assets/icons/green_check.svg'
import pluralize from 'pluralize'
import { ReactComponent as EmptyStateIcon } from 'assets/icons/search.svg'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { getAssetType } from 'pages/assetManager/utils'
import { SQLPreviewModal } from 'pages/assetManager/scenario/components'
import { MaybeNull } from 'interfaces'
import { theme } from '@ketch-com/deck'
import { ScenarioDetailsParams } from 'utils/routing/routesV2/systems'

const PREFIX = 'DatasetConfigTable'

const classes = {
  chip: `${PREFIX}-chip`,
}

const Root = styled(Box)(({ theme: { spacing, palette } }) => ({
  [`& .${classes.chip}`]: {
    backgroundColor: palette.marine.main,
  },
}))

type Props = {
  assets: AssetSummaryDTO[]
  isLoading: boolean
  isFetchingNextPage?: boolean
  hasNextPage?: boolean
  fetchNextPage: () => {}
  setSearchQuery: Dispatch<SetStateAction<string>>
  skipDataset: any
  dsrConfigCount: GetDSRConfigCountResponseBodyDTO
}

export const NoDirectIdentityLookup: React.FC<Props> = ({
  assets,
  isLoading,
  isFetchingNextPage,
  hasNextPage,
  fetchNextPage,
  setSearchQuery,
  skipDataset,
  dsrConfigCount,
}) => {
  const [searchString, setSearchString] = useState<string | null>(null)
  const [selectedDatasetForSqlPreview, setSelectedDatasetForSqlPreview] = useState<MaybeNull<AssetSummaryDTO>>(null)
  const navigate = useNavigate()
  const { scenarioId } = useParams<ScenarioDetailsParams>()
  const rightCode = dsrConfigCount?.dsrScenario?.[scenarioId!]?.canonicalRight?.code || ''
  const connectionResourceTypes = assets?.[0]?.asset?.connection?.resourceTypes || []
  const attributeResourceType = connectionResourceTypes?.[connectionResourceTypes.length - 1] || {}
  const attributeResourceTypeCode = attributeResourceType?.code || ''
  const providerCode = assets?.[0]?.asset?.connection?.providerCode || ''
  const databaseType = getAssetType(providerCode, 1)
  const datasetType = getAssetType(providerCode, 2)
  const attributeType = getAssetType(providerCode, 3)

  useDebounce(() => setSearchQuery(searchString ?? ''), 500, [searchString])

  if (!isLoading && !assets?.length && searchString === null)
    return (
      <EmptyState
        variant="page"
        bgVariant="grey"
        icon={<SchemasIcon />}
        title={`No Direct Lookup ${pluralize.plural(datasetType)}`}
        description={`There are no ${pluralize.plural(
          datasetType,
        )} containing personal data and does not support a direct lookup in this ${databaseType.toLowerCase()}.`}
      />
    )

  return (
    <Root>
      <Box>
        <Box marginBottom={2}>
          <SearchInput
            disabled={isLoading}
            size="small"
            onChange={nextSearchString => setSearchString(nextSearchString)}
            onClear={() => setSearchString('')}
            value={searchString ?? ''}
          />
        </Box>

        {isLoading ? <Spinner /> : null}

        {!isLoading && !assets?.length && searchString ? (
          <EmptyState
            variant="page"
            bgVariant="white"
            icon={<EmptyStateIcon />}
            title={`No matches for ${searchString ? `"${searchString}"` : 'your search'}`}
            description="Please try another search."
          />
        ) : null}

        {!isLoading && assets?.length ? (
          <>
            <Table
              isSmallHeaderText
              isCapitalizedHeaderText
              items={assets}
              pending={isLoading}
              rowIdKey={assetSummary => assetSummary?.asset?.resource?.id || ''}
              cellSettings={{
                name: {
                  width: 500,
                  label: 'Name',
                  cellRenderer: assetSummary => {
                    const technology = assetSummary.asset?.connection?.technology || ''
                    const dataSetType = (
                      <Box display="flex" alignItems="center" gap={0.5} mt={0.5}>
                        <img src={getAssetCloudImgUrl(technology)} alt={technology} width={15} height={15} />
                        <Typography fontSize={11} fontWeight={500} textTransform="capitalize">
                          {datasetType}
                        </Typography>
                      </Box>
                    )
                    return (
                      <NameAndCodeCellRenderer
                        name={
                          // TODO - decide if we want to use assetScenarios routing to column assets.  If not delete commented code.
                          // <Link
                          //   to={RoutesManager.systems.dsrInvocation.assetScenarios.getURL({
                          //     assetId: assetSummary.asset?.resource?.id!,
                          //     resourceType: assetSummary.asset?.resource?.resourceType?.code!,
                          //   })}
                          // >
                          //   <Button size="large" variant="link">
                          <Typography variant="label">{assetSummary?.asset?.resource?.name}</Typography>
                          //   </Button>
                          // </Link>
                        }
                        code={dataSetType}
                      />
                    )
                  },
                },
                personalDataType: {
                  label: 'Data Sensitivity',
                  cellRenderer: assetSummary => {
                    const hasPersonalDataTypes = (assetSummary?.asset?.dataCategory?.length || 0) > 0
                    return hasPersonalDataTypes ? (
                      <Box display="flex" alignItems="center" gap={1}>
                        {assetSummary?.asset?.dataCategory?.map((dataCategory: LabelDTO) => {
                          return <Badge key={getLabelDTOUniqueIdentifier(dataCategory)}>{dataCategory?.value}</Badge>
                        })}
                      </Box>
                    ) : (
                      <EmptyValueRenderer color="darkDuskFaded" />
                    )
                  },
                },
                attributes: {
                  label: `${pluralize(attributeType, assets?.length || 0)}`,
                  cellRenderer: (assetSummary, index) => {
                    const count = assetSummary?.hierarchyCounts?.[attributeResourceTypeCode] || 0
                    return (
                      <>
                        <Chip className={classes.chip} label={count} />
                        {(assets?.length || 0) - 5 === index ? (
                          <Waypoint
                            onEnter={() => {
                              if (!isFetchingNextPage && hasNextPage) fetchNextPage()
                            }}
                          />
                        ) : null}
                      </>
                    )
                  },
                },
                actions: {
                  width: 300,
                  cellRenderer: assetSummary => {
                    const configureIdentityUrl = RoutesManager.systems.dsrInvocation.configureIdentity.getURL({
                      scenarioId: scenarioId!,
                      id: assetSummary.asset?.resource?.id!,
                      formMode: FormMode.EDIT,
                    })
                    const dsrConfig = assetSummary.asset?.dsrConfiguration
                    const selectedDsrConfig = dsrConfig?.[scenarioId!] || {}
                    const { isConfigured, isSkipped } = selectedDsrConfig || {}
                    let component = null

                    if (selectedDsrConfig && !isConfigured && isSkipped) {
                      component = (
                        <>
                          <SkippedIcon />
                          <Typography variant="body">Skipped</Typography>
                          <Button
                            color="secondary"
                            onClick={() => {
                              navigate(configureIdentityUrl)
                            }}
                          >
                            Configure Identity
                          </Button>
                        </>
                      )
                    }

                    if (selectedDsrConfig && isConfigured && isSkipped) {
                      component = (
                        <>
                          <SkippedIcon />
                          <Typography variant="body">Skipped</Typography>
                          <Button
                            color="secondary"
                            onClick={() => {
                              navigate(configureIdentityUrl)
                            }}
                          >
                            Configure Identity
                          </Button>
                        </>
                      )
                    }

                    if (selectedDsrConfig && isConfigured && !isSkipped) {
                      component = (
                        <>
                          <ConfiguredIcon />
                          <Typography color={theme.palette.greenHaze.main} fontSize={14} fontWeight={600}>
                            Configured
                          </Typography>
                          <Button
                            color="tertiary"
                            onClick={() => {
                              setSelectedDatasetForSqlPreview(assetSummary)
                            }}
                          >
                            SQL
                          </Button>
                          <ButtonActions
                            variant="secondary"
                            items={[
                              {
                                content: 'Configure Identity',
                                onClick: () => {
                                  navigate(configureIdentityUrl)
                                },
                              },
                              {
                                content: 'Skip',
                                onClick: async () => {
                                  await skipDataset({
                                    params: {
                                      scenarioId,
                                      assetId: assetSummary.asset?.resource?.id!,
                                      payload: {
                                        isSkipped: true,
                                      },
                                    },
                                  })
                                },
                              },
                            ]}
                          >
                            Edit
                          </ButtonActions>
                        </>
                      )
                    }

                    if (!Object.keys(selectedDsrConfig).length) {
                      component = (
                        <ButtonActions
                          variant="secondary"
                          items={[
                            {
                              content: 'Configure Identity',
                              onClick: () => {
                                navigate(configureIdentityUrl)
                              },
                            },
                            {
                              content: 'Skip',
                              onClick: async () => {
                                await skipDataset({
                                  params: {
                                    scenarioId,
                                    assetId: assetSummary.asset?.resource?.id,
                                    payload: {
                                      isSkipped: true,
                                    },
                                  },
                                })
                              },
                            },
                          ]}
                        >
                          Configure
                        </ButtonActions>
                      )
                    }

                    return (
                      <Box width={260} display="flex" justifyContent="right" alignItems="center" gap={1}>
                        {component}
                      </Box>
                    )
                  },
                },
              }}
            />

            <InfiniteScrollLoadingIndicator isFetchingNextPage={isFetchingNextPage} />
          </>
        ) : null}
        {selectedDatasetForSqlPreview ? (
          <SQLPreviewModal
            scenarioId={scenarioId!}
            assetSummary={selectedDatasetForSqlPreview}
            rightCode={rightCode}
            onCancel={() => {
              setSelectedDatasetForSqlPreview(null)
            }}
          />
        ) : null}
      </Box>
    </Root>
  )
}
