import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'
import { useFeatureFlag } from 'utils/hooks'
import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from '../../common/mutation'
import { deleteProcessingActivity } from '../fetchers/deleteProcessingActivity'

const useCustomMutation = createUseMutation({
  mutationFn: deleteProcessingActivity,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useDeleteProcessingActivity = (config?: Config) => {
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isV2FeatureFlagEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.ASSESSMENT,
    FEATURE_FLAGS_VALUES.ASSESSMENTS_VERSION,
  )
  const mutation = useCustomMutation(config)
  return useBindMutationStaticParams(mutation, {
    isV2FeatureFlagEnabled,
  })
}
