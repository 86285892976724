import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { CanvasStepType } from 'pages/orchestration/workflows/edit/utils/constants'
import { getStepsOfTypeBeforeStep } from 'pages/orchestration/workflows/edit/utils/steps/getStepsOfTypeBeforeStep'

type Props = {
  step: ICanvasStep
  steps: ICanvasStep[]
}

type Result = {
  hasPrecedingSystemTiles: boolean
  activities: ICanvasStep[]
}

/**
 * Get the system call steps before the current step
 * @param step - The current step
 * @param steps - All steps in the workflow
 * @returns The system call steps before the current step
 */
export const getUpstreamSystemOutcomeVariables = ({ step, steps }: Props): Result => {
  // Get the system call steps before the current step
  const systemStepsBefore = getStepsOfTypeBeforeStep({ step, steps, type: CanvasStepType.Workflow })
  if (!systemStepsBefore.length) return { hasPrecedingSystemTiles: false, activities: [] }
  const hasPrecedingSystemTiles = systemStepsBefore.length > 0

  return {
    hasPrecedingSystemTiles,
    activities: systemStepsBefore,
  }
}
