import { Box } from '@mui/material'
import { OrganizationAsessmentsList, OrganizationAssessmentsListProps } from './OrganizationAssessmentsList'

export interface MainAssessmentsListProps {
  assessmentsByOrgList: OrganizationAssessmentsListProps[]
}

export const MainAsessmentsList: React.FC<MainAssessmentsListProps> = ({ assessmentsByOrgList }) => {
  return (
    <Box display="flex" flexDirection="column" gap={2} width="100%">
      {assessmentsByOrgList.map(orgAssessments => (
        <OrganizationAsessmentsList
          organizationCode={orgAssessments.organizationCode}
          assessmentsList={orgAssessments.assessmentsList}
          // change to id
          key={orgAssessments.organizationCode}
        />
      ))}
    </Box>
  )
}
