import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { CreateProcessingActivityRequestBodyDTO, CreateProcessingActivityResponseBodyDTO } from '@ketch-com/figurehead'

type Params = {
  formData: CreateProcessingActivityRequestBodyDTO
  isV2FeatureFlagEnabled?: boolean
}

export const createProcessingActivity = ({ formData, isV2FeatureFlagEnabled }: Params) => {
  const entityUrl = isV2FeatureFlagEnabled ? `/api/risk-review/processing-activities` : `/api/processing-activities`
  return API.post<CreateProcessingActivityResponseBodyDTO>(
    formatRequestString({
      entityUrl: entityUrl,
    }),
    formData,
  )
}
