import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormikProvider, useFormik } from 'formik'
import { Box } from '@mui/material'
import { ContentGroup, Spinner, UpsertLayout, UpsertLayoutMode } from '@ketch-com/deck'

import { CollectAdditionalInformation } from './components/CollectAdditionalInformation'
import { CustomDataSubjectType } from './components/CustomDataSubjectType'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { SelectDataSubjectType } from './components/SelectDataSubjectType'
import { CopyAuthorizedAgent } from './components/CopyAuthorizedAgent'

// utils
import { getDataSubjectTypeFormValues, useDataSubjectTypeFormValidation } from './utils'
import { RoutesManager } from 'utils/routing/routesManager'

// types
import { MaybeNull } from 'interfaces/common'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { DataSubjectTypeDTO, DataSubjectTypeTemplateDTO } from '@ketch-com/figurehead'
type Props = {
  isReady: boolean
  isEditMode: boolean
  dataSubjectType: MaybeNull<DataSubjectTypeDTO>
  languages: LanguageWithStatusDTO[]
  onSubmit: (values: DataSubjectTypeDTO) => Promise<void>
  templates: DataSubjectTypeTemplateDTO[]
}

export const DataSubjectTypesUpsertView: React.FC<Props> = ({
  isReady,
  isEditMode,
  dataSubjectType,
  languages,
  onSubmit,
  templates,
}) => {
  const navigate = useNavigate()

  const initialValues = useMemo(() => getDataSubjectTypeFormValues({ dataSubjectType }), [dataSubjectType])
  const validationSchema = useDataSubjectTypeFormValidation({ isEditMode })

  const breadcrumbs = isEditMode
    ? [
        { title: 'Settings', link: RoutesManager.settings.root.getURL() },
        { title: 'Subject Types', link: RoutesManager.settings.dataSubjectTypes.root.getURL() },
        { title: dataSubjectType?.name },
        { title: 'Edit' },
      ]
    : [
        { title: 'Settings', link: RoutesManager.settings.root.getURL() },
        { title: 'Subject Types', link: RoutesManager.settings.dataSubjectTypes.root.getURL() },
        { title: 'Create Subject Type' },
      ]

  const formikProps = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  })
  const { submitForm, isSubmitting, validateForm, setFieldTouched } = formikProps

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <FormikProvider value={formikProps}>
        <UpsertLayout
          renderFormTitle={() => (
            <ContentGroup
              title={isEditMode ? 'Edit Subject Type' : 'Create Subject Type'}
              titleVariant="h2"
              isDivider
            />
          )}
          showStepper={false}
          mode={isEditMode ? UpsertLayoutMode.edit : UpsertLayoutMode.create}
          onCancel={() => navigate(RoutesManager.settings.dataSubjectTypes.root.getURL())}
          acceptButtonProps={{
            pending: isSubmitting,
          }}
          cancelButtonProps={{
            pending: isSubmitting,
          }}
          acceptActionButtonText="Save"
          cancelActionButtonText="Cancel"
          onAccept={async () => {
            const errors = await validateForm()
            if (Object.keys(errors).length) {
              Object.keys(errors).forEach(errorKey => {
                setFieldTouched(errorKey, true)
              })
              return
            }
            submitForm()
          }}
        >
          {isReady ? (
            <>
              <SelectDataSubjectType templates={templates} isEditMode={isEditMode} />

              <CopyAuthorizedAgent />

              <CustomDataSubjectType isEditMode={isEditMode} languages={languages} />

              <CollectAdditionalInformation />
            </>
          ) : (
            <Box
              sx={{
                margin: '100px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spinner size="32px" thickness={2.5} />
            </Box>
          )}
        </UpsertLayout>
      </FormikProvider>
    </>
  )
}
