import React, { useContext } from 'react'
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd'
import { useFormikContext } from 'formik'
import { Box, Tooltip, Typography } from '@mui/material'
import { FormTemplateDTO, FormTemplateSectionDTO } from '@ketch-com/figurehead'
import { FormTemplateUpsertContext } from 'pages/consentAndRights/forms/templates/upsert/context/FormTemplateUpsertContextProvider'
import { FormFieldPreviewGrid } from './components'
import { formTemplateSectionIsEmpty } from 'pages/consentAndRights/forms/templates/upsert/utils'
import { SectionDescription } from 'pages/consentAndRights/forms/templates/components'
import { Icon, theme, Chip, IconDictionary } from '@ketch-com/deck'
import { EditableOverlay } from 'pages/consentAndRights/forms/templates/upsert/components/TemplateSections/components/DraggableFormTemplateSectionItem/components/EditableOverlay'

type Props = {
  sectionIndex: number
  provided: DraggableProvided
  snapshot: DraggableStateSnapshot
  formTemplateSection: FormTemplateSectionDTO
}

export const DraggableFormTemplateSectionItem: React.FC<Props> = ({
  sectionIndex,
  provided,
  snapshot,
  formTemplateSection,
}) => {
  const { setActiveSectionIndex, activeSectionIndex, dataSubjectTypes } = useContext(FormTemplateUpsertContext)
  const { values } = useFormikContext<FormTemplateDTO>()

  const section = values?.sections?.[sectionIndex]
  const isSectionEmpty = formTemplateSectionIsEmpty(section)
  const isDescriptionEmpty = !section?.description?.length || section.description === '<p><br></p>'
  const sectionCondition = section?.conditions?.[0] || {}
  const selectedDataSubjectTypes = sectionCondition?.values || []
  const selectedDataSubjectTypesDetails =
    dataSubjectTypes?.filter(dataSubjectType => selectedDataSubjectTypes.includes(dataSubjectType.code || '')) || []
  return (
    <Box
      position="relative"
      ref={provided.innerRef}
      key={sectionIndex}
      display="flex"
      py={1.5}
      mb={2}
      sx={{
        outline: ({ palette }) => (snapshot.isDragging ? `3px dashed ${palette.fadedGrey.main}` : undefined),
      }}
      style={{
        ...provided.draggableProps.style,
      }}
      onClick={() => setActiveSectionIndex(sectionIndex)}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <Box display="flex">
        <Icon name="ODragHandle" iconColor={theme.palette.fadedDarkGrey.main} />
      </Box>
      <EditableOverlay
        active={activeSectionIndex === sectionIndex}
        onClick={() => setActiveSectionIndex('form-title')}
        containerSx={{
          width: '100%',
        }}
        overlayOverride={{
          top: -5,
          right: 0,
          bottom: -10,
          left: 0,
        }}
      >
        <Box display="flex" alignItems="center" flex={1} pl={1} pr={3}>
          {isSectionEmpty && (
            <Box display="flex" flex={1} width="100%">
              <Typography variant="h4" color="fadedDarkGrey.main" component={Box} width="100%">
                Section Title
              </Typography>
            </Box>
          )}

          {!isSectionEmpty && (
            <Box display="flex" flex={1} width="100%" flexDirection="column">
              {/* Section Title */}
              {section?.title ? (
                <Typography variant="h4" color="darkDusk.main" mb={!isDescriptionEmpty ? 0.5 : 2.5}>
                  {section?.title}
                </Typography>
              ) : (
                <Typography variant="h4" color="fadedDarkGrey.main" mb={!isDescriptionEmpty ? 0.5 : 2.5}>
                  Section Title
                </Typography>
              )}
              {/* Section Description */}
              {!isDescriptionEmpty && <SectionDescription description={section?.description} />}
              {/* Section Form Fields */}
              <FormFieldPreviewGrid formFields={formTemplateSection?.formFields} />
            </Box>
          )}
        </Box>
      </EditableOverlay>
      {selectedDataSubjectTypes?.length ? (
        <Box position="absolute" top={12} right={8}>
          <Tooltip
            placement="bottom"
            title={
              <Box display="flex" flexDirection="column" alignItems="flex-start" gap={1}>
                This section is only visible to
                <Box display="flex" flexDirection="column" gap={1.5}>
                  {selectedDataSubjectTypesDetails?.map(subjectType => {
                    return (
                      <Box key={subjectType.code} display="flex" flexDirection="column">
                        <Typography variant="smallLabel">{subjectType.name}</Typography>
                        <Typography variant="smallBody">{subjectType.code}</Typography>
                      </Box>
                    )
                  })}
                </Box>
              </Box>
            }
          >
            <Box>
              <Chip
                icon={<Icon name={IconDictionary.OEyeShow} width={20} height={20} />}
                label={<Typography variant="label">{selectedDataSubjectTypes.length}</Typography>}
                size="small"
              />
            </Box>
          </Tooltip>
        </Box>
      ) : null}
    </Box>
  )
}
