import React, { useCallback, useState } from 'react'
import { FormikProps } from 'formik'
import { compact } from 'lodash'

import { ICanvasStep, IIdentityVerificationActivityParams } from 'pages/orchestration/workflows/edit/interfaces'

import { getIdentityVerificationFormInitialValues, getIdentityVerificationFormValidation } from '../utils'
import { SETTINGS_TAB_VALUES } from '../constants'
import { useAuthenticators } from 'api/authenticators/queries/useAuthenticators'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useAppInstances } from 'api/apps/queries/useAppInstances'
import { useIsEntitled } from 'utils/hooks'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { useUserMe } from 'api/users/queries/useUserMe'
import { getDemoFormLink } from 'utils/helpers/getDemoFormLink'
import { IGetIdentityVerificationInitialValues } from '../utils/getIdentityVerificationFormInitialValues'

type Args = {
  step: ICanvasStep
  steps: Array<ICanvasStep>
  handleSave: (params: { step: ICanvasStep }) => void
}

export const useIdentityVerificationFormUtils = ({ steps, step, handleSave }: Args) => {
  const [activeTab, setActiveTab] = useState<SETTINGS_TAB_VALUES>(SETTINGS_TAB_VALUES.EMAIL_NOTIFICATION)
  const [bodyCursorPos, setBodyCursorPos] = useState([-1, -1])

  const usedCodes = compact(steps.map(({ code }) => code).filter(code => code !== step.code))

  const initialValues = getIdentityVerificationFormInitialValues({ step })
  const validationSchema = getIdentityVerificationFormValidation({ usedCodes })

  const { isEntitled } = useIsEntitled()
  const isEntitledToUseIdMe = isEntitled(ENTITLEMENTS.ID_ME)

  const { data: user } = useUserMe()
  const onContactSalesClick = useCallback(() => {
    const salesLink = getDemoFormLink([ENTITLEMENTS.ID_ME], user || undefined)
    window.open(salesLink, '_blank', 'noreferrer')
  }, [user])

  const { data: authenticators, isLoading: isLoadingAuthenticators } = useAuthenticators({
    itemsPerPage: 1000,
    params: {},
    onError: () => {
      showToast({ content: 'Failed to fetch authenticators', type: 'error' })
    },
  })

  const { data: lexisNexisAppInstances, isLoading: isLoadingLexisNexisAppInstances } = useAppInstances({
    params: {
      appCode: 'lexisnexis',
    },
  })

  const onSubmit = (values: IIdentityVerificationActivityParams) => {
    const handleSavePayload = {
      step: {
        ...step,
        valid: true,
        code: values.code,
        description: values.description,
        activity: {
          ...step.activity,
          params: {
            ...values,
            isCustomIDV: !values.isStandard,
            identityVerificationTimeoutDays: Number(values.identityVerificationTimeoutDays),
          },
        } as ICanvasStep['activity'],
      },
    }

    handleSave(handleSavePayload)
  }

  const updateBodyCursorPos = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    const { selectionStart, selectionEnd } = e.target
    if (
      selectionStart !== null &&
      Number.isInteger(selectionStart) &&
      selectionEnd !== null &&
      Number.isInteger(selectionEnd)
    ) {
      setBodyCursorPos([selectionStart, selectionEnd])
    }
  }

  const appendBodyVariable = (form: FormikProps<IGetIdentityVerificationInitialValues>, text: string): void => {
    const [start, end] = bodyCursorPos
    let bodyText = form.values.body || ''

    if (start > -1 && end > -1 && end >= start) {
      bodyText = `${bodyText.slice(0, start)}${text}${bodyText.slice(end, bodyText.length)}`
      form.setFieldValue('body', bodyText)
      setBodyCursorPos(([start, end]) => [start + text.length, end + text.length])
    } else {
      form.setFieldValue('body', form.values.body + `${text}`)
    }
  }

  const isReady = !isLoadingAuthenticators && !isLoadingLexisNexisAppInstances

  return {
    activeTab,
    setActiveTab,
    bodyCursorPos,
    setBodyCursorPos,
    isReady,
    initialValues,
    validationSchema,
    onSubmit,
    appendBodyVariable,
    updateBodyCursorPos,
    authenticators,
    lexisNexisAppInstances,
    isEntitledToUseIdMe,
    onContactSalesClick,
  }
}
