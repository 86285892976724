import { MaybeNull } from 'interfaces/common'
import { PolicyScopeDTO } from 'interfaces/policyScopes/policyScope'
import { RegulationDTO } from 'interfaces/regulations/regulation'
import { getPossibleRightsFulfillment } from 'utils/helpers/rightsRegulationsHelpers'
import { PolicyScopeFormValues } from '../interfaces'
import { PurposeDTO } from 'interfaces/purposes/purpose'

export const getEditPolicyScopeFormInitialValues = ({
  policyScope,
  regulations,
  purposes,
}: {
  policyScope: MaybeNull<PolicyScopeDTO>
  regulations: RegulationDTO[]
  purposes: PurposeDTO[]
}): PolicyScopeFormValues => {
  const regulationCodes = policyScope?.regulationCodes || []

  const policyScopeRightsFulfillmentValues = (policyScope?.rightsFulfillment || []).map(
    ({ rightCode, fulfillment, allowAppeal, customRightNameID }) => ({
      checked: true,
      rightCode,
      fulfillment,
      allowAppeal,
      customRightNameID,
    }),
  )

  const otherPossibleRightsFulfillmentValues = getPossibleRightsFulfillment({ regulationCodes, regulations }).filter(
    ({ rightCode }) =>
      !policyScopeRightsFulfillmentValues.find(rightFulfillment => rightFulfillment.rightCode === rightCode),
  )

  const rightsFulfillment = [...policyScopeRightsFulfillmentValues, ...otherPossibleRightsFulfillmentValues].map(
    ({ rightCode, fulfillment, checked, allowAppeal, customRightNameID }) => ({
      checked,
      rightCode,
      fulfillment: `${fulfillment}`,
      allowAppeal,
      customRightNameID,
    }),
  )

  // Initialize legalBases and requiresDisplay with existing values from policyScope
  const existingLegalBases = policyScope?.legalBases || {}
  const existingRequiresDisplay = policyScope?.requiresDisplay || {}

  // Populate legalBases and requiresDisplay objects
  const { legalBases, requiresDisplay } = purposes.reduce(
    (acc, purpose) => {
      // Use existing values if available, otherwise set defaults
      acc.legalBases[purpose.ID] = existingLegalBases[purpose.ID] || ''
      acc.requiresDisplay[purpose.ID] = existingRequiresDisplay[purpose.ID] || false
      return acc
    },
    {
      legalBases: {} as Record<string, string>,
      requiresDisplay: {} as Record<string, boolean>,
    },
  )

  const payload = {
    name: policyScope?.name || '',
    code: policyScope?.code || '',
    description: policyScope?.description || '',
    regionCodes: policyScope?.regionCodes || [],
    regulationCodes,
    rightsFulfillment: rightsFulfillment.map(right => ({
      ...right,
      checked: !!policyScope?.rightsFulfillment?.find(({ rightCode }) => rightCode === right?.rightCode) || false,
    })),
    timeToInvokeAppealInDays: policyScope?.timeToInvokeAppealInDays || '30',
    timeToFulfillAppealInDays: policyScope?.timeToFulfillAppealInDays || '45',
    legalBases,
    requiresDisplay,
  }

  return payload
}
